import BorderColorIcon from "@mui/icons-material/BorderColor";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PreviewIcon from "@mui/icons-material/Preview";
import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import AppTable from "../../../components/UI/AppTable";
import Loader from "../../../components/UI/Loader";
import { getIsTimerFinished } from "../../../helperFunctions";
import useAuth from "../../../hooks/useBackAuth";
import {
  getEvalGroupIds,
  getFilteredAssessmentProcedures,
} from "../../../services/Evaluations/EvaluationRegister.service";
import { getRegistryById } from "../../../services/PersonnelManagement.service";
import UserService from "../../../services/userService";
import { getDocumentFormPath } from "../evaluationsHelperFuncs";
import { assessmentProceduresColumns } from "./AssessmentProceduresColumnConfig";
import AssessmentProceduresFilters from "./AssessmentProceduresFilters";
import { useGetTimeArray } from "./data-access/useGetTimeArray";
const AssessmentProcedures = () => {
  const initialFilterValues = {
    name: "",
    surname: "",
    afm: "",
    positionId: "",
    submissionStage: "",
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [userData, setUserData] = useState();

  const employeeId = useParams().id;
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilterValues);
  const navigate = useNavigate();
  const { userInfo, hasRole } = useAuth();
  const handleClick = (row, action) => action.onClickFunc(row);
  const createOnClickFunc = (type, row) => {
    navigate(getDocumentFormPath(row.documentType.code, row.id, row.registry.id), {
      state: {
        action: type,
        data: row,
        employeeId,
        evaluatorData: {
          id: userData?.id,
          name: userData?.name,
          surname: userData?.surname,
          afm: userData?.afm,
        },
      },
    });
  };
  const { data: timeArray, isLoading: timeArrayIsLoading } =
    useGetTimeArray(true);
  const actionIcons = [
    {
      id: "edit",
      icon: <BorderColorIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("edit", row),
    },
    {
      id: "preview",
      icon: <PreviewIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("preview", row),
    },
    {
      id: "print",
      icon: <PictureAsPdfIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("print", row),
    },
  ];
  const getIsActive = (row, id) => {
    const isTimerFinished = getIsTimerFinished(
      row?.documentType?.code,
      row?.submissionStage?.code,
      timeArray
    );
    switch (id) {
      case "edit":
        return (
          +employeeId === +userInfo?.id &&
          (hasRole("admin") || hasRole("supervisor")) &&
          !isTimerFinished
        );
      case "preview":
        return +employeeId === +userInfo?.id ?
       row?.isFinalized : isTimerFinished  ;
      case "print":
        return +employeeId === +userInfo?.id ?
        row?.isFinalized : isTimerFinished  ;
      default:
    }
  };
  const getEvaluationGroups = async (data) => {
    return await getEvalGroupIds(
      data?.supervisorEvaluationGroup?.evaluationGroup?.id ||
      data?.registryEvaluationGroup?.evaluationGroup?.id
    )
  };
  useEffect(() => {
  
    const fetchAndSetAssessmentDocs = async (supervisorGroupId, evalGroups) => {
      const institutionId =
        userData?.serviceAccount?.institute?.id ||
        UserService.profile()?.attributes?.instituteId?.[0];
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      setIsLoading(true);
      const allRegistries = await getFilteredAssessmentProcedures({
        page,
        size: rowsPerPage,
        referenceYears: nonEmptyFilters.referenceYear,
        institutionIds: institutionId,
        name: nonEmptyFilters.name,
        surname: nonEmptyFilters.surname,
        afm: nonEmptyFilters.afm,
        evaluationGroupIds: hasRole("SuperAdmin")
          ? null
          : hasRole("admin") && +userInfo?.id == +employeeId
          ? [
              ...evalGroups,
              supervisorGroupId,
            ]
          : supervisorGroupId,
        positionIds: nonEmptyFilters.positionId,
        submissionStageIds: nonEmptyFilters.submissionStage,
        isSupervisor: hasRole("admin") && +userInfo?.id == +employeeId,
      });
      setCount(allRegistries?.count);
      setData(allRegistries.data);
      setIsLoading(false);
    };
    const fetchAndSetRegistry = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
      const evalGroups = hasRole("admin") && +userInfo?.id == +employeeId && ( data?.supervisorEvaluationGroup?.evaluationGroup?.id ||
      data?.registryEvaluationGroup?.evaluationGroup?.id) && await getEvaluationGroups(data)
      setIsLoading(false);
      data?.supervisorEvaluationGroup?.evaluationGroup?.id &&
        fetchAndSetAssessmentDocs(data?.supervisorEvaluationGroup?.evaluationGroup?.id, evalGroups);
    };
    fetchAndSetRegistry();
  }, [page, rowsPerPage, filters, employeeId]);
  return (
    <Box>
      <Box>
        {userData && (
          <AppBreadcrumbs
            breadcrumbsList={[
              {
                title: "Μητρώο Αξιολόγησης Προσωπικού",
                url: "/personnel-registry-evaluation",
              },
              {
                title: `Καρτέλα Αξιολογήσεων: ${userData.surname}  ${userData.name}  ${userData.afm} `,
                url: `/evaluation-card/${employeeId}`,
              },
              {
                title: ` Διενέργειες Αξιολογήσεων`,
                url: ``,
              },
            ]}
          />
        )}
      </Box>
      <h2>Προβολή Διενεργειών Αξιολογήσεων (ως Προϊστάμενος - Αξιολογητής)</h2>
      <Divider sx={{ bgcolor: "black.main" }} />
      <AssessmentProceduresFilters setFilters={setFilters} filters={filters} />
      {userData && (
        <h3>{`Στοιχεία Αξιολογητή:  ${userData.surname}  ${userData.name},  ${userData.afm},  ${userData.serviceAccount.position.description},  ${userData.serviceAccount.institute.description} `}</h3>
      )}

      {isLoading || timeArrayIsLoading ? (
        <Loader />
      ) : userData?.supervisorEvaluationGroup ? (
        <AppTable
          noDataMsg={"Δεν βρέθηκαν έντυπα αξιολόγησης."}
          isLoading={isLoading}
          actionIcons={actionIcons
            .filter((action) => action)
            .map((action) => ({
              key: action.id,
              icon: action.icon,
              onClickFunc: (row) => handleClick(row, action),
              disabled: (row) => !getIsActive(row, action.id),
            }))}
          columns={assessmentProceduresColumns}
          rows={data}
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : (
        <Typography>
          {` Ο/Η ${userData.surname}  ${userData.name} δεν είναι αξιολογητής/τρια σε κάποια ομάδα αξιολόγησης`}
        </Typography>
      )}
    </Box>
  );
};
export default AssessmentProcedures;
