import HttpService from "../httpClient";
export const addNewServiceActionRegistry = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-service-action`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  export const addNewServiceActionRegistries = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-service-action/multiple/registries`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  export const getServiceActionRegistriesById = (
    page,
    size,
    registryId
  ) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .get(`/registry-service-action`, {
          params: {
            page,
            size,
            "registryId.equals":registryId,
          },
        })
        .then(function (response) {
          const allRegistries = {
            data: response.data,
            count: +response.headers["x-total-count"],
          };
          resolve(allRegistries);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  export const deleteServiceActionRewardRegistryById = (registryId) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .delete(`/registry-service-action/${registryId}`)
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };