import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckboxElement, FormContainer, useForm } from "react-hook-form-mui";
import { useNavigate, useParams } from "react-router-dom";
import EmployeeCard from "../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import PageTitle from "../../components/PageTitle";
import AppAccordion from "../../components/UI/AppAccordion";
import Loader from "../../components/UI/Loader";
import useAuth from "../../hooks/useBackAuth";
import usePostRequest from "../../hooks/useHttpCallHandler";
import { getEvaluationGroupData } from "../../services/Evaluations/SuggestionToolsInspections.service";
import { getDocumentInfo, postDevelopmentDoc } from "./data-access/gateway";
import { useGetRegistryInfoById } from "./data-access/useGetRegistryInfoById";
import { useGetSkills } from "./data-access/useGetSkills";
import DevelopmentAccordionItem from "./shared/ui/DevelopmentAccordionItem";
import ΕvaluationReportPeriod from "./shared/ui/DevelopmentDocPeriod";
import { router } from "../../App";
import InfoBox from "../shared/ui/InfoBox";
import { yupResolver } from "@hookform/resolvers/yup";
import developmentValidation from "./developmentValidation";
import AppModal from "../../components/UI/Modal/AppModal";
function DevelopmentDocument() {
  const { docId: id, registryId } = useParams();
  const navigate = useNavigate();
  const { userInfo, referenceUserInfo } = useAuth();
  const [evaluatorData, setEvaluatorData] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const [documentInfoIsLoading, setDocumentInfoIsLoading] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState();
  const currentYear = new Date().getFullYear();
  const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } =
    useGetRegistryInfoById(
      documentInfo?.data?.documentInfo?.registry?.id || registryId
    );
  const { data: skills, isLoading: skillsIsLoading } = useGetSkills();
  const mappedSkills =
    !skillsIsLoading &&
    skills?.data["growth_plan_type"]?.map((skill) => ({
      value: skill.id.toString(),
      label: skill.description,
    }));
  const submissionStage = id
    ? documentInfo?.data?.documentInfo?.submissionStage?.code
    : "stage:1";

  const getResetValues = (id, skillId) => {
    const devData = getDevData(skillId);
    return {
      [`isDeveloped${id}`]: devData?.isDeveloped ? "developed" : "developing",
      [`reason${id}`]: devData ? devData.reason : "",
      [`method${id}`]: devData ? devData.developmentMethod : "",
      [`description${id}`]: devData ? devData.description : "",
      [`implementation${id}`]: devData ? devData.implementationSchedule : "",
    };
  };
  const dataTransform = (data, documentInfo) => {
    const growthPlanFormDetails = [];

    const updateGrowthPlanFormDetails = () => {
      console.log(data, "data[i]");
      for (let i = 1; i <= 8; i++) {
        growthPlanFormDetails.push({
          growthPlanType: {
            id: i * 50 + 14001, // Assuming you want to start the growthPlanType.id from 14051
          },
          isDeveloped: data[`isDeveloped${i}`] === "developed",
          reason: data[`reason${i}`],
          developmentMethod: data[`method${i}`],
          description: data[`description${i}`],
          implementationSchedule: data[`implementation${i}`],
        });
      }
      return growthPlanFormDetails;
    };
    const preData = () => {
      switch (submissionStage) {
        case "stage:1": {
          return {
            documentInfo: {
              referenceYear: currentYear + 1,
              startDate: data.startDate,
              endDate: data.endDate,
              documentType: {
                id: 13851,
                code: null,
                description: "Έντυπο Σχεδίου Ανάπτυξης",
              },
              submissionStage: {
                id: 6101,
                code: "stage:1",
                description: "Στάδιο 1ο",
              },
              registry: registryInfoDetails?.data,
              position: {
                id: registryInfoDetails?.data?.serviceAccount?.position?.id,
              },
              institution: {
                id: registryInfoDetails?.data?.serviceAccount?.institute?.id,
              },
              evaluationGroup: {
                id: userInfo?.supervisorEvaluationGroup?.evaluationGroup?.id,
              },
            },
            startDate: data.startDate,
            endDate: data.endDate,
            meetingDate: data.meetingDate,
            formProtocolNumber: data.formProtocolNumber,
            isConfirmed: data.isConfirmed,
            growthPlanFormDetails: updateGrowthPlanFormDetails(),
          };
        }
        case "stage:2": {
          return {
            ...documentInfo?.data,
            startDate: data?.startDate,
            endDate: data?.endDate,
            reviewDate: data?.reviewDate,
            isConfirmed: data?.isConfirmed,
            isUpdated: data?.isUpdated,
            growthPlanFormDetails: updateGrowthPlanFormDetails(),
          };
        }

        case "stage:3": {
          return {
            ...documentInfo.data,
            startDate: data.startDate,
            endDate: data.endDate,
            evaluationDate: data.evaluationDate,
            isConfirmed: data.isConfirmed,
            isUpdated: data.isUpdated,
            growthPlanFormDetails: updateGrowthPlanFormDetails(),
          };
        }
        default:
      }
    };
    const transformedData = preData();
    console.log(transformedData);
    return transformedData;
  };
  const {
    handlePostRequest,
    isLoading: isPostLoading,
    error,
  } = usePostRequest(postDevelopmentDoc);
  const formContext = useForm({
    resolver: yupResolver(developmentValidation(submissionStage)),
  });
  const getDevData = (skillId) => {
    return documentInfo?.data?.growthPlanFormDetails?.find(
      (grPlan) => grPlan.growthPlanType.id === skillId
    );
  };
  useEffect(() => {
    const fetchAndEvaluatorData = async () => {
      const evaluationGroupResults = await getEvaluationGroupData(
        userInfo?.supervisorEvaluationGroup?.evaluationGroup?.id
      );
      const evaluator = evaluationGroupResults.registries.find(
        (registry) => registry.isEvaluationGroupSupervisor === true
      );

      setEvaluatorData(evaluator);
    };
    const fetchDocInfoData = async () => {
      setDocumentInfoIsLoading(true);
      const infoData = await getDocumentInfo(id);
      setDocumentInfo(infoData);
      setDocumentInfoIsLoading(false);
    };
    fetchAndEvaluatorData();
    id && fetchDocInfoData();
  }, [id]);
  useEffect(() => {
    if (documentInfoIsLoading) return;
    !id
      ? formContext.reset({
          referenceYear: currentYear + 1,
        })
      : formContext.reset({
          referenceYear: currentYear + 1,
          startDate: documentInfo?.data?.startDate,
          endDate: documentInfo?.data?.endDate,
          reviewDate: documentInfo?.data?.reviewDate,
          evaluationDate: documentInfo?.data?.evaluationDate,
          meetingDate: documentInfo?.data?.meetingDate,
          submissionDate: documentInfo?.data?.submissionDate,
          formProtocolNumber: documentInfo?.data?.formProtocolNumber,
          isConfirmed: documentInfo?.data?.isConfirmed,
          isUpdated: documentInfo?.data?.isUpdated,
          ...getResetValues(1, 14051),
          ...getResetValues(2, 14101),
          ...getResetValues(3, 14151),
          ...getResetValues(4, 14201),
          ...getResetValues(5, 14251),
          ...getResetValues(6, 14301),
          ...getResetValues(7, 14351),
          ...getResetValues(8, 14401),
        });
  }, [documentInfoIsLoading]);
  const handlePost = async (data) => {
    await handlePostRequest(dataTransform(data, documentInfo));

    navigate(
      `/evaluation-card/${registryInfoDetails?.data?.id}/assessment-procedures`
    );

    setIsModalOpen(false);
  };
  if (registryInfoDetailsIsLoading) return <Loader />;
  return (
    <Box mt={17}>
      <AppBreadcrumbs
        breadcrumbsList={[
          {
            title: "Μητρώο Αξιολόγησης Προσωπικού",
            url: "/personnel-registry-evaluation",
          },
          {
            title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${referenceUserInfo?.name} - ${referenceUserInfo?.afm}`,
            url: `/evaluation-card/${referenceUserInfo?.id}`,
          },
          {
            title: "Διενέργειες Αξιολογήσεων",
            url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
          },
          {
            title: `Έντυπο Σχεδίου Ανάπτυξης:${registryInfoDetails?.data?.surname} ${registryInfoDetails?.data?.name} - ${registryInfoDetails?.data?.afm}`,
          },
        ]}
      />
      <Box></Box>
      <PageTitle title={"Έντυπο Σχεδίου Ανάπτυξης"} />
      <InfoBox
        content={
          "Επιλέξτε 3 Δεξιότητες οι οποίες αξιολογούνται ως Αναπτυγμένες. Στη συνέχεια, επιλέξτε υποχρεωτικά τις υπόλοιπες Δεξιότητες Προς Ανάπτυξη."
        }
      />

      <FormContainer
        formContext={formContext}
        onSuccess={(data) => {
          setFormData(data);
          setIsModalOpen(true);
        }}
      >
        <Typography variant="h3"> Στοιχεία Αξιολογούμενου </Typography>
        <Box display="flex" justifyContent="center" my={2}>
          <EmployeeCard data={registryInfoDetails?.data}>
            <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
          </EmployeeCard>
        </Box>

        <ΕvaluationReportPeriod stage={submissionStage} />
        <CheckboxElement
          disabled={submissionStage === "stage:1"}
          name={"isUpdated"}
          label={"Επιλέξτε σε περίπτωση επικαιροποίησης του Σχεδίου Ανάπτυξης"}
        />
        <CheckboxElement
          name={"isConfirmed"}
          label={
            "Επιβεβαίωση ότι η κατάρτιση του Σχεδίου έγινε σε συνεργασία με τον Αξιολογούμενο"
          }
        />

        {mappedSkills && !skillsIsLoading ? (
          <Box sx={{ marginY: 3 }}>
            {mappedSkills?.map((skill, index) => {
              return (
                <AppAccordion
                  key={index}
                  id={index}
                  title={skill.label}
                  details={<DevelopmentAccordionItem id={index + 1} />}
                />
              );
            })}
          </Box>
        ) : (
          <Loader />
        )}
        <Box sx={{ marginY: 6 }}>
          <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
          {evaluatorData && (
            <Box display="flex" justifyContent="center" my={2}>
              <EmployeeCard data={evaluatorData}>
                <RegistrationEmployeeInfo data={evaluatorData} />
              </EmployeeCard>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginY: 3,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => router.navigate(-1)}
          >
            Επιστροφή
          </Button>
          {
            <Button variant="contained" type="submit">
              Οριστικοποίηση
            </Button>
          }
        </Box>
      </FormContainer>
      {isModalOpen && (
        <AppModal onHideModal={() => setIsModalOpen(false)}>
          <Box sx={{ zIndex: "1000" }}>
            <h5>
              Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποιήσετε το Έντυπο
              Σχεδίου Ανάπτυξης;
            </h5>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 6,
              }}
            >
              <Button onClick={() => setIsModalOpen(false)}>Ακύρωση</Button>
              <Button
                onClick={() => handlePost(formData)}
                type="submit"
                variant="contained"
              >
                Ναι
              </Button>
            </Box>
          </Box>
        </AppModal>
      )}
    </Box>
  );
}

export default DevelopmentDocument;
