import { format } from "date-fns";
export const transformUserDataToFEFormat = (data) => {
  const {
    name,
    surname,
    fathersName,
    fathersSurname,
    mothersName,
    mothersSurname,
    dateOfBirth,
    placeOfBirth,
    adt,
    issuingAuthority,
    afm,
    bloodType,
    amka,
    payrollAccountNumber,
    iban,
    serviceAccount,
    children,
    spouse,
    address,
    phone,
  } = data;
  console.log(phone, 'phone')
  const transformedData = {
    surname,
    name,
    fathersName,
    fathersSurname,
    mothersName,
    mothersSurname,
    dateOfBirth: dateOfBirth,
    placeOfBirth,
    adt,
    issuingAuthority,
    afm,
    amka,
    bloodType,
    city: address[address.length - 1] && address[address.length - 1].city,
    region: address[address.length - 1] && address[address.length - 1].region,
    street: address[address.length - 1] && address[address.length - 1].street,
    numberOfStreet:
      address[address.length - 1] && address[address.length - 1].numberOfStreet,
    postalCode:
      address[address.length - 1] && address[address.length - 1].postalCode,
    telephone:
      phone.find((entry) => entry.phoneType.id === 4251)
        ?.phoneNumber || "",
    mobile:
      phone.find((entry) => entry.phoneType.id === 4201)
        ?.phoneNumber || "",
    contactNumber:
      phone.find(
        (entry) => entry.phoneType.id === 4301
      )?.phoneNumber || "",
    payrollAccountNumber,
    payrollIBAN: iban,
    wives: spouse.map(
      ({ spouseFirstName, spouseLastName, dateOfWedding, endDate }) => ({
        spouseFirstName,
        spouseLastName,
        weddingDate: dateOfWedding,
        // expWeddingDate: endDate,
      })
    ),
    children: children.map(
      ({
        name: childFirstName,
        surname: childLastName,
        dateOfBirth: childDateOfBirth,
      }) => ({
        childFirstName,
        childLastName,
        childDateOfBirth,
      })
    ),
    grade: serviceAccount?.grade?.description,
    position: serviceAccount.position.description,
    institution: serviceAccount.institute.description,
    guardType: serviceAccount.isInternalGuard
      ? "Internal Guard"
      : "External Guard",
  };
  console.log("transformedData", transformedData);
  return transformedData;
};
export const transformUserDataToBEFormat = (data, BEConfigInfo) => {
  console.log(data, 'mydata')
  const {
    postalCode,
    region,
    city,
    numberOfStreet,
    street,
    adt,
    afm,
    amka,
    bloodType,
    contactNumber,
    dateOfBirth,
    fathersName,
    fathersSurname,
    grade,
    guardType,
    institution,
    issuingAuthority,
    mobile,
    mothersName,
    mothersSurname,
    name,
    payrollAccountNumber,
    payrollIBAN,
    placeOfBirth,
    position,
    positionCode,
    surname,
    telephone,
    serviceAccount
  } = data;
  console.log('spam', serviceAccount?.position, serviceAccount);
  // Create the transformed object
  const transformedData = {
    name,
    surname,
    address: [{ postalCode, region, city, numberOfStreet, street }],
    fathersName,
    fathersSurname,
    mothersName,
    mothersSurname,
    dateOfBirth:
      dateOfBirth && dateOfBirth instanceof Date
        ? dateOfBirth.toLocaleString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).split('/').reverse().join('-')
        : dateOfBirth,
    placeOfBirth,
    adt,
    issuingAuthority,
    afm,
    bloodType,
    amka,
    payrollAccountNumber,
    iban: payrollIBAN,
    serviceAccount: {
      id: serviceAccount?.id || null,
      grade: { id: serviceAccount ? serviceAccount.grade.id : +grade },
      position: { id: serviceAccount ? serviceAccount.position.id : +position, code: positionCode },
      institute: { id: serviceAccount ? serviceAccount.institute.id : +institution },
      isInternalGuard: guardType === "internal",
    },
    children: !data.childFirstName0 ? [] : transformKids(data),
    spouse: !data.spouseFirstName0 ? [] : transformWives(data),
    phone: [
      {
        phoneType: BEConfigInfo.phone_type.find(
          (phoneTypeItem) => phoneTypeItem.id === 4201
        ),
        phoneNumber: mobile,
      },
      {
        phoneType: BEConfigInfo.phone_type.find(
          (phoneTypeItem) => phoneTypeItem.id === 4251
        ),
        phoneNumber: telephone,
      },
      {
        phoneType: BEConfigInfo.phone_type.find(
          (phoneTypeItem) => phoneTypeItem.id === 4301
        ),
        phoneNumber: contactNumber,
      },
    ],
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
const transformKids = (data) => {
  console.log(data);
  const children = [];
  for (
    let i = 0;
    i <
    Object.keys(data).filter((item) => item.includes("childFirstName")).length;
    i++
  ) {
    const child = {
      name: data[`childFirstName${i}`]
        ? data[`childFirstName${i}`]
        : data.children[i] && data.children[i].childFirstName,
      surname: data[`childLastName${i}`]
        ? data[`childLastName${i}`]
        : data.children[i] && data.children[i].childLastName,
      dateOfBirth: data[`childDateOfBirth${i}`]
        ? data[`childDateOfBirth${i}`].toLocaleString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).split('/').reverse().join('-')
        : data.children[i] && data.children[i].childDateOfBirth,
    };
    if (child.dateOfBirth) {
      children.push(child);
    }
  }
  return children;
};
const transformWives = (data) => {
  const wives = [];
  for (
    let i = 0;
    i <
    Object.keys(data).filter((item) => item.includes("spouseFirstName")).length;
    i++
  ) {
    const spouse = {
      spouseFirstName:
        data[`spouseFirstName${i}`] && data[`spouseFirstName${i}`],
      spouseLastName: data[`spouseLastName${i}`] && data[`spouseLastName${i}`],
      dateOfWedding:
        data[`weddingDate${i}`] && data[`weddingDate${i}`].toLocaleString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).split('/').reverse().join('-'),
      // endDate:
      //   data[`expWeddingDate${i}`] &&
      //   data[`expWeddingDate${i}`].toLocaleString('en-GB', {
      //     year: 'numeric',
      //     month: '2-digit',
      //     day: '2-digit'
      //   }).split('/').reverse().join('-'),
    };
    if (spouse.dateOfWedding || spouse.endDate) {
      wives.push(spouse);
    }
  }
  return wives;
};
export const formatDateTime = (key, data) => {
  console.log(key, data, 'form')
  return key.toLowerCase().includes("date")
    ? format(data[key], 'yyyy-MM-dd')
    : key.toLowerCase().includes("time")
      ? format(data[key], 'hh:mm')
      : data[key];
};
export const registrationDetailsTransformer = (formSc, data) => {
  console.log(data);
  const labels = ["Ονοματεπώνυμο - ΑΦΜ", "Θέση"];
  const values = [data.userInfo, data.userPosition];
  Object.values(formSc).map((key) => {
    labels.push(key.label);
    values.push(formatDateTime(key.name, data));
  });

  return { labels: labels, values: values };
};

export const transformEducationDataToBEFormat = (data, BEConfigInfo) => {
  console.log(data, BEConfigInfo, "hello there");
  const {
    registry,
    institution,
    position,
    durationInHours,
    educationTitle,
    grade,
    notes,
    scale,
    startDate,
    endDate,
    typeOfEducation,
  } = data;
  const transformedData = {
    registry,
    education: {
      isInternalGuard: registry.isInternalGuard,
      educationTitle,
      degreeGrade: grade,
      gradingScale: scale,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      durationInHours,
      notes,
      educationType: BEConfigInfo.find(
        (educationType) => (educationType.id = typeOfEducation)
      ),
    },
    institution,
    position,
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
export const transformIntangibleRewardDataToBEFormat = (data, BEConfigInfo) => {
  const { registry, intangibleRewardDate, intangibleRewardType, order, notes } =
    data;
  console.log(intangibleRewardType, 'intangibleRewardType', BEConfigInfo["intouchable_fee_type"])
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    dateOfFee: format(intangibleRewardDate, 'yyyy-MM-dd'),
    orderNumber: order,
    notes,
    category: BEConfigInfo["intouchable_fee_type"].find((intouchableFeeType) =>
      (intouchableFeeType.id === +intangibleRewardType)
    ),
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
export const transformServicePositionDataToBEFormat = (data, BEConfigInfo) => {
  const { registry, servicePositionDate, servicePositionType, serviceOrder, notes } =
    data;
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    actionDate: format(servicePositionDate, 'yyyy-MM-dd'),
    formProtocolNumber: serviceOrder,
    notes,
    positionType: BEConfigInfo["position"].find((positionType) =>
      (positionType.id === +servicePositionType)
    ),
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};


export const transformServiceGradeDataToBEFormat = (data, BEConfigInfo) => {
  const { registry, serviceGradeDate, serviceGradeType, serviceOrder, notes } =
    data;
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    actionDate: format(serviceGradeDate, 'yyyy-MM-dd'),
    formProtocolNumber: serviceOrder,
    notes,
    gradeType: BEConfigInfo["grade_type"].find((gradeType) =>
      (gradeType.id === +serviceGradeType)
    ),
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
export const transformEducationLevelDataToBEFormat = (data, BEConfigInfo) => {
  const { registry, educationLevel, certificationTitle, certificationGrade, startDate, endDate, notes } =
    data;
  console.log(BEConfigInfo["educational_level_type"], ' BEConfigInfo["educational_level_type"]', educationLevel)
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    educationalLevelType: BEConfigInfo["educational_level_type"].find((educationLevelType) =>
      (educationLevelType.id === +educationLevel)
    ),

    certificateTitle: certificationTitle,
    grade: certificationGrade,

    notes,
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
export const transformOvertimeDataToBEFormat = (data, BEConfigInfo) => {
  console.log(data, 'transformOvertimeDataToBEFormat')
  const {
    registry,
    institution,
    position,
    overtimeDate,
    overtimeStart,
    overtimeEnd,
    reason,
    notes,
  } = data;
  console.log(format(overtimeStart, 'hh:mm', { timeZone: 'America/New_York' }), 'overtimeStart')
  const transformedData = {
    registry,
    institution,
    position,
    overtime: {
      isInternalGuard: registry.isInternalGuard,
      overtimeDate: format(overtimeDate, 'yyyy-MM-dd'),
      overtimeStart: format(overtimeStart, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      overtimeEnd: format(overtimeEnd, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      notes,
      overtimeReason: BEConfigInfo["overtime_reason"].find((overtimeType) =>
        typeof reason === "number"
          ? (overtimeType.id = reason)
          : (overtimeType.description = reason)
      ),
    },
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};

export const transformServiceActionDataToBEFormat = (data, BEConfigInfo) => {
  console.log("hello", data);

  const {
    registry,
    institution,
    position,
    serviceActionDate,
    serviceActionType,
    notes,
  } = data;
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    institution,
    position,
    serviceAction: {
      actionDate: format(serviceActionDate, 'yyyy-MM-dd'),
      notes,
      actionType: BEConfigInfo["action_type"].find(
        (actionType) => (actionType.id = serviceActionType)
      ),
    },
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
export const transformServiceChangeDataToBEFormat = (data, BEConfigInfo) => {
  const {
    registry,
    serviceChangeDate,
    serviceChangeType,
    institution,
    position,
    order,
    notes,
  } = data;
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    institution: BEConfigInfo.institutionsTypes.find(
      (institutionType) => (institutionType.id === +institution)
    ),
    position,
    serviceModification: {
      serviceDate: format(serviceChangeDate, 'yyyy-MM-dd'),
      notes,
      orderNumber: order,
      institution: BEConfigInfo.institutionsTypes.find(
        (institutionType) => (institutionType.id === +institution)
      ),
      typeOfService: BEConfigInfo.serviceChangeTypes["type_of_service"].find(
        (changeType) => (changeType.id = serviceChangeType)
      ),
    },
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};

export const transformSwitchDataToBEFormat = (data, BEConfigInfo) => {
  console.log(BEConfigInfo);
  const { registry, institution, position, switchDate, switchType, notes } =
    data;
  const transformedData = {
    registry,
    isInternalGuard: registry.isInternalGuard,
    institution,
    position,
    transfer: {
      transferDate: format(switchDate, 'yyyy-MM-dd'),
      notes,
      transferType: BEConfigInfo["transfer_type"].find(
        (typeOfSwitch) => (typeOfSwitch.id = switchType)
      ),
    },
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};


