export const initialFieldValues = {
  name: "",
  surname: "",
  fathersName: "",
  fathersSurname: "",
  mothersName: "",
  mothersSurname: "",
  dateOfBirth: "",
  placeOfBirth: "",
  adt: "",
  issuingAuthority: "",
  afm: "",
  amka: "",
  bloodType: "",
  street:"",
  numberOfStreet: "",
  postalCode: "",
  city: "",
  region: "",
  telephone: "",
  mobile: "",
  contactNumber: "",
  payrollAccountNumber: "",
  payrollIBAN: "",
  grade: "",
  position: "",
  institution: "",
  // guardType: "",
};

export const initialFormSchema = {
  id: {
    name: "id",
    label: "id*",
    value:'spam',
  },
  surname: {
    name: "surname",
    label: "Επώνυμο*",

  },
  name: {
    name: "name",
    label: " Όνομα*",

  },
  fathersName: {
    name: "fathersName",
    label: " Όνομα Πατρός*",
  },
  fathersSurname: {
    name: "fathersSurname",
    label: "Επώνυμο Πατρός*",
  },
  mothersName: {
    name: "mothersName",
    label: " Όνομα Μητρός*",
  },
  mothersSurname: {
    name: "mothersSurname",
    label: "Επώνυμο Μητρός*",
  },
  dateOfBirth: {
    name: "dateOfBirth",
    label: "Hμ.Γέννησης*",
  },
  placeOfBirth: {
    name: "placeOfBirth",
    label: "Τόπος Γέννησης*",
  },
  adt: {
    name: "adt",
    label: "ΑΔΤ*",
  },
  issuingAuthority: {
    name: "issuingAuthority",
    label: "Εκδούσα Αρχή ΑΔΤ*",
  },
  afm: {
    name: "afm",
    label: "ΑΦΜ*",
  },
  amka: {
    name: "amka",
    label: "ΑΜΚΑ*",
  },
  bloodType: {
    name: "bloodType",
    label: "Ομάδα Αίματος*",
  },
  street: {
    name: "street",
    label: "Οδός*",
  },
  numberOfStreet: {
    name: "numberOfStreet",
    label: "Αριθμός*",
  },
  postalCode: {
    name: "postalCode",
    label: "Τ.Κ.*",
  },
  city: {
    name: "city",
    label: "Πόλη*",
  },
  region: {
    name: "region",
    label: "Περιοχή*",
  },
  telephone: {
    name: "telephone",
    label: "  Τηλέφωνο Κατοικίας*",
  },
  mobile: {
    name: "mobile",
    label: " Κινητό Τηλέφωνο*",
  },
  contactNumber: {
    name: "contactNumber",
    label: " Τηλέφωνο Επικοινωνίας",
  },
  payrollAccountNumber: {
    name: "payrollAccountNumber",
    label: "Αρ.Λογαριασμού Μισθοδοσίας*",
  },
  payrollIBAN: {
    name: "payrollIBAN",
    label: "IBAN Λογαριασμού Μισθοδοσίας*",
  },
  wives: [
    {
      spouseFirstName: {
        name: "spouseFirstName0",
        label: " Όνομα Συζύγου",
      },
      spouseLastName: {
        name: "spouseLastName0",
        label: "Επώνυμο Συζύγου",
      },
      weddingDate0: {
        name: "weddingDate0",
        label: "Ημερομηνία Τέλεσης Γάμου",
      },
      // expWeddingDate0: {
      //   name: "expWeddingDate0",
      //   label: "Ημερομηνία Λήξης Γάμου",
      // },
    },
  ],
  children: [
    {
      childFirstName0: {
        name: "childFirstName0",
        label: " Όνομα Τέκνου",
      },
      childLastName0: {
        name: "childLastName0",
        label: "Επώνυμο Τέκνου",
      },
      childDateOfBirth0: {
        name: "childDateOfBirth0",
        label: "Ημερομηνία Γέννησης Τέκνου",
      },
    },
  ],
  grade: {
    name: "grade",
    label: "Βαθμός*",
  },
  position: {
    name: "position",
    label: "Θέση στο κατάστημα κράτησης *",
  },
  institution: {
    name: "institution",
    label: "Kατάστημα κράτησης*",
  },
  // guardType: {
  //   name: "guardType",
  //   label: "Εσωτερική/Εξωτερική Φρούρηση*",
  // },
};

export const personalDataFieldNames = [
  "name",
  "surname",
  "fathersName",
  "fathersSurname",
  "mothersName",
  "mothersSurname",
  "dateOfBirth",
  "placeOfBirth",
  "adt",
  "issuingAuthority",
  "afm",
  "amka",
];
export const contactDataFieldNames = [
  "street",
  "numberOfStreet",
  "postalCode",
  "city",
  "region",
  "telephone",
  "mobile",
  "contactNumber",
];
export const payrollDataFieldNames = ["payrollAccountNumber", "payrollIBAN"];
export const familyDataFieldNames = [
  "spouseFirstName",
  "spouseLastName",
  "weddingDate",
  // "expWeddingDate",
];
export const professionalDAtaFieldNames = ["grade", "position", "institution"];

export const getNewChildFieldNames = (numOfChild) => {
  const newChild = {
    [`childFirstName${numOfChild}`]: {
      name: `childFirstName${numOfChild}`,
      label: " Όνομα Τέκνου",
    },
    [`childLastName${numOfChild}`]: {
      name: `childLastName${numOfChild}`,
      label: "Επώνυμο Τέκνου",
    },
    [`childDateOfBirth${numOfChild}`]: {
      name: `childDateOfBirth${numOfChild}`,
      label: "Ημερομηνία Γέννησης Τέκνου",
    },
  };
  return newChild;
};
export const getNewWifeFieldNames = (numOfWives) => {
  const newWife = {
    [`spouseFirstName${numOfWives}`]: {
      name: `spouseFirstName${numOfWives}`,
      label: "Όνομα Συζύγου",
    },
    [`spouseLastName${numOfWives}`]: {
      name: `spouseLastName${numOfWives}`,
      label: "Επώνυμο Συζύγου",
    },
    [`weddingDate${numOfWives}`]: {
      name: `weddingDate${numOfWives}`,
      label: "Ημερομηνία Τέλεσης Γάμου",
    },
    // [`expWeddingDate${numOfWives}`]: {
    //   name: `expWeddingDate${numOfWives}`,
    //   label: "Ημερομηνία Λήξης Γάμου",
    // },
  };
  return newWife;
};
