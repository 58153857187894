import * as yup from 'yup';

const formValidationSchema = yup.object().shape({
    creationDate: yup.date().required('Ημερομηνία Δημιουργίας Ομάδας είναι υποχρεωτική').max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της σημερινής")
    ,
    groupName: yup.string().required('Όνομα Ομάδας είναι υποχρεωτικό'),
    isInternalGuard: yup.string().oneOf(['1', '2'], 'Επιλέξτε μία από τις επιλογές Εσωτερική Φρούρηση ή Εξωτερική Φρούρηση'),
    superVisor: yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
        details: yup.object(),
    }).required('Αξιολογητής είναι υποχρεωτικός'),
    registries: yup.array().of(
        yup.object().shape({
            id: yup.string().required(),
            label: yup.string().required(),
            details: yup.object(),
        })
    ).min(1, 'Τουλάχιστον ένα Μητρώο είναι υποχρεωτικό'),
});

export default formValidationSchema;
