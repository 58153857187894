import BorderColorIcon from "@mui/icons-material/BorderColor";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PreviewIcon from "@mui/icons-material/Preview";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import Loader from "../../../components/UI/Loader";
import { getSuggestionToolsInspections } from "../../../services/Evaluations/SuggestionToolsInspections.service";
import { getRegistryById } from "../../../services/PersonnelManagement.service";
import { getSuggToolsInspPath } from "../evaluationsHelperFuncs";
import TableAccordionItem from "./../../EmployeeRegistry/TableAccordionItem";
import RegistrationEmployeeInfo from "./../../Registrations/RegistrationEmployeeInfo";
import {
  improvingToolsColumns,
  inspectionsColumns,
  suggestionsColumns,
} from "./SugToolInspColumnsConfig";
import EmployeeCard from "../../EmployeeRegistry/EmployeeCard";
import useAuth from "../../../hooks/useBackAuth";

const SuggestionsToolsInspections = () => {
  const employeeId = useParams().id;
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const { userInfo, hasRole,referenceUserInfo } = useAuth();


  function isDateInRange(date) {
    const today = new Date();
    const lastYearStart = new Date(today.getFullYear() - 1, 11, 1); // December 1st of last year
    const thisYearEnd = new Date(today.getFullYear(), 10, 30); // November 30th of current year
  
    return date >= lastYearStart && date <= thisYearEnd;
  }

  const getIsActive = (row, id) => {
    switch (id) {
      case "edit":
        return (
          +employeeId !== +userInfo?.id &&
          (hasRole("admin") || hasRole("supervisor")) &&
          isDateInRange(new Date(row?.referenceYear)) &&
          userData?.registryEvaluationGroup?.evaluationGroup?.id ===
            userInfo?.supervisorEvaluationGroup?.evaluationGroup?.id
        );
      case "preview":
        return true;
      case "print":
        return true;
      default:
    }
    return false;
  };
  useEffect(() => {
    const fetchAndSetUserData = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
    };

    fetchAndSetUserData();
  }, [employeeId]);
  const createOnClickFunc = (type, row) => {
    navigate(getSuggToolsInspPath(row.objectiveType.id, row.registry.id), {
      state: {
        row: row,
        type: type,
      },
    });
  };
  const handleClick = (row, action) => action.onClickFunc(row);

  const actionIcons = [
    (hasRole("admin") || hasRole("supervisor")) && {
      id: "edit",
      icon: <BorderColorIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("edit", row),
    },
    {
      id: "preview",
      icon: <PreviewIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("preview", row),
    },
    {
      id: "print",
      icon: <PictureAsPdfIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("print", row),
    },
  ];
  return (
    <Box>
      {userData && (
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: "Μητρώο Αξιολόγησης Προσωπικού",
              url: "/personnel-registry-evaluation",
            },
            {
              title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
                referenceUserInfo?.name
              } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
              url: `/evaluation-card/${referenceUserInfo?.id}`,
            },
            {
              title:
                "Προτάσεις - Εργαλεία - Επιθεωρήσεις για Εσωτερική Οργάνωση και Λειτουργία",
              url: "/",
            },
          ]}
        />
      )}
      <Box>
        {userData && (
          <Box>
            <h2>
              Καρτέλα Προτάσεων - Εργαλείων - Επιθεωρήσεων για Εσωτερική
              Οργάνωση και Λειτουργία
            </h2>
            <Divider sx={{ bgcolor: "black.main" }} />
          </Box>
        )}

        <Box sx={{ marginLeft: 9, marginTop: 6, marginBottom: 0 }}>
          <h3>Στοιχεία Προσωπικού</h3>
        </Box>
        <Box
          sx={{
            marginY: 8,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {userData ? (
            <EmployeeCard data={userData}>
              <RegistrationEmployeeInfo data={userData} />
            </EmployeeCard>
          ) : (
            <Loader />
          )}
          <Box
            sx={{
              display: "flex",
              marginTop: 4,
              width: "100%",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <TableAccordionItem
            userData={userData}
              evaluationTable={true}
              getCallFunc={(page, rowsPerPage) =>
                getSuggestionToolsInspections(
                  page,
                  rowsPerPage,
                  hasRole("SuperAdmin")
                    ? null
                    : userInfo?.serviceAccount?.institute?.id,
                  employeeId,
                  4401
                )
              }
              columns={suggestionsColumns}
              employeeId={employeeId}
              actionIcons={actionIcons
                .filter((action) => action)
                .map((action) => ({
                  key: action.id,
                  icon: action.icon,
                  onClickFunc: (row) => handleClick(row, action),
                  disabled: (row) => !getIsActive(row, action.id),
                }))}
              buttonLabel={"+ Προσθήκη Πρότασης"}
              buttonUri={"add-functional-suggestion"}
              title="Προτάσεις σχετικά με την εσωτερική οργάνωση και λειτουργία του καταστήματος κράτησης"
              id="evaluationFunctionalSuggestions"
            />

            <TableAccordionItem
                        userData={userData}

              evaluationTable={true}
              getCallFunc={(page, rowsPerPage) =>
                getSuggestionToolsInspections(
                  page,
                  rowsPerPage,
                  hasRole("SuperAdmin")
                    ? null
                    : userInfo?.serviceAccount?.institute?.id,
                  employeeId,
                  4451
                )
              }
              columns={suggestionsColumns}
              employeeId={employeeId}
              actionIcons={actionIcons
                .filter((action) => action)
                .map((action) => ({
                  key: action.id,
                  icon: action.icon,
                  onClickFunc: (row) => handleClick(row, action),
                  disabled: (row) => !getIsActive(row, action.id),
                }))}
              buttonLabel={"+ Προσθήκη Πρότασης"}
              buttonUri={"add-improving-suggestion"}
              title="Προτάσεις σχετικά με τη βελτίωση του συστήματος αξιολόγησης παρακίνησης και ανταμοιβών"
              id="evaluationImprovingSuggestions"
            />

            <TableAccordionItem
                        userData={userData}

              evaluationTable={true}
              getCallFunc={(page, rowsPerPage) =>
                getSuggestionToolsInspections(page, rowsPerPage, null, 4501)
              }
              columns={improvingToolsColumns}
              employeeId={employeeId}
              actionIcons={actionIcons
                .filter((action) => action)
                .map((action) => ({
                  key: action.id,
                  icon: action.icon,
                  onClickFunc: (row) => handleClick(row, action),
                  disabled: (row) => !getIsActive(row, action.id),
                }))}
              buttonLabel={"+ Προσθήκη Εργαλείου"}
              buttonUri={"add-improving-tool"}
              title="Εργαλεία για τη βελτιστοποίηση διαδικασιών του καταστήματος κράτησης"
              id="evaluationImprovingTools"
            />

            <TableAccordionItem
                        userData={userData}

              evaluationTable={true}
              getCallFunc={(page, rowsPerPage) =>
                getSuggestionToolsInspections(
                  page,
                  rowsPerPage,
                  hasRole("SuperAdmin")
                    ? null
                    : userInfo?.serviceAccount?.institute?.id,
                  employeeId,
                  4551
                )
              }
              columns={inspectionsColumns}
              employeeId={employeeId}
              actionIcons={actionIcons
                .filter((action) => action)
                .map((action) => ({
                  key: action.id,
                  icon: action.icon,
                  onClickFunc: (row) => handleClick(row, action),
                  disabled: (row) => !getIsActive(row, action.id),
                }))}
              buttonLabel={"+ Προσθήκη Επιθεώρησης"}
              buttonUri={"add-inspection"}
              title="Επιθεωρήσεις που διενεργήθηκαν"
              id="evaluationInspections"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SuggestionsToolsInspections;
