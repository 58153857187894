import { Typography } from "@mui/material";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import DatePickerFns from "../../../Groups/ui/DatePickerFns";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";

function FormEvaluation({ stage }) {
  return (
    <>
      <Typography mb={1} variant="h4">
        Χρονική Περίοδος Αξιολόγησης
      </Typography>
      <HorizontalVerticalTogglerLayout direction="column">
        <HorizontalVerticalTogglerLayout>
          <TextFieldElement
            fullWidth
            inputProps={
              {
                readOnly: true
              }
            }
            sx={{
              input: { color: "grey" },
            }}

            label="Έτος Αναφοράς"
            name="referenceYear"
          />
        </HorizontalVerticalTogglerLayout>
        <HorizontalVerticalTogglerLayout>

          <DatePickerFns label="Από" name="startDate" />
          <DatePickerFns label="Έως" name="endDate" />
        </HorizontalVerticalTogglerLayout>

        <DatePickerFns
          readOnly={stage == "stage:2" || stage == "stage:3"}
          label="Ημερομηνία Υποχρεωτικής Συνάντησης Προγραμματισμού Εργασιών"
          name="workPlanningDate"
        />
        <DatePickerFns
          readOnly={stage == "stage:1" || stage == "stage:3"}
          label="Ημερομηνία Υποχρεωτικής Συνάντησης Επισκόπησης Προόδου"
          name="reviewMeetingDate"
        />
        <DatePickerFns
          readOnly={stage == "stage:1" || stage == "stage:2"}
          label="Ημερομηνία Υποχρεωτικής Συνάντησης Συνολικής Αξιολόγησης"
          name="evaluationMeetingDate"
        />
        <TextFieldElement
          inputProps={
            {
              readOnly: true
            }
          }
          sx={{
            input: { color: "grey" },
          }}

          label="Ημερομηνία Υποβολής Εντύπου"
          name="submissionDate"
        />

        <TextFieldElement
          inputProps={
            {
              readOnly: stage == "stage:2" || stage == "stage:3"
            }
          }
          sx={{
            input: { color: stage == "stage:2" || stage == "stage:3" ? "grey" : 'inherit' },
          }}

          label="Αριθμός Πρωτοκόλλου Εντύπου"
          name="formProtocolNumber"
        />

        <CheckboxElement
          disabled={stage == "stage:1" || stage == "stage:3"}
          label="Αναθεώρηση Στόχων εν μέσω της Χρονικής Περιόδου Αξιολόγησης"
          name="check"// TODO
        />

      </HorizontalVerticalTogglerLayout>
    </>
  );
}

export default FormEvaluation;