import HttpService from "../../../services/httpClient";


export const getRegistryInfoById = (id) => HttpService.getAxiosClient().get(`registry-evaluation/group/registry-info/${id}`)
export const getDocumentInfo = (id) => HttpService.getAxiosClient().get(`assessment-report/document-info/${id}`)
export const getSkills = () => HttpService.getAxiosClient().get('coded-type/codes/skill_selection_type')
export const postReport = (data) => HttpService.getAxiosClient().post(`/assessment-report/document/info`, data)
export const getSkillGrade = (documentType,achievementType,registryId) => HttpService.getAxiosClient().get(`/assessment-report/document-type/${documentType}/achievementType/${achievementType}/registry/${registryId}`)
export const getAchievementScore = (documentType,registryId) => HttpService.getAxiosClient().get(`/assessment-report/document-type/${documentType}/registry/${registryId}/achievement-score`)
export const getPulseScore = (documentType,evaluationGroupId) => HttpService.getAxiosClient().get(`/assessment-report/document-type/${documentType}/evaluation-group/${evaluationGroupId}`)
