
export const transformer = (formSc, excludedKeys = []) => {
    const formScTransformed = {}
    Object.keys(formSc).forEach(key => {
        if (excludedKeys?.includes(key)) return;
        formScTransformed[key] = {
            ...formSc[key],
            name: key
        }
    })
    return formScTransformed;
}


export const resetFormikForm = (formContext, formSc, excludedKeys = []) => {
    const formScTransformed = Object.keys(formSc).map(key => {
        return {
            [key]: ""
        }
    });
    const obj = Object.assign({}, ...formScTransformed);
    formContext.reset(obj)
}