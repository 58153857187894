import RegistrationWrapper from "../RegistrationWrapper";
import IntangibleRewardRegistrationForm from "./IntangibleRewardRegistrationForm";

const IntangibleRewardsRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Άυλης Αμοιβής"}
      FormComponent={IntangibleRewardRegistrationForm}
    />
  );
};
export default IntangibleRewardsRegistration;
