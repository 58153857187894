export const defaultServiceGradeRegistrationValues = {
    serviceGradeDate: "",
    serviceGradeType: "",
    serviceOrder: "Α.Π.",
    notes: "",
  }

  export  const formServiceGradeRegistrationSchema = {
    serviceGradeDate: {
      name: "serviceGradeDate",
      label: "Ημερομηνία",
    },
    serviceGradeType: {
      name: "serviceGradeType",
      label: "Υπηρεσιακός Βαθμός",
    },
    serviceOrder: {
      name: "serviceOrder",
      label: "Α.Π Διαταγής",
    },
    notes: {
      name: "notes",
      label: "Παρατηρήσεις",
    },
  };
  export const serviceGradeColumns = [

    
    {
      id: "actionDate",
      label: "Ημερομηνία",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "gradeType.description",
      label: "Υπηρεσιακός Βαθμός",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "formProtocolNumber",
      label: "Α .Π Διαταγής",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.notes",
      label: "Παρατηρήσεις",
      minWidth: 170,
      isClickable: false,
    },

  ];