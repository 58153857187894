import { Box } from "@mui/material";

function HorizontalVerticalTogglerLayout({ sx, gap = 3, direction, children }) {
    return (
        <Box sx={sx} display='flex' gap={gap} flexDirection={direction ? direction : { xs: 'column', md: 'row' }} >
            {children}
        </Box>
    );
}

export default HorizontalVerticalTogglerLayout;