import { yupResolver } from "@hookform/resolvers/yup";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BalanceIcon from "@mui/icons-material/Balance";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import HomeIcon from "@mui/icons-material/Home";
import PaymentIcon from "@mui/icons-material/Payment";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer, useForm } from "react-hook-form-mui";
import FormTextField from "../../components/FormTextField";
import CustomIconBox from "../../components/UI/CustomIconBox";
import AppAccordion from "./../../components/UI/AppAccordion";
import ChildData from "./ChildData";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import PreviewButton from "../../components/PreviewButton";
import ProfessionalData from "./ProfessionalData";
import ResetModal from "../../components/UI/Modal/ResetModal";
import SaveModal from "../../components/UI/Modal/SaveModal";
import WifeData from "./WifeData";
import {
  contactDataFieldNames,
  getNewChildFieldNames,
  getNewWifeFieldNames,
  initialFieldValues,
  initialFormSchema,
  payrollDataFieldNames,
  personalDataFieldNames,
  professionalDAtaFieldNames,
} from "./fieldConfiguration";
import { fieldValidation } from "./fieldValidation";
import { getBEConfigInfo, getPersonInfoForUpdate } from "../../services/dropdownData.service";
import { useNavigate, useParams } from "react-router-dom";
import { addNewRegistry, updateOldRegistry } from "../../services/AddNewOfficer.service";
import { transformUserDataToBEFormat } from "../Registrations/dataTransformFunctions";
import { enqueueSnackbar } from "notistack";
import FormSelectField from "../../components/FormSelectField";
import useAuth from "../../hooks/useBackAuth";

const AddNewOfficer = (props) => {
  const [BEConfigInfo, setBEConfigInfo] = useState();
  const { hasRole } = useAuth();

  const formContext = useForm({
    resolver: yupResolver(fieldValidation),
    defaultValues: initialFieldValues,
  });





  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formSchema, setFormSchema] = useState(initialFormSchema);
  const [formData, setFormData] = useState();
  const { employeeId } = useParams();
  const handleSave = async () => {
    try {
      let response;
      if (props.mode === 'edit') {
        response = await updateOldRegistry(formData);
      }
      else {
        response = await addNewRegistry(formData);
      }
      setIsShownSaveModal(false);
      enqueueSnackbar("Η καταχώριση εγινε με επιτυχία!", {
        variant: "success",
      });
      response && navigate("/personnel-management");
    } catch (error) {
      console.log(error.response.data.message, "message");
      enqueueSnackbar("Η καταχώριση απέτυχε!", { variant: "error" });
      throw error;
    }
  };
  const bloodTypeOptions = [
    { id: "A+", label: "A+" },
    { id: "A-", label: "A-" },
    { id: "B+", label: "B+" },
    { id: "B-", label: "B-" },
    { id: "AB+", label: "AB+" },
    { id: "AB-", label: "AB-" },
    { id: "0+", label: "O+" },
    { id: "0-", label: "O-" },
  ];
  const handleAddWife = () => {
    const numOfWives = formSchema.wives.length;
    const newWife = getNewWifeFieldNames(numOfWives);

    setFormSchema((prevState) => {
      const updatedFormSchema = {
        ...prevState,
        wives: [...prevState.wives, newWife],
      };
      return updatedFormSchema;
    });
  };
  const handleDeleteWife = () => {
    setFormSchema((prevState) => {
      const updatedFormSchema = {
        ...prevState,
        wives: [prevState.wives.pop()],
      };
      return updatedFormSchema;
    });
  };
  const handleAddChild = () => {
    const numOfChild = formSchema.children.length;
    const newChild = getNewChildFieldNames(numOfChild);
    setFormSchema((prevState) => {
      const updatedFormSchema = {
        ...prevState,
        children: [...prevState.children, newChild],
      };
      return updatedFormSchema;
    });
  };
  const handleDeleteChild = () => {
    console.log(formSchema.children);
    console.log(formSchema.children.slice(0, -1));
    setFormSchema({
      ...formSchema,
      children: formSchema.children.slice(0, -1)  ,

    });
    formContext.setValue(`childFirstName${formSchema.children.length - 1}`, '');
    formContext.setValue(`childLastName${formSchema.children.length - 1}`, '');
    formContext.setValue(`childDateOfBirth${formSchema.children.length - 1}`, '');

  
  };

  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const BEConfigInfo = await getBEConfigInfo();
      setBEConfigInfo(BEConfigInfo);
    };

    fetchBEConfigInfo();
  }, []);


  useEffect(() => {

    if (props.mode !== 'edit') return;

    const generateFormSchemaForReadyOnlyFields = (readOnlyFieldNames) => {
      const updatedSchema = { ...initialFormSchema };
      readOnlyFieldNames.forEach(fieldName => {
        updatedSchema[fieldName] = {
          ...updatedSchema[fieldName],
          readOnly: true
        };
      });
      return updatedSchema;
    };

    if (hasRole('SuperAdmin')) {
      setFormSchema(generateFormSchemaForReadyOnlyFields([
        "grade",
        "position",
        "institution"]));
    }

    if (hasRole('KATAXORITIS') || hasRole('admin')) {
      setFormSchema(generateFormSchemaForReadyOnlyFields(["name",
        "surname",
        "fathersName",
        "fathersSurname",
        "mothersName",
        "mothersSurname",
        "dateOfBirth",
        "placeOfBirth",
        "afm",
        "amka",
        "bloodType",
        "grade",
        "position",
        "institution"]));
    }



  }, [])

  useEffect(() => {
    if (props.mode !== 'edit') return;
    const getInfo = async () => {
      setIsLoading(true)

      const resp = await getPersonInfoForUpdate(employeeId);


      const addChidlrenOnFormSchemaDependingOnResponse = (resp) => {
        if (resp?.children?.length > 1) {
          for (let i = 0; i < resp.children.length - 1; i++) {
            handleAddChild();
          }
        }

      }
      const oldChildrenData = (children) => {
        // create an object with childFirstName0, childLastName0, childDateOfBirth0, childFirstName1, childLastName1, childDateOfBirth1, etc depending on how many children there are 

        const childrenData = children?.reduce((acc, child, index) => {
          acc[`childFirstName${index}`] = child?.name;
          acc[`childLastName${index}`] = child?.surname;
          acc[`childDateOfBirth${index}`] = child?.dateOfBirth;
          return acc;
        }
          , {});
        return childrenData;

      }


      addChidlrenOnFormSchemaDependingOnResponse(resp);

      const childrenForReactHookForms = oldChildrenData(resp.children);



      console.log(oldChildrenData(resp.children), 'scum');
      const oldData = {
        ...resp,
        payrollIBAN: resp.iban,
        street: resp?.address[0]?.street,
        numberOfStreet: resp?.address[0]?.numberOfStreet,
        postalCode: resp?.address[0]?.postalCode,
        city: resp?.address[0]?.city,
        region: resp?.address[0]?.region,
        telephone: resp?.phone?.find(z => z?.phoneType?.description === 'Home phone')?.phoneNumber,
        mobile: resp?.phone?.find(z => z?.phoneType?.description === 'Cell phone')?.phoneNumber,
        contactNumber: resp?.phone?.find(z => z?.phoneType?.description === 'Τηλέφωνο Επικοινωνίας')?.phoneNumber,
        spouseFirstName0: resp?.spouse?.[0]?.spouseFirstName,
        spouseLastName0: resp?.spouse?.[0]?.spouseLastName,
        weddingDate0: resp?.spouse?.[0]?.dateOfWedding,
        ...childrenForReactHookForms,
        institution: resp?.serviceAccount?.institute?.id,
        position: resp?.serviceAccount?.position?.id,
        grade: resp?.serviceAccount?.grade?.id,

      }

      formContext.reset(oldData);
      setIsLoading(false);
    }
    getInfo();
  }, [])





  return (
    <>

      <Box>
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: "Διαχείριση Φυλακτικού Προσωπικού",
              url: "/personnel-management",
            },
            { title: "Επεξεργασία Στοιχείων Φυλακτικού Προσωπικού", url: "/" },
          ]}
        />
      </Box>
      <Box>
        <h2>Επεξεργασία Στοιχείων Φυλακτικού Προσωπικού</h2>
        <Divider sx={{ bgcolor: "black.main" }} />
      </Box>
      <div></div>
      <Box display="flex" justifyContent='center' mt={2}>
        {isLoading && <CircularProgress />}
      </Box>

      <FormContainer
        formContext={formContext}
        onSuccess={async (data) => {
          console.log(BEConfigInfo.position);
          const selectedPositionCode = BEConfigInfo?.position?.find(
            (position) => position?.id == data?.position
          )?.code;
          setIsShownSaveModal(true);
          let transformedData = transformUserDataToBEFormat({ ...data, positionCode: selectedPositionCode }, BEConfigInfo)
          if (props.mode === 'edit') transformedData.id = employeeId;
          setFormData(transformedData);
        }}
      >
        <Box sx={{ display: "flex", marginTop: 5, paddingBottom: 5 }}>
          <CustomIconBox icon={<AccountBoxIcon />} />
          <AppAccordion
            id="personalData"
            title="Προσωπικά Στοιχεία"
            details={
              <Box>
                <FormTextField
                  key="personalData"
                  fieldNames={personalDataFieldNames}
                  values={formSchema}
                  isPreviewMode={isPreviewMode}
                />
                <FormSelectField
                  label={initialFormSchema.bloodType.label}
                  name={initialFormSchema.bloodType.name}
                  options={bloodTypeOptions}
                  isPreviewMode={isPreviewMode}
                />
              </Box>
            }
          />
        </Box>
        <Box sx={{ display: "flex", paddingBottom: 5 }}>
          <CustomIconBox icon={<HomeIcon />} />
          <AppAccordion
            id="contactDetails"
            title="Στοιχεία Επικοινωνίας"
            details={
              <FormTextField
                key="contactDetails"
                fieldNames={contactDataFieldNames}
                values={formSchema}
                isPreviewMode={isPreviewMode}
              />
            }
          />
        </Box>

        <Box sx={{ display: "flex", paddingBottom: 5 }}>
          <CustomIconBox icon={<PaymentIcon />} />
          <AppAccordion
            id="payrollData"
            title="Στοιχεία Λογαριασμού Μισθοδοσίας"
            details={
              <FormTextField
                key="payrollData"
                fieldNames={payrollDataFieldNames}
                values={formSchema}
                isPreviewMode={isPreviewMode}
              />
            }
          />
        </Box>

        <Box sx={{ display: "flex", paddingBottom: 5 }}>
          <CustomIconBox icon={<FamilyRestroomIcon />} />
          <AppAccordion
            id="wifeData"
            title="Οικογενειακά Στοιχεία - Στοιχεία Συζύγου"
            details={
              <>
                {formSchema.wives &&
                  formSchema.wives.map((wifeDAta, index) => (
                    <Box key={index}>
                      <WifeData
                        id={index}
                        values={wifeDAta}
                        isPreviewMode={isPreviewMode}
                      />
                    </Box>
                  ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <Button onClick={() => handleAddWife()}>
                      +Προσθήκη Συζύγου
                    </Button> */}
                  {formSchema.wives.length > 1 && (
                    <Button onClick={() => handleDeleteWife()}>
                      -Διαγραφή Συζύγου
                    </Button>
                  )}
                </Box>
              </>
            }
          />
        </Box>
        <Box sx={{ display: "flex", paddingBottom: 5 }}>
          <CustomIconBox icon={<FamilyRestroomIcon />} />
          <AppAccordion
            id="childData"
            title="Οικογενειακά Στοιχεία - Στοιχεία Τέκνου"
            details={
              <>
                {formSchema.children &&
                  formSchema.children.map((childData, index) => (
                    <Box key={index} my={3} sx={{
                      display: "flex", marginTop: 5, paddingBottom: 5, borderRadius: "8px"
                    }}>
                      <ChildData
                        id={index}
                        values={childData}
                        isPreviewMode={isPreviewMode}
                      />
                    </Box>
                  ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button onClick={() => handleAddChild()}>
                    +Προσθήκη Τέκνου
                  </Button>
                  {formSchema.children.length > 1 && (
                    <Button onClick={() => handleDeleteChild()}>
                      -Διαγραφή Τέκνου
                    </Button>
                  )}
                </Box>
              </>
            }
          />
        </Box>
        <Box sx={{ display: "flex", marginBottom: 5 }}>
          <CustomIconBox icon={<BalanceIcon />} />
          <AppAccordion
            id="professionalData"
            title="Υπηρεσιακά Στοιχεία"
            details={
              <ProfessionalData
                fieldNames={professionalDAtaFieldNames}
                values={formSchema}
                isPreviewMode={isPreviewMode}
              />
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 3,
            marginY: 4,
          }}
        >
          {isPreviewMode ? (
            <>
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  setIsPreviewMode(false);
                }}
              >
                Επιστροφή
              </Button>
              <Button variant="contained" type="submit">
                Οριστικοποίηση
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <PreviewButton
                label={"Προεπισκόπηση"}
                setIsPreviewMode={setIsPreviewMode}
              />
            </>
          )}
        </Box>
        {isShownResetModal && (
          <ResetModal setIsShownResetModal={setIsShownResetModal} />
        )}
        {isShownSaveModal && (
          <SaveModal
            setIsShownSaveModal={setIsShownSaveModal}
            onSave={handleSave}
          />
        )}
      </FormContainer>
    </>
  );
};

export default AddNewOfficer;
