import HttpService from "./httpClient";
export const getFilteredRegistries = (
  {page,
  size,
  name,
  surname,
  afm,
  guardType,
  evaluationGroupId,
  institutionIds,
  positionIds}
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry`, {
        params: {
          page,
          size,
          "name.equals":name,
          "surname.equals":surname,
          "afm.equals": afm,
          "isInternalGuard.equals": guardType && guardType === "internal",
          "evaluationGroupId.equals": evaluationGroupId ?  evaluationGroupId : null,
          "institutionId.in": Array.isArray(institutionIds) ? (institutionIds.length>1 ? institutionIds?.join(',') : institutionIds[0]): institutionIds ||null,
          "positionId.in": Array.isArray(positionIds) ? (positionIds.length>1 ? positionIds?.join(',') : positionIds[0]): null,
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getRegistryById = (registryId) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry/${registryId}`)
      .then(function (response) {
        const registry = response.data;
        resolve(registry);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};


export const deleteRegistryById = (registryId) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .delete(`/registry/${registryId}`)
      .then(function (response) {
        const registry = response.data;
        console.log(registry);
        resolve(registry);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};


