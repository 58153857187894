import { Box, Button, Divider, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormContainer,
  useForm
} from "react-hook-form-mui";
import ResetFormButton from '../../../components/ResetButton';
import AppInput from "../../../components/UI/AppInput";
import AppSelect from '../../../components/UI/AppSelect';
import { getInstitutionDropdown, getSubmissionStage } from "../../../services/dropdownData.service";
import { years } from "../../../helperFunctions";


const SubordinateEvaluationsFilters = (props) => {
  const [institutions, setInstitutions] =  useState()
  const [submissionStage, setSubmissionStage] = useState();

  const formSchema = {
    referenceYear: { name: "referenceYear", label: "Έτος Αναφοράς" },
    institutionId: { name: "institutionId", label: "Kατάστημα Kράτησης" },
    submissionStage: { name: "submissionStage", label: "Στάδιο Αξιολόγησης" },

  };

  useEffect(() => {
    const fetchDropdowns = async () => {
      const institutes = await getInstitutionDropdown();
      const submissionStages = await getSubmissionStage();
      const mappedSubmissionStages = submissionStages["submission_stage"].map(
        (sub) => ({
          id: sub.id,
          label: sub.description,
        })
      );
      const mappedInstitutions = institutes.institution.map(inst => ({
        id: inst.id,
        label: inst.description,
      }));
      setSubmissionStage(mappedSubmissionStages);
      setInstitutions(mappedInstitutions);
    };
    fetchDropdowns();
  }, [props.filters]);

  const { formContext, reset } = useForm({
    defaultValues: props.filters,
  });

  return (
    <Box sx={{ paddingY: 5 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <FormContainer
          formContext={formContext}
          onSuccess={async (data) => {
            props.setFilters(data);
            reset();
          }}
        >
          {" "}
          <h4>Φίλτρα Αναζήτησης</h4>
          <Divider />
          <Grid container spacing={4} sx={{ paddingY: 3 }}>
            <Grid item xs={6}>
            {institutions && <AppSelect
                  name={formSchema.institutionId.name}
                  value={formSchema.institutionId.name}
                  label={formSchema.institutionId.label}
                  options={institutions}
                  isMultiple={true}

                />}
            </Grid>

            
            <Grid item xs={4}>
            {submissionStage && <AppSelect
                name={formSchema.submissionStage.name}
                value={formSchema.submissionStage.name}
                label={formSchema.submissionStage.label}
                options={submissionStage}
                isMultiple={true}
              />}
            </Grid>
            <Grid item xs={2}>
            {years && <AppSelect
                name={formSchema.referenceYear.name}
                value={formSchema.referenceYear.name}
                label={formSchema.referenceYear.label}
                options={years}
                isMultiple={true}
              />}
            </Grid>
          </Grid>
         
          <Box sx={{ gap: 2, display: "flex", justifyContent: "flex-end" }}>
            <ResetFormButton variant={"outlined"} label={"Καθαρισμός"} />
            <Button variant="contained" type="submit">
              Αναζήτηση
            </Button>
          </Box>
        </FormContainer>
      </Paper>
    </Box>
  );
};
export default SubordinateEvaluationsFilters;
