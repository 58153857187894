export const defaultServiceActionRegistrationValues = {
  serviceActionDate: "",
  serviceActionType: "",
  notes: "",
}

export  const formServiceActionRegistrationSchema = {
  
  serviceActionDate: {
    name: "serviceActionDate",
    label: "Ημερομηνία",
  },
  serviceActionType: {
    name: "serviceActionType",
    label: "Επιλογή Υπηρεσιακής Ενέργειας",
  },
  notes: {
    name: "notes",
    label: "Παρατηρήσεις",
  },
};
export const serviceActionsColumns = [

  {
    id: "serviceAction.actionDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: false,
  },
    {
    id: "serviceAction.actionType.description",
    label: "Υπηρεσιακή Ενέργεια",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "serviceAction.notes",
    label: "Παρατηρήσεις",
    minWidth: 170,
    isClickable: false,
  },
];