import { useState } from "react";

function usePostRequest(httpCall) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setData] = useState(null);
    const handlePostRequest = async (data, id) => {
        try {
            setIsLoading(true);
            setError(null);

            const response = await httpCall(data, id);

            // Handle the response as needed
            // For example, update the state with the response data
            const responseData = response?.data;
            setData(responseData);

            return responseData;
        } catch (error) {
            // Handle error
            setError(error);
            throw error;

        } finally {
            setIsLoading(false);
        }
    };

    return { handlePostRequest, isLoading, error, responseData, setData };
}

export default usePostRequest;
