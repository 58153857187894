import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import '../../../App.css'
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import Details from "../../../components/Details/Details";
import AppAccordion from "../../../components/UI/AppAccordion";
import FormDatePicker from "../../../components/UI/FormDatePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";
import {
  getGradeInstitutionPositionDropdown,
  getTransferTypesDropdown,
} from "../../../services/dropdownData.service";

import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import {
  defaultSwitchRegistrationValues as defaultValues,
  formSwitchRegistrationSchema as formSchema,
} from "./switchRegistrationConfig";
import { switchesFieldValidation } from "./switchesFieldValidation";
import {
  addNewSwitchRegistries,
  addNewSwitchRegistry,
} from "../../../services/Registrations/Switches.service";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  registrationDetailsTransformer,
  transformSwitchDataToBEFormat,
} from "../dataTransformFunctions";
import {
  transformSwitchMultipleDataToBEFormat,
  transformedMultipleRegistryData,
} from "./../../MultipleRegistrations/multipleDataTransformFunctions";
import { NoRowsOverlay } from "../../../helperFunctions";

const SwitchRegistrationForm = ({
  data,
  isMultipleRegistration,
  guardType,
  selectedPeople,
}) => {
  const employeeId = useParams().employeeId;
  const navigate = useNavigate();
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [transferTypes, setTransferTypes] = useState();
  const [transferOptions, setTransferOptions] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [multipleRegistriesData, setMultipleRegistriesData] = useState();
  const [configObjects, setConfigObjects] = useState();
  const [date, setDate] = useState();
  const registry = {
    id: data?.id,
    name: data?.name,
    surname: data?.surname,
    fathersName: data?.fathersName,
    fathersSurname: data?.fathersSurname,
    mothersName: data?.mothersName,
    mothersSurname: data?.mothersSurname,
    dateOfBirth: data?.dateOfBirth,
    placeOfBirth: data?.placeOfBirth,
    adt: data?.adt,
    issuingAuthority: data?.issuingAuthority,
    afm: data?.afm,
    bloodType: data?.bloodType,
    amka: data?.amka,
    payrollAccountNumber: data?.payrollAccountNumber,
    iban: data?.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };

  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const switchOptions = await getTransferTypesDropdown();
      const configObj = await getGradeInstitutionPositionDropdown();
      setConfigObjects(configObj);
      setTransferTypes(switchOptions);
      setTransferOptions(
        switchOptions["transfer_type"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
        );
      };
      
      fetchBEConfigInfo();
      transferOptions && console.log(transferOptions,'transferOptions')
  }, []);
  const onSave = () => {
    const data = isMultipleRegistration
      ? transformSwitchMultipleDataToBEFormat(
          multipleRegistriesData?.rows[0],
          {
            ...configObjects,
            transferTypes,
          },
          selectedPeople,
          guardType
        )
      : transformSwitchDataToBEFormat(formData, transferTypes);
    transferTypes && isMultipleRegistration
      ? addNewSwitchRegistries(data)
      : addNewSwitchRegistry(data);
    setIsShownSaveModal(false);
    isMultipleRegistration
      ? navigate("/personnel-management")
      : navigate(`/employee-info/${employeeId}`);
  };
  const formContext = useForm({
    resolver: yupResolver(switchesFieldValidation),
    defaultValues: defaultValues,
  });
  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (switchData) => {
            console.log(switchData,'switchData')
            setDate(switchData.switchDate.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).split(",")[0]);
            const typeOfSwitchDescription = transferOptions.find(
              (option) => option.id === +switchData.switchType
            )?.label;

            const transformedData = isMultipleRegistration
              ? transformedMultipleRegistryData(
                  {
                    ...switchData,
                    switchType: typeOfSwitchDescription,
                  },
                  formSchema,
                  selectedPeople
                )
              : registrationDetailsTransformer(formSchema, {
                  ...switchData,
                  switchType: typeOfSwitchDescription,
                  userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
                  userPosition: data.serviceAccount.position.description,
                });

            isMultipleRegistration
              ? setMultipleRegistriesData(transformedData)
              : setFormData({
                  ...switchData,
                  registry,
                  institution: data.serviceAccount.institute,
                  position: data.serviceAccount.position,
                });
            !isMultipleRegistration && setPreviewData(transformedData);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.switchDate.label}>
              <FormDatePicker
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.switchDate.name}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.switchType.label}>
              {transferOptions && (
                <SelectElement
                  fullWidth
                  label={formSchema.switchType.label}
                  name={formSchema.switchType.name}
                  options={transferOptions}
                />
              )}
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
          <h4>
          {`Μεταγωγές ${date}`}
          </h4>
         {
              isMultipleRegistration && multipleRegistriesData ? (
                <DataGrid
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={multipleRegistriesData.rows}
                  columns={multipleRegistriesData.columns.map((column) => ({
                    ...column,
                    cellClassName: 'dataGridCell', 
                  }))}
                  slots={{
                    noRowsOverlay: NoRowsOverlay,
                  }}
                />
              ) : (
                <Details
                  renderFirstRowsAsHeader
                  renderArraysAsColumns
                  rows={[
                    {
                      name: previewData.labels[0],
                      value: previewData.labels.slice(1, previewData.length),
                    },
                    {
                      name: previewData.values[0],
                      value: previewData.values.slice(1, previewData.length),
                    },
                  ]}
                />
              )
            }
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              type="button"
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default SwitchRegistrationForm;
