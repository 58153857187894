import HttpService from "./httpClient";

export const getPositionDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/position`,)
      .then(function (response) {
        const positionDropdown = response.data;
        resolve(positionDropdown);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getEducationLevelDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/educational_level_type`,)
      .then(function (response) {
        const positionDropdown = response.data;
        resolve(positionDropdown);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getInstitutionDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/institution`,)
      .then(function (response) {
        const institutionDropdown = response.data;
        resolve(institutionDropdown);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getServiceGradeDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/grade_type`,)
      .then(function (response) {
        const institutionDropdown = response.data;
        resolve(institutionDropdown);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getInstitutionPositionDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/institution,position`,)
      .then(function (response) {
        const dropdowns = response.data;
        resolve(dropdowns);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getGradeDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/grade`,)
      .then(function (response) {
        const gradeDropdown = response.data;
        resolve(gradeDropdown);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getGradeInstitutionPositionDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/grade,position,institution`,)
      .then(function (response) {
        const dropdowns = response.data;
        resolve(dropdowns);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getFullNamesAutocomplete = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry/names/info`,)
      .then(function (response) {
        const fullNames = response.data;
        resolve(fullNames);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getBEConfigInfo = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/grade,position,institution,phone_type`,)
      .then(function (response) {
        const phoneTypes = response.data;
        resolve(phoneTypes);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getPersonInfoForUpdate = (id) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .get(`/registry/${id}`, )
        .then(function (response) {
          const personInfo = response.data;
          resolve(personInfo);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
}

export const getServiceChangeDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/type_of_service`,)
      .then(function (response) {
        const serviceChangeDropdown = response.data;
        resolve(serviceChangeDropdown);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getOvertimeReasonDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/overtime_reason`,)
      .then(function (response) {
        const overtimeReasons = response.data;
        resolve(overtimeReasons);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getActionTypesDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/action_type`,)
      .then(function (response) {
        const overtimeReasons = response.data;
        resolve(overtimeReasons);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getTransferTypesDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/transfer_type`,)
      .then(function (response) {
        const transferType = response.data;
        resolve(transferType);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getIntangibleRewardDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/intouchable_fee_type`,)
      .then(function (response) {
        const intangibleRewardTypes = response.data;
        resolve(intangibleRewardTypes);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getEducationTypesDropdown = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/education_type`,)
      .then(function (response) {
        const educationType = response.data;
        resolve(educationType);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};


export const getSubmissionStage = () => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/coded-type/codes/submission_stage`,)
      .then(function (response) {
        const submissionStages = response.data;
        resolve(submissionStages);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};