import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import { useGetEvaluationGroup } from "../../data-access/useGetEvaluationGroup";
import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { registryEvaluationGroupDataGridColumns } from "../../utils/DataGridColumns";
import EmployeeCard from "../../../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../../../Pages/Registrations/RegistrationEmployeeInfo";
import '../../../../App.css'
export default function AccordionContentEvaluationTeam({ id, isOpen = false }) {
    // if (!isOpen) return <></>
    const { data: evaluationGroupDetails, isLoading: evaluationGroupDetailsLoading, } = useGetEvaluationGroup(id || null)
    if (evaluationGroupDetailsLoading) return <></>
    // TODO: DELETE THE OR LOGIC!!!
    const superVisor = evaluationGroupDetails
        ?.data
        ?.registries
        ?.find(x => x?.isEvaluationGroupSupervisor) || evaluationGroupDetails?.data?.registries?.[0];

    return (<HorizontalVerticalTogglerLayout direction={'column'}>
        <Typography variant="h4" style={{ marginLeft: "10px" }}>
            {"Στοιχεία Προϊστάμενου - Αξιολογητής"}
        </Typography>
        {superVisor
            ? <EmployeeCard data={superVisor}>
                <RegistrationEmployeeInfo data={superVisor} />
            </EmployeeCard>
            : <Typography>
                Δεν υπάρχει Αξιολογητής
            </Typography>}
        <Typography variant="h2">
            Στοιχεία Αξιολογούμενου Προσωπικού
        </Typography>

        {evaluationGroupDetails?.data?.registries?.length ? <Box height='400px'>
            <DataGrid
                rows={evaluationGroupDetails?.data?.registries.filter(z=> !z?.isEvaluationGroupSupervisor) || []}
                columns={registryEvaluationGroupDataGridColumns.map((column) => ({
                    ...column,
                    cellClassName: 'dataGridCell',
                }))}
            />
        </Box>
            : <Typography>
                Δεν υπάρχει Αξιολογούμενο Προσωπικό
            </Typography>
        }

    </HorizontalVerticalTogglerLayout>)

}
