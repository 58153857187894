import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getEducationHistory } from "../../../services/history.service";
import RegistryHistoryWrapper from "../RegistryHistoryWrapper";
import { EducationColumns } from "./EducationColumnsConfig";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const EducationHistory = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo, hasRole } = useAuth()
  const handleClick = (row) => {
    const date = data.find((item) => (item.id === row.id)).endDate;
    navigate(`/detailed-history/education/${date}/${row.id}`);
  };

  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getEducationHistory(
        page,
        pageSize,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id,);
      const rows = allRegistries.data.flatMap((item) => ({
        id: item.id,
        endDate: item.endDate,
        educationType: item.educationType.description,
      }));

      setData(rows);
      setCount(allRegistries.count);
      setIsLoading(false);
    };

    fetchAndSetHistoryRegistries();
  }, [page, pageSize, userInfo?.serviceAccount?.institute?.id]);
  return (<RegistryHistoryWrapper
    title={"Εκπαιδεύσεων "}
    department={userInfo?.serviceAccount?.institute?.description}
    rows={data}
    columns={EducationColumns}
    isLoading={isLoading}
    onClickFunc={handleClick}
    page={page}
    setPage={setPage}
    rowsPerPage={rowsPerPage}
    setRowsPerPage={setRowsPerPage}
    pageSize={pageSize}
    setPageSize={setPageSize}
    count={count || data.length}
    buttonTitle={'Καταχώριση Εκπαίδευσης'}
    buttonURL={'/multiple-registries/education'}
  />)
}
export default EducationHistory