import { createTheme } from "@mui/material/styles";
import { elGR } from "@mui/material/locale";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const LOADER_SIZE = 20;

export default createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      h1: {
        fontSize: "2rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "2.5rem",
        },
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "2rem",
        },
      },
      h3: {
        fontSize: "1.125rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "1.3rem",
        },
      },
      h4: {
        fontSize: "1rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "1.1rem",
        },
      },
      h5: {
        fontSize: "0.81rem",
        fontWeight: "700",
      },
      h6: {
        fontSize: "0.81rem",
        fontWeight: "700",
      },
      body1: {
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.43",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            padding: "8px 22px",
            borderRadius: "20px",
          },
        },
      },
      // MuiAccordion: {
      //   styleOverrides: {
      //     root: {
      //       width: "100%",
      //       marginLeft: '10px',
      //       marginBottom:'20px',
      //       borderRadius: "20px",
      //       "&.MuiAccordion-root:before": {
      //         backgroundColor: "white",
      //       },
      //       "& .MuiAccordionSummary-content": {
      //         marginBottom: "12px",
      //       },
      //       "&.Mui-expanded": {
      //         minHeight: 0,
      //       },
      //       "& .MuiAccordionSummary-content.Mui-expanded": {
      //         margin: "12px 0",
      //       },
      //     },
      //   },
      // },
      MuiTextField: {
        defaultProps: {
          inputProps: {
            style: {
              padding: 13,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "grey",
          },
        },
      },
      MuiSelectElement: {
        styleOverrides: {
          root: {
            
            borderRadius: "30px", // Set the border radius here for the TextField
            " .MuiOutlinedInput-root ": {
              "& fieldset": {
                borderColor: "black",
              },
            },
            "& .MuiSelect-select": {
              paddingTop: 13,
              paddingBottom: 13,
            },
          },
        },
        defaultProps: {
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            padding: '4px', // Replace 'your-padding-value' with the desired padding value
          },
          root: {
            width: "100%",
            
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", 
              borderRadius: '20px', 
            },
            "& .MuiInputBase-input": {
              padding: '4px', 
            },
           " .MuiInputLabel-root ": {
              color: "black" 
            } ,
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: '5px'
            }
          },
          option: {
            '&[aria-disabled="true"]': {
              color: "black",
            },
          },
        },
      },
    },

    palette: {
      white: {
        main: "#FFFFFF",
      },
      black: {
        main: "#000000",
      },
      primary: {
        main: "#003476",
      },
      secondary: {
        main: "#046ec5",
      },
      success: {
        main: "#00703c",
        light: "#EDF7ED",
      },
      error: {
        main: "#ca2e2e",
      },
      light: {
        main: "#616161",
      },
      lightBlue: {
        main: "#54a0cd",
      },
      chartPink: {
        main: "rgb(255, 99, 132)",
      },
      disabled: {
        main: "#ccc",
      },
      warning_alert: {
        light: "rgb(255, 244, 229)",
        dark: "rgb(102, 60, 0)",
      },
      gray: {
        main: "#293845",
        light: "#e8ecef",
      },
      action: {
        disabledOpacity: 1,
      },
    },
  },
  elGR
);
