import { Box } from "@mui/system";
import AppBreadcrumbs from "../../../../components/Breadcrumbs";
import PageTitle from "../../../../components/PageTitle";
import CreateUpdateEvaluationGroup from "../../ui/CreateUpdate";

function CreateEvaluationGroups() {

    return <Box mt={17}>
        <AppBreadcrumbs
            breadcrumbsList={[
                {
                    title: "Ομάδες Αξιολόγησης",
                    url: "/evaluation/groups",
                },
                {
                    title: 'Δημιουργία Ομάδας Αξιολόγησης',
                },
            ]}
        />

        <PageTitle title="Δημιουργία Ομάδας Αξιολόγησης" />

        <CreateUpdateEvaluationGroup />
    </Box>
}

export default CreateEvaluationGroups;