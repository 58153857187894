export const defaultIntangibleRewardRegistrationValues = {
  intangibleRewardDate: "",
  intangibleRewardType: "",
  order: "Α.Π.",
  notes: "",
}

export  const formIntangibleRewardRegistrationSchema = {
  
  intangibleRewardDate: {
    name: "intangibleRewardDate",
    label: "Ημερομηνία",
  },
  intangibleRewardType: {
    name: "intangibleRewardType",
    label: " Κατηγορία Ηθικής Αμοιβής",
  },
  order: {
    name: "order",
    label: "Α.Π. Διαταγής",
  },
  notes: {
    name: "notes",
    label: "Παρατηρήσεις",
  },
};

export const intangibleRewardsColumns = [
  {
    id: "dateOfFee",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "category.description",
    label: "Κατ. Άυλης Αμοιβής",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "orderNumber",
    label: "Α.Π Διαταγής",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "notes",
    label: "Παρατηρήσεις",
    minWidth: 170,
    isClickable: false,
  },
 ];