import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  FormContainer,
  RadioButtonGroup,
  TextareaAutosizeElement,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form-mui";
import { useLocation, useParams } from "react-router-dom";
import { router } from "../../../App";
import EmployeeCard from "../../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import PageTitle from "../../../components/PageTitle";
import usePostRequest from "../../../hooks/useHttpCallHandler";
import { useGetRegistryEvaluationRegistryInfo } from "../../Armodiothtes/data-access/useGetRegistryEvaluationRegistryInfo";
import FormEvaluationPeriod from "../../Armodiothtes/shared/ui/FormEvaluationPeriod";
import FromItem from "../../Armodiothtes/shared/utils/PredefinedBackendForm/FromItem";
import InfoBox from "../../shared/ui/InfoBox";
import GenericModal from "../../shared/ui/modals/GenericModal";
import {
  failedSubmitHandler,
  successfullySubmittedHandler,
} from "../../shared/utils";
import { getAutomaticTarget, postDexiotita } from "../data-access/gateway";
import { useGetSkillCaseInfo } from "../data-access/useGetSkillCaseInfo";
import { useGetSkillTypes } from "../data-access/useGetSkillTypes";
import { enqueueSnackbar } from "notistack";
import GradeDisplay from "../../Armodiothtes/shared/ui/Display";
import { average } from "../../../services/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import dexiotitesValidationSchema from "./../validationSChema";
import useAuth from "../../../hooks/useBackAuth";

function SharedDextiotitesForm({ mode }) {
  const { referenceUserInfo } = useAuth();
  const { data: caseInfo, isLoading: caseInfoIsLoading } = useGetSkillTypes();
  const location = useLocation();
  const { id } = useParams();
  const { data: skillCaseInfo, isLoading: skillCaseInfoIsLoading } =
    useGetSkillCaseInfo(id);
  const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } =
    useGetRegistryEvaluationRegistryInfo(
      skillCaseInfo?.data?.documentInfo?.registry?.id
    );
  const actionOfForm = location?.state?.action;
  const formContext = useForm({
    resolver: yupResolver(dexiotitesValidationSchema),
  });
  const {
    handlePostRequest,
    responseData,
    isLoading: isPostLoading,
  } = usePostRequest(postDexiotita);
  const {
    append: appendTargetType1,
    remove: removeTargetType1Fields,
    fields
  } = useFieldArray({
    control: formContext.control,
    name: "skillsFormDetails",
  });
// TODO delete Προσανατολισμός στον συνάδελφο
  useEffect(() => {
    registryInfoDetails &&
      actionOfForm === "print" &&
      setTimeout(window.print(), 1500);
  }, []);

  useEffect(() => {
    if (caseInfoIsLoading || skillCaseInfoIsLoading) return;
    formContext.reset({
      referenceYear: skillCaseInfo?.data?.referenceYear || undefined,
      startDate: skillCaseInfo?.data?.startDate || undefined,
      endDate: skillCaseInfo?.data?.endDate || undefined,
      submissionDate: skillCaseInfo?.data?.submissionDate || undefined,
      formProtocolNumber: skillCaseInfo?.data?.formProtocolNumber || undefined,
    });
  
    // Filter out the entry with code "orientation_to_the_colleague"
    const filteredSkillTypes = caseInfo?.data?.skill_selection_type
      ?.filter(skillType => skillType.code !== "orientation_to_the_colleague")
      || [];
  
    // Populate the skillsFormDetails
    filteredSkillTypes.forEach((x) => {
      const _detailsType = skillCaseInfo?.data.skillsFormDetails?.find(
        (z) => z?.skillType?.id === x?.id
      );
  
      appendTargetType1({
        notes: _detailsType?.notes || "",
        isDeveloped:
          _detailsType?.isDeveloped === null ||
          _detailsType?.isDeveloped === undefined
            ? null
            : _detailsType?.isDeveloped
            ? "1"
            : "2",
        skillType: {
          ...x,
        },
        targetScore:
          _detailsType?.targetScore === undefined
            ? undefined
            : String(_detailsType?.targetScore),
      });
    });
  }, [skillCaseInfoIsLoading]);
  

  const onSubmit = async (data) => {
    const body = {
      ...data,
      id: skillCaseInfo?.data?.id,
      isRevised: true,
      isCompleted: null,
      achievementGrade: average(
        skillsFormDetails?.map((x) => +x.targetScore).filter((z) => z),
        caseInfo?.data?.skill_selection_type?.length
      )?.toFixed(2),
      documentInfo: skillCaseInfo?.data?.documentInfo,
      skillsFormDetails: data?.skillsFormDetails?.map((yy) => {
        return {
          ...yy,
          id: skillCaseInfo?.data?.skillsFormDetails?.find(
            (zz) => zz?.skillType?.id === yy?.skillType?.id
          )?.id,
          isDeveloped:
            yy?.isDeveloped === null
              ? null
              : yy?.isDeveloped == 1
              ? true
              : false,
        };
      }),
    };
    try {
      await handlePostRequest(body);
      successfullySubmittedHandler(referenceUserInfo?.id);
    } catch (error) {
      console.error(error);
      failedSubmitHandler();
    }
  };

  const documentInfoRegistry = skillCaseInfo?.data?.documentInfo?.registry;
  const documenType = skillCaseInfo?.data?.documentInfo?.documentType;

  const skillsFormDetails = useWatch({
    control: formContext.control,
    name: "skillsFormDetails",
  });
  if (
    registryInfoDetailsIsLoading ||
    caseInfoIsLoading ||
    skillCaseInfoIsLoading
  )
    return <Typography> Προετοιμασία Πεδίων</Typography>;
  return (
    <Box mt={17}>
      <AppBreadcrumbs
        breadcrumbsList={[
          {
            title: "Μητρώο Αξιολόγησης Προσωπικού",
            url: "/personnel-registry-evaluation",
          },
          {
            title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
              referenceUserInfo?.name
            } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
            url: `/evaluation-card/${referenceUserInfo?.id}`,
          },
          {
            title: "Διενέργειες Αξιολογήσεων",
            url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
          },
          {
            title: `${documenType?.description || ""}:

                    ${documentInfoRegistry?.name || ""} ${
              documentInfoRegistry?.surname || ""
            } - ${documentInfoRegistry?.afm || ""}`,
            url: "/evaluation/groups/create",
          },
        ]}
      />
      {mode === "upallilos" ? (
        <PageTitle title="Έντυπο Αποτύπωσης Δεξιοτήτων Υπαλλήλου" />
      ) : (
        <PageTitle title="Έντυπο Αποτύπωσης Δεξιοτήτων Προϊσταμένου" />
      )}

      <InfoBox
        content={'                Καταχωρίστε την Κατηγορία καθώς και την Περιγραφή του κάθε Στόχου που είχε ανατεθεί στον Αξιολογούμενο . Εν συνεχεία, αποτυπώστε το επίπεδο επίτευξης κάθε στόχου από τον αξιολογούμενο με βάση την πενταβάθμια περιγραφική κλίμακα, όπου "Πολύ Χαμηλή Επίτευξη του                Στόχου" αντιστοιχεί σε βαθμολογία = ένα (1), και "Σημαντική Υπέρβαση Στόχου" αντιστοιχεί σε βαθμολογία = πέντε (5)                '
        }
      />

      <Box display="flex" justifyContent="center" my={2}>
        <EmployeeCard data={registryInfoDetails?.data}>
          <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
        </EmployeeCard>
      </Box>
      <FormContainer onSuccess={()=>{console.log('success');}} formContext={formContext}>
        <fieldset disabled={actionOfForm === "preview" || skillCaseInfo?.data?.documentInfo?.isFinalized}>
          <FormEvaluationPeriod
            stage={skillCaseInfo?.data?.documentInfo?.submissionStage?.code}
          />

          <Typography variant="h3">Αποτύπωση Επιπέδου Δεξιοτήτων</Typography>

          {fields.map((item, index) => {
            return (
             (
                <Box mt={2}>
                  <Typography variant="h4">
                    {index +1}.{" "}
                    {item?.skillType?.description}
                  </Typography>

                  <Box display="flex">
                    <Box display="flex" flexDirection="column" gap={1} ml={4}>
                      {mode === "upallilos" ? (
                        <RadioButtonGroup
                          row
                          name={`skillsFormDetails.${index}.isDeveloped`}
                          options={[
                            {
                              id: "1",
                              label: "Ανεπτυγμένη Δεξιότητα",
                            },
                            {
                              id: "2",
                              label: "Προς Ανάπτυξη Δεξιότητα",
                            },
                          ]}
                        />
                      ) : (
                        <></>
                      )}

                      <TextareaAutosizeElement
                        InputProps={{ multiline: true }}
                        multiline
                        label="Αιτιολόγηση"
                        name={`skillsFormDetails.${index}.notes`}
                      />

                      <FromItem
                        code={"row?.code"}
                        mode="2"
                        name={`skillsFormDetails.${index}.targetScore`}
                      />
                    </Box>

                    <Box mx={5} display="flex" alignItems="center">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={async () => {
                          try {
                            enqueueSnackbar(
                              "Υπολογισμός Αυτόματης Βαθμολογίας",
                              { variant: "info" }
                            );
                            const respAuto = await getAutomaticTarget(
                              caseInfo?.data?.skill_selection_type?.[index]
                                ?.code,
                              skillCaseInfo?.data?.documentInfo?.registry?.id,
                              skillCaseInfo?.data?.documentInfo?.registry?.id ==
                                skillCaseInfo?.data?.evaluationGroupSupervisor
                                  ?.id
                            );
                            formContext.setValue(
                              `skillsFormDetails.${index}.targetScore`,
                              String(respAuto?.data) || undefined
                            );
                          } catch (error) {
                            console.error(error);
                            enqueueSnackbar("Αδυναμία αυτόματης βαθμολόγησης", {
                              variant: "error",
                            });
                          }
                        }}
                      >
                        Αυτόματη Βαθμολόγηση
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            );
          })}

          <Box sx={{ marginY: 6 }}>
            <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
            {skillCaseInfo?.data?.evaluationGroupSupervisor && (
              <Box display="flex" justifyContent="center" my={2}>
                <EmployeeCard
                  data={skillCaseInfo?.data?.evaluationGroupSupervisor}
                >
                  <RegistrationEmployeeInfo
                    data={skillCaseInfo?.data?.evaluationGroupSupervisor}
                  />
                </EmployeeCard>
              </Box>
            )}
          </Box>

          {!skillCaseInfoIsLoading &&
            !registryInfoDetailsIsLoading &&
            skillsFormDetails && (
              <Box display="flex" justifyContent="center">
                <GradeDisplay
                  grade={average(
                    skillsFormDetails
                      ?.map((x) => +x.targetScore)
                      .filter((z) => z),
                    caseInfo?.data?.skill_selection_type?.length
                  )?.toFixed(2)}
                />
              </Box>
            )}

          {skillCaseInfo?.data?.documentInfo?.isFinalized && (
            <Typography textAlign="center" variant="h2">
              Η αξιολόγηση έχει οριστικοποιηθεί
            </Typography>
          )}
          {!isPostLoading && (
            <Box display="flex" justifyContent="flex-end">
              {<Button onClick={() => router.navigate(-1)}>Επιστροφή</Button>}
              {(actionOfForm === "edit" || !actionOfForm) &&
                  <GenericModal
                    onAccept={
                      formContext.formState.isValid &&
                      formContext.handleSubmit(onSubmit)
                    }
                    agreeButtonTitle="Οριστικοποίηση"
                    isValid={formContext.formState.isValid}
                    dialogContent="Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποίησετε το Έντυπο Αποτύπωσης Δεξιοτήτων; Μετά την οριστικοποίηση δεν θα έχετε δικαίωμα να επεξεργαστείτε τις βαθμολογίες των κατηγοριών δεξιοτήτων"
                  >
                    <Button
                      disabled={actionOfForm === "preview" || skillCaseInfo?.data?.documentInfo?.isFinalized}
                      type="submit"
                      variant="contained"
                    >
                      <span>Οριστικοποίηση</span>
                    </Button>
                  </GenericModal>
                }
            </Box>
          )}
        </fieldset>
      </FormContainer>
    </Box>
  );
}

export default SharedDextiotitesForm;
