export const defaultEducationLevelRegistrationValues = {
    educationLevel: "",
    certificationTitle: "",
    certificationGrade: "",
    startDate:'',
    endDate:'',
    notes: "",
  }

  export  const formEducationLevelRegistrationSchema = {
    educationLevel: {
      name: "educationLevel",
      label: "Εκπαιδευτικό Επίπεδο",
    },
    certificationTitle: {
      name: "certificationTitle",
      label: "Τίτλος Πιστοποιησης",
    },
    certificationGrade: {
      name: "certificationGrade",
      label: "Βαθμός",
    },
    startDate: {
      name: "startDate",
      label: "Ημερομηνία Από",
    },
    endDate: {
      name: "endDate",
      label: "Ημερομηνία Έως",
    },
    notes: {
      name: "notes",
      label: "Παρατηρήσεις",
    },
  };
  export const educationsLevelColumns = [

    
    {
      id: "educationalLevelType.description",
      label: "Εκπαιδευτικό Επίπεδο",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "certificateTitle",
      label: "Τίτλος Πιστοποίησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "grade",
      label: "Βαθμός",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "startDate",
      label: "Από",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "endDate",
      label: "Εώς",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "notes",
      label: "Παρατηρήσεις",
      minWidth: 170,
      isClickable: false,
    },

  ];