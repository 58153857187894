import { yupResolver } from "@hookform/resolvers/yup";
import SaveModal from "./../../../../components/UI/Modal/SaveModal";

import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import {
  evaluationDetailsTransformer,
  transformSuggToolInspDataToBEFormat,
} from "./../../evaluationDataTranfrormFuncs";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppAccordion from "../../../../components/UI/AppAccordion";
import FormDatePicker from "../../../../components/UI/FormDatePicker";
import { addNewSuggToolInsp, updateSuggToolInsp } from "../../../../services/Evaluations/SuggestionToolsInspections.service";
import Details from "./../../../../components/Details/Details";
import ResetModal from "./../../../../components/UI/Modal/ResetModal";
import { suggToolInspFormFieldValidation } from "./suggToolInspFormFieldValidation";
import {
  defaultSuggestionsToolsInspectionsValues as defaultValues,
  formSuggestionsToolsInspectionsSchema as formSchema,
} from "./suggestionsToolsInspectionsConfig";
import { getEvaluationGroupId } from "../../../../services/Evaluations/EvaluationRegister.service";
import Loader from "../../../../components/UI/Loader";
import { formatDateFNS } from "../../../../Evaluation/Armodiothtes/shared/utils/formatDates";
const AddSuggToolInspForm = ({ data, labels, formTitle, objectiveType }) => {
  const registryId = useParams().id;
  const registry = {
    id: data.id,
    name: data.name,
    surname: data.surname,
    fathersName: data.fathersName,
    fathersSurname: data.fathersSurname,
    mothersName: data.mothersName,
    mothersSurname: data.mothersSurname,
    dateOfBirth: data.dateOfBirth,
    placeOfBirth: data.placeOfBirth,
    adt: data.adt,
    issuingAuthority: data.issuingAuthority,
    afm: data.afm,
    bloodType: data.bloodType,
    amka: data.amka,
    payrollAccountNumber: data.payrollAccountNumber,
    iban: data.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };

  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const employeeId = useParams().id;
  const [evaluationGroupId, setEvaluationGroupId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const selectedFormValues = {
    description: location?.state?.row.description,
    proposalDate: (location?.state?.row.proposalDate),
    isImplemented: location?.state?.row.isImplemented,
    notes: location?.state?.row.notes,
  };
  const typeOfForm = location?.state?.type;
  const formContext = useForm({
    resolver: yupResolver(suggToolInspFormFieldValidation),
    defaultValues: typeOfForm ? selectedFormValues : defaultValues,
  });
  const printPage = () => {
    window.print();
    navigate(`/evaluation-card/${employeeId}/suggestions-tools-inspections`);
  };
  useEffect(() => {
    const getEvaluationGroup = async () => {
      setIsLoading(true);
      console.log(typeOfForm,'typeOfForm')
      const evaluationGroup = await getEvaluationGroupId(registryId);
      console.log(evaluationGroup, "evaluationGroup");
      setEvaluationGroupId(evaluationGroup.id);
      setIsLoading(false);
      !isLoading &&
        data &&
        selectedFormValues &&
        typeOfForm === "print" &&
        setTimeout(printPage, 1500);
    };
    getEvaluationGroup();
  }, [registryId]);
  const navigate = useNavigate();
  const onSave = () => {
    const finalData = typeOfForm === 'edit' ? {...location?.state?.row, description:formData?.description, isImplemented:formData?.isImplemented,
      notes:formData?.notes, proposalDate:formatDateFNS(new Date(formData?.proposalDate)) } :
       transformSuggToolInspDataToBEFormat({
      ...formData,
      position: data?.serviceAccount.position.id,
      institution: data?.serviceAccount.institute.id,
      evaluationGroupId,
      objectiveType: {
        id: objectiveType,
      },
    });
    console.log(finalData,'finalData', location?.state?.row.id)
    typeOfForm === 'edit' ?  updateSuggToolInsp({...finalData, id:location?.state?.row.id, }) : addNewSuggToolInsp({...finalData, id:null});
    setIsShownSaveModal(false);
    navigate(`/evaluation-card/${employeeId}/suggestions-tools-inspections`);
  };

  return (
    <>
      {(isLoading || !data) && <Loader />}
      {!isPreviewMode && !isLoading && !evaluationGroupId && (
        <Typography>
          Ο χρήστης ο οποίος επιλέξατε δεν ανήκει σε κάποια ομάδα αξιολόγησης.
        </Typography>
      )}
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (dataForm) => {
            console.log(dataForm);
            setFormData({ ...dataForm, registry });
            const transformedData = evaluationDetailsTransformer(
              formSchema,
              {
                ...dataForm,
              },
              labels
            );
            setPreviewData(transformedData);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>{labels[0]}</Typography>
            <TextFieldElement
              fullWidth
              id={formSchema.description.name}
              name={formSchema.description.name}
              multiline
              rows={3}
              variant="outlined"
              disabled={typeOfForm === "preview"}
            />
            <Typography sx={{ marginTop: 4 }}>{labels[1]}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormDatePicker
                width={"400px"}
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.proposalDate.name}
                disabled={typeOfForm === "preview"}
              />

              <CheckboxElement
                disableRipple
                defaultValue={false}
                sx={{ paddingLeft: 17, width: "50px" }}
                id={formSchema.isImplemented.name}
                name={formSchema.isImplemented.name}
                disabled={typeOfForm === "preview"}
              />
              <Typography>{labels[2]}</Typography>
            </Box>
            <Typography sx={{ marginTop: 4 }}>{labels[3]}</Typography>

            <TextFieldElement
              fullWidth
              id={formSchema.notes.name}
              name={formSchema.notes.name}
              multiline
              rows={3}
              variant="outlined"
              disabled={typeOfForm === "preview"}
            />
            {typeOfForm !== "preview" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginY: 3,
                  gap: 2,
                }}
              >
                <Button onClick={() => setIsShownResetModal(true)}>
                  Ακύρωση
                </Button>
                <Button type="submit" variant="contained">
                  Προεπισκόπηση
                </Button>
              </Box>
            )}
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
          <AppAccordion
            id="formDetails"
            title={formTitle}
            details={
              <Details
                renderFirstRowsAsHeader
                renderArraysAsColumns
                rows={[
                  {
                    name: previewData.labels[0],
                    value: previewData.labels.slice(1, previewData.length),
                  },
                  {
                    name: previewData.values[0],
                    value: previewData.values.slice(1, previewData.length),
                  },
                ]}
              />
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default AddSuggToolInspForm;
