import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import FormDatePicker from "./FormDatePicker";
import el from "date-fns/locale/el";

const DateRangePickerStyled = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const DateRangePicker = (props) => {
  return (
    <DateRangePickerStyled>
      <LocalizationProvider adapterLocale={el} dateAdapter={AdapterDateFns}>
        <FormDatePicker name={props.start.name} placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"} />
        <Box sx={{ mx: 2 }}> Έως </Box>
        <FormDatePicker name={props.end.name} placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"} />
      </LocalizationProvider>
    </DateRangePickerStyled>
  );
};

export default DateRangePicker;
