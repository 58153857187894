import { Box, Button, Divider, Grid, InputLabel, Paper } from "@mui/material";
import {
  FormContainer,
  RadioButtonGroup,
  useForm,
} from "react-hook-form-mui";
import AppSelect from "./UI/AppSelect";
import ResetFormButton from "./ResetButton";
import { useEffect, useState } from "react";
import {
  getFullNamesAutocomplete,
  getInstitutionPositionDropdown,
} from "../services/dropdownData.service";
import AppInput from "./UI/AppInput";
import useAuth from "../hooks/useBackAuth";

const Filters = (props) => {
  const [institutions, setInstitutions] = useState();
  const [positions, setPositions] = useState();

  const formSchema = {
    name: { name: "name", label: "Όνομα" },
    surname: { name: "surname", label: "Επώνυμο" },
    afm: { name: "afm", label: "ΑΦΜ" },
    institutionId: { name: "institutionId", label: "Kατάστημα Kράτησης" },
    positionId: { name: "positionId", label: "Θέση" },
    // guardType: { name: "guardType", label: "Εσωτερική/Εξωτερική Φρούρηση" },
  };

  useEffect(() => {
    const fetchDropdowns = async () => {
      const dropdowns = await getInstitutionPositionDropdown();
      const mappedInstitutions = dropdowns.institution.map((inst) => ({
        id: inst.id,
        label: inst.description,
      }));

      const mappedPositions = dropdowns.position.map((pos) => ({
        id: pos.id,
        label: pos.description,
      }));
      mappedPositions && console.log(mappedPositions,'mappedPositions')
      setInstitutions(mappedInstitutions);
      setPositions(mappedPositions);
    };
    fetchDropdowns();
  }, [props.filters, props.isAfmIncluded]);

  // const guardTypes = [
  //   { id: "internal", label: "Εσωτερική Φρούρηση" },
  //   { id: "external", label: "Εξωτερική Φρούρηση" },
  // ];

  const { formContext, reset } = useForm({
    defaultValues: props.filters,
  });
  const { hasRole } = useAuth();

  return (
    <Box sx={{ paddingY: 5 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <FormContainer
          formContext={formContext}
          onSuccess={async (data) => {
            props.setFilters(data);
            reset();
          }}
        >
          {" "}
          <h4>Φίλτρα Αναζήτησης</h4>
          <Divider />
          <Grid container spacing={2} sx={{ paddingY: 3 }}>
            <Grid item xs={4}>
              <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ width: 350 }}>
                    <AppInput
                      isLabelBlack={true}
                      label={formSchema.name.label}
                      name={formSchema.name.name}
                      value={formSchema.name.name}
                    />
                  </Box>

                  <Box sx={{ width: 350 }}>
                    <AppInput
                      isLabelBlack={true}
                      label={formSchema.surname.label}
                      name={formSchema.surname.name}
                      value={formSchema.surname.name}
                    />
                  </Box>
                {props.isAfmIncluded && (
                  <Box sx={{ width: 350 }}>
                    <AppInput
                      isLabelBlack={true}
                      label={formSchema.afm.label}
                      name={formSchema.afm.name}
                      value={formSchema.afm.name}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            {hasRole("SuperAdmin") && (
              <Grid item xs={4}>
                {institutions && <AppSelect
                  name={formSchema.institutionId.name}
                  value={formSchema.institutionId.name}
                  label={formSchema.institutionId.label}
                  options={institutions}
                  isMultiple={true}

                />}
              </Grid>
            )}
            <Grid item xs={4}>
              {positions && <AppSelect
                name={formSchema.positionId.name}
                value={formSchema.positionId.name}
                label={formSchema.positionId.label}
                options={positions}
                isMultiple={true}
              />}
            </Grid>
          </Grid>
          {/* <Box>
            <InputLabel
              sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
              id={formSchema.guardType.name}
            >
              {formSchema.guardType.label}
            </InputLabel>

            <RadioButtonGroup
              row
              key={formSchema.guardType.name}
              name={formSchema.guardType.name}
              value={formSchema.guardType.name}
              options={guardTypes}
            />
          </Box> */}
          <Box sx={{ gap: 2, display: "flex", justifyContent: "flex-end" }}>
            <ResetFormButton variant={"outlined"} label={"Καθαρισμός"} />
            <Button variant="contained" type="submit">
              Αναζήτηση
            </Button>
          </Box>
        </FormContainer>
      </Paper>
    </Box>
  );
};
export default Filters;
