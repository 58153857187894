import { TextFieldElement } from "react-hook-form-mui";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import DatePickerFns from "../../../Groups/ui/DatePickerFns";
import { TextField, Typography } from "@mui/material";

function FormEvaluationPeriod({ stage }) {
    return (<>

        <Typography mb={1} variant="h4">
            Χρονική Περίοδος Αξιολόγησης
        </Typography>
        <HorizontalVerticalTogglerLayout direction='column'>

            <HorizontalVerticalTogglerLayout>

                <TextFieldElement
                    inputProps={
                        {
                            readOnly: true
                        }
                    }
                    sx={{
                        input: { color: "grey" },
                    }}

                    fullWidth
                    label="Έτος Αναφοράς"
                    name="referenceYear"
                />

                <DatePickerFns
                    renderInput={(params) => <TextField {...params} />}
                    label="Από"
                    name="startDate"
                />


                <DatePickerFns
                    name="endDate"
                    label="Έως"
                />
            </HorizontalVerticalTogglerLayout>

            <DatePickerFns
                readOnly
                label="Ημερομηνία Υποβολής Εντύπου"
                name="submissionDate" />

            <TextFieldElement
                inputProps={
                    {
                        readOnly: stage == "stage2" || stage == "stage3"
                    }
                }
                sx={{
                    input: { color: stage == "stage2" || stage == "stage3" ? "grey" : 'inherit' },
                }}


                name="formProtocolNumber"
                label="Αριθμός Πρωτοκόλλου Εντύπου"
            />

        </HorizontalVerticalTogglerLayout>

    </>);
}

export default FormEvaluationPeriod;