export const registryEvaluationGroupDataGridColumns = [
    {
        field: 'fullName',
        flex: 1,
        headerName: 'Ονοματεπώνυμο',
        width: 70,
        renderCell: ({ row }) => <div>{row?.surname || ""} {row?.name || ""}</div>
    },
    {
        field: 'afm',
        flex: 1,
        headerName: 'ΑΦΜ',
        width: 130,
    },
    {
        field: 'grade',
        flex: 1,
        headerName: 'Θέση στο Κατάστημα Κράτησης',
        width: 130,
        renderCell: ({ row }) => <div>{row?.serviceAccount?.position?.description || ""}</div>
    },
    {
        field: 'dateOfEntry',
        flex: 1,
        headerName: 'Ημερομηνία Ένταξης στην Ομάδα Αξιολόγησης',
        type: 'number',
        width: 90,
        renderCell: ({ row }) => <div>{row?.evaluationGroupIntegrationDate || ""}</div>
    },
]


export const registryEvaluationPREVIEWGroupDataGridColumns = [
    {
        field: 'fullName',
        flex: 1,
        headerName: 'Ονοματεπώνυμο',
        renderCell: ({ row }) => <div>{row?.surname || ""} {row?.name || ""}</div>
    },
    {
        field: 'afm',
        flex: 1,
        headerName: 'ΑΦΜ',
        renderCell: ({ row }) => <div>{row?.afm || ""} </div>

    },
    {
        field: 'grade',
        flex: 1,
        headerName: 'Θέση στο Κατάστημα Κράτησης',
        renderCell: ({ row }) => <div>{row?.serviceAccount?.position?.description || ""}</div>
    },
    {
        field: 'dateOfEntry',
        flex: 1,
        headerName: 'Ημερομηνία Ένταξης στην Ομάδα Αξιολόγησης',
        type: 'number',
        width: 90,
        renderCell: ({ row }) => <div>{row?.evaluationGroupIntegrationDate || ""}</div>
    },
]


