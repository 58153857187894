import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { AutocompleteElement, FormContainer, RadioButtonGroup, SelectElement, TextFieldElement, useForm, useWatch } from "react-hook-form-mui";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import HorizontalVerticalTogglerLayout from "../../../components/Layout/HorizontalVerticalTogglerLayout";
import { useGetEvaluationGroup } from "../data-access/useGetEvaluationGroup";
import { useGetRegistries } from "../data-access/useGetRegistries";
import { resetFormikForm, transformer } from "../utils/transfromer";
import DatePickerFns from "./DatePickerFns";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidationSchema from "../feature/create/validationSchema";
import { useGetPossibleSupervisors } from "../data-access/useGetPossibleSupervisors";
import { useGetPossibleEvaluators } from "../data-access/useGetPossibleEvaluators";
import useAuth from "../../../hooks/useBackAuth";

function CreateUpdateEvaluationGroup({ mode = 'create' }) {
    const { id: editEvaluationGroupId } = useParams();
    const { data: groupEditDetails, isLoading: groupEditDetailsIsLoading, } = useGetEvaluationGroup(editEvaluationGroupId);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const dataFromPreviewGoingBack = location?.state
    const instituteId = searchParams.get("instituteId") || groupEditDetails?.data?.institution?.id;
    // useEffect(() => {
    //     if (mode == 'create')
    //         if (!instituteId)
    //             navigate('/evaluation/groups')
    // }, [])

    const { data: possibleSupervisorsData, isLoading: possibleSupervisorsIsLoading } = useGetPossibleSupervisors(instituteId)
    const { data: possibleEvalatorsData, isLoading: possibleEvalatorsIsLoading } = useGetPossibleEvaluators(instituteId)
    const { data: instituteRegistries, isLoading: instituteRegistriesLoading } = useGetRegistries(instituteId)


    const [possibleSupervisors, setPossibleSupervisors] = useState([])
    const [possibleEvalators, setPossibleEvalators] = useState([])

    const formContext = useForm({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {

        }
        // dataFromPreviewGoingBack || (!instituteRegistriesLoading && instituteRegistries?.data) || null
    })

    const formSchema = {
        creationDate: {
            name: 'creationDate',
            label: mode === 'create' ? 'Ημερομηνία Δημιουργίας Ομάδας' : 'Ημερομηνία Επεξεργασίας Ομάδας',
        },
        groupName: {
            name: 'groupName',
            label: 'Όνομα Ομάδας',
        },

        isInternalGuard: {
            name: 'isInternalGuard',
            label: 'Εσωτερική Φρούρηση',
            options: [
                {
                    id: '1',
                    label: 'Εσωτερική Φρούρηση'
                },
                {
                    id: '2',
                    label: 'Εξωτερική Φρούρηση'
                }
            ],
        },

        superVisor: {
            name: 'superVisor',
            label: "Αξιολογητής",
        },
        registries: {
            name: 'registries',
            label: "Αξιολογούμενοι",
        }
    }

    const isInternalGuard = useWatch({ control: formContext.control, name: formSchema.isInternalGuard.name })
    const selectedSuperVisor = useWatch({ control: formContext.control, name: formSchema.superVisor.name })



    useEffect(() => {
        if (dataFromPreviewGoingBack) {
            formContext.reset(dataFromPreviewGoingBack)
        }
        if (mode === 'edit' && !dataFromPreviewGoingBack) {
            const superVisor = groupEditDetails?.data?.registries.find((row) => row?.isEvaluationGroupSupervisor)
            const registries = groupEditDetails?.data?.registries.filter((row) => !row?.isEvaluationGroupSupervisor)
            formContext.reset({
                groupName: groupEditDetails?.data?.groupName || "",
                creationDate: groupEditDetails?.data?.creationDate,

                isInternalGuard: groupEditDetails?.data?.isInternalGuard ? '1' : '2',
                superVisor: !superVisor ? null : {
                    id: superVisor?.id || "",
                    label: `${superVisor?.name || ""} ${superVisor?.surname || ""} - ${superVisor?.afm || ""} - ${superVisor?.serviceAccount?.position?.description || ""}`,
                    details: superVisor
                },
                registries: registries?.map((registry) => {
                    return {
                        id: registry?.id || "",
                        label: `${registry?.name || ""} ${registry?.surname || ""} - ${registry?.afm || ""} - ${registry?.serviceAccount?.position?.description || ""}`,
                        details: registry
                    }
                }) || []



            })
        }

    }, [groupEditDetailsIsLoading])




    // Possible Supervisors
    useEffect(() => {
        if (groupEditDetailsIsLoading || possibleSupervisorsIsLoading || possibleEvalatorsIsLoading) {
            return;
        }
        const _possibleSupervisors = possibleSupervisorsData?.data?.filter(z => z.serviceAccount?.isInternalGuard === (isInternalGuard == '1'))?.map((registry) => {
            if (!registry) return null;

            return {
                id: registry.id,
                label: `${registry.name} ${registry.surname} - ${registry.afm} - ${registry?.serviceAccount?.position?.description || ""}`,
                details: registry
            }
        });

        console.log(_possibleSupervisors);

        const appendedSupervisorOnEdit = groupEditDetails?.data?.registries?.find((row) => row?.isEvaluationGroupSupervisor);
        if (appendedSupervisorOnEdit)
            setPossibleSupervisors([..._possibleSupervisors, {
                id: appendedSupervisorOnEdit?.id,
                label: `${appendedSupervisorOnEdit?.name} ${appendedSupervisorOnEdit?.surname} - ${appendedSupervisorOnEdit?.afm} - ${appendedSupervisorOnEdit?.serviceAccount?.position?.description || ""}`,
                details: appendedSupervisorOnEdit
            }])
        else {
            setPossibleSupervisors(_possibleSupervisors)
        }

    }, [groupEditDetailsIsLoading,
        possibleSupervisorsIsLoading,
        possibleEvalatorsIsLoading,
        isInternalGuard
    ])


    useEffect(() => {
        setPossibleEvalators(possibleEvalatorsData?.data?.filter(z =>
            selectedSuperVisor?.details?.serviceAccount?.position?.code === 'admin'
                ? true : z?.serviceAccount?.isInternalGuard === (isInternalGuard === '1'))?.filter(xy => {
                    const positionCode = xy?.serviceAccount?.position?.code;
                    const superVisorIsSergeant = selectedSuperVisor?.details?.serviceAccount?.position?.code?.includes('sergeant');

                    if (superVisorIsSergeant && (positionCode?.includes('sergeant') && !positionCode?.includes('lt_sergeant'))) {
                        return false;
                    }
                    return true
                }
                )?.map((registry) => {
                    if (!registry) return null;

                    return {
                        id: registry?.id,
                        label: `${registry?.name} ${registry?.surname} - ${registry?.afm} - ${registry?.serviceAccount?.position?.description || ""}`,
                        details: registry
                    }
                }))

    }, [selectedSuperVisor,
        groupEditDetailsIsLoading,
        possibleSupervisorsIsLoading,
        possibleEvalatorsIsLoading])




    // RESET FIELDs WHEN CHANGING SUPERVISOR
    useEffect(() => {
        if (!formContext.getFieldState('superVisor')?.isDirty) return;
        formContext.resetField('registries', { defaultValue: [] })
    }, [selectedSuperVisor]);



    // RESET FIELDs WHEN CHANGING INTERNAL GUARD
    useEffect(() => {
        if (!formContext.getFieldState('isInternalGuard')?.isDirty) return;
        formContext.resetField('superVisor', { defaultValue: [] })
        formContext.resetField('registries', { defaultValue: [] })

    }, [isInternalGuard]);

    const { userInfo } = useAuth()
    return (<>

        <FormContainer
            formContext={formContext}

            onSuccess={async (data) => {
                console.log(data);
                navigate('/evaluation/groups/preview', {
                    state: {
                        mode,
                        data,
                        instituteId: instituteId || userInfo?.serviceAccount?.institute?.id,
                        evaluationGroupId: editEvaluationGroupId,
                        names: transformer(formSchema, ['externalGuard'])
                    }
                })


            }}
        >

            <HorizontalVerticalTogglerLayout direction={'column'}>

                <RadioButtonGroup
                    labelProps={{
                        sx: {
                            color: mode != 'create' ? "grey" : "inherit",
                            pointerEvents: mode != 'create' ? "none !important" : "auto",
                        },
                    }}

                    name={formSchema.isInternalGuard.name}
                    options={formSchema.isInternalGuard.options}
                />

                <DatePickerFns

                    name={formSchema.creationDate.name}
                    label={formSchema.creationDate.label}
                />

                <TextFieldElement
                    name={formSchema.groupName.name}
                    label={formSchema.groupName.label}
                />

                {isInternalGuard != null && <AutocompleteElement
                    autocompleteProps={{ readOnly: mode !== "create" }}
                    loading={possibleSupervisorsIsLoading}
                    name={formSchema.superVisor.name}
                    label={formSchema.superVisor.label}
                    textFieldProps={{
                        sx: {
                            input: {
                                color: mode !== "create" ? 'grey' : 'inherit',
                            }
                        },

                        helperText: "Αναζητήστε με όνομα ή ΑΦΜ"
                    }}

                    // autocompleteProps={{
                    //     filterOptions: (options, params) => { console.log(options, params); return options.filter((option) => option.test.toLowerCase().includes(params.inputValue.toLowerCase())); },
                    // }}

                    options={possibleSupervisors}
                />}

                {isInternalGuard != null && selectedSuperVisor && <AutocompleteElement
                    loading={possibleEvalatorsIsLoading}
                    multiple={true}

                    textFieldProps={{ helperText: "Αναζητήστε με όνομα ή ΑΦΜ" }}
                    label={formSchema.registries.label}
                    name={formSchema.registries.name}
                    options={possibleEvalators || []} />}



            </HorizontalVerticalTogglerLayout>

            <div>
            </div>
            <Box display="flex" justifyContent="flex-end" gep={3}>

                <Button variant="outlined" onClick={() => resetFormikForm(formContext, formSchema)}><span>Καθαρισμός</span></Button>
                <Button variant="contained" type="submit"><span>Προεπισκόπηση</span></Button>
            </Box>
            {/* <pre>{JSON.stringify(values, null, 2)} sfsfd</pre> */}

        </FormContainer >
    </>);
}

export default CreateUpdateEvaluationGroup;