import { Box, InputLabel } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import FormDatePicker from "../../components/UI/FormDatePicker";

const WifeData = (props) => {
  const keys = Object.keys(props.values);
  const keyIndexes = [0, 1, 2];
  return (
    <Box sx={{ width: "100%" }} key={props.id}>
      {keyIndexes.map((item) => (
        <Box key={item}>
          <InputLabel
            key={props.id}
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={props.values[keys[item]].name}
          >
            {props.values[keys[item]].label}
          </InputLabel>
          {props.values[keys[item]].name
            .toString()
            .toLowerCase()
            .includes("date") ? (
            <FormDatePicker
            readOnly={props.readOnly}
              name={props.values[keys[item]].name}
            />
          ) : (
            <TextFieldElement
              key={props.values[keys[item]].name}
              fullWidth
              inputProps={{
                readOnly: props.isPreviewMode,
              }}
              sx={{
                input: { color: props.isPreviewMode ? "grey" : "inherit" },
              }}
              id={props.values[keys[item]].name}
              name={props.values[keys[item]].name}
              value={props.values[keys[item]].name}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
export default WifeData;
