import * as yup from "yup";

const reportWrapperValidation= yup.object().shape({
    startDate: yup
      .date()
      .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
      .required("Το πεδίο είναι υποχρεωτικό"),
    endDate: yup
      .date()
      .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
      .required("Το πεδίο είναι υποχρεωτικό"),
      formProtocolNumber:yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      meetingDate:yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      'skillToDevelop1':yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      'skillToDevelop2':yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      'skillToDevelop3':yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      'developedSkill1':yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      'developedSkill2':yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      'developedSkill3':yup.string().required("Το πεδίο είναι υποχρεωτικό"),

  });

  export default reportWrapperValidation