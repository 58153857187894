import { Box, Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import AppAccordion from "../../../components/UI/AppAccordion";
import Loader from "../../../components/UI/Loader";
import { getRegistryById } from "../../../services/PersonnelManagement.service";
import EmployeeCard from "../../EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Registrations/RegistrationEmployeeInfo";
import useAuth from "../../../hooks/useBackAuth";
import HttpService from "../../../services/httpClient";
import { enqueueSnackbar } from "notistack";

const EvaluationCard = () => {
  const employeeId = useParams().id;
  const [userData, setUserData] = useState();
  const [type, setType] = useState();
  const navigate = useNavigate();
  const { userInfo, hasRole, setReferenceUser } = useAuth();

  useEffect(() => {
    const employeeIds = [351, 401, 451, 201, 151];
    const fetchAndSetUserData = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
      setReferenceUser(employeeId);
      employeeIds.includes(data?.serviceAccount?.position?.id)
        ? setType("type2")
        : setType("type1");
    };

    fetchAndSetUserData();
  }, [employeeId]);
  console.log(
    userData?.registryEvaluationGroup?.isSupervisor ||
    userData?.supervisorEvaluationGroup?.isSupervisor,
    "data"
  );
  return (
    <Box>
      {userData && (
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: "Μητρώο Αξιολόγησης Προσωπικού",
              url: "/personnel-registry-evaluation",
            },
            {
              title: `Καρτέλα Αξιολογήσεων: ${userData.surname}  ${userData.name}  ${userData.afm} `,
              url: "/",
            },
          ]}
        />
      )}
      <Box>
        {userData && (
          <Box>
            <h2>{`Καρτέλα Αξιολογήσεων: ${userData.surname}  ${userData.name}  ${userData.afm} `}</h2>
            <Divider sx={{ bgcolor: "black.main" }} />
          </Box>
        )}

        <Box sx={{ marginLeft: 9, marginTop: 6, marginBottom: 0 }}>
          <h3>Στοιχεία Προσωπικού</h3>
        </Box>
        <Box
          sx={{
            marginY: 8,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {userData ? (
            <EmployeeCard data={userData}>
              <RegistrationEmployeeInfo data={userData} />
            </EmployeeCard>
          ) : (
            <Loader />
          )}
          <Box
            sx={{
              display: "flex",
              marginTop: 4,
              width: "100%",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <AppAccordion
              id="evaluationSelection"
              title="Επιλογή Αξιολογήσεων"
              details={
                <>
                  {/* {!hasRole("employee") && ( */}
                    <Button
                      onClick={() => navigate("assessment-procedures")}
                      style={{ justifyContent: "flex-start" }}
                      fullWidth
                    >
                      Διενέργειες Αξιολογήσεων
                    </Button>
                  <Button
                    onClick={() => navigate("subordinate-evaluations")}
                    style={{ justifyContent: "flex-start" }}
                    fullWidth
                  >
                    Υφιστάμενες Αξιολογήσεις
                  </Button>

                  <Button
                    onClick={() => navigate("group-pulse-form")}
                    style={{ justifyContent: "flex-start" }}
                    fullWidth
                  >
                    Συμπληρώσεις Εντύπου Σφυγμού Ομάδας
                  </Button>

                </>
              }
            />
            {userInfo?.id !== employeeId &&
              userInfo?.supervisorEvaluationGroup &&
              (hasRole("admin") || hasRole("supervisor")) &&
              userInfo?.supervisorEvaluationGroup?.evaluationGroup.id ===
              userData?.registryEvaluationGroup?.evaluationGroup?.id && (
                <AppAccordion
                  id="helperForms"
                  title="Βοηθητικά Έντυπα Αξιολογήσεων"
                  details={
                    <>
                      <Button
                        onClick={async () => {
                          try {
                            const resp =
                              await HttpService.getAxiosClient().post(
                                `/model-targeting-form/model-form-${userData?.registryEvaluationGroup
                                  ?.isSupervisor ||
                                  userData?.supervisorEvaluationGroup
                                    ?.isSupervisor
                                  ? "1"
                                  : "2"
                                }/registry/${employeeId}/${userData?.registryEvaluationGroup
                                  ?.isSupervisor ||
                                  userData?.supervisorEvaluationGroup
                                    ?.isSupervisor
                                  ? "isSupervisor"
                                  : "isEmployee"
                                }`
                              );
                            enqueueSnackbar(
                              "Δημιουργία Υποδείγματος Στοχοθεσίας",
                              { variant: "success" }
                            );
                            navigate(
                              `/evaluation/modelTargetUpodeigmatos/${type}/${resp.data}`
                            );
                          } catch (error) {
                            enqueueSnackbar(
                              "Αδυναμία Δημιουργίας Υποδείγματος Στοχοθεσίας",
                              { variant: "error" }
                            );
                          }
                        }}
                        style={{ justifyContent: "flex-start" }}
                        fullWidth
                      >
                        Δημιουργία Υποδείγματος Στοχοθεσίας{" "}
                      </Button>
                      <Button
                        onClick={async () => {
                          try {
                            const resp =
                              await HttpService.getAxiosClient().post(
                                `/model-targeting-form/model-form-${userData?.registryEvaluationGroup
                                  ?.isSupervisor ||
                                  userData?.supervisorEvaluationGroup
                                    ?.isSupervisor ? "3"
                                  : "4"
                                }/registry/${employeeId}/${userData?.registryEvaluationGroup
                                  ?.isSupervisor ||
                                  userData?.supervisorEvaluationGroup
                                    ?.isSupervisor
                                  ? "isSupervisor"
                                  : "isEmployee"
                                }`
                              );
                            enqueueSnackbar(
                              "Δημιουργία Υποδείγματος Ανάπτυξης και Αποτύπωσης Δεξιότητας",
                              { variant: "success" }
                            );
                            navigate(
                              `/evaluation/modelTargeting/${type}/${resp.data}`
                            );
                          } catch (error) {
                            enqueueSnackbar(
                              "Αδυναμία Δημιουργίας Υποδείγματος Ανάπτυξης και Αποτύπωσης Δεξιότητας",
                              { variant: "error" }
                            );
                          }
                        }}
                        style={{ justifyContent: "flex-start" }}
                        fullWidth
                      >
                        Δημιουργία Υποδείγματος Ανάπτυξης και Αποτύπωσης
                        Δεξιότητας
                      </Button>
                    </>
                  }
                />
              )}
            <AppAccordion
              id="tools"
              title="Προτάσεις - Εργαλεία - Επιθεωρήσεις"
              details={
                <>
                  <Button
                    onClick={() => navigate("suggestions-tools-inspections")}
                    style={{ justifyContent: "flex-start" }}
                    fullWidth
                  >
                    Προβολή Προτάσεων - Εργαλείων - Επιθεωρήσεων
                  </Button>
                </>
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EvaluationCard;
