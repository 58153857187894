import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import Details from "../../../components/Details/Details";
import AppAccordion from "../../../components/UI/AppAccordion";
import FormDateRangePicker from "../../../components/UI/FormDateRangePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";

import { getEducationLevelDropdown } from "../../../services/dropdownData.service";
import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import {
  defaultEducationLevelRegistrationValues as defaultValues,
  formEducationLevelRegistrationSchema as formSchema,
} from "./educationLevelRegistrationConfig";
import { addNewEducationLevelRegistry } from "../../../services/Registrations/EducationLevel.service";
import { useNavigate, useParams } from "react-router-dom";
import { registrationDetailsTransformer, transformEducationLevelDataToBEFormat } from "../dataTransformFunctions";
import { educationLevelFieldValidation } from './educationLevelFieldValidation';
const EducationLevelRegistrationForm = ({ data }) => {
  const registry = {
    id: data.id,
    name: data.name,
    surname: data.surname,
    fathersName: data.fathersName,
    fathersSurname: data.fathersSurname,
    mothersName: data.mothersName,
    mothersSurname: data.mothersSurname,
    dateOfBirth: data.dateOfBirth,
    placeOfBirth: data.placeOfBirth,
    adt: data.adt,
    issuingAuthority: data.issuingAuthority,
    afm: data.afm,
    bloodType: data.bloodType,
    amka: data.amka,
    payrollAccountNumber: data.payrollAccountNumber,
    iban: data.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,

  };
  const formContext = useForm({
    resolver: yupResolver(educationLevelFieldValidation),
    defaultValues: defaultValues,
  });
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [educationLevelTypes, setEducationLevelTypes] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const employeeId = useParams().employeeId;

  const navigate = useNavigate();
  const onSave = () => {
    const data = transformEducationLevelDataToBEFormat(
      formData,
      educationLevelTypes
    );
    educationLevelTypes && addNewEducationLevelRegistry(data);
    setIsShownSaveModal(false);
    navigate(`/employee-info/${employeeId}`);
  };
  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const educationLevelOptions = await getEducationLevelDropdown();
      setEducationLevelTypes(educationLevelOptions);
      setEducationLevelOptions(
        educationLevelOptions["educational_level_type"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    fetchBEConfigInfo();
  }, []);

  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (educationLevelData) => {
            setFormData({ ...educationLevelData, registry });
            const educationLevelTypeDescription =
              educationLevelOptions.find(
                (option) => option.id === +educationLevelData.educationLevelType
              )?.label;
              console.log(data)
            const transformedData = registrationDetailsTransformer(formSchema, {
              ...educationLevelData,
              educationLevelType: educationLevelTypeDescription,
              userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
              userPosition: data.serviceAccount.position.description,
            });
            setPreviewData(transformedData);
            console.log(educationLevelTypeDescription);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >

<RegistrationFormItem label={formSchema.educationLevel.label}>
              {educationLevelOptions && (
                <SelectElement
                  fullWidth
                  label={formSchema.educationLevel.label}
                  name={formSchema.educationLevel.name}
                  options={educationLevelOptions}
                />
              )}
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.startDate.label}>
              <FormDateRangePicker
                end={formSchema.endDate}
                start={formSchema.startDate}
              />
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.certificationTitle.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.certificationTitle.label}
                name={formSchema.certificationTitle.name}
                variant="outlined"
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.certificationGrade.label}>
              <TextFieldElement
                fullWidth
                type="number"
                id={formSchema.certificationGrade.label}
                name={formSchema.certificationGrade.name}
                variant="outlined"
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
                    <h4> {`Εκπαιδευτικό Επίπεδο ${previewData.values[6]}`}</h4>
{
              <Details
                renderFirstRowsAsHeader
                renderArraysAsColumns
                rows={[
                  {
                    name: previewData.labels[0],
                    value: previewData.labels.slice(1, previewData.length),
                  },
                  {
                    name: previewData.values[0],
                    value: previewData.values.slice(1, previewData.length),
                  },
                ]}
              />
            }
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default EducationLevelRegistrationForm;
