import * as Yup from 'yup';

const validationSchema = (data) => {
    if (!data) return Yup.object().shape({});
    return Yup.object().shape({

        referenceYear: Yup.number().required('Απαιτείται το Έτος Αναφοράς'),
        startDate: Yup.date().required('Απαιτείται Ημερομηνία Έναρξης'),
        endDate: Yup.date().required('Απαιτείται Ημερομηνία Λήξης').min(Yup.ref('startDate'), 'Η Ημερομηνία Λήξης πρέπει να είναι μετά την Ημερομηνία Έναρξης'),
        submissionDate: Yup.date().required('Απαιτείται Ημερομηνία Υποβολής'),
        formProtocolNumber: Yup.string().required('Απαιτείται Αριθμός Πρωτοκόλλου Φόρμας'),
        nested: Yup.object().shape(
            (data || {}).reduce((accumulator, key) => {
                accumulator[`_${key.id}`] = Yup.string().required('Το πεδίο είναι υποχρεωτικό');
                return accumulator;
            }, {})
        ),
    });
}

export default validationSchema;
