import HttpService from "../../../services/httpClient";

export const getFormContext = (responsibility_type) => HttpService.getAxiosClient().get(`coded-type/codes/${responsibility_type}`)

export const getInfo = () => HttpService.getAxiosClient().get('responsibility/information')

export const getRegistryEvaluationRegistryInfoDetails = (id) => { return !id ? { data: {} } : HttpService.getAxiosClient().get(`registry-evaluation/group/registry-info/${id}`) }

export const getEvaluationOfCompetencies = (id) => HttpService.getAxiosClient().get(`evaluation-of-competency/document-info/${id}`)

export const postArmodiotites = (data, id) => HttpService.getAxiosClient().post(`evaluation-of-competency/document/info`, data)