import { RadioButtonGroup } from "react-hook-form-mui";
import { Box, IconButton, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

function FromItem({
    context,
    description = '',
    name,
    code,
    mode = 'boolo',
    currentStage,
    info 
}) {

    const options = mode == 'boolo' ? [
        {
            id: '1',
            code,
            label: 'Οχι'
        },
        {
            id: '2',
            code,
            label: 'Ναι'
        },
    ] : [
        {
            id: '1',
            code,
            label: 'Εμφανίζει Ελάχιστα ή Καθόλου Στοιχεία της Δεξιότητας'
        },
        {
            id: '2',
            code,
            label: 'Εμφανίζει Περιορισμένα Στοιχεία της Δεξιότητας'
        },
        {
            id: '3',
            code,
            label: 'Εμφανίζει Επαρκή Στοιχεία της Δεξιότητας'
        },
        {
            id: '4',
            code,
            label: 'Εμφανίζει Αρκετά Στοιχεία της Δεξιότητας'
        },
        {
            id: '5',
            code,
            label: 'Εμφανίζει Πολλά Στοιχεία της Δεξιότητας'
        },]

    return (
        <>
            <Box sx={{ display:'flex'}}>
                <Typography sx={{
                    color: (context?.stage == 'stage:1' || context?.stage == 'stage:2') || (context?.stage == 'stage:3' && !context?.isOther)
                        ? "grey"
                        : "inherit",
                    pointerEvents: (context?.stage == 'stage:1' || context?.stage == 'stage:2') || (context?.stage == 'stage:3' && !context?.isOther)
                        ? "none !important"
                        : "auto",
                }}>{description}</Typography>
                {info && <Box sx={{ display: 'flex', alignItems: 'flex-start', alignSelf:'flex-start' }}>
                    <IconButton sx={{ml:1, p:0}} color="primary" size="medium" >
                        <InfoIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>
                        {info}
                    </Typography>
                </Box>}
            </Box>

            <RadioButtonGroup

                disabled={currentStage == 'stage:1' || currentStage == 'stage:2'}
                row
                name={name}
                options={options}
            />

        </>
    );
}

export default FromItem;