import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import { DatePickerElement } from "react-hook-form-mui";
import el from "date-fns/locale/el";
import de from "date-fns/locale/de";

export default function FormDatePicker(props) {
  return (
    <LocalizationProvider
      adapterLocale={el}
      dateAdapter={AdapterDateFns}
      >
      <DatePickerElement
        // {...props}
        
        name={props.name}
        inputProps={{
          placeholder: props.placeholder ? props.placeholder : "",
        }}
        format="dd/MM/yyyy"
        readOnly={props.readOnly}
        sx={{
          width: props.width ? props.width : "100%",
          "& .MuiInputBase-input": {
            label: "transparent",
          },
        }}
      />
    </LocalizationProvider>
  );
}
