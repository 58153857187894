import RegistrationWrapper from "../RegistrationWrapper";
import EducationRegistrationForm from "./EducationRegistrationForm";

const EducationRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Εκπαίδευσης"}
      FormComponent={EducationRegistrationForm}
    />
  );
};
export default EducationRegistration;
