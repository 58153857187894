import HttpService from "../httpClient";
export const getServiceGradeRegistries = (page, size, registryId) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-grade`, {
        params: {
          page,
          size,
          "registryId.equals": registryId,
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const addNewServiceGradeRegistry = (registry) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .post(`/registry-grade`, { ...registry })
      .then(function (response) {
        const registry = response.data;
        console.log(registry);
        resolve(registry);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteServiceGradeRegistryById = async (gradeId,registryId) => {
  console.log(gradeId,registryId, 'gradeId,registryId')
  try {
    const resp = await HttpService.getAxiosClient().delete(`/registry-grade/${gradeId}/registry/${registryId}`);
    console.log(resp.data.message,'message');

    return resp.data
  } catch (error) {
    console.log(error.response.data.message,'message');
    throw error;
  }
};
