import { Typography } from "@mui/material";
import EntipoYpodigmatosStoxothesiasStage1 from "./Stage1";
import EntipoYpodigmatosStoxothesiasStage2 from "./stage2/Stage2";
import EntipoYpodigmatosStoxothesiasStage3 from "./Stage3";


const stageComponents = {
    "stage1": { title: 'Στάδιο 1', component: EntipoYpodigmatosStoxothesiasStage1  },
    "stage2": { title: 'Στάδιο 2', component: EntipoYpodigmatosStoxothesiasStage2 },
    "stage3": { title: 'Στάδιο 3', component: EntipoYpodigmatosStoxothesiasStage3 },
};

export const RenderStage = ({ stage }) => {
    const StageComponent = stageComponents[stage];
    if (!StageComponent) {
        return <Typography>Διαλέξτε ένα Στάδιο</Typography>;

    }

    const { title, component: Component } = StageComponent;

    return (
        <>
            <Typography sx={{my:2}} variant="h2">
                {title}
            </Typography>
            <Component />
        </>
    );
}