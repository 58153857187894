import { Typography } from "@mui/material";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import { TextFieldElement } from "react-hook-form-mui";
import DatePickerFns from "../../../Groups/ui/DatePickerFns";

const  DevelopmentDocPeriod = (props) =>{
  return (
    <>
      <Typography mb={1} variant="h4">
        Χρονική Περίοδος Αξιολόγησης*
      </Typography>
      <HorizontalVerticalTogglerLayout direction="column">
        <HorizontalVerticalTogglerLayout>
          <TextFieldElement
            fullWidth
            type="number"
            label="Έτος Αναφοράς"
            name="referenceYear"
            disabled={true}
          />

          <DatePickerFns label="Από" name="startDate" />

          <DatePickerFns name="endDate" label="Έως" />
        </HorizontalVerticalTogglerLayout>
        <DatePickerFns
          label="Ημερομηνία Υποχρεωτικής Συνάντησης Προγραμματισμού Εργασιών"
          name="meetingDate"
          disabled={props.stage === 'stage:2'|| props.stage === 'stage:3'}
        />
         <DatePickerFns
          label="Ημερομηνία Υποχρεωτικής Συνάντησης Επισκόπησης Προόδου"
          name="reviewDate"
          disabled={props.stage === 'stage:1' || props.stage === 'stage:3'}
        />
         <DatePickerFns
          label="Ημερομηνία Υποχρεωτικής Συνάντησης Συνολικής Αξιολόγησης"
          name="evaluationDate"
          disabled={props.stage === 'stage:1' || props.stage === 'stage:2'}
        />
        <DatePickerFns
          label="Ημερομηνία Υποβολής Εντύπου"
          name="submissionDate"
          disabled={true}
        />

        <TextFieldElement
          name="formProtocolNumber"
          label="Αριθμός Πρωτοκόλλου Εντύπου"
          disabled={props.stage === 'stage:2'|| props.stage === 'stage:3'}

        />
      </HorizontalVerticalTogglerLayout>
    </>
  );
}

export default DevelopmentDocPeriod;
