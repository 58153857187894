import { RadioButtonGroup, useWatch } from "react-hook-form-mui";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import { Typography } from "@mui/material";
import Case1 from "./case1";
import Case2 from "./case2";
import Case3 from "./case3";
const RenderCaseStage2 = ({ stage }) => {
  const stageComponents = {
    20001: { title: "Εξέλιξη Επίτευξης Επιλεγμένου Στόχου", component: Case1 },
    9901: {
      title: "Αναθεώρηση Βαθμού Επίτευξης Στόχου",
      component: Case2,
    },
    9951: { title: "Αναθεώρηση Στόχου (Νέος Στόχος)", component: Case3 },
  };

  const StageComponent = stageComponents[stage];
  if (!StageComponent) {
    return <Typography>Διαλέξτε ένα Στάδιο</Typography>;
  }

  const { title, component: Component } = StageComponent;

  return (
    <>
      <Typography variant="h2">{title}</Typography>
      <Component />
    </>
  );
};

function EntipoYpodigmatosStoxothesiasStage2() {
  const value = useWatch({ name: "modelTargetType" });

  return (
    <>
      <HorizontalVerticalTogglerLayout direction="column">
        <RadioButtonGroup
          row
          name={"modelTargetType"}
          options={[
            {
              id: "20001",
              label: "Εξέλιξη Επίτευξης Επιλεγμένου Στόχου",
            },
            {
              id: "9901",
              label: "Αναθεώρηση Βαθμού Επίτευξης Στόχου",
            },
            {
              id: "9951",
              label: "Αναθεώρηση Στόχου (Νέος Στόχος)",
            },
          ]}
        />

        <RenderCaseStage2 stage={value} />
      </HorizontalVerticalTogglerLayout>
    </>
  );
}

export default EntipoYpodigmatosStoxothesiasStage2;
