import { Box, Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import Filters from "../../../components/Filters";
import AppTable from "../../../components/UI/AppTable";
import Loader from "../../../components/UI/Loader";
import { getFilteredEvaluationRegistries } from "../../../services/Evaluations/EvaluationRegister.service";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";
import { getFilteredRegistries } from "../../../services/PersonnelManagement.service";
import { adminColumns, columns } from "../EvaluationColumnsConfig";

const EvaluationRegister = () => {
  const navigate = useNavigate();
  const initialFilterValues = {
    name: "",
    surname: "",
    institutionId: "",
    positionId: "",
    guardType: "",
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilterValues);
  const { userInfo, hasRole } = useAuth();

  const handleClick = (row) => {
    hasRole("admin") || hasRole("SuperAdmin")
      ? navigate(`/evaluation-card/${row.id}`)
      : navigate(`/evaluation-card/${row.registry.id}`);
  };
  useEffect(() => {
    const fetchAndSetRegistries = async () => {
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      setIsLoading(true);
      const allRegistries = await getFilteredEvaluationRegistries({
        page,
        size: rowsPerPage,
        name: nonEmptyFilters.name,
        surname: nonEmptyFilters.surname,
        afm: nonEmptyFilters.afm,
        guardType: nonEmptyFilters.guardType,
        evaluationGroupId: hasRole("supervisor")
          ? userInfo.supervisorEvaluationGroup.evaluationGroup.id || undefined
          : undefined,
        institutionIds: hasRole("admin")
          ? userInfo?.serviceAccount?.institute?.id ||
            UserService.profile()?.attributes?.instituteId?.[0]
          : nonEmptyFilters.institutionId,
        positionIds: nonEmptyFilters.positionId,
      });
      setCount(
        hasRole("SuperAdmin") ? allRegistries.count : allRegistries.count - 1
      );
      setData(
        allRegistries.data.filter((reg) => reg?.registry?.id !== userInfo?.id)
      );
      setIsLoading(false);
    };
    const fetchAndSetAdminReg = async () => {
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      setIsLoading(true);
      console.log(
        userInfo?.serviceAccount?.institute?.id,
        UserService.profile()?.attributes?.instituteId?.[0]
      );
      const allRegistries = await getFilteredRegistries({
        page,
        size: rowsPerPage,
        name: nonEmptyFilters.name,
        surname: nonEmptyFilters.surname,
        afm: nonEmptyFilters.afm,
        guardType: nonEmptyFilters.guardType,
        evaluationGroupId: hasRole("supervisor")
          ? userInfo.supervisorEvaluationGroup.evaluationGroup.id || undefined
          : undefined,
        institutionIds: hasRole("SuperAdmin")
          ? nonEmptyFilters.institutionId
          : userInfo?.serviceAccount?.institute?.id ||
            UserService.profile()?.attributes?.instituteId?.[0],
        positionIds: nonEmptyFilters.positionId,
      });
      setCount(
        hasRole("SuperAdmin") ? allRegistries.count : allRegistries.count - 1
      );
      setData(
        allRegistries.data.filter((reg) => reg?.id !== userInfo?.id)
      );
      setIsLoading(false);
    };
    hasRole("admin") || hasRole("SuperAdmin")
      ? fetchAndSetAdminReg()
      : fetchAndSetRegistries();
  }, [page, rowsPerPage, filters]);
  return (
    <Box>
      <Box>
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: "Μητρώο Αξιολόγησης Προσωπικού",
              url: "/personnel-registry-evaluation",
            },
          ]}
        />
      </Box>
      <h2>Προβολή Μητρώου Αξιολόγησης Προσωπικού</h2>
      <Divider sx={{ bgcolor: "black.main" }} />
      {(hasRole("employee") || hasRole("admin") || hasRole("supervisor")) && (
        <Box sx={{ justifyContent: "flex-end", display: "flex", marginTop: 3 }}>
          <Button
            variant="contained"
            onClick={() => navigate(`/evaluation-card/${userInfo.id}`)}
          >
            Καρτέλα Αξιολογήσεων
          </Button>
        </Box>
      )}
      {(hasRole("SuperAdmin") || hasRole("admin") || hasRole("supervisor")) && (
        <>
          <Filters
            setFilters={setFilters}
            isAfmIncluded={true}
            filters={filters}
          />

          <h3>Μητρώο Αξιολόγησης Προσωπικού (Αξιολογητών - Αξιολογουμένων)</h3>

          {!isLoading ? (
            <AppTable
              noDataMsg={"Δεν βρέθηκαν έντυπα αξιολόγησης."}
              isLoading={isLoading}
              columns={
                hasRole("SuperAdmin") || hasRole("admin")
                  ? adminColumns
                  : columns
              }
              rows={data}
              handleClick={handleClick}
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          ) : (
            <Loader />
          )}
        </>
      )}
    </Box>
  );
};
export default EvaluationRegister;
