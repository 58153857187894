import { useNavigate } from "react-router-dom";
import styles from "./ErrorPage.module.css"
const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}
    >
      <div className={styles.container}
      >
        <h2>Something went wrong!</h2>
        <button onClick={() => navigate("/")} className="button">
          Go Back
        </button>
      </div>
    </div>
  );
};
export default ErrorPage;
