import PersonIcon from "@mui/icons-material/Person";
import { Box, Paper } from "@mui/material";
import CustomIconBox from "../../components/UI/CustomIconBox";

const EmployeeCard = (props) => {
  return (
    <Paper
      elevation={3}
      sx={{
        // width: 850,
        paddingX: 4,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          bottom: 20,
          position: "relative",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CustomIconBox icon={<PersonIcon />} />
        <h5
          style={{ margin: 0 }}
        >{`${props?.data?.surname}  ${props?.data?.name}`}</h5>
        <h5 style={{ margin: 0 }}> {props?.data?.afm} </h5>
      </Box>
      {props.children}
    </Paper>
  );
};

export default EmployeeCard;
