import { styled } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';

// Define a styled component using the `styled` function
const CustomTextField = styled(TextFieldElement)(({ theme, isLabelBlack }) => ({
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderRadius: '20px',
  },
  '& .MuiInputLabel-outlined': {
    color: isLabelBlack ? 'black' : 'lightgrey',
  },
  '& .MuiOutlinedInput-input': {
    padding: 13,
  },
  '& label.Mui-focused': {
    padding: 0,
  },
}));

export default function AppInput(props) {
  const { name, label, value, error = null, isLabelBlack = false } = props;

  return (
    <CustomTextField
      variant="outlined"
      type={props.type}
      label={label}
      name={name}
      value={value}
      isLabelBlack={isLabelBlack} // Pass the prop to the styled component
      {...(error && { error: true, helperText: error })}
    />
  );
}
