import { formatDateTime } from "../Registrations/dataTransformFunctions";


export const transformedMultipleRegistryData = (
  data,
  formSchema,
  selectedPeople,

) => {
  return multipleRegistrationDetailsTransformer(
    formSchema,
    data,
    selectedPeople
  );
};

export const transformServiceChangeMultipleDataToBEFormat = (
  data,
  BEConfigInfo,
  selectedPeople,
  guardType
) => {
  console.log(data, selectedPeople);
  const registries = [];
  const { serviceChangeDate, serviceChangeType, institution, order, notes } =
    data;
  for (let selectedPerson of selectedPeople) {
    registries.push({
      id: selectedPerson.id,
      serviceAccount: {
        grade: BEConfigInfo.grade.find(
          (gradeType) => (gradeType.id === selectedPerson.serviceAccount.grade.id)
        ),
        position: BEConfigInfo.position.find(
          (positionType) =>
            (positionType.id === selectedPerson.serviceAccount.position.id)
        ),
        institute: BEConfigInfo.institution.find(
          (institutionType) =>
            (institutionType.id === selectedPerson.serviceAccount.institute.id)
        ),
        isInternalGuard: selectedPerson.serviceAccount.isInternalGuard,
      },
    });
  }
  const transformedData = {
    serviceDate: serviceChangeDate,
    notes,
    orderNumber: order,
    institution: BEConfigInfo.institution.find(
      (institutionType) => (institutionType.description === institution)
    ),
    typeOfService: BEConfigInfo.serviceChangeTypes["type_of_service"].find(
      (changeType) => (changeType.description === serviceChangeType)
    ),
    registries: [...registries],
    isInternalGuard:   guardType === 'internal',

  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};

export const transformOvertimeMultipleDataToBEFormat = (
  data,
  BEConfigInfo,
  selectedPeople,
  guardType

) => {
  console.log(data, selectedPeople);
  const registries = [];
  const { overtimeDate, overtimeEnd, overtimeStart, reason, notes } = data;
  for (let selectedPerson of selectedPeople) {
    registries.push({
      id: selectedPerson.id,
      serviceAccount: {
        grade: BEConfigInfo.grade.find(
          (gradeType) => (gradeType.id === selectedPerson.serviceAccount.grade.id)
        ),
        position: BEConfigInfo.position.find(
          (positionType) =>
            (positionType.id === selectedPerson.serviceAccount.position.id)
        ),
        institute: BEConfigInfo.institution.find(
          (institutionType) =>
            (institutionType.id === selectedPerson.serviceAccount.institute.id)
        ),
        isInternalGuard: selectedPerson.serviceAccount.isInternalGuard,
      },
    });
  }
  const transformedData = {
    overtimeDate: overtimeDate,
    notes,
    overtimeStart,
    overtimeEnd,
    overtimeReason: BEConfigInfo.overtimeTypes["overtime_reason"].find(
      (changeType) => (changeType.description === reason)
    ),
    registries: [...registries],
    isInternalGuard:   guardType === 'internal',

  };
  console.log("transformedBEDatamultiple", transformedData);
  return transformedData;
};

export const transformServiceActionMultipleDataToBEFormat = (
  data,
  BEConfigInfo,
  selectedPeople,
  guardType

) => {
  console.log(data, selectedPeople);
  const registries = [];
  const { serviceActionDate, serviceActionType, notes } = data;
  for (let selectedPerson of selectedPeople) {
    registries.push({
      id: selectedPerson.id,
      serviceAccount: {
        grade: BEConfigInfo.grade.find(
          (gradeType) => (gradeType.id === selectedPerson.serviceAccount.grade.id)
        ),
        position: BEConfigInfo.position.find(
          (positionType) =>
            (positionType.id === selectedPerson.serviceAccount.position.id)
        ),
        institute: BEConfigInfo.institution.find(
          (institutionType) =>
            (institutionType.id === selectedPerson.serviceAccount.institute.id)
        ),
        isInternalGuard: selectedPerson.serviceAccount.isInternalGuard,
      },
    });
  }
  const transformedData = {
    actionDate: serviceActionDate,
    notes,
    actionType: BEConfigInfo.actionTypes["action_type"].find(
      (actionType) => (actionType.description === serviceActionType)
    ),
    registries: [...registries],
    isInternalGuard:   guardType === 'internal',

  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};

export const transformSwitchMultipleDataToBEFormat = (
  data,
  BEConfigInfo,
  selectedPeople,
  guardType

) => {
  console.log(data, selectedPeople);
  const registries = [];
  const { switchDate, switchType, notes } = data;
  for (let selectedPerson of selectedPeople) {
    registries.push({
      id: selectedPerson.id,
      serviceAccount: {
        grade: BEConfigInfo.grade.find(
          (gradeType) => (gradeType.id === selectedPerson.serviceAccount.grade.id)
        ),
        position: BEConfigInfo.position.find(
          (positionType) =>
            (positionType.id === selectedPerson.serviceAccount.position.id)
        ),
        institute: BEConfigInfo.institution.find(
          (institutionType) =>
            (institutionType.id === selectedPerson.serviceAccount.institute.id)
        ),
        isInternalGuard: selectedPerson.serviceAccount.isInternalGuard,
      },
    });
  }
  const transformedData = {
     transferDate: switchDate,
    notes,
    transferType: BEConfigInfo.transferTypes["transfer_type"].find(
      (switchTypeInfo) => (switchTypeInfo.description === switchType)
    ),
    registries: [...registries],
    isInternalGuard:   guardType === 'internal',

  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};

export const transformEducationMultipleDataToBEFormat = (
  data,
  BEConfigInfo,
  selectedPeople,
  guardType
) => {
  console.log(guardType,'guardType');
  const registries = [];
  const {
    typeOfEducation,
    educationTitle,
    notes,
    grade,
    scale,
    startDate,
    endDate,
    durationInHours,
  } = data;
  for (let selectedPerson of selectedPeople) {
    registries.push({
      id: selectedPerson.id,
      serviceAccount: {
        grade: BEConfigInfo.grade.find(
          (gradeType) => (gradeType.id === selectedPerson.serviceAccount.grade.id)
        ),
        position: BEConfigInfo.position.find(
          (positionType) =>
            (positionType.id === selectedPerson.serviceAccount.position.id)
        ),
        institute: BEConfigInfo.institution.find(
          (institutionType) =>
            (institutionType.id === selectedPerson.serviceAccount.institute.id)
        ),
        isInternalGuard: selectedPerson.serviceAccount.isInternalGuard,
      },
    });
  }
  const transformedData = {
    educationTitle,
    notes,
    degreeGrade: grade,
    gradingScale: scale,
    startDate:startDate,
    endDate:endDate,
    durationInHours,
    educationType: BEConfigInfo.educationTypes.find(
      (educationType) => (educationType.description === typeOfEducation)
    ),
    isInternalGuard:   guardType === 'internal',
    registries: [...registries],
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
};
export const multipleRegistrationDetailsTransformer = (
  formSc,
  data,
  selectedPeople
) => {
  const rows = [];
  let values = {};
  const columns = [
    {
      field: "userInfo",
      headerName: "Ονοματεπώνυμο - ΑΦΜ",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Θέση",
      flex: 1,
    },
  ];
  Object.values(formSc).map((key) => {
    console.log(key, "key");
    key.label !== "Ημερομηνία" &&
      columns.push({ field: key.name, headerName: key.label, flex: 1 });
    values = { ...values, [key.name]: formatDateTime(key.name, data) };
  });

  for (let selectedPerson of selectedPeople) {
    const userData = {
      id: selectedPerson.id,
      userInfo: `${selectedPerson.surname} ${selectedPerson.name} ${selectedPerson.afm}`,
      position: selectedPerson.serviceAccount.position.description,
      ...values,
    };
    rows.push(userData);
  }
  console.log('rows', rows);
  return { columns, rows };
};