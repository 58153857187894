import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const OvertimeDetailsColumns  = (setIsOpenDeleteModal,registriesLength,setIds)=> [
  {
    field: "fullname",
    headerName: "Ονοματεπώνυμο",
    width: 150,
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography>{`${params.row.name} ${params.row.surname}`}</Typography>
    ),
  },
  {
    field: "afm",
    headerName: "ΑΦΜ",
    flex: 1,
  },
  {
    field: "position",
    headerName: "Θέση",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.serviceAccount?.position?.description || ""}
      </Typography>
    ),
  },
  {
    field: "duration",
    headerName: "Διάρκεια Υπερωρίας",
    flex: 1,
    renderCell: (params) => (
      <Typography>{`${params.row.overtimeStart.split("T")[1].substring(0, 5)} - ${params.row.overtimeEnd.split("T")[1].substring(0, 5)}`}</Typography>

    ),
  },
  {
    field: "overtimeReason",
    headerName: "Αιτιολόγηση",
    flex: 1,
   
  },
  {
    field: "notes",
    headerName: "Παρατηρήσεις",
    flex: 1,
  },
  {
    field: "action",
    headerName: "Ενέργεια",
    width: 70,
    renderCell: (params) => (
      <Button disabled={registriesLength<2  && !params.row.isKataxoritis}  onClick={()=>{setIsOpenDeleteModal(true); setIds({employeeId:params.row.id,overtimeId:params.row.overtimeId})}}>    <DeleteIcon /> 
      </Button>
    ),

  },
];

export const OvertimeColumns = [
  {
    id: "overtimeDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: true,
  },
  {
    id: "overtimeReason",
    label: "Αιτιολόγηση",
    minWidth: 170,
    isClickable: false,
  },
];