import ReportWrapper from "./shared/ui/ReportWrapper";

  
  function EvaluationReportEmployee() {

    return (
    <ReportWrapper title={'Έκθεση Αξιολόγησης Υπαλλήλου'} isManager={false} />
    );
  }
  
  export default EvaluationReportEmployee;
  