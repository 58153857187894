import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  deleteEducationHistory,
  getEducationHistory,
} from "../../../services/history.service";
import RegistryDetailedHistoryWrapper from "../RegistryDetailedHistoryWrapper";
import { EducationDetailsColumns } from "./EducationColumnsConfig";
import DeleteModal from "../DeleteModal";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const EducationDetailedHistory = () => {
  const [data, setData] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const educationId = useParams().id;
  const date = useParams().date;
  const [ids, setIds] = useState();
  const { userInfo, hasRole } = useAuth()
  const [guardType, setGuardType] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [registriesLength, setRegistriesLength] = useState();
  const onDelete = () => {
    deleteEducationHistory(ids.educationId, ids.employeeId);
    setIsUpdated(true);
  };
  const createEducationData = (data) => {
    const education = data.find((education) => (education.id === +educationId));
    education ? setData(
      education.registries.map((employee) => ({
        ...employee,
        id: employee.id,
        educationId: education.id,
        educationType: education.educationType.description,
        educationTitle: education.educationTitle,
        degreeGrade: education.degreeGrade,
        gradingScale: education.gradingScale,
        durationInHours: education.durationInHours,
        notes: education.notes,
      }))
    ) : setData([])
    education && setRegistriesLength(education.registries.length);
  };
  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getEducationHistory(
        0,
        10,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id,
        date,
        guardType && guardType
      );
      allRegistries.data.length > 0
        ? createEducationData(allRegistries.data)
        : setData([]);
      setIsLoading(false);
      setIsUpdated(false);

    };

    fetchAndSetHistoryRegistries();
  }, [educationId, date, guardType, isUpdated, registriesLength]);

  return (
    <>
      <RegistryDetailedHistoryWrapper
        breadcrumbs={[
          {
            title: "Εκπαιδεύσεων",
            url: "/history/education",
          },
          {
            title: "Εκπαιδεύσεων",
            url: "",
          },
        ]}
        title={"Εκπαιδεύσεις "}
        department={userInfo?.serviceAccount?.institute?.description}
        date={date}
        columns={EducationDetailsColumns(
          setIsOpenDeleteModal,
          registriesLength,
          setIds
        )}
        isLoading={isLoading}
        rows={data}
        guardType={guardType}
        setGuardType={setGuardType}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          setIsShownModal={setIsOpenDeleteModal}
          onDelete={onDelete}
        />
      )}
    </>
  );
};
export default EducationDetailedHistory;
