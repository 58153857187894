import { Box, Button } from "@mui/material";
import { router } from "../../../../App";
import AppModal from "../../../../components/UI/Modal/AppModal";

const ReportDenyModal = ({setDenyModal, setConfirmModal}) => {
  return (
    <AppModal onHideModal={() => setDenyModal(false)}>
      <Box>
        {
          "Αν δεν δημιουργήσετε Σχέδιο Ανάπτυξης για τον Προϊστάμενο, οφείλετε να συμπληρώσετε τις επιμέρους Αιτιολογήσεις στο Έντυπο Επίτευξης Στόχων Προϊσταμένου - 3ο Στάδιο, στο Έντυπο Αποτύπωσης Δεξιοτήτων Προϊσταμένου και στην Έκθεση Αξιολόγησης Προϊσταμένου»"
        }
        <Box
          sx={{
            marginY: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 3,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setDenyModal(false);
              setConfirmModal(true);
            }}
          >
            <span>'Επιστροφή</span>
          </Button>

          <Button
            variant="contained"
            type="button"
            onClick={() => router.navigate(-1)}
          >
            <span>Συνέχεια</span>
          </Button>
        </Box>
      </Box>
    </AppModal>
  );
};
export default ReportDenyModal;
