import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/system";
import React from "react";
import { MultiSelectElement, SelectElement } from "react-hook-form-mui";

const CustomMultipleSelect = styled(MultiSelectElement)({
  "& .MuiOutlinedInput-root": {
    borderColor: "black",
    borderRadius: "20px",
    color:'green'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderRadius: "20px",
  },
  "& .MuiInputBase-root": {
    color: "black",
    border: "1px solid black",
  },
  "& .MuiInputLabel-root": {
    color: "green", 
    paddingTop: 13,

  },
  "& .MuiSelect-select": {
    paddingTop: 13,
    paddingBottom: 13,
  },
});

const CustomSelect = styled(SelectElement)(({ theme }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderRadius: "20px",
  },
  "& .MuiInputLabel-root": {
    color: "black",
  },
  "& .MuiSelect-select": {
    paddingTop: 13,
    paddingBottom: 13,
  },
}));

export default function AppSelect(props) {
  const { name, error = null, label, value, options, isMultiple } = props;

  return (
    <FormControl sx={{ width: "100%" }}>
      {isMultiple ? (
        <CustomMultipleSelect
          label={label}
          name={name}
          value={value}
          options={options}
          size="small"

        />
      ) : (
        <CustomSelect
          label={label}
          name={name}
          value={value}
          options={options}
        ></CustomSelect>
      )}
    </FormControl>
  );
}
