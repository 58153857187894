import { Box, Button } from "@mui/material"
import AppModal from "./AppModal"
import ResetFormButton from "../../ResetButton"

const ResetModal = (props) => {
    return (
        <AppModal onHideModal={() => props.setIsShownResetModal(false)}>
            <Box sx={{ zIndex: "1000" }}>
              <h5>Είστε σίγουρος/ή ότι επιθυμείτε να ακυρώσετε την Καταχώριση  στοιχείων;</h5>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <Button onClick={() => props.setIsShownResetModal(false)}>
                  Ακύρωση
                </Button>
                <ResetFormButton
                  variant={"contained"}
                  setIsShownAddModal={props.setIsShownResetModal}
                  label={"Καθαρισμός Φόρμας "}
                />
              </Box>
            </Box>
          </AppModal>
    )
}
export default ResetModal