import { Box, Button, Typography } from "@mui/material";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import PageTitle from "../../components/PageTitle";
import FormEvaluationPeriod from "../Armodiothtes/shared/ui/FormEvaluationPeriod";
import {
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useGetPulseReason } from "./data-access/useGetPulseReason";
import { useGetPulseFormType } from "./data-access/useGetPulseFormType";
import { useGetPulseCaseInfo } from "./data-access/useGetPulseCaseInfo";
import { useParams } from "react-router-dom";
import usePostRequest from "../../hooks/useHttpCallHandler";
import { postPulseForm } from "./data-access/gateway";
import { useEffect, useState } from "react";
import GradeDisplay from "../Armodiothtes/shared/ui/Display";
import { yupResolver } from "@hookform/resolvers/yup";
import sfigmosValidationSchema from "./sfigmosValidationSchema";
import EmployeeCard from "../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Pages/Registrations/RegistrationEmployeeInfo";
import { useGetRegistryEvaluationRegistryInfo } from "../Armodiothtes/data-access/useGetRegistryEvaluationRegistryInfo";
import {
  failedSubmitHandler,
  isSubmittingFormHandler,
  successfullySubmittedHandler,
} from "../shared/utils";
import InfoBox from "../shared/ui/InfoBox";
import GenericModal from "../shared/ui/modals/GenericModal";
import Loader from "../../components/UI/Loader";
import useAuth from "../../hooks/useBackAuth";

function EntipoSfigmouOmadas() {
  const { data: pulseFormType, isLoading: isLoadingPulseFormType } =
    useGetPulseFormType();
  const { data: pulseReason1, isLoading: isLoadingPulseReason1 } =
    useGetPulseReason(1);
  const { data: pulseReason2, isLoading: isLoadingPulseReason2 } =
    useGetPulseReason(2);
  const { data: pulseReason3, isLoading: isLoadingPulseReason3 } =
    useGetPulseReason(3);
  const { data: pulseReason4, isLoading: isLoadingPulseReason4 } =
    useGetPulseReason(4);
  const { data: pulseReason5, isLoading: isLoadingPulseReason5 } =
    useGetPulseReason(5);
  const { data: pulseReason6, isLoading: isLoadingPulseReason6 } =
    useGetPulseReason(6);
  const { data: pulseReason7, isLoading: isLoadingPulseReason7 } =
    useGetPulseReason(7);
  const { data: pulseReason8, isLoading: isLoadingPulseReason8 } =
    useGetPulseReason(8);
  const formContext = useForm({
    resolver: yupResolver(sfigmosValidationSchema),
  });
  const otherIds = [11101, 11551, 11901, 12251, 12551, 12801, 13201, 13451];
  const { id } = useParams();
  const { data: caseInfo, isLoading: isLoadingCaseInfo } =
    useGetPulseCaseInfo(id);
  const { handlePostRequest, responseData } = usePostRequest(postPulseForm);
  const pulseReasons = [
    pulseReason1?.data?.["pulse_reason:1"],
    pulseReason2?.data?.["pulse_reason:2"],
    pulseReason3?.data?.["pulse_reason:3"],
    pulseReason4?.data?.["pulse_reason:4"],
    pulseReason5?.data?.["pulse_reason:5"],
    pulseReason6?.data?.["pulse_reason:6"],
    pulseReason7?.data?.["pulse_reason:7"],
    pulseReason8?.data?.["pulse_reason:8"],
  ];
  const { data: registryInfoDetails, isLoading: isLoadingRegistryInfoDetails } =
    useGetRegistryEvaluationRegistryInfo(
      caseInfo?.data?.documentInfo?.registry?.id
    );
  const { referenceUserInfo } = useAuth();
  const getTotalGrade = () => {
    let grades = [];
    let sum = 0;
    for (let i = 0; i < 8; i++) {
      grades = responseData
        ? responseData?.groupPulseFormDetails?.map((item) => +item?.targetScore)
        : caseInfo?.data?.groupPulseFormDetails.map(
            (item) => +item?.targetScore
          );
    }
    const gradeLength = grades?.length;
    for (let i = 0; i < gradeLength; i++) {
      sum += grades[i];
    }
    const average = sum / gradeLength;
    return parseFloat(average?.toFixed(2));
  };
  const onSubmit = async (data) => {
    isSubmittingFormHandler();
    try {
      const body = {
        ...data,
        id: caseInfo?.data?.id,
        documentInfo: caseInfo?.data?.documentInfo,
        totalGrade: getTotalGrade(),
        groupPulseFormDetails: data.groupPulseFormDetails.map((item, index) => {
          return {
            ...item,
            pulseFormType: pulseFormType?.data?.pulse_form_type?.[index],
          };
        }),
      };

      const resp = await handlePostRequest(body);
      successfullySubmittedHandler(referenceUserInfo?.id);
    } catch (error) {
      failedSubmitHandler();
    }
  };
  useEffect(() => {
    if (isLoadingCaseInfo) return;
    formContext.reset({
      ...caseInfo?.data,
      groupPulseFormDetails: caseInfo?.data?.groupPulseFormDetails?.map(
        (item, index) => {
          return {
            ...item,
            targetScore: item.targetScore.toString(),
          };
        }
      ),
    });
  }, [isLoadingCaseInfo]);
  if (isLoadingRegistryInfoDetails) return <></>;

  if (
    isLoadingPulseFormType ||
    isLoadingPulseReason1 ||
    isLoadingPulseReason2 ||
    isLoadingPulseReason3 ||
    isLoadingPulseReason4 ||
    isLoadingPulseReason5 ||
    isLoadingPulseReason6 ||
    isLoadingPulseReason7 ||
    isLoadingPulseReason8
  )
    return <Loader />;
  const documentInfoRegistry = caseInfo?.data?.documentInfo?.registry;
  const documenType = caseInfo?.data?.documentInfo?.documentType;

  return (
    <Box mt={16}>
      <AppBreadcrumbs
        breadcrumbsList={[
          {
            title: "Μητρώο Αξιολόγησης Προσωπικού",
            url: "/personnel-registry-evaluation",
          },
          {
            title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
              referenceUserInfo?.name
            } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
            url: `/evaluation-card/${referenceUserInfo?.id}`,
          },
          {
            title: "Διενέργειες Αξιολογήσεων",
            url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
          },
          {
            title: `${documenType?.description || ""}:

                ${documentInfoRegistry?.name || ""} ${
              documentInfoRegistry?.surname || ""
            } - ${documentInfoRegistry?.afm || ""}`,
            url: "/evaluation/groups/create",
          },
        ]}
      />

      <PageTitle title="Έντυπο Σφυγμού Ομάδας" />

      <InfoBox
        content={`Αποτυπώστε το βαθμό με τον οποίο συμφωνείτε ή διαφωνείτε με τα παρακάτω στοιχεία που συνιστούν 
        την εργασιακή σας εμπειρία, με βάση την πενταβάθμια περιγραφική κλίμακα, όπου "Διαφωνώ" 
        αντιστοιχεί σε βαθμολογία = ένα (1), και "Συμφωνώ Απόλυτα" αντιστοιχεί 
        σε βαθμολογία = πέντε (5)`}
      />

      <Box display="flex" justifyContent="center" my={2}>
        {caseInfo?.data && (
          <EmployeeCard data={caseInfo?.data?.evaluationGroupSupervisor}>
            <RegistrationEmployeeInfo
              data={caseInfo?.data?.evaluationGroupSupervisor}
            />
          </EmployeeCard>
        )}
      </Box>
      <FormContainer formContext={formContext} onSuccess={onSubmit}>
        <FormEvaluationPeriod
          stage={caseInfo?.data?.documentInfo?.submissionStage?.code}
        />

        <Typography variant="h6" gutterBottom>
          Ερωτήσεις προς υφιστάμενο
        </Typography>

        {pulseFormType?.data?.pulse_form_type?.map((item, index) => {
          return (
            <Box display="flex" flexDirection="column" mt={5}>
              <Typography key={item.code} variant="body1" gutterBottom>
                {index + 1}. {item?.description}
              </Typography>
              {pulseReasons?.[index]?.length && (
                <SelectElement
                  options={  pulseReasons?.[index]?.map((z) => ({
                    label: z.description,
                    id: z.id,
                  }))}
                  name={`groupPulseFormDetails.${index}.pulseReason.id`}
                />
              )}

              {otherIds.includes(
                formContext.watch(
                  `groupPulseFormDetails.${index}.pulseReason.id`
                )
              ) && (
                <TextFieldElement
                  sx={{ mt: 1 }}
                  label="Αναλύτική Αιτιολόγηση"
                  name="`groupPulseFormDetails.${index}.pulseReason.notes`"
                />
              )}
              <RadioButtonGroup
                row
                name={`groupPulseFormDetails.${index}.targetScore`}
                options={[
                  {
                    id: "1",
                    label: "Διαφωνώ",
                  },
                  {
                    id: "2",
                    label: "Διαφωνώ Μερικώς",
                  },
                  {
                    id: "3",
                    label: "Ούτε Διαφωνώ Ούτε Συμφωνώ",
                  },
                  {
                    id: "4",
                    label: "Συμφωνώ Αρκετά",
                  },
                  {
                    id: "5",
                    label: "Συμφωνώ Απόλυτα",
                  },
                ]}
              />
            </Box>
          );
        })}

        <Box sx={{ marginY: 6 }}>
          <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
          {caseInfo?.data && (
            <Box display="flex" justifyContent="center" my={2}>
              <EmployeeCard data={registryInfoDetails?.data}>
                <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
              </EmployeeCard>
            </Box>
          )}
        </Box>
        {!responseData && (
          <Box display="flex" justifyContent="center">
            <GradeDisplay
              grade={getTotalGrade() || caseInfo?.data?.totalGrade}
            />
          </Box>
        )}
        <GenericModal
          onAccept={formContext.handleSubmit(onSubmit)}
          agreeButtonTitle="Οριστικοποίηση"
          dialogContent="Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποίησετε την Έντυπο Σφυγμού Ομάδας;"
        >
          <Box display="flex" justifyContent="flex-end">
            <Button
              type={!formContext?.formState?.isValid ? "submit" : "button"}
              variant="contained"
            >
              <span>Οριστικοποίηση</span>
            </Button>
          </Box>
        </GenericModal>
      </FormContainer>
    </Box>
  );
}

export default EntipoSfigmouOmadas;
