import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import GenericModal from "../../../shared/ui/modals/GenericModal";

function EvaluationGroupAccordionContentActionButtons({ deactivateGroup, evalGroupId, groupName = "", }) {
    const navigate = useNavigate()
    return (<Box display="flex" gap={3} justifyContent={"flex-end"}>

        <GenericModal onAccept={() => deactivateGroup(evalGroupId)} agreeButtonTitle="Ναι" dialogContent={`Είστε σίγουρος/η ότι θέλετε να απενεργοποιήσετε την ομάδα αξιολόγησης; Δεν θα έχετε τη δυνατότητα να την ενεργοποιήσετε μετά την απενεργοποίηση.`}>
            <Button variant="contained" color="error"> Απενεργοποίηση Ομάδας </Button>
        </GenericModal>


        <Button variant="contained" onClick={() => navigate(`edit/${evalGroupId}`)}> Επεξεργασία Ομάδας </Button>
    </Box >);
}

export default EvaluationGroupAccordionContentActionButtons;