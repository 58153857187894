import HttpService from "./httpClient";
export const updateRegistryById = async (registryId, registry) => {
  try {
    console.log(registry);

    const response = await HttpService.getAxiosClient().post(
      `/registry/update`,
      { ...registry, id: +registryId },
    );

    const updatedRegistry = response.data;
    console.log(updatedRegistry);

    return updatedRegistry;
  } catch (error) {
    console.error(error);
    throw error; 
  }
};
