import {Button} from '@mui/material'
import {useFormContext} from 'react-hook-form'

export default function ResetFormButton(props) {
  const {reset} = useFormContext()
  return (
    <Button
    type="button"
    variant={props.variant}
    onClick={() => {
        
        reset({})
        props.setIsShownAddModal && props.setIsShownAddModal(false);
      }}
    >
      {props.label}
    </Button>
  )
}
export function isValid(){
  return isValid
}