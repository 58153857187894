import HttpService from "../httpClient";

export const getSuggestionToolsInspections = (
  page,
  size,
  institutionId,
  registryId,
  objectiveTypeId
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/development-plan`, {
        params: {
          page,
          size,
          "institutionId.equals": institutionId,
          "registryId.equals": registryId,
          "objectiveTypeId.equals": objectiveTypeId,
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getEvaluationGroupData = (evaluationGroupId) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-evaluation/group/${evaluationGroupId}`)
      .then(function (response) {
        const data = response.data;
        resolve(data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};


export const addNewSuggToolInsp = (registry) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .post(`/development-plan`, { ...registry })
      .then(function (response) {
        const registry = response.data;
        console.log(registry);
        resolve(registry);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const updateSuggToolInsp = (data) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .post(`/development-plan`,{ ...data })
      .then(function (response) {
        const registry = response.data;
        resolve(registry);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};