import { TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../components/Details/utils";
import { TextField } from "@mui/material";

function Case2() {
  return (
    <>
      <TextFieldElement
        fullWidth
        label="Ονομασία Στόχου*"
        name="stage2.targetName"
      />
      <TextFieldElement
        fullWidth
        label="Τιμές Δεικτών Μέτρησης Προόδου"
        name="stage2.measurementIndicator"
      />

      <TextFieldElement
        fullWidth
        label="Περιγραφή της Προόδου"
        name="stage2.description"
      />

      <TextFieldElement label="Παρατηρήσεις" name="stage2.notes" />
      <TextFieldElement
        label="Περιγραφή των Αναθεωρημένων (Νέων) Τιμών των Δεικτών"
        name="stage2.eligibility"
      />
      <TextField
        disabled={true}
        label="Ημερομηνία"
        value={defaultRenderDates(new Date())}
      />
    </>
  );
}

export default Case2;
