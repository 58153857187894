import { TextFieldElement } from "react-hook-form-mui";
import HorizontalVerticalTogglerLayout from "../../../components/Layout/HorizontalVerticalTogglerLayout";
import DatePickerFns from "../../Groups/ui/DatePickerFns";
import { defaultRenderDates } from "../../../components/Details/utils";
import { TextField } from "@mui/material";

function EntipoYpodigmatosStoxothesiasStage3() {
  return (
    <>
      <HorizontalVerticalTogglerLayout direction="column">
        <TextFieldElement
          fullWidth
          label="Ονομασία Στόχου*"
          name="stage3.targetName"
        />
        <TextFieldElement
          fullWidth
          label="Τιμές Αξιολόγησης των Αποτελεσμάτων"
          name="stage3.measurementIndicator"
        />

        <TextFieldElement
          fullWidth
          label="Συμπεράσματα"
          name="stage3.description"
        />
        <TextFieldElement label="Παρατηρήσεις" name="stage3.notes" />
        <TextField
          disabled={true}
          label="Ημερομηνία"
          value={defaultRenderDates(new Date())}
        />
      </HorizontalVerticalTogglerLayout>
    </>
  );
}

export default EntipoYpodigmatosStoxothesiasStage3;
