import { Box, Button, Typography } from "@mui/material"
import WarningIcon from '@mui/icons-material/Warning';
import AppModal from '../../components/UI/Modal/AppModal';
const DeleteModal = (props) => {
    return (
        <AppModal onHideModal={() => props.setIsShownModal(false)}>
            <Box sx={{ zIndex: "1000" }}>
              <h5>Είστε σίγουρος/ή ότι επιθυμείτε να διαγράψετε αυτή την καταχώριση;</h5>

              <Box sx={{display:'flex', gap:2, justifyContent:'center', alignItems:'center'}}>
              <WarningIcon/> <Typography variant="caption">Θα πρέπει να υπάρχει τουλάχιστον μια εγγραφή στο ιστορικό.</Typography>


              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <Button onClick={() => props.setIsShownModal(false)}>
                  Ακύρωση
                </Button>
                <Button onClick={()=>{props.onDelete(); props.setIsShownModal(false)}}>Διαγραφή</Button>
              </Box>
            </Box>
          </AppModal>
    )
}
export default DeleteModal