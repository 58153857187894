import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import Details from "../../../components/Details/Details";
import AppAccordion from "../../../components/UI/AppAccordion";
import FormDateRangePicker from "../../../components/UI/FormDateRangePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";
import {
  getEducationTypesDropdown,
  getGradeInstitutionPositionDropdown,
} from "../../../services/dropdownData.service";

import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import { educationFieldValidation } from "./educationFieldValidation";
import {
  defaultEducationRegistrationValues as defaultValues,
  formEducationRegistrationSchema as formSchema,
} from "./educationRegistrationConfig";
import {
  addNewEducationRegistries,
  addNewEducationRegistry,
} from "../../../services/Registrations/Education.service";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  registrationDetailsTransformer,
  transformEducationDataToBEFormat,
} from "../dataTransformFunctions";
import {
  transformEducationMultipleDataToBEFormat,
  transformedMultipleRegistryData,
} from "../../MultipleRegistrations/multipleDataTransformFunctions";
import { NoRowsOverlay } from "../../../helperFunctions";
import "../../../App.css";
const EducationRegistrationForm = ({
  data,
  isMultipleRegistration,
  selectedPeople,
  guardType,
}) => {
  const employeeId = useParams().employeeId;
  const navigate = useNavigate();
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [educationTypes, setEducationTypes] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [educationOptions, setEducationOptions] = useState([]);
  const [multipleRegistriesData, setMultipleRegistriesData] = useState();
  const [configObjects, setConfigObjects] = useState();
  const [date, setDate] = useState();
  const registry = {
    id: data?.id,
    name: data?.name,
    surname: data?.surname,
    fathersName: data?.fathersName,
    fathersSurname: data?.fathersSurname,
    mothersName: data?.mothersName,
    mothersSurname: data?.mothersSurname,
    dateOfBirth: data?.dateOfBirth,
    placeOfBirth: data?.placeOfBirth,
    adt: data?.adt,
    issuingAuthority: data?.issuingAuthority,
    afm: data?.afm,
    bloodType: data?.bloodType,
    amka: data?.amka,
    payrollAccountNumber: data?.payrollAccountNumber,
    iban: data?.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };

  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const options = await getEducationTypesDropdown();
      const configObj = await getGradeInstitutionPositionDropdown();
      setConfigObjects(configObj);
      console.log(options["education_type"]);
      setEducationTypes(options["education_type"]);
      setEducationOptions(
        options["education_type"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    fetchBEConfigInfo();
  }, []);
  const formContext = useForm({
    resolver: yupResolver(educationFieldValidation),
    defaultValues: defaultValues,
  });
  const onSave = () => {
    const data = isMultipleRegistration
      ? transformEducationMultipleDataToBEFormat(
          multipleRegistriesData?.rows[0],
          {
            ...configObjects,
            educationTypes,
          },
          selectedPeople,
          guardType
        )
      : transformEducationDataToBEFormat(formData, educationTypes);
    educationTypes && isMultipleRegistration
      ? addNewEducationRegistries(data)
      : addNewEducationRegistry(data);
    setIsShownSaveModal(false);
    isMultipleRegistration
      ? navigate("/personnel-management")
      : navigate(`/employee-info/${employeeId}`);
  };
  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (educationData) => {
            setDate(educationData.endDate.toLocaleString().split(",")[0]);
            const typeOfEducationDescription = educationOptions.find(
              (option) => option.id === +educationData.typeOfEducation
            )?.label;
            const transformedData = isMultipleRegistration
              ? transformedMultipleRegistryData(
                  {
                    ...educationData,
                    typeOfEducation: typeOfEducationDescription,
                  },
                  formSchema,
                  selectedPeople
                )
              : registrationDetailsTransformer(formSchema, {
                  ...educationData,
                  typeOfEducation: typeOfEducationDescription,
                  userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
                  userPosition: data.serviceAccount.position.description,
                });

            isMultipleRegistration
              ? setMultipleRegistriesData(transformedData)
              : setFormData({
                  ...educationData,
                  registry,
                  institution: data.serviceAccount.institute,
                  position: data.serviceAccount.position,
                });
            !isMultipleRegistration && setPreviewData(transformedData);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.typeOfEducation.label}>
              {educationOptions && (
                <SelectElement
                  fullWidth
                  label={formSchema.typeOfEducation.label}
                  name={formSchema.typeOfEducation.name}
                  options={educationOptions}
                />
              )}
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.educationTitle.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.educationTitle.label}
                name={formSchema.educationTitle.name}
                variant="outlined"
              />
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.grade.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.grade.label}
                name={formSchema.grade.name}
                variant="outlined"
                type="number"
              />
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.scale.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.scale.label}
                name={formSchema.scale.name}
                variant="outlined"
                type="number"
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.startDate.label}>
              <FormDateRangePicker
                end={formSchema.endDate}
                start={formSchema.startDate}
              />
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.durationInHours.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.durationInHours.label}
                name={formSchema.durationInHours.name}
                variant="outlined"
                type="number"
              />
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "100%", marginTop: 4 }}>
          <h4> {`Εκπαιδεύσεις ${date}`}</h4>
          {isMultipleRegistration && multipleRegistriesData ? (
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              rows={multipleRegistriesData.rows}
              columns={multipleRegistriesData.columns.map((column) => ({
                ...column,
                cellClassName: "dataGridCell",
              }))}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          ) : (
            <Details
              renderFirstRowsAsHeader
              renderArraysAsColumns
              rows={[
                {
                  name: previewData.labels[0],
                  value: previewData.labels.slice(1, previewData.length),
                },
                {
                  name: previewData.values[0],
                  value: previewData.values.slice(1, previewData.length),
                },
              ]}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default EducationRegistrationForm;
