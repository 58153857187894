import useAuth from "../../hooks/useBackAuth";
import EducationRegistrationForm from "../Registrations/Education/EducationRegistrationForm";
import MultipleRegistriesWrapper from "./MultipleRegistriesWrapper";

const MultipleEducationRegistries = () => {
  const { userInfo} = useAuth()

  return (
    <MultipleRegistriesWrapper
      title={"Εκπαίδευσης"}
      department={userInfo?.serviceAccount?.institute?.description}
      FormComponent={EducationRegistrationForm}
    />
  );
};
export default MultipleEducationRegistries;
