// Get Requests that only fetch data

import { useQuery } from "@tanstack/react-query";
import { getKatastimata } from "./gateway";

export function useGetKatastimata() {

    return useQuery({
        queryFn: () => getKatastimata(),
    });
}
