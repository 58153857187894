import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useParams } from "react-router-dom";
import { router } from "../../App";
import EmployeeCard from "../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import HorizontalVerticalTogglerLayout from "../../components/Layout/HorizontalVerticalTogglerLayout";
import PageTitle from "../../components/PageTitle";
import useAuth from "../../hooks/useBackAuth";
import usePostRequest from "../../hooks/useHttpCallHandler";
import { getRegistryById } from "../../services/PersonnelManagement.service";
import {
  failedSubmitHandler,
  isSubmittingFormHandler,
  successfullySubmittedHandler,
} from "../shared/utils";
import { postCaseInfoEntipoYpodigmatosStoxothesias } from "./data-access/gateway";
import { useGetCaseInfoEntipoYpodigmatosStoxothesias } from "./data-access/useGetCaseInfoEntipoYpodigmatosStoxothesias";
import { RenderStage } from "./ui/RenderStage";
import { yupResolver } from "@hookform/resolvers/yup";
import ypodeigmaStoxValidSchema from "./validationSChema";

function EntipoYpodigmatosStoxothesias({ person }) {
  const { handlePostRequest } = usePostRequest(
    postCaseInfoEntipoYpodigmatosStoxothesias
  );
  const { id } = useParams();
  const [userData, setUserData] = useState();
  const { referenceUserInfo } = useAuth();
  const { data: caseInfo, isLoading: caseinfoIsLoading } =
    useGetCaseInfoEntipoYpodigmatosStoxothesias(id);
  const stageNumber =
    caseInfo?.data?.documentInfo?.submissionStage?.code?.split(":")[1];
  const formContext = useForm({
    resolver: yupResolver(ypodeigmaStoxValidSchema(stageNumber)),
  });
  const title = person
    ? "Έντυπο Υποδείγματος Στοχοθεσίας Υπάλληλου"
    : "Έντυπο Υποδείγματος Στοχοθεσίας Προϊσταμένου";
  useEffect(() => {
    const fetchAndSetRegistries = async () => {
      const data = await getRegistryById(
        caseInfo?.data?.documentInfo?.registry?.id
      );
      setUserData(data);
    };
    if (caseinfoIsLoading) return;
    !caseinfoIsLoading && fetchAndSetRegistries();
    console.log(caseInfo?.data, "hello");

    formContext.reset({
      referenceYear: caseInfo?.data?.referenceYear,
      modelName: caseInfo?.data?.modelName,
      stage1: {
        ...caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9701
        ),
      },
      stage2: {
        ...caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9751
        ),
      },
      stage3: {
        ...caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9801
        ),
      },
      modelTargetType: String(
        caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9751
        )?.modelTargetType?.id
      ),
    });
  }, [caseinfoIsLoading]);

  return (
    <Box mt={17}>
      <AppBreadcrumbs
        breadcrumbsList={[
          {
            title: "Μητρώο Αξιολόγησης Προσωπικού",
            url: "/personnel-registry-evaluation",
          },
          {
            title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
              referenceUserInfo?.name
            } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
            url: `/evaluation-card/${referenceUserInfo?.id}`,
          },
          {
            title: "Διενέργειες Αξιολογήσεων",
            url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
          },
          {
            title: `Αρμοδιότητες Αρχιφύλακα Εσωτερικής Φρούρησης: ${
              caseInfo?.data?.documentInfo?.registry.surname
            }-${caseInfo?.data?.documentInfo?.registry.name}-${
              caseInfo?.data?.documentInfo?.registry.afm
            } - ${
              caseInfo?.data?.documentInfo?.registry?.serviceAccount?.position
                ?.description || ""
            }`,
          },
        ]}
      />

      <PageTitle title={title} />

      <FormContainer
        formContext={formContext}
        onSuccess={async (data) => {
          console.log(data);
          const modelTargetingFormDetails = [
            {
              id: caseInfo?.data?.modelTargetingFormDetails?.find(
                (row) => +row?.modelType?.id === 9701
              )?.id,
              modelType: {
                id: 9701,
              },
              ...data.stage1,
            },
            ...(stageNumber >= 2
              ? [
                  {
                    id: caseInfo?.data?.modelTargetingFormDetails?.find(
                      (row) => +row?.modelType?.id === 9751
                    )?.id,
                    modelType: {
                      id: 9751,
                    },
                    modelTargetType: {
                      id: data.modelTargetType,
                    },
                    ...data.stage2,
                  },
                ]
              : []),
            ...(stageNumber >= 3
              ? [
                  {
                    id: caseInfo?.data?.modelTargetingFormDetails?.find(
                      (row) => +row?.modelType?.id === 9801
                    )?.id,
                    modelType: {
                      id: 9801,
                    },
                    ...data.stage3,
                  },
                ]
              : []),
          ];
          const body = {
            referenceYear: data.referenceYear,
            modelName: data.modelName,

            id: caseInfo?.data?.id,
            documentInfo: caseInfo?.data?.documentInfo,
            modelTargetingFormDetails: modelTargetingFormDetails,
          };
          try {
            isSubmittingFormHandler();
            await handlePostRequest(body);
            successfullySubmittedHandler(referenceUserInfo?.id);
          } catch (error) {
            failedSubmitHandler();
          }
        }}
      >
        <Typography variant="h3"> Στοιχεία Αξιολογούμενου </Typography>
        <Box display="flex" justifyContent="center" my={2}>
          {userData && (
            <EmployeeCard data={userData}>
              <RegistrationEmployeeInfo data={userData} />
            </EmployeeCard>
          )}
        </Box>

        <Typography variant="h2">Χρονική Περίοδος Αξιολόγησης*</Typography>

        <Box my={3}>
          <HorizontalVerticalTogglerLayout>
            <TextFieldElement
              inputProps={{
                readOnly: true,
              }}
              sx={{
                input: { color: "grey" },
              }}
              label="Έτος Αναφοράς"
              name="referenceYear"
            />

            <TextFieldElement label="Όνομα υποδείγματος" name="modelName" />
          </HorizontalVerticalTogglerLayout>
        </Box>

        <RenderStage stage={"stage1"} />
        {stageNumber >= 2 && <RenderStage stage={"stage2"} />}
        {stageNumber >= 3 && <RenderStage stage={"stage3"} />}

        <Box sx={{ marginY: 6 }}>
          <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
          {caseInfo?.data && (
            <Box display="flex" justifyContent="center" my={2}>
              <EmployeeCard data={caseInfo?.data?.evaluationGroupSupervisor}>
                <RegistrationEmployeeInfo
                  data={caseInfo?.data?.evaluationGroupSupervisor}
                />
              </EmployeeCard>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button type="button" onClick={() => router.navigate(-1)}>
            Επιστροφή
          </Button>
          <Button type="submit" variant="contained">
            Οριστικοποίηση
          </Button>
        </Box>
      </FormContainer>
    </Box>
  );
}

export default EntipoYpodigmatosStoxothesias;
