import { useQuery } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect } from "react";
import HttpService from "../services/httpClient";
import UserService from "./../services/userService";
import { getRegistryById } from "../services/PersonnelManagement.service";

function useGetRolesCall(afm) {
  return useQuery({
    queryFn: () =>
      afm
        ? HttpService.getAxiosClient().get(
            `registry-evaluation/group/registry/afm/${afm}`
          )
        : undefined,
    queryKey: `_roles`,
  });
}

export function useGetUserInfo({ refetch }) {
  const profile = UserService?.profile();
  const { data: roles, isLoading: rolesIsLoading } = useGetRolesCall(
    profile?.attributes?.afm?.[0]
  );
  const [rolesState, setRolesState] = useLocalStorage("roles");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");

  useEffect(() => {
    if (!refetch) return;
    setUserInfo(roles?.data);
    if (roles) {
      setRolesState(roles?.data?.registryRole);
    }
  }, [
    // roles,
    refetch,
    rolesIsLoading,
  ]);

  return {
    userInfo,
    roles,
    rolesIsLoading,
  };
}

function useAuth() {
  const [rolesState, setRolesState] = useLocalStorage("roles");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");
  const [referenceUserInfo, setReferenceUserInfo] =
    useLocalStorage("referenceUserInfo");

  const clearStorage = () => {
    setRolesState(null);
    setUserInfo(null);
  };
  const setReferenceUser = async (id) => {
    const refUser = await getRegistryById(+id);
    setReferenceUserInfo(refUser);
  };
  const hasRole = (role) => {
    if (role === "KATAXORITIS" || role === "SuperAdmin") {
      return UserService?.hasRole([role]);
    }
    return rolesState?.includes(role);
  };

  const hasAnyRole = (roles) => {
    return roles?.some((role) => hasRole(role));
  };

  const hasAllRoles = (roles) => {
    return roles?.every((role) => hasRole(role));
  };

  return {
    clearStorage,
    roles: rolesState,
    setRoles: setRolesState,
    hasRole,
    hasAnyRole,
    hasAllRoles,
    userInfo,
    referenceUserInfo,
    setReferenceUser,
  };
}

export default useAuth;
