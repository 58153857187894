import { Box, Button } from "@mui/material";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import FromItem from "../../Armodiothtes/shared/utils/PredefinedBackendForm/FromItem";

function FormItemComp({ context, targetTypes = [], index, mainName }) {
  return (
    <Box flexDirection="column" gap={1} display="flex" justifyContent="center">
      {/* We use This Component hidden so backend to know what field to update, cause otherwise it performs create instead of update */}
      <TextFieldElement sx={{ display: 'none' }} name={`${mainName}.${index}.id`}></TextFieldElement>
      <SelectElement
        name={`${mainName}.${index}.targetType.id`}
        label="Επιλογή Στόχου"
        options={targetTypes}
      />
      <FromItem
        context={context}
        mode={2}
        code={"row?.code"}
        name={`${mainName}.${index}.targetScore`}
      />

      <TextFieldElement
        name={`${mainName}.${index}.notes`}
        label="Αιτιολογία"
      />
    </Box>
  );
}

export default FormItemComp;
