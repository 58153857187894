// Get Requests that only fetch data

import { useQuery } from "@tanstack/react-query";
import { getEvaluationGroups } from "./gateway";

export function useGetEvaluationGroups(id) {
    return useQuery({
      queryKey: ['evaluationGroups', id],
      queryFn: () => getEvaluationGroups(id),
    });
  }