import { getSuggToolsInspLabels } from '../../evaluationsHelperFuncs';
import AddSuggestionToolInspectionWrapper from '../AddSuggestionToolInspectionWrapper';

const AddInspection = () => {
    return(<AddSuggestionToolInspectionWrapper
        title={
          "Καταχώριση-Επεξεργασία Επιθεώρησης που διενεργήθηκε"
        }
        formTitle="Επιθεώρηση που διενεργήθηκε"
        labels={getSuggToolsInspLabels(4551)}        
        objectiveType={4551}
        />)
}
export default AddInspection