import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import ErrorPage from "./ErrorPage/ErrorPage";
import AddNewOfficer from "./Pages/AddNewOfficer/AddNewOfficer";
import EmployeeRegistry from "./Pages/EmployeeRegistry/EmployeeRegistry";
import EvaluationCard from "./Pages/Evaluations/EvaluationCard/EvaluationCard";
import EvaluationRegister from "./Pages/Evaluations/EvaluationRegister/EvaluationRegister";
import AddFunctionalSuggestion from './Pages/Evaluations/SuggestionsToolsInspections/AddFunctionalSuggestion/AddFunctionalSuggestion';
import AddImprovingSuggestion from './Pages/Evaluations/SuggestionsToolsInspections/AddImprovingSuggestion/AddImprovingSuggestion';
import AddImprovingTool from './Pages/Evaluations/SuggestionsToolsInspections/AddImprovingTool/AddImprovingTool';
import AddInspection from './Pages/Evaluations/SuggestionsToolsInspections/AddInspection/AddInspection';

import ArxifilkasEvaluationPage from "./Evaluation/Armodiothtes/Arxifilakas_type1";
import DexiotitesProistamenou from "./Evaluation/EntipoDexiotiton/DexiotitesProistamenou";
import CreateEvaluationGroups from "./Evaluation/Groups/feature/create/group-create";
import UpdateEvaluationGroups from "./Evaluation/Groups/feature/edit/group-edit";
import ListEvaluationGroups from "./Evaluation/Groups/feature/list-groups";
import PreviewEvaluationGroup from "./Evaluation/Groups/feature/preview/preview";
// import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SuggestionsToolsInspections from "./Pages/Evaluations/SuggestionsToolsInspections/SuggestionsToolsInspections";
import EducationDetailedHistory from './Pages/History/Education/EducationDetailedHistory';
import EducationHistory from "./Pages/History/Education/EducationHistory";
import OvertimeDetailedHistory from "./Pages/History/Overtime/OvertimeDetailedHistory";
import OvertimeHistory from "./Pages/History/Overtime/OvertimeHistory";
import ServiceActionDetailedHistory from "./Pages/History/ServiceAction/ServiceActionDetailedHistory";
import ServiceActionHistory from "./Pages/History/ServiceAction/ServiceActionHistory";
import ServiceChangeDetailedHistory from "./Pages/History/ServiceChange/ServiceChangeDetailedHistory";
import ServiceChangeHistory from "./Pages/History/ServiceChange/ServiceChangeHistory";
import SwitchDetailedHistory from './Pages/History/Switch/SwitchDetailedHistory';
import SwitchHistory from "./Pages/History/Switch/SwitchHistory";
import MultipleEducationRegistries from "./Pages/MultipleRegistrations/MultipleEducationRegistries";
import MultipleOvertimeRegistries from "./Pages/MultipleRegistrations/MultipleOvertimeRegistries";
import MultipleServiceActionRegistries from "./Pages/MultipleRegistrations/MultipleServiceActionRegistries";
import MultipleServiceChangeRegistries from "./Pages/MultipleRegistrations/MultipleServiceChangeRegistries";
import MultipleSwitchesRegistries from "./Pages/MultipleRegistrations/MultipleSwitchesRegistries";
import PersonnelManagement from "./Pages/PersonnelManagement/PersonnelManagement";
import EducationRegistration from "./Pages/Registrations/Education/EducationRegistration";
import EducationLevelRegistration from './Pages/Registrations/EducationLevel/EducationLevelRegistration';
import IntangibleRewardsRegistration from "./Pages/Registrations/IntangibleRewards/IntangibleRewardsRegistration";
import OvertimeRegistration from "./Pages/Registrations/Overtimes/OvertimeRegistration";
import ServiceActionsRegistration from "./Pages/Registrations/ServiceActions/ServiceActionsRegistration";
import ServiceChangesRegistration from "./Pages/Registrations/ServiceChanges/ServiceChangesRegistration";
import ServiceGradeRegistration from './Pages/Registrations/ServiceGrade/ServiceGradeRegistration';
import ServicePositionRegistration from './Pages/Registrations/ServicePosition/ServicePositionRegistration';
import SwitchesRegistration from "./Pages/Registrations/Switches/SwitchesRegistration";
import MainLayout from "./components/MainLayout";

import DevelopmentDocument from "./Evaluation/DevelopmentDocument/DevelopmentDocument";
import EvaluationMiddleReportManager from "./Evaluation/EkthesiAxiologisis/EvaluationMiddleReportManager";
import EvaluationReportEmployee from "./Evaluation/EkthesiAxiologisis/EvaluationReportEmployee";
import EvaluationReportManager from "./Evaluation/EkthesiAxiologisis/EvaluationReportManager";
import { StoxosProistamenos } from "./Evaluation/Entipo/StoxosProistamenos";
import { StoxosYpallilos } from "./Evaluation/Entipo/StoxosUpallilos";
import EntipoAnaptixiApotiposiProistamenos from './Evaluation/EntipoAnaptixiApotiposi/ModelTargetingProistamenos';
import EntipoAnaptixiApotiposiYpallilos from "./Evaluation/EntipoAnaptixiApotiposi/ModelTargetingYpallilos";
import DexiotitesUpallilon from "./Evaluation/EntipoDexiotiton/DexiotitesUpallilou";
import EntipoYpodigmatosStoxothesias from "./Evaluation/EntipoYpodigmatosStoxothesias/EntipoYpodigmatosStoxothesiasArxifilakas";
import EntipoSfigmouOmadas from "./Evaluation/Sfigmos/EntipoSfigmou";
import EnieosSfigmos from "./Evaluation/SfigmosEnieos/SfigmosEnieos";
import AssessmentProcedures from './Pages/Evaluations/AssessmentProcedures/AssessmentProcedures';
import GroupPulseForm from "./Pages/Evaluations/GroupPulseForm/GroupPulseForm";
import SubordinateEvaluations from "./Pages/Evaluations/SubordinateEvaluations/SubordinateEvaluations";
import LoginPage from "./Pages/LoginPage";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/personnel-management",
        element: <PersonnelManagement />,
      },
      {
        path: "/add-officer",
        element: <AddNewOfficer />,
      },
      {
        path: '/evaluation',

        children: [
          {
            path: 'groups', children: [
              { index: true, element: <ListEvaluationGroups /> },
              { path: 'create', element: <CreateEvaluationGroups /> },
              { path: 'preview', element: <PreviewEvaluationGroup /> },
              { path: 'edit/:id', element: <UpdateEvaluationGroups /> },
            ]
          },
          {
            path: 'competencies', children: [
              { path: 'type1/:id', element: <ArxifilkasEvaluationPage responsibility_type='responsibility_type_1' /> },
              { path: 'type2/:id', element: <ArxifilkasEvaluationPage responsibility_type='responsibility_type_2' /> },
              { path: 'type3/:id', element: <ArxifilkasEvaluationPage responsibility_type='responsibility_type_3' /> },
              { path: 'type4/:id', element: <ArxifilkasEvaluationPage responsibility_type='responsibility_type_4' /> },
              { path: 'type5/:id', element: <ArxifilkasEvaluationPage responsibility_type='responsibility_type_5' /> },
              { path: 'type6/:id', element: <ArxifilkasEvaluationPage responsibility_type='responsibility_type_6' /> },
            ]
          },
          {
            path: 'targetAchievement', children: [
              { path: 'type1/:id', element: <StoxosProistamenos /> },
              { path: 'type2/:id', element: <StoxosYpallilos /> },
            ]
          },
          {
            path: 'skillsForm', children: [
              { path: 'type1/:id', element: <DexiotitesProistamenou /> },
              { path: 'type2/:id', element: <DexiotitesUpallilon /> }
            ]
          },
          {
            path: 'modelTargeting', children: [
              {
                path: 'type1', children: [
                  { index: true, element: <EntipoAnaptixiApotiposiProistamenos /> },
                  { path: ':id', element: <EntipoAnaptixiApotiposiProistamenos /> }
                ]
              },
              {
                path: 'type2', children: [
                  { index: true, element: <EntipoAnaptixiApotiposiYpallilos /> },
                  { path: ':id', element: <EntipoAnaptixiApotiposiYpallilos /> }
                ]
              },
            ]
          },
          {
            path: 'modelTargetUpodeigmatos', children: [
              {
                path: 'type1', children: [
                  { index: true, element: <EntipoYpodigmatosStoxothesias /> },
                  { path: ':id', element: <EntipoYpodigmatosStoxothesias /> }
                ]
              },
              {
                path: 'type2', children: [
                  { index: true, element: <EntipoYpodigmatosStoxothesias person="ipallilos" /> },
                  { path: ':id', element: <EntipoYpodigmatosStoxothesias person="ipallilos" /> }
                ]
              },
            ]
          },
          {
            path: 'assessmentReport', children: [
              { path: 'type1/:id', element: <EntipoAnaptixiApotiposiProistamenos /> },
              { path: 'type2/:id', element: <EntipoAnaptixiApotiposiYpallilos /> }
            ]
          },
          {
            path: 'evaluationReport', children: [
              { path: 'type1/:id', element: <EvaluationReportManager /> },
              { path: 'type2/:id', element: <EvaluationMiddleReportManager /> },
              { path: 'type3/:id', element: <EvaluationReportEmployee /> }
            ]
          },
          {
            path: 'sfigmos', children: [
              { path: 'type1/:id', element: <EntipoSfigmouOmadas /> },
            ]
          },
          {
            path: 'whole-pulse/:id', element: <EnieosSfigmos />
          },
          {
            path: 'growth_plan/:registryId',
            children: [
              { index: true, element: <DevelopmentDocument /> },
              { path: ":docId", element: <DevelopmentDocument /> }]

          },
          { path: 'test', element: <h1 style={{ marginTop: '100px' }}>test</h1> },

        ],
      },
      {
        path: "/employee-info/:employeeId/",
        children: [
          { index: true, element: <EmployeeRegistry /> },
          { path: "edit", element: <AddNewOfficer mode="edit"/> },
          { path: "service-grade-registry", element: <ServiceGradeRegistration /> },
          { path: "service-position-registry", element: <ServicePositionRegistration /> },
          { path: "education-level-registry", element: <EducationLevelRegistration /> },

          {
            path: "service-changes-registry",
            element: <ServiceChangesRegistration />,
          },
          { path: "overtime-registry", element: <OvertimeRegistration /> },
          {
            path: "service-actions-registry",
            element: <ServiceActionsRegistration />,
          },
          { path: "switches-registry", element: <SwitchesRegistration /> },
          { path: "education-registry", element: <EducationRegistration /> },
          {
            path: "intangible-rewards-registry",
            element: <IntangibleRewardsRegistration />,
          },
        ],
      },

      {
        path: "/multiple-registries/",
        children: [
          // { index: true, element: <EmployeeRegistry /> },
          {
            path: "service-changes",
            element: <MultipleServiceChangeRegistries />,
          },
          { path: "overtime", element: <MultipleOvertimeRegistries /> },
          {
            path: "service-actions",
            element: <MultipleServiceActionRegistries />,
          },
          { path: "switches", element: <MultipleSwitchesRegistries /> },
          { path: "education", element: <MultipleEducationRegistries /> },
        ],
      },

      {
        path: "/history/",
        children: [
          // { index: true, element: <EmployeeRegistry /> },
          {
            path: "service-changes",
            element: <ServiceChangeHistory />,
          },
          { path: "overtime", element: <OvertimeHistory /> },
          {
            path: "service-actions",
            element: <ServiceActionHistory />,
          },
          { path: "switches", element: <SwitchHistory /> },
          { path: "education", element: <EducationHistory /> },
        ],
      },
      {
        path: "/detailed-history/",
        children: [
          // { index: true, element: <EmployeeRegistry /> },
          {
            path: "service-changes/:date/:id",
            element: <ServiceChangeDetailedHistory />,
          },
          { path: "overtime/:date/:id", element: <OvertimeDetailedHistory /> },
          {
            path: "service-actions/:date/:id",
            element: <ServiceActionDetailedHistory />,
          },
          { path: "switches/:date/:id", element: <SwitchDetailedHistory /> },
          { path: "education/:date/:id", element: <EducationDetailedHistory /> },
        ],
      },
      {
        path: "/personnel-registry-evaluation",
        element: <EvaluationRegister />,
      },
      {
        path: "/evaluation-card/:id/",
        children: [
          { index: true, element: <EvaluationCard /> },
          {
            path: "assessment-procedures",
            element: <AssessmentProcedures />,
          },
          {
            path: "subordinate-evaluations",
            element: <SubordinateEvaluations />,
          },
          {
            path: "group-pulse-form",
            element: <GroupPulseForm />,
          },
          {
            path: "suggestions-tools-inspections",
            children: [
              { index: true, element: <SuggestionsToolsInspections /> },
              { path: "add-functional-suggestion", element: <AddFunctionalSuggestion /> },
              { path: "add-improving-suggestion", element: <AddImprovingSuggestion /> },
              { path: "add-improving-tool", element: <AddImprovingTool /> },
              { path: "add-inspection", element: <AddInspection /> },
            ]
          },
        ]
      },
    ],
  },
]);
function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
