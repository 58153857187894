import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import Details from "../../../components/Details/Details";
import AppAccordion from "../../../components/UI/AppAccordion";
import FormDatePicker from "../../../components/UI/FormDatePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";

import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import {
  defaultServiceGradeRegistrationValues as defaultValues,
  formServiceGradeRegistrationSchema as formSchema,
} from "./serviceGradeRegistrationConfig";
import { useNavigate, useParams } from "react-router-dom";
import {
  registrationDetailsTransformer,
  transformServiceGradeDataToBEFormat,
} from "../dataTransformFunctions";
import { serviceGradeFieldValidation } from "./serviceGradeFieldValidation";
import { addNewServiceGradeRegistry } from "../../../services/Registrations/ServiceGrade.service";
import { getServiceGradeDropdown } from "../../../services/dropdownData.service";
const ServiceGradeRegistrationForm = ({ data }) => {
  const registry = {
    id: data.id,
    name: data.name,
    surname: data.surname,
    fathersName: data.fathersName,
    fathersSurname: data.fathersSurname,
    mothersName: data.mothersName,
    mothersSurname: data.mothersSurname,
    dateOfBirth: data.dateOfBirth,
    placeOfBirth: data.placeOfBirth,
    adt: data.adt,
    issuingAuthority: data.issuingAuthority,
    afm: data.afm,
    bloodType: data.bloodType,
    amka: data.amka,
    payrollAccountNumber: data.payrollAccountNumber,
    iban: data.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };
  const formContext = useForm({
    resolver: yupResolver(serviceGradeFieldValidation),
    defaultValues: defaultValues,
  });
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [serviceGradeTypes, setServiceGradeTypes] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [serviceGradeOptions, setServiceGradeOptions] = useState([]);
  const employeeId = useParams().employeeId;

  const navigate = useNavigate();
  const onSave = () => {
    const data = transformServiceGradeDataToBEFormat(
      formData,
      serviceGradeTypes
    );
    serviceGradeTypes && addNewServiceGradeRegistry(data);
    setIsShownSaveModal(false);
    navigate(`/employee-info/${employeeId}`);
  };
  console.log(formSchema);
  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const serviceGradeOptions = await getServiceGradeDropdown();
      setServiceGradeTypes(serviceGradeOptions);
      setServiceGradeOptions(
        serviceGradeOptions["grade_type"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    fetchBEConfigInfo();
  }, []);

  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (serviceGradeData) => {
            console.log(serviceGradeData);
            setFormData({ ...serviceGradeData, registry });
            const serviceGradeTypeDescription = serviceGradeOptions.find(
              (option) => option.id === +serviceGradeData.serviceGradeType
            )?.label;
            console.log(data);
            const transformedData = registrationDetailsTransformer(formSchema, {
              ...serviceGradeData,
              serviceGradeType: serviceGradeTypeDescription,
              userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
              userPosition: data.serviceAccount.position.description,
            });
            setPreviewData(transformedData);
            console.log(serviceGradeTypeDescription);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.serviceGradeDate.label}>
              <FormDatePicker
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.serviceGradeDate.name}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.serviceGradeType.label}>
              {serviceGradeOptions && (
                <SelectElement
                  fullWidth
                  label={formSchema.serviceGradeType.label}
                  name={formSchema.serviceGradeType.name}
                  options={serviceGradeOptions}
                />
              )}
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.serviceOrder.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.serviceOrder.label}
                name={formSchema.serviceOrder.name}
                variant="outlined"
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
          <h4>{`Υπηρεσιακός Βαθμός ${previewData.values[2]}`}</h4>
          {
            <Details
              renderFirstRowsAsHeader
              renderArraysAsColumns
              rows={[
                {
                  name: previewData.labels[0],
                  value: previewData.labels.slice(1, previewData.length),
                },
                {
                  name: previewData.values[0],
                  value: previewData.values.slice(1, previewData.length),
                },
              ]}
            />
          }
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ServiceGradeRegistrationForm;
