export const defaultServicePositionRegistrationValues = {
  servicePositionDate: "",
    servicePositionType: "",
    serviceOrder: "Α.Π.",
    notes: "",
  }

  export  const formServicePositionRegistrationSchema = {
    servicePositionDate: {
      name: "servicePositionDate",
      label: "Ημερομηνία",
    },
    servicePositionType: {
      name: "servicePositionType",
      label: "Θέση",
    },
    serviceOrder: {
      name: "serviceOrder",
      label: "Α.Π Διαταγής",
    },
    notes: {
      name: "notes",
      label: "Παρατηρήσεις",
    },
  };
  export const servicePositionColumns = [

    
    {
      id: "actionDate",
      label: "Ημερομηνία",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "positionType.description",
      label: "Θέση",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "formProtocolNumber",
      label: "Α.Π Διαταγής",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "notes",
      label: "Παρατηρήσεις",
      minWidth: 170,
      isClickable: false,
    },

  ];