import BasicStage from "../shared/ui/BasicStage";
import Stadio2 from "../shared/ui/Stages/Stage2/Stage2";
import { Stadio3 } from "../shared/ui/Stages/Stage3";
import { Typography } from "@mui/material";

const stageComponents = {
  stage1: { title: "Στάδιο 1", component: BasicStage },
  stage2: { title: "Στάδιο 2", component: Stadio2 },
  stage3: { title: "Στάδιο 3", component: Stadio3 },
};

export const RenderStageYpodeigmatos = ({ stage, isManager }) => {
  const developTypes = [
    { id: "developed", label: "Ανεπτυγμένη Δεξιότητα" },
    { id: "developing", label: "Προς Ανάπτυξη Δεξιότητα" },
  ];
  const StageComponent = stageComponents[stage];
  if (!StageComponent) {
    return <Typography>Διαλέξτε ένα Στάδιο</Typography>;
  }

  const { title, component: Component } = StageComponent;

  return (
    <>
      <Typography variant="h2">{title}</Typography>
      <Component isManager={isManager} developTypes={developTypes} />
    </>
  );
};
