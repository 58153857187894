import { Box, InputLabel } from "@mui/material";
import {
  RadioButtonGroup,
  TextFieldElement
} from "react-hook-form-mui";

const DevelopmentAccordionItem = ({ id }) => {
  const developTypes = [
    { id: "developed", label: "Ανεπτυγμένη Δεξιότητα" },
    { id: "developing", label: "Προς Ανάπτυξη Δεξιότητα" },

  ];
  return (
    <>
        <RadioButtonGroup
          row
          key={id}
          name={`isDeveloped${id}`}
          options={developTypes}
        />
        <Box
          key={`reason${id}`}
          sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <InputLabel
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={`reason${id}`}
          >
            Αιτιολογία:
          </InputLabel>
          <TextFieldElement
            fullWidth
            id={`reason${id}`}
            key={`reason${id}`}
            name={`reason${id}`}
          />

          <InputLabel
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={`method${id}`}
          >
            Μέθοδος Ανάπτυξης:
          </InputLabel>
          <TextFieldElement
            fullWidth
            id={`method${id}`}
            key={`method${id}`}
            name={`method${id}`}
          />
          <InputLabel
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={`method${id}`}
          >
            Αναλυτική Περιγραφή Ενέργειας Ανάπτυξης:
          </InputLabel>
          <TextFieldElement
            fullWidth
            id={`description${id}`}
            key={`description${id}`}
            name={`description${id}`}
          />
          <InputLabel
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={`implementation`}
          >
            Χρονοδιάγραμμα Υλοποίησης Ενέργειας Ανάπτυξης:
          </InputLabel>
          <TextFieldElement
            fullWidth
            id={`implementation${id}`}
            key={`implementation${id}`}
            name={`implementation${id}`}
          />
        </Box>
    </>
  );
};

export default DevelopmentAccordionItem;
