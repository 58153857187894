import { Box, Grid, InputLabel } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import FormDatePicker from "./UI/FormDatePicker";
import DatePickerFns from "../Evaluation/Groups/ui/DatePickerFns";

const FormTextField = (props) => {
  return (
    <Grid container sx={{ paddingTop: 3 }}>
      {props?.fieldNames?.map((field) => (
        <Box
          key={field}
          sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <InputLabel
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={props?.values?.[field]?.name}
          >
            {props?.values?.[field]?.label}{" "}
          </InputLabel>
          {props?.values?.[field]?.name?.includes("date") ? (
            <DatePickerFns
              readOnly={props?.isPreviewMode || props?.values?.[field]?.readOnly}
              name={props?.values?.[field]?.name}
            />
          ) : (
            <TextFieldElement
              inputProps={{
                  readOnly: props?.isPreviewMode || props?.values?.[field]?.readOnly,
              }}
              sx={{
                input: { color: props?.isPreviewMode ? "grey" : "inherit" },
              }}
              fullWidth
              id={props?.values?.[field]?.name}
              key={props?.values?.[field]?.name}
              name={props?.values?.[field]?.name}
              value={props?.values?.[field]?.name}
            />
          )}
        </Box>
      ))}
    </Grid>
  );
};
export default FormTextField;
