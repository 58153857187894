import { TextField } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../components/Details/utils";
import HorizontalVerticalTogglerLayout from "../../../components/Layout/HorizontalVerticalTogglerLayout";

const EntipoYpodigmatosStoxothesiasStage1 = () => {
  return (
    <>
      <HorizontalVerticalTogglerLayout direction="column">
        <TextFieldElement
          fullWidth
          label="Ονομασία Στόχου*"
          name="stage1.targetName"
        />

        <TextFieldElement
          fullWidth
          label="Περιγραφή Αναμενόμενου Οφέλους για το Κατάστημα Κράτησης"
          name="stage1.measurementIndicator"
        />

        <TextFieldElement
          fullWidth
          label="Αιτολόγηση"
          name="stage1.description"
        />
        <TextFieldElement label="Παρατηρήσεις" name="stage1.notes" />
        <TextField
          disabled={true}
          label="Ημερομηνία"
          value={defaultRenderDates(new Date())}
        />
      </HorizontalVerticalTogglerLayout>
    </>
  );
};

export default EntipoYpodigmatosStoxothesiasStage1;
