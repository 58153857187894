import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import { getRegistryById } from "../../services/PersonnelManagement.service";
import EmployeeCard from "../EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "./RegistrationEmployeeInfo";
import AppModal from "../../components/UI/Modal/AppModal";

const RegistrationWrapper = ({ title, FormComponent }) => {
  const employeeId = useParams().employeeId;
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  useEffect(() => {
    console.log("useEffect");
    const fetchAndSetUserData = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
    };
    fetchAndSetUserData();
  }, [employeeId]);
  const userFullName = userData
    ? `${userData.surname} ${userData.name} ${userData.afm}`
    : "";
  const breadcrumbsList = [
    {
      title: "Διαχείριση Φυλακτικού Προσωπικού",
      url: "/personnel-management",
    },
    {
      title: `Στοιχεία Μητρώου ${userFullName}`,
      url: `/employee-info/${employeeId}`,
    },
    {
      title: title,
      url: "",
    },
  ];
  return (
    userData && (
      <>
        <Box>
          <AppBreadcrumbs breadcrumbsList={breadcrumbsList} />
        </Box>
        <Box>
          <h2>{title}</h2>
          <Divider sx={{ bgcolor: "black.main" }} />
        </Box>
        <Box
          sx={{
            marginY: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <EmployeeCard data={userData}>
            <RegistrationEmployeeInfo data={userData} />
          </EmployeeCard>
          <FormComponent
            data={userData}
            setModalOpen={setModalOpen}
            setModalContent={setModalContent}
          />
        </Box>
        {modalOpen && (
          <AppModal  onHideModal={() => setModalOpen(false)}>

            <Typography sx={{ margin: 3}}>{modalContent}</Typography>
            <Button onClick={() => navigate(`/employee-info/${employeeId}`)}>
              Επιστροφή
            </Button>{" "}

           
          </AppModal>
        )}
      </>
    )
  );
};
export default RegistrationWrapper;
