import el from "date-fns/locale/el";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePickerElement } from "react-hook-form-mui";
import { elGR } from "@mui/x-date-pickers/locales";

function DatePickerFns(props) {
  const greekLocale = {
    ...elGR.components.MuiLocalizationProvider.defaultProps.localeText,
    fieldDayPlaceholder: () => "ΗΗ",
    fieldMonthPlaceholder: () => "ΜΜ",
    fieldYearPlaceholder: () => "ΕΕΕΕ",
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={el}
      localeText={greekLocale}
    >
      <DatePickerElement
        {...props}
        readOnly={props.disabled || props.readOnly}
        inputProps={{ disabled: props.disabled || props.readOnly }}
        sx={{
          width: "100%",
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePickerFns;
