import { TextField } from "@mui/material";
import { RadioButtonGroup, TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../components/Details/utils";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";

const BasicStage = ({ isManager, developTypes }) => {
  
  return (
    <>
      <HorizontalVerticalTogglerLayout direction="column">
        <TextFieldElement
          fullWidth
          label="Περιγραφή Δεξιότητας*"
          name="stage1.targetName"
        />
        {isManager && (
          <RadioButtonGroup row name={"isDeveloped"} options={developTypes} />
        )}
        <TextFieldElement
          fullWidth
          label="Δείκτες Μέτρησης (και περιγραφή των Τιμών των Δεικτών)"
          name="stage1.measurementIndicator"
        />

        <TextFieldElement
          fullWidth
          label="Περιγραφή Επίτευξης Στόχου Ανάπτυξης Δεξιότητας"
          name="stage1.description"
        />
        <TextFieldElement
          label="Αιτιολόγηση Επιλογής Δεξιότητας"
          name="stage1.evaluation"
        />
        <TextField
          disabled={true}
          label="Ημερομηνία"
          value={defaultRenderDates(new Date())}
        />
      </HorizontalVerticalTogglerLayout>
    </>
  );
};

export default BasicStage;
