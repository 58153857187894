import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useBackAuth";
import UserService from "../services/userService";

const LoginPage = () => {
  const { userInfo, hasRole } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(UserService.hasRole(['KATAXORITIS']));
    if (!userInfo) {
      if (UserService.hasRole(['SuperAdmin']) || UserService.hasRole(['KATAXORITIS'])) {
        console.log('tes');
        navigate("/personnel-management");
      }
      else {

      }
    }
    else navigate(
      hasRole('employee')
        ? `/employee-info/${userInfo?.id}`
        : "/personnel-management"
    )
  }, [userInfo])

};
export default LoginPage;
