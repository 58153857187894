import useAuth from "../../hooks/useBackAuth";
import OvertimeRegistrationForm from "../Registrations/Overtimes/OvertimeRegistrationForm";
import MultipleRegistriesWrapper from "./MultipleRegistriesWrapper";

const MultipleOvertimeRegistries = () => {
  const { userInfo} = useAuth()

  return (
    <MultipleRegistriesWrapper
      title={"Υπερωρίας"}
      department={userInfo?.serviceAccount?.institute?.description}
      FormComponent={OvertimeRegistrationForm}
    />
  );
};
export default MultipleOvertimeRegistries;
