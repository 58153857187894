/* eslint-disable no-undef */
import Keycloak from 'keycloak-js';
import { config } from '../config';

// code for cinvince while using the keycloak object
// and for not using keyclaok object directly in our code
// eslint-disable-next-line no-underscore-dangle
const _kc = new Keycloak({
  realm: 'assessment',
  url: config.keycloakURL,
  clientId: 'evaluation',
});





/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = async (onAuthenticatedCallback) => {
  try {
    console.log(
      'Initializing Keycloak instance with URL: ',
    );
    const authenticated = await _kc.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
      // silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      // 'ssl-required': 'external',
      // 'public-client': true,
      // 'confidential-port': 0,
      // // silentCheckSsoRedirectUri: `${window.location.origin}/sfffilent-check-sso.html`,
      pkceMethod: 'S256',
    });
    if (!authenticated) {
      console.info('user is not authenticated..!');
    }
    if (authenticated)
      await allInfo()

    console.log('authenticated: ', authenticated);
    onAuthenticatedCallback && onAuthenticatedCallback();
  } catch (error) {
    console.error(error);
  }

};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isAuthenticated = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);


const getUsername = () => _kc.tokenParsed?.preferred_username;

const getFullName = () => _kc.tokenParsed?.name;

const getCoName = () => _kc.tokenParsed?.co_name;

const getKeycloackId = () => _kc.tokenParsed?.sub;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const allInfo = () => _kc.loadUserProfile();

const profile = () => _kc.profile;


const getRole = () => _kc?.realmAccess?.roles;

const UserService = {
  allInfo,
  getRole,
  initKeycloak,
  doLogin,
  doLogout,
  isAuthenticated,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getKeycloackId,
  profile,
  getFullName,
  getCoName
};

export default UserService;