import * as yup from "yup";

export const suggToolInspFormFieldValidation = yup.object().shape({
  proposalDate: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
    description: yup.string().required("Το πεδίο είναι υποχρεωτικό"),

  
  });
