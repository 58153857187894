import HttpService from "./httpClient";

export const getServiceChangeHistory = (
  page,
  size,
  institutionId,
  date,
  guardType
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-service-modification/history`, {
        params: {
          page,
          size,
          "institutionId.equals": institutionId,
          "modificationDate.equals":date,
          "isInternalGuard.equals": guardType && guardType === "internal",
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getServiceActionHistory = (
  page,
  size,
  institutionId,
  date,
  guardType
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-service-action/history`, {
        params: {
          page,
          size,
          "institutionId.equals": institutionId,
          "actionDate.equals": date,
          "isInternalGuard.equals": guardType && guardType === "internal",
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getOvertimeHistory = (
  page,
  size,
  institutionId,
  date,
  guardType
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-overtime/history`, {
        params: {
          page,
          size,
          "institutionId.equals": institutionId,
          "overtimeDate.equals": date,
          "isInternalGuard.equals": guardType && guardType === "internal",
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getSwitchHistory = (
  page,
  size,
  institutionId,
  date,
  guardType
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-transfer/history`, {
        params: {
          page,
          size,
          "institutionId.equals": institutionId,
          "transferDate.equals": date,
          "isInternalGuard.equals": guardType && guardType === "internal",
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getEducationHistory = (
  page,
  size,
  institutionId,
  date,
  guardType
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-education/history`, {
        params: {
          page,
          size,
          "institutionId.equals": institutionId,
          "educationDate.equals": date,
          "isInternalGuard.equals": guardType && guardType === "internal",
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteEducationHistory = (
  educationId,registryId
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .delete(`/registry-education/education/${educationId}/registry/${registryId}`,)
      .then(function (response) {
        const allRegistries =response.data;
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const deleteOvertimeHistory = (
  registryId,overtimeId
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .delete(`/registry-overtime/overtime/${overtimeId}/registry/${registryId}`,)
      .then(function (response) {
        const allRegistries =response.data;
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};export const deleteServiceActionHistory = (
  serviceActionId, registryId
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .delete(`/registry-service-action/service-action/${serviceActionId}/registry/${registryId}`,)
      .then(function (response) {
        const allRegistries =response.data;
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};export const deleteServiceChangeHistory = (
  serviceModificationId,registryId
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .delete(`/registry-service-modification/service-modification/${serviceModificationId}/registry/${registryId}`,)
      .then(function (response) {
        const allRegistries =response.data;
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};export const deleteSwitchHistory = (
  transferId,registryId
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .delete(`/registry-transfer/transfer/${transferId}/registry/${registryId}`,)
      .then(function (response) {
        const allRegistries =response.data;
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};