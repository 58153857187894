import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

function PageTitle({ title }) {
    return (<Box sx={{marginY:6}}>
        <Typography variant="h2">{title}</Typography>
        <Divider sx={{ bgcolor: "black.main" }} />
    </Box>);
}

export default PageTitle;