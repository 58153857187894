import { Box } from "@mui/material"

const RegistrationFormItem = (props) => {
    return (<Box sx={{marginTop:1,  display:'flex', flexDirection:'row', gap:2, alignItems:'center'}}>
        <p>{props.label}</p>
        <Box sx={{width: 400}}>
        {props.children}

        </Box>
        </Box>
    )
}
export default RegistrationFormItem