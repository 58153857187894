import RegistrationWrapper from "../RegistrationWrapper";
import OvertimeRegistrationForm from "./OvertimeRegistrationForm";

const OvertimeRegistration = () => {
  return (
    <RegistrationWrapper
    title={"Καταχώριση Υπερωρίας"}
    FormComponent={OvertimeRegistrationForm}
  />
  );
};
export default OvertimeRegistration;
