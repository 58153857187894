import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import GenericApplicationPreviewPage from "../../../../components/UI/GenericApplicationPreviewPage";
import { postEvaluationGroup } from "../../data-access/gateway";
import { registryEvaluationPREVIEWGroupDataGridColumns } from "../../utils/DataGridColumns";
import AppBreadcrumbs from "../../../../components/Breadcrumbs";
import usePostRequest from "../../../../hooks/useHttpCallHandler";
import PageTitle from "../../../../components/PageTitle";
import { enqueueSnackbar } from "notistack";
import '../../../../App.css'
import { isSubmittingFormHandler } from "../../../shared/utils";
function PreviewEvaluationGroup() {
    const location = useLocation();
    const navigate = useNavigate();
    const { handlePostRequest, isLoading, error } = usePostRequest(postEvaluationGroup);
    const evaluationGroupId = location?.state?.evaluationGroupId
    const mode = location.state.mode
    const instituteId = location.state.instituteId

    const handleGoBack = () => {
        const goBackPath = mode == 'create' ? 'create' : `edit/${evaluationGroupId}`
        navigate(location.pathname.replace('preview', goBackPath), { state: location.state.data, })
    };

    const onSubmit = async (data) => {
        const registries = data.registries?.map(x => ({ id: x.id, isEvaluationGroupSupervisor: false }))
        registries.push({ id: data.superVisor.id, isEvaluationGroupSupervisor: true })
        const body = {
            id: evaluationGroupId,
            isInternalGuard: data.isInternalGuard == '1',
            groupName: data.groupName,
            institutionId: data.institutionId,
            registries: registries,
            creationDate: data.creationDate,
            isActive: true,
            institution: { id: instituteId }
        }
        try {
            isSubmittingFormHandler()
            await handlePostRequest(body);
            enqueueSnackbar('Η ομάδα αξιολόγησης δημιουργήθηκε επιτυχώς', { variant: 'success' })
            navigate('/evaluation/groups')

        } catch (error) {
            enqueueSnackbar('Η ομάδα αξιολόγησης δεν δημιουργήθηκε, προσπαθήστε να αλλάξετε το όνομα ομάδας.', { variant: 'error' })
            console.error(error);
        }
        return body;
    }

    const state = location.state;
    const rows = state?.data?.registries?.map(x => x?.details) || [];
    console.log(rows,'rows')
    return (<>
        <Box mt={17}>
            <AppBreadcrumbs
                breadcrumbsList={[
                    {
                        title: "Ομάδες Αξιολόγησης",
                        url: "/evaluation/groups",
                    },
                    {
                        title: 'Ανασκόπηση Δημιουργίας Ομάδας Αξιολόγησης',
                    },
                ]}
            />

            <PageTitle title="Ανασκόπηση Ομάδας Αξιολόγησης" />

            <HorizontalVerticalTogglerLayout direction={'column'}>

                <GenericApplicationPreviewPage
                    location={location}
                >
                    <Typography variant="h2"> Στοιχεία Αξιολογούμενων</Typography>
                    <DataGrid rows={rows}

                        columns={registryEvaluationPREVIEWGroupDataGridColumns.map((column) => ({
                            ...column,
                            cellClassName: 'dataGridCell',
                        }))} />
                </GenericApplicationPreviewPage>
            </HorizontalVerticalTogglerLayout>
        </Box>


        <Box display="flex" justifyContent='flex-end' gap={3} my={3}>

            <Button variant="outlined" onClick={handleGoBack}>Πίσω</Button>
            <Button variant="contained" onClick={() => onSubmit(state.data)}>
                Οριστικοποίηση
            </Button>
        </Box>

    </>);
}

export default PreviewEvaluationGroup;