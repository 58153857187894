import { enqueueSnackbar, closeSnackbar } from "notistack";
import { router } from "../../App";

export const successfullySubmittedHandler = (referenceUserInfoId) => {

    enqueueSnackbar('Η αξιολόγηση καταχωρήθηκε επιτυχώς', { variant: 'success' })
    router.navigate( `/evaluation-card/${referenceUserInfoId}/assessment-procedures`)
}

export const isSubmittingFormHandler = () => {
    enqueueSnackbar('Η αξιολόγηση βρίσκεται σε διαδικασία καταχώρησης', { variant: 'info' })
}

export const failedSubmitHandler = () => {
    enqueueSnackbar('Η υποβολή της φόρμας απέτυχε', { variant: 'error' })
}