import { Typography } from "@mui/material";
import Stage2Case1 from "../shared/ui/Stages/Stage2/case1";
import Stage2Case2 from "../shared/ui/Stages/Stage2/case2";
import Stage2Case3 from "../shared/ui/Stages/Stage2/case3";

export const RenderCaseStage2 = ({ stage, isManager, developTypes }) => {
  const stageComponents = {
    20051: {
      title: "Εξέλιξη Ανάπτυξης Επιλεγμένης Δεξιότητας",
      component: Stage2Case1,
    },
    10101: {
      title: "Αναθεώρηση Βαθμού Ανάπτυξης Δεξιότητας",
      component: Stage2Case2,
    },
    10151: {
      title: "Αναθεώρηση Δεξιότητας (Νέα Δεξιότητα)",
      component: Stage2Case3,
    },
  };

  const StageComponent = stageComponents[stage];
  if (!StageComponent) {
    return <Typography>Διαλέξτε ένα Στάδιο</Typography>;
  }

  const { title, component: Component } = StageComponent;

  return (
    <>
      <Typography sx={{my:2}} variant="h2">{title}</Typography>
      <Component isManager={isManager} developTypes={developTypes} />
    </>
  );
};
