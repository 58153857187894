import * as yup from "yup";

export const fieldValidation = yup.object().shape({
  name: yup.string().required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[^0-9]+$/u, "Επιτρέπονται μόνο γράμματα"),
  surname: yup.string().required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[^0-9]+$/u, "Επιτρέπονται μόνο γράμματα"),
  fathersSurname: yup.string().required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[^0-9]+$/u, "Επιτρέπονται μόνο γράμματα"),
  fathersName: yup.string().required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[^0-9]+$/u, "Επιτρέπονται μόνο γράμματα"),
  mothersName: yup.string().required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[^0-9]+$/u, "Επιτρέπονται μόνο γράμματα"),
  dateOfBirth: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
  mothersSurname: yup.string().required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[A-Za-zΑ-Ωα-ωΆ-Ώά-ώ]+$/, "Επιτρέπονται μόνο γράμματα"),
  placeOfBirth: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  adt: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  issuingAuthority: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  afm: yup
    .string()
    .required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[0-9]+$/, "Επιτρέπονται μόνο αριθμοί")
    .min(9, "Πληκτρολογήστε έναν αριθμο με 9 ψηφία")
    .max(9, "Πληκτρολογήστε έναν αριθμο με 9 ψηφία"),
  amka: yup
    .string()
    .required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[0-9]+$/, "Επιτρέπονται μόνο αριθμοί")
    .min(11, "Πληκτρολογήστε έναν αριθμο με 11 ψηφία")
    .max(11, "Πληκτρολογήστε έναν αριθμο με 11 ψηφία"),
  bloodType: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  postalCode: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  city: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  region: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  street: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  numberOfStreet: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  telephone: yup
    .string()
    .required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[0-9]+$/, "Επιτρέπονται μόνο αριθμοί")
    .min(10, "Πληκτρολογήστε έναν αριθμο με 10 ψηφία")
    .max(10, "Πληκτρολογήστε έναν αριθμο με 10 ψηφία"),
  mobile: yup
    .string()
    .required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^[0-9]+$/, "Επιτρέπονται μόνο αριθμοί")
    .min(10, "Πληκτρολογήστε έναν αριθμο με 10 ψηφία")
    .max(10, "Πληκτρολογήστε έναν αριθμο με 10 ψηφία"),
  contactNumber: yup.string().when('type', {
    is: type => type === true,
    then: yup
      .string()
      .matches(/^[0-9]+$/, "Επιτρέπονται μόνο αριθμοί")
      .min(10, "Πληκτρολογήστε έναν αριθμο με 10 ψηφία")
      .max(10, "Πληκτρολογήστε έναν αριθμο με 10 ψηφία"),
    else: yup.string().nullable(true),
  }),
  payrollAccountNumber: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  payrollIBAN: yup
    .string()
    .required("Το πεδίο είναι υποχρεωτικό")
    .matches(/^\S*$/, "Δεν επιτρέπονται κενά.")
    .min(27, "Πληκτρολογήστε 27 χαρακτήρες χωρίς κενά, εκ των οποίων οι 2 πρώτοι είναι λατινικοί χαρακτήρες και οι επόμενοι 25 είναι ψηφία.")
    .max(27, "Πληκτρολογήστε 27 χαρακτήρες χωρίς κενά, εκ των οποίων οι 2 πρώτοι είναι λατινικοί χαρακτήρες και οι επόμενοι 25 είναι ψηφία."),
  weddingDate:
    yup.string().when('type', {
      is: type => type === true,
      then: yup
        .date()
        .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
        .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας"),
      else: yup.string().nullable(true),
    }),
  // expWeddingDate: yup.string().when('type', {
  //   is: type => type === true,
  //   then: yup
  //   .date()
  //   .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
  //   .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας"),
  //   else: yup.string().nullable(true),
  // }),
  childDateOfBirth: yup
    .date()
    .optional()
    .nullable()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας"),
  grade: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  position: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  institution: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  // guardType: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
});
