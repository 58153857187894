import { Box } from "@mui/system";
import CreateUpdateEvaluationGroup from "../../ui/CreateUpdate";
import AppBreadcrumbs from "../../../../components/Breadcrumbs";
import PageTitle from "../../../../components/PageTitle";

function UpdateEvaluationGroups() {
    return <Box mt={17}>
        <AppBreadcrumbs
            breadcrumbsList={[
                {
                    title: "Ομάδες Αξιολόγησης",
                    url: "/evaluation/groups",
                },
                {
                    title: 'Επεξεργασία Ομάδας Αξιολόγησης',
                },
            ]}
        />
        <PageTitle title="Επεξεργασία Ομάδας Αξιολόγησης" />


        <CreateUpdateEvaluationGroup mode="edit" />
    </Box>
}

export default UpdateEvaluationGroups;