import { Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useBackAuth";

const RegistrationTableItem = (props) => {
    const navigate = useNavigate()  
    const { hasRole,userInfo } = useAuth();
    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            {props.children}
           { ((hasRole('KATAXORITIS') && !props.id?.includes('evaluation')) || (props.id?.includes('evaluation') && props.userData?.registryEvaluationGroup?.evaluationGroup?.id ===
            userInfo?.supervisorEvaluationGroup?.evaluationGroup?.id) )&& <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                <Button onClick={()=>navigate(`${props.url}`)} >{props.buttonLabel}</Button>
            </Box>}
        </Box>
    )
}
export default RegistrationTableItem