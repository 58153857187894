import RegistrationWrapper from "../RegistrationWrapper";
import ServiceActionRegistrationForm from "./ServiceActionRegistrationForm";

const ServiceActionsRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Υπηρεσιακής Ενέργειας"}
      FormComponent={ServiceActionRegistrationForm}
    />
  );
};
export default ServiceActionsRegistration;
