import * as yup from "yup";

export const intangibleRewardFieldValidation = yup.object().shape({
    intangibleRewardDate: yup .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
    intangibleRewardType: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    order: yup.string().matches(/^Α.Π./,  "Το πεδίο πρεπει να ξεκινα με Α.Π.")
    .min(5, "Το πεδίο είναι υποχρεωτικό")    
    .required("Το πεδίο είναι υποχρεωτικό"),});
