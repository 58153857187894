import useAuth from "../../hooks/useBackAuth";
import ServiceChangeRegistrationForm from "../Registrations/ServiceChanges/ServiceChangeRegistrationForm";
import MultipleRegistriesWrapper from "./MultipleRegistriesWrapper";

const MultipleServiceChangeRegistries = () => {
  const { userInfo} = useAuth()

  return (
    <MultipleRegistriesWrapper
      title={" Υπηρεσιακής Μεταβολής"}
      department={userInfo?.serviceAccount?.institute?.description}
      FormComponent={ServiceChangeRegistrationForm}
    />
  );
};
export default MultipleServiceChangeRegistries;
