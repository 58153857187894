import * as yup from "yup";

const ypodeigmaAnaptValidSchema = (stage)=> yup.object().shape({
  referenceYear: yup
    .number()
    .required("Εισαγάγετε το Έτος Αναφοράς")
    .integer("Το Έτος Αναφοράς πρέπει να είναι ακέραιος αριθμός"),
    modelTargetType : yup.string().required("Εισαγάγετε το όνομα στόχου"),
    modelName: yup.string().required("Εισαγάγετε το όνομα υποδείγματος"),


    [`stage${stage}`]: 
      yup.object().shape({
        targetName: yup.string().required("Εισαγάγετε το τύπο στόχου")
      })
    ,
});

export default ypodeigmaAnaptValidSchema;
