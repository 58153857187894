import useAuth from "../../hooks/useBackAuth";
import ServiceActionRegistrationForm from "../Registrations/ServiceActions/ServiceActionRegistrationForm";
import MultipleRegistriesWrapper from "./MultipleRegistriesWrapper";

const MultipleServiceActionRegistries = () => {
  const { userInfo} = useAuth()

  return (
    <MultipleRegistriesWrapper
      title={"Υπηρεσιακής Ενέργειας"}
      department={userInfo?.serviceAccount?.institute?.description}
      FormComponent={ServiceActionRegistrationForm}
    />
  );
};
export default MultipleServiceActionRegistries;
