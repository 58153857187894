export const defaultEducationRegistrationValues = {
    typeOfEducation: "",
    educationTitle: "",
    grade: "",
    scale:"",
    durationInHours:"",
    notes: "",
  }

  export  const formEducationRegistrationSchema = {
    typeOfEducation: {
      name: "typeOfEducation",
      label: "Είδος Εκπαίδευσης",
    },
    educationTitle: {
      name: "educationTitle",
      label: "Τίτλος Σπουδών/ Σεμιναρίου",
    },
    grade: {
      name: "grade",
      label: "Βαθμός",
    },
    scale: {
      name: "scale",
      label: "Κλίμακα Βαθμολογίας",
    },
    startDate: {
      name: "startDate",
      label: "Ημερομηνία Από",
    },
    endDate: {
      name: "endDate",
      label: "Ημερομηνία Έως",
    },
    durationInHours: {
      name: "durationInHours",
      label: "Διάρκεια σε ώρες",
    },
    notes: {
      name: "notes",
      label: "Παρατηρήσεις",
    },
  };
  export const educationsColumns = [

    
    {
      id: "education.educationType.description",
      label: "Είδος Πιστοποιησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.educationTitle",
      label: "Τίτλος Πιστοποίησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.degreeGrade",
      label: "Βαθμός",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.gradingScale",
      label: "Κλίμακα",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.startDate",
      label: "Από",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.endDate",
      label: "Εώς",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.durationInHours",
      label: "Διάρκεια",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "education.notes",
      label: "Παρατηρήσεις",
      minWidth: 170,
      isClickable: false,
    },
  ];