export const prodConfig = {
    backendURL: 'https://mzrs.knowledge.gr/api',
    frontendURL: 'https://mzrs.knowledge.gr',
    keycloakURL: 'https://mzrsauth.knowledge.gr',
}


export const devConfig = {
    backendURL: 'http://localhost:8080/api',
    frontendURL: 'http://localhost:3000',
    keycloakURL: 'https://mzrsauth.knowledge.gr',
}

const config = process.env.NODE_ENV === 'development' ? devConfig : prodConfig;

export { config };