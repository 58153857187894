// import moment from "moment";

import { format } from "date-fns";

export const mapByRefValue = (objectWithRef_Values,
    refDetails) => {
    const _refDetails = refDetails.map((item) => {
        if (!item.refValue) return item;
        return {
            ...item,
            value: objectWithRef_Values[item?.refValue],
        };
    });
    return _refDetails;
};

export const defaultRenderObject = (object) => {
    return JSON.stringify(object);
}

export const defaultRenderArray = (array) => {
    return array.join(', ');
};

export const defaultRenderDates = (date) => {
    return format(date, 'dd/MM/yyyy');

};
