import axios, { AxiosRequestConfig } from 'axios';
import { config } from '../config';
// import UserService from './UserService';

// for adding the Auth token on every request automaticlaly

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
};

const _axios = axios.create({
  baseURL: config.backendURL,
  
});
export const AxiosInterceptorConfigure = () => {
  _axios.interceptors.request.use((config) => {
    
    // config.baseURL = `${process.env.REACT_APP_BACKEND_URL}`;
    // if (UserService.isAuthenticated()) {
    //   const cb = () => {
    //     if (config.headers) {

    //       config.headers.Authorization = `Bearer ${UserService.getToken()}`;
    //     }
    //     return Promise.resolve(config);
    //   };
    //   return UserService.updateToken(cb);
    // }
    return config;
  });
  // _axios.interceptors.response.use(async (config)=> {
  //   // if (config.status == 403){
  //   // await UserService.doLogin({maxAge: 1});
  //   // }
  //   return config;
  // }, (error)=> {
  //   // if (error.response.status === 401 && 'enabled' in error.response.data && !error.response.data.enabled) {
  //   //   navigate('/user-not-enabled');
  //   // }
  //   return Promise.reject(error);
  // });
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure: AxiosInterceptorConfigure,
  getAxiosClient,
};

export {_axios}
export default HttpService;