import { Box, Button, Divider, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer, useForm } from "react-hook-form-mui";
import {
  getInstitutionPositionDropdown,
  getSubmissionStage,
} from "../../../services/dropdownData.service";
import AppSelect from "../../../components/UI/AppSelect";
import AppInput from "../../../components/UI/AppInput";
import ResetFormButton from "../../../components/ResetButton";
import { years } from "../../../helperFunctions";

const AssessmentProceduresFilters = (props) => {
  const [positions, setPositions] = useState();
  const [submissionStage, setSubmissionStage] = useState();
 
  const formSchema = {
    name: { name: "name", label: "Όνομα Αξιολογούμενου" },
    surname: { name: "surname", label: "Επώνυμο Αξιολογούμενου" },
    afm: { name: "afm", label: "ΑΦΜ Αξιολογούμενου" },
    positionId: { name: "positionId", label: "Θέση Αξιολογούμενου" },
    submissionStage: { name: "submissionStage", label: "Στάδιο Αξιολόγησης" },
    referenceYear: { name: "referenceYear", label: "Έτος Αναφοράς" },
  };

  useEffect(() => {
    const fetchDropdowns = async () => {
      const dropdowns = await getInstitutionPositionDropdown();
      const submissionStages = await getSubmissionStage();
      const mappedSubmissionStages = submissionStages && submissionStages["submission_stage"]?.map(
        (sub) => ({
          id: sub.id,
          label: sub.description,
        })
      );
      const mappedPositions = dropdowns && dropdowns?.position?.map((pos) => ({
        id: pos.id,
        label: pos.description,
      }));
      setSubmissionStage(mappedSubmissionStages);
      setPositions(mappedPositions);
    };
    fetchDropdowns();
  }, [props.filters]);

  const { formContext, reset } = useForm({
    defaultValues: props.filters,
  });

  return (
    <Box sx={{ paddingY: 5 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <FormContainer
          formContext={formContext}
          onSuccess={async (data) => {
            props.setFilters(data);
            reset();
          }}
        >
          {" "}
          <h4>Φίλτρα Αναζήτησης</h4>
          <Divider />
          <Grid container spacing={2} sx={{ paddingY: 3 }}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ width: 350 }}>
                  <AppInput
                    isLabelBlack={true}
                    label={formSchema.name.label}
                    name={formSchema.name.name}
                    value={formSchema.name.name}
                  />
                </Box>

                <Box sx={{ width: 350 }}>
                  <AppInput
                    isLabelBlack={true}
                    label={formSchema.surname.label}
                    name={formSchema.surname.name}
                    value={formSchema.surname.name}
                  />
                </Box>

                <Box sx={{ width: 350 }}>
                  <AppInput
                    isLabelBlack={true}
                    label={formSchema.afm.label}
                    name={formSchema.afm.name}
                    value={formSchema.afm.name}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4}>
              {positions && <AppSelect
                name={formSchema.positionId.name}
                value={formSchema.positionId.name}
                label={formSchema.positionId.label}
                options={positions}
                isMultiple={true}
              />}
            </Grid>

            <Grid item xs={2}>
             {submissionStage && <AppSelect
                name={formSchema.submissionStage.name}
                value={formSchema.submissionStage.name}
                label={formSchema.submissionStage.label}
                options={submissionStage}
                isMultiple={true}
              />}
            </Grid>
            <Grid item xs={2}>
              {years && <AppSelect
                name={formSchema.referenceYear.name}
                value={formSchema.referenceYear.name}
                label={formSchema.referenceYear.label}
                options={years}
                isMultiple={true}
              />}
            </Grid>
          </Grid>
          <Box sx={{ gap: 2, display: "flex", justifyContent: "flex-end" }}>
            <ResetFormButton variant={"outlined"} label={"Καθαρισμός"} />
            <Button variant="contained" type="submit">
              Αναζήτηση
            </Button>
          </Box>
        </FormContainer>
      </Paper>
    </Box>
  );
};
export default AssessmentProceduresFilters;
