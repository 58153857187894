import RegistrationWrapper from "../RegistrationWrapper";
import ServicePositionRegistrationForm from './ServicePositionRegistrationForm';

const ServicePositionRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Νέας Θέσης"}
      FormComponent={ServicePositionRegistrationForm}
    />
  );
};
export default ServicePositionRegistration;
