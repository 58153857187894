import { formatDateFNS } from "../../Evaluation/Armodiothtes/shared/utils/formatDates";
import { formatDateTime } from "../Registrations/dataTransformFunctions";

export const evaluationDetailsTransformer = (formSc, data, labels) => {
    const values = [];
    Object.values(formSc).map((key) => {
      typeof  data[key.name] == "boolean" ? (data[key.name] ? values.push('Ναι') : values.push('Όχι') ) :
      values.push(formatDateTime(key.name, data));
    });
    return { labels: labels, values: values };
  };

  export const transformSuggToolInspDataToBEFormat=(data)=>{
    const { registry, description,objectiveType, proposalDate, isImplemented, notes, position, institution, evaluationGroupId } =
    data;
  const transformedData = {
    registry,
    isImplemented,
    proposalDate:  formatDateFNS(proposalDate) ,
    description,
    notes,
    position:{id:position},
    institution:{id:institution},
    evaluationGroup: {id: evaluationGroupId },
    objectiveType
    
  };
  console.log("transformedBEData", transformedData);
  return transformedData;
  }