import RegistrationWrapper from "../RegistrationWrapper";
import ServiceChangeRegistrationForm from "./ServiceChangeRegistrationForm";

const ServiceChangesRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Υπηρεσιακής Μεταβολής"}
      FormComponent={ServiceChangeRegistrationForm}
    />
  );
};

export default ServiceChangesRegistration;
