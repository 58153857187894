import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import Details from "../../../components/Details/Details";
import AppAccordion from "../../../components/UI/AppAccordion";
import FormDatePicker from "../../../components/UI/FormDatePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";

import { getIntangibleRewardDropdown } from "../../../services/dropdownData.service";
import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import { intangibleRewardFieldValidation } from "./intangibleRewardFieldValidation";
import {
  defaultIntangibleRewardRegistrationValues as defaultValues,
  formIntangibleRewardRegistrationSchema as formSchema,
} from "./intangibleRewardRegistrationConfig";
import { addNewIntangibleRewardRegistry } from "../../../services/Registrations/IntangibleRewards.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  registrationDetailsTransformer,
  transformIntangibleRewardDataToBEFormat,
} from "../dataTransformFunctions";
const IntangibleRewardRegistrationForm = ({ data }) => {
  const registry = {
    id: data.id,
    name: data.name,
    surname: data.surname,
    fathersName: data.fathersName,
    fathersSurname: data.fathersSurname,
    mothersName: data.mothersName,
    mothersSurname: data.mothersSurname,
    dateOfBirth: data.dateOfBirth,
    placeOfBirth: data.placeOfBirth,
    adt: data.adt,
    issuingAuthority: data.issuingAuthority,
    afm: data.afm,
    bloodType: data.bloodType,
    amka: data.amka,
    payrollAccountNumber: data.payrollAccountNumber,
    iban: data.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };
  const formContext = useForm({
    resolver: yupResolver(intangibleRewardFieldValidation),
    defaultValues: defaultValues,
  });
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [intangibleRewardTypes, setIntangibleRewardTypes] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [intangibleRewardOptions, setIntangibleRewardOptions] = useState([]);
  const employeeId = useParams().employeeId;

  const navigate = useNavigate();
  const onSave = () => {
    const data = transformIntangibleRewardDataToBEFormat(
      formData,
      intangibleRewardTypes
    );
    intangibleRewardTypes && addNewIntangibleRewardRegistry(data);
    setIsShownSaveModal(false);
    navigate(`/employee-info/${employeeId}`);
  };
  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const intangibleRewardOptions = await getIntangibleRewardDropdown();
      setIntangibleRewardTypes(intangibleRewardOptions);
      setIntangibleRewardOptions(
        intangibleRewardOptions["intouchable_fee_type"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    fetchBEConfigInfo();
  }, []);

  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (intangibleRewardData) => {
            setFormData({ ...intangibleRewardData, registry });
            const intangibleRewardTypeDescription =
              intangibleRewardOptions.find(
                (option) =>
                  option.id === +intangibleRewardData.intangibleRewardType
              )?.label;
            console.log(data);
            const transformedData = registrationDetailsTransformer(formSchema, {
              ...intangibleRewardData,
              intangibleRewardType: intangibleRewardTypeDescription,
              userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
              userPosition: data.serviceAccount.position.description,
            });
            setPreviewData(transformedData);
            console.log(intangibleRewardTypeDescription);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.intangibleRewardDate.label}>
              <FormDatePicker
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.intangibleRewardDate.name}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.intangibleRewardType.label}>
              {intangibleRewardOptions && (
                <SelectElement
                  fullWidth
                  label={formSchema.intangibleRewardType.label}
                  name={formSchema.intangibleRewardType.name}
                  options={intangibleRewardOptions}
                />
              )}
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.order.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.order.label}
                name={formSchema.order.name}
                variant="outlined"
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
          <h4>{`Άυλες Αμοιβές ${previewData.values[1]}`}</h4>{" "}
          {
            <Details
              renderFirstRowsAsHeader
              renderArraysAsColumns
              rows={[
                {
                  name: previewData.labels[0],
                  value: previewData.labels.slice(1, previewData.length),
                },
                {
                  name: previewData.values[0],
                  value: previewData.values.slice(1, previewData.length),
                },
              ]}
            />
          }
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default IntangibleRewardRegistrationForm;
