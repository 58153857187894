import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormContainer, useForm } from "react-hook-form-mui";
import { useLocation, useParams } from "react-router-dom";
import EmployeeCard from "../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import PageTitle from "../../components/PageTitle";
import usePostRequest from "../../hooks/useHttpCallHandler";
import { postArmodiotites } from "./data-access/gateway";
import { useGetEvaluationOfCompetnecies } from "./data-access/useGetEvaluationOfCompetnecies";
import { useGetFormContext } from "./data-access/useGetFormContext";
import { useGetRegistryEvaluationRegistryInfo } from "./data-access/useGetRegistryEvaluationRegistryInfo";
import GradeDisplay from "./shared/ui/Display";
import FormEvaluationPeriod from "./shared/ui/FormEvaluationPeriod";
import FromItem from "./shared/utils/PredefinedBackendForm/FromItem";
import validationSchema from "./shared/utils/PredefinedBackendForm/yupValidation";
import { parseDateFns } from "./shared/utils/formatDates";
import { amrmodiotitesMutateMapper } from "./shared/utils/postMappers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  isSubmittingFormHandler,
  failedSubmitHandler,
  successfullySubmittedHandler,
} from "../shared/utils";
import { router } from "../../App";
import GenericModal from "../shared/ui/modals/GenericModal";
import InfoBox from "../shared/ui/InfoBox";
import Loader from "../../components/UI/Loader";
import useAuth from "../../hooks/useBackAuth";
import { useGetInfoDetails } from "./data-access/useGetInfoDetails";

function ArxifilkasEvaluationPage({ responsibility_type }) {
  const location = useLocation();
  const actionOfForm = location?.state?.action;
  const { referenceUserInfo } = useAuth();

  const typesPageMapping = {
    responsibility_type_1:
      "Αξιολόγηση Αρμοδιοτήτων Αρχιφύλακα Εσωτερικής Φρούρησης",
    responsibility_type_2:
      "Αξιολόγηση Αρμοδιοτήτων Υπαρχιφύλακα Εσωτερικής Φρούρησης",
    responsibility_type_3:
      "Αξιολόγηση Αρμοδιοτήτων Φυλακα Εσωτερικής Φρούρησης",
    responsibility_type_4:
      "Αξιολόγηση Αρμοδιοτήτων Αρχιφύλακα Εξωτερικής Φρούρησης",
    responsibility_type_5:
      "Αξιολόγηση Αρμοδιοτήτων Υπαρχιφύλακα Εξωτερικής Φρούρησης",
    responsibility_type_6:
      "Αξιολόγηση Αρμοδιοτήτων Φρουρού Εξωτερικής Φρούρησης",
  };

  const { data: dataContext, isLoading } =
    useGetFormContext(responsibility_type);
  const { id } = useParams();
  const { data: caseInfo, isLoading: caseInfoIsLoading } =
    useGetEvaluationOfCompetnecies(id);
  const {
    handlePostRequest,
    responseData: postResp,
    isLoading: postIsLoading,
    setData: setDataPostResp,
  } = usePostRequest(postArmodiotites);
  const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } =
    useGetRegistryEvaluationRegistryInfo(
      caseInfo?.data?.documentInfo?.registry?.id
    );
    const { data: infoDetails, isLoading: infoDetailsIsLoading } =
    useGetInfoDetails();
  const contextTypeResponsibilities = dataContext?.data?.[responsibility_type];
  const x = validationSchema(contextTypeResponsibilities);
  const formContext = useForm({
    resolver: yupResolver(x),
  });

  const printPage = () => {
    window.print();
  };
  useEffect(() => {
    dataContext &&
      !isLoading &&
      actionOfForm === "print" &&
      setTimeout(printPage, 1500);
  }, []);

  useEffect(() => {
    if (caseInfoIsLoading) return;

    const nested = caseInfo?.data?.responsibilities.map((row) => {
      return {
        [`_${row?.responsibilityType?.id}`]: row?.isPositive ? "2" : "1",
      };
    });

    formContext.reset({
      referenceYear: new Date().getFullYear(),
      startDate: caseInfo?.data?.startDate
        ? parseDateFns(new Date(caseInfo?.data?.startDate))
        : undefined,
      endDate: caseInfo?.data?.endDate
        ? parseDateFns(new Date(caseInfo?.data?.endDate))
        : undefined,
      submissionDate: parseDateFns(new Date(caseInfo?.data?.submissionDate)),
      formProtocolNumber: caseInfo?.data?.formProtocolNumber,
      nested: Object.assign(...nested, {}),
    });
  }, [caseInfoIsLoading]);
const getInfoByCode =(code)=>{
  return infoDetails?.data?.find(item=>item?.code == code)?.information || ''
}
  const documentInfoRegistry = caseInfo?.data?.documentInfo?.registry;
  const documentType = caseInfo?.data?.documentInfo?.documentType;
  const onSubmit = async (data) => {
    try {
      isSubmittingFormHandler();
      const resp = await handlePostRequest(
        amrmodiotitesMutateMapper(
          data,
          registryInfoDetails,
          contextTypeResponsibilities,
          caseInfo
        ),
        id
      );
      setDataPostResp(resp);
      successfullySubmittedHandler(referenceUserInfo?.id);
    } catch (error) {
      console.log(error);
      failedSubmitHandler();
    }
  };

  if (registryInfoDetailsIsLoading || caseInfoIsLoading || isLoading)
    return <Loader />;
  return (
    <>
      <Box mt={17}>
        <AppBreadcrumbs
          breadcrumbsList={[
            { title: "Αξιολόγηση", url: "/" },
            {
              title: "Μητρώο Αξιολόγησης Προσωπικού",
              url: "/personnel-registry-evaluation",
            },
            {
              title: `Καρτέλα Αξιολογήσεων: ${referenceUserInfo?.name} ${referenceUserInfo?.surname} - ${referenceUserInfo?.afm}`,
              url: `/evaluation-card/${referenceUserInfo?.id}`,
            },
            {
              title: "Διενέργειες Αξιολογήσεων",
              url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
            },
            {
              title: `${documentType?.description || ""}:

                        ${documentInfoRegistry?.name || ""} ${
                documentInfoRegistry?.surname || ""
              } - ${documentInfoRegistry?.afm || ""}`,
            },
          ]}
        />

        <InfoBox
          content={`Α: Αρμοδιότητα που για την επιτυχή επίτευξη της αξίζει ανταμοιβή
Κ: Αρμοδιότητα που για την ανεπιτυχή επίτευξη της αξίζει κύρωση
Ο: Αρμοδιότητα που για την επιτυχή επίτευξη της δεν προβλέπεται ανταμοιβή/ Αρμοδιότητα που η ανεπιτυχής επίτευξη της δεν προβλέπεται κύρωση”`}
        />

        <PageTitle title={typesPageMapping[responsibility_type]} />

        <FormContainer formContext={formContext} onSuccess={onSubmit}>
          <Typography variant="h3"> Στοιχεία Αξιολογούμενου </Typography>
          <Box display="flex" justifyContent="center" my={2}>
            <EmployeeCard data={registryInfoDetails?.data}>
              <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
            </EmployeeCard>
          </Box>
          <fieldset disabled={actionOfForm === "preview"}>
            <FormEvaluationPeriod
              stage={caseInfo?.data?.documentInfo?.submissionStage?.code}
            />

            <Typography mt={3} variant="h3">
              Αρμοδιότητες
            </Typography>

            {contextTypeResponsibilities?.map((row, index) => {
              return (
                <Box key={row?.id} my={2}>
                  <FromItem
                    code={row?.code}
                    description={`${index + 1} ${row?.description}`}
                    name={`nested._${row?.id}`}
                    info={getInfoByCode(row?.code)}
                  />
                </Box>
              );
            })}

            <Box sx={{ marginY: 6 }}>
              <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
              {caseInfo?.data?.evaluationGroupSupervisor && (
                <Box display="flex" justifyContent="center" my={2}>
                  <EmployeeCard
                    data={caseInfo?.data?.evaluationGroupSupervisor}
                  >
                    <RegistrationEmployeeInfo
                      data={caseInfo?.data?.evaluationGroupSupervisor}
                    />
                  </EmployeeCard>
                </Box>
              )}
            </Box>

            {!postIsLoading && (
              <Box display="flex" justifyContent="center">
                <GradeDisplay
                  grade={postResp?.totalGrade || caseInfo?.data?.totalGrade}
                />
              </Box>
            )}
          </fieldset>

          {!postIsLoading && (
            <Box display="flex" justifyContent="flex-end"  marginY={2}>
              {<Button onClick={() => router.navigate(-1)}>Επιστροφή</Button>}
              {(actionOfForm === "edit" || !actionOfForm) && (
                <GenericModal
                  onAccept={formContext.handleSubmit(onSubmit)}
                  agreeButtonTitle="Οριστικοποίηση"
                  dialogContent="Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποίησετε το Έντυπο Αποτύπωσης Δεξιοτήτων; Μετά την οριστικοποίηση δεν θα έχετε δικαίωμα να επεξεργαστείτε τις βαθμολογίες των κατηγοριών δεξιοτήτων"
                >
                  <Button
                    type={!formContext.formState.isValid ? "submit" : "button"}
                    variant="contained"
                  >
                    <span>Οριστικοποίηση</span>
                  </Button>
                </GenericModal>
              )}
            </Box>
          )}
        </FormContainer>
      </Box>
    </>
  );
}

export default ArxifilkasEvaluationPage;
