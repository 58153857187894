import { Box, Button, Typography } from "@mui/material";
import AppModal from "../../components/UI/Modal/AppModal";

const ReportModal = ({
  setModalOpen,
  setConfirmModal,
  onConfirm,
  isManager,
  overallScore,
  achievementScore,
  skillsScore,
}) => {
  console.log(isManager,overallScore,
    achievementScore,
    skillsScore,)
  return (
    <>
      <AppModal onHideModal={() => setModalOpen(false)}>
        <Box>
          {isManager ? (
            <>
              <strong>Η Συνολική Βαθμολογία Αξιολογούμενου είναι:</strong>{" "}
              {overallScore|| 5}.
              <Typography sx={{mt:2}}> Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποιήσετε
              την Έκθεση Αξιολόγησης Προϊσταμένου; Μετά την οριστικοποίηση δεν
              θα έχετε δικαίωμα να επεξεργαστείτε τις βαθμολογίες των κατηγοριών
              δεξιοτήτων</Typography>
            </>
          ) : (
            <>
              <strong>Η Συνολική Βαθμολογία Επίτευξης Στόχων είναι:</strong>{" "}
              <Typography>{`${achievementScore || 5} και η `}</Typography>
              <strong>Συνολική Βαθμολογία Αποτύπωσης Δεξιοτήτων είναι:</strong>{" "}
              {skillsScore || 5}.
              <Typography sx={{mt:2}}>Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποιήσετε την Έκθεση
              Αξιολόγησης Υπαλλήλου; Μετά την οριστικοποίηση δεν θα έχετε
              δικαίωμα να επεξεργαστείτε τις βαθμολογίες των κατηγοριών
              δεξιοτήτων. </Typography>
            </>
          )}

          <Box
            sx={{
              marginY: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: 3,
            }}
          >
            <Button
              variant="outlined"
              type="button"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <span>'Οχι</span>
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => {
                onConfirm();
                setConfirmModal(true);
                setModalOpen(false);
              }}
            >
              <span>Ναι</span>
            </Button>
          </Box>
        </Box>
      </AppModal>
    </>
  );
};
export default ReportModal;
