import { Box, Button, Divider } from "@mui/material";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import Loader from "../../components/UI/Loader";
import AppTable from "../../components/UI/AppTable";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useBackAuth";

const RegistryHistoryWrapper = (props) => {
  const navigate = useNavigate();
  const { hasRole} = useAuth()

  return (
    <>
      <Box >
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: `Ιστορικό ${props.title}`,
              url: "/",
            },
          ]}
        />
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <h2>{`Ιστορικό ${props.title} ${props.department || ''}`}</h2>
        <Divider sx={{ bgcolor: "black.main" }} />
      </Box>

      {hasRole('KATAXORITIS') && <Box sx={{paddingBottom: 2, display:'flex', justifyContent:'flex-end'}}>
        <Button variant="contained" onClick={() => navigate(props.buttonURL)}>
          {props.buttonTitle}
        </Button>
      </Box>}
      {props.isLoading ? (
        <Loader />
      ) : (
        <AppTable
        noDataMsg={'Χωρίς δεδομένα.'}
          columns={props.columns}
          rows={props.rows}
          handleClick={props.onClickFunc}
          count={props.count}
          page={props.page}
          setPage={props.setPage}
          rowsPerPage={props.rowsPerPage}
          setRowsPerPage={props.setRowsPerPage}
        />
      )}
    </>
  );
};
export default RegistryHistoryWrapper;
