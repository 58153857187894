import AccountCircle from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import icon from "../assets/icon.jpg";
import { Typography } from "@mui/material";
import useAuth from "../hooks/useBackAuth";
import { router } from "../App";
export default function MenuAppBar() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { hasRole } = useAuth();

  const { userInfo } = useAuth();
  const handleMenu = (event) => {
    router.navigate(`/employee-info/${userInfo?.id}`);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getUserDetails = () => {
    if (hasRole("SuperAdmin")) {
      return 'Χρήστης Υπουργείου ΠΡΟ.ΠΟ'
    } else if (hasRole("KATAXORITIS")) {
      return 'Καταχωρητής'
    } else {
      return `${userInfo?.name} ${userInfo?.surname} / ${userInfo?.serviceAccount?.position?.description}`;
    }
  };
  return (
    <AppBar position="static" color="white">
      {auth && (
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Box
            component="img"
            sx={{
              height: 80,
              width: 280,
            }}
            src={icon}
          />
          {}
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">{getUserDetails()}</Typography>
            <IconButton size="large" onClick={handleMenu} color="inherit" disabled={hasRole("SuperAdmin") || hasRole("KATAXORITIS")}>
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
}
