import * as yup from 'yup';

const entipoValidationSchema = yup.object().shape({
  startDate: yup.date().nullable().required("Επιλέξτε ημερομηνία Έναρξης"),

  endDate: yup.date()
    .nullable()
    .required("Επιλέξτε ημερομηνία Λήξης")
    .min(yup.ref("startDate"), "Η Λήξη δεν μπορεί να είναι προγενέστερη από την Έναρξη"),

    type1: yup.array().of(yup.object().shape({
      targetType: yup.object().shape({
          id: yup.string().required("Επιλέξτε τον τύπο στόχου"),
      }),
      targetScore: yup.string(),
      notes: yup.string().test('targetType.id', "Απαιτείται αιτιολογία για τον επιλεγμένο τύπο στόχου 'Άλλο'", function (value) {
          return !(this.resolve(yup.ref('targetType.id')) === '7051' && (value === null || value === undefined || value.trim() === ''));
      }),
  })),
  type2: yup.array().of(yup.object().shape({
      targetType: yup.object().shape({
          id: yup.string().required("Επιλέξτε τον τύπο στόχου"),
      }),
      targetScore: yup.string(),
      notes: yup.string().test('targetType.id', "Απαιτείται αιτιολογία για τον επιλεγμένο τύπο στόχου 'Άλλο'", function (value) {
          return !(this.resolve(yup.ref('targetType.id')) === '7351' && (value === null || value === undefined || value.trim() === ''));
      }),
  })),
  type3: yup.array().of(yup.object().shape({
      targetType: yup.object().shape({
          id: yup.string().required("Επιλέξτε τον τύπο στόχου"),
      }),
      targetScore: yup.string(),
      notes: yup.string().test('targetType.id', "Απαιτείται αιτιολογία για τον επιλεγμένο τύπο στόχου 'Άλλο'", function (value) {
          return !(this.resolve(yup.ref('targetType.id')) === '13601' && (value === null || value === undefined || value.trim() === ''));
      }),
  })),
  
  // Add similar conditions for type2 and type3 if needed
});
const description = entipoValidationSchema.describe()
console.log(description)
export default entipoValidationSchema;
