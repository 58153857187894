import HttpService from "./httpClient";

export const addNewRegistry = async (registry) => {
  try {
    const response = await HttpService.getAxiosClient().post(`/registry`, { ...registry });
    const newRegistry = response.data;
    return newRegistry;
  } catch (error) {
    console.error(error);
    throw error; 
  }
};

export const updateOldRegistry = async (registry) => {
  try {
    const response = await HttpService.getAxiosClient().post(`/registry/update`, { ...registry });
    const updatedRegistry = response.data;
    return updatedRegistry;
  } catch (error) {
    console.error(error);
    throw error; 
  }
}