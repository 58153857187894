import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useAuth from "../../../hooks/useBackAuth";

export const EducationDetailsColumns=(setIsOpenDeleteModal,registriesLength, setIds) => [
  {
    field: "fullname",
    headerName: "Ονοματεπώνυμο",
    width: 150,
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography>{`${params.row.name} ${params.row.surname}`}</Typography>
    ),
  },
  {
    field: "afm",
    headerName: "ΑΦΜ",
    flex: 1,
  },
  {
    field: "position",
    headerName: "Θέση",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.serviceAccount?.position?.description || ""}
      </Typography>
    ),
  },
  {
    field: "educationType",
    headerName: "Είδος Πιστοποιησης",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.educationType || ""}
      </Typography>
    ),
  },
  {
    field: "educationTitle",
    headerName: "Τίτλος Πιστοποιησης",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.educationTitle || ""}
      </Typography>
    ),
  },
  {
    field: "degreeGrade",
    headerName: "Βαθμός",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.degreeGrade || ""}
      </Typography>
    ),
  },
  {
    field: "gradingScale",
    headerName: "Κλίμακα",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.gradingScale || ""}
      </Typography>
    ),
  },
  {
    field: "durationInHours",
    headerName: "Διάρκεια",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.durationInHours || ""}
      </Typography>
    ),
  },
  {
    field: "notes",
    headerName: "Παρατηρήσεις",
    flex: 1,
  },
  {
    field: "action",
    headerName: "Ενέργεια",
    width: 70,
    renderCell: (params) => (
      <Button disabled={registriesLength<2 && !params.row.isKataxoritis }  onClick={()=>{setIsOpenDeleteModal(true); setIds({employeeId:params.row.id,educationId:params.row.educationId})} }>    <DeleteIcon /> 
      </Button>
    )

  },
];

export const EducationColumns = [
  {
    id: "endDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: true,
  },
  {
    id: "educationType",
    label: "Είδος Πιστοποιησης",
    minWidth: 170,
    isClickable: false,
  },
];