import { initialFormSchema } from "../AddNewOfficer/fieldConfiguration"
import CardItem from "../EmployeeRegistry/CardItem"

const RegistrationEmployeeInfo = (props) => {
    const fieldNames = ['name', 'surname', 'afm', 'institute', 'grade', 'position']
    return(
        fieldNames.map(fieldName=>(
            
            <CardItem key={fieldName}
            name={initialFormSchema?.[fieldName] ? initialFormSchema?.[fieldName]?.name : props?.data?.serviceAccount?.[fieldName]?.id}
            label={initialFormSchema?.[fieldName] && fieldName !== 'institute' ? initialFormSchema[fieldName].label.toString().replace('*', ':') : initialFormSchema?.institution?.label?.toString()?.replace('*', ':')}
            value={props?.data?.[fieldName] ? props?.data?.[fieldName] : props?.data?.serviceAccount?.[fieldName]?.description }
          />
        ))
    )
}
export default RegistrationEmployeeInfo