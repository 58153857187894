import { Box, Grid, InputLabel } from "@mui/material";
import { SelectElement } from "react-hook-form-mui";

const FormSelectField = (props) => {
  const { name, label, options, isPreviewMode } = props;
  return (
    <Grid container>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <InputLabel
          sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
          id={name}
        >
          {label}{" "}
        </InputLabel>

        <SelectElement
          fullWidth
          inputProps={{
            readOnly: props.isPreviewMode,
          }}
          SelectProps={{
            sx: { color: props.isPreviewMode ? "grey" : "inherit" },
          }}
          labelProps={{
            sx: {
              pointerEvents: props.isPreviewMode ? "none !important" : "auto",
            },
          }}
          id={name}
          key={name}
          name={name}
          options={options}
        />
      </Box>
    </Grid>
  );
};
export default FormSelectField;
