import React from 'react';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function AppBreadcrumbs(props) {
  const numOfBreadcrumbs = props.breadcrumbsList.length;

  return (
    <Breadcrumbs size="lg" aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
      <Link component={RouterLink} to="/" underline="hover" sx={{ display: "flex", alignItems: "center", color: "black" }}>
        <HomeIcon fontSize="large" color="black" />
      </Link>
      {props.breadcrumbsList.map((item, index) => {
        return index < numOfBreadcrumbs - 1 ? (
          <div key={item.url}>
            <Link
              component={RouterLink}
              to={item.url}
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "primary.main",
                '&:focus': {
                  textDecoration: 'none', 
                },
              }}
            >
              <Typography sx={{ color: "primary.main", fontWeight: "bold" }}>
                {item.title}
              </Typography>
            </Link>
          </div>
        ) : (
          <Typography key={item.url} sx={{ color: "black.main", fontWeight: "bold" }}>
            {item.title}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}
