import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import { getFilteredRegistries } from "../../services/PersonnelManagement.service";
import RowSelectionTables from "./RowSelectionTables";
import Loader from "../../components/UI/Loader";
import useAuth from "../../hooks/useBackAuth";
import UserService from "../../services/userService";
const MultipleRegistriesWrapper = ({ FormComponent, title, department }) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [guardType, setGuardType] = useState();
  const { userInfo, hasRole } = useAuth();
  const [selectedPeople, setSelectedPeople] = useState([]);

  const columns = [
    {
      field: "fullname",
      headerName: "Ονοματεπώνυμο",
      width: 150,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Typography>{`${params.row.name} ${params.row.surname}`}</Typography>
      ),
    },
    {
      field: "afm",
      headerName: "ΑΦΜ",
      width: 150,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "grade",
      headerName: "Βαθμός",
      width: 150,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Typography>
          {params.row.serviceAccount?.grade?.description || ""}
        </Typography>
      ),
    },
    {
      field: "serviceAccount.position.description",
      headerName: "Θέση",
      width: 150,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Typography>
          {params.row.serviceAccount?.position?.description || ""}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    console.log(UserService.profile()?.attributes?.instituteId?.[0], "rows");

    const fetchAndSetRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getFilteredRegistries({
        page: paginationModel.page,
        size: paginationModel.pageSize,
        name: null,
        surname: null,
        afm: null,
        guardType: guardType && guardType,
        evaluationGroupId: hasRole("supervisor")
          ? userInfo.supervisorEvaluationGroup.evaluationGroup.id
          : null,
        institutionIds:
          userInfo?.serviceAccount?.institute?.id ||
          UserService.profile()?.attributes?.instituteId?.[0],
      });
      setData(allRegistries.data);
      setCount(allRegistries.count);
      setIsLoading(false);
    };

    fetchAndSetRegistries();
  }, [paginationModel, guardType]);
  return (
    <>
      <Box>
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: `Καταχώριση ${title}`,
              url: "/",
            },
          ]}
        />
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <h2>
          Καταχώριση {title} {department}
        </h2>
        <Divider sx={{ bgcolor: "black.main" }} />
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ display: "", flexDirection: "column" }}>
          <RowSelectionTables
            setPaginationModel={setPaginationModel}
            data={data}
            title={title}
            count={count}
            paginationModel={paginationModel}
            guardType={guardType}
            setGuardType={setGuardType}
            columns={columns}
            rows={data}
            isLoading={isLoading}
            setSelectedPeople={setSelectedPeople}
            selectedPeople={selectedPeople}
          />
          <FormComponent
            data={data}
            isMultipleRegistration={true}
            guardType={guardType}
            selectedPeople={selectedPeople}
          />
        </Box>
      )}
    </>
  );
};
export default MultipleRegistriesWrapper;
