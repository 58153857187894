import { Typography } from "@mui/material";
import EmployeeCard from "../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Pages/Registrations/RegistrationEmployeeInfo";
import Details from "../Details/Details";
import { convertDate } from "../../helperFunctions";

function GenericApplicationPreviewPage({ location, children }) {
  const supervisor = location?.state?.data.superVisor.details;
  console.log(location.state.data);
  return (
    <>
      <Typography variant="h3">
        {location?.state?.data.isInternalGuard == "1"
          ? "Εσωτερική Φρούρηση"
          : "Εξωτερική Φρούρηση"}
      </Typography>
      <Typography variant="h3">
        {`Ομάδα Αξιολόγησης: «${location.state.data.groupName}»`}
      </Typography>
      <Typography variant="h3">{`Ημερομηνία Ορισμού Ομάδας: ${convertDate(location.state.data.creationDate)}`}</Typography>

      <Typography>Στοιχεία Προϊστάμενου - Αξιολογητής</Typography>
      <EmployeeCard data={supervisor}>
        <RegistrationEmployeeInfo data={supervisor} />
      </EmployeeCard>
      {children}
    </>
  );
}

export default GenericApplicationPreviewPage;
