import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { serviceChangeDetailsColumns } from "./ServiceChangeColumnsConfig";
import RegistryDetailedHistoryWrapper from "../RegistryDetailedHistoryWrapper";
import {
  deleteServiceChangeHistory,
  getServiceChangeHistory,
} from "../../../services/history.service";
import DeleteModal from "../DeleteModal";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const ServiceChangeDetailedHistory = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const date = useParams().date;
  const { userInfo, hasRole } = useAuth()
  const serviceChangeId = useParams().id;
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [registriesLength, setRegistriesLength] = useState();
  const [ids, setIds] = useState();
  const [guardType, setGuardType] = useState();

  const onDelete = () => {
    deleteServiceChangeHistory(ids.serviceChangeId, ids.employeeId);
    setIsUpdated(true);
  };
  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getServiceChangeHistory(
        0,
        10,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id,
        date,
        guardType && guardType
      );
      allRegistries.data.length > 0
        ? setServiceChangeData(allRegistries.data)
        : setData([]);
      setIsLoading(false);
      setIsUpdated(false);
    };

    fetchAndSetHistoryRegistries();
  }, [date, guardType, isUpdated, registriesLength, serviceChangeId]);
  const setServiceChangeData = (data) => {
    const serviceChange = data.find(
      (serviceChange) => serviceChange.id === +serviceChangeId
    );
    serviceChange ? setData(
      serviceChange.registries.map((employee) => ({
        ...employee,
        id: employee.id,
        serviceChangeId: serviceChange.id,
        typeOfService: serviceChange.typeOfService.description,
        orderNumber: serviceChange.orderNumber,
        notes: serviceChange.notes,
      }))
    ) : setData([])
    serviceChange && setRegistriesLength(serviceChange.registries.length);
  };
  return (
    <>
      <RegistryDetailedHistoryWrapper
        breadcrumbs={[
          {
            title: "Υπηρεσιακών Μεταβολών",
            url: "/history/service-changes",
          },
          {
            title: "Υπηρεσιακής Μεταβολής",
            url: "",
          },
        ]}
        title={"Υπηρεσιακές Μεταβολές"}
        department={userInfo?.serviceAccount?.institute?.description}
        date={date}
        columns={serviceChangeDetailsColumns(
          setIsOpenDeleteModal,
          registriesLength,
          setIds,
          hasRole('KATAXORITIS')
        )}
        isLoading={isLoading}
        rows={data}
        guardType={guardType}
        setGuardType={setGuardType}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          setIsShownModal={setIsOpenDeleteModal}
          onDelete={onDelete}
        />
      )}
    </>
  );
};
export default ServiceChangeDetailedHistory;
