import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useLocation, useParams } from "react-router-dom";
import { useGetModelTargetCaseInfo } from "../../date-access/useGetModelTargetCaseInfo";
import usePostRequest from "../../../../hooks/useHttpCallHandler";
import { postCaseInfoEntipoYpodigmatosStoxothesias } from "../../../EntipoYpodigmatosStoxothesias/data-access/gateway";
import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import AppBreadcrumbs from "../../../../components/Breadcrumbs";
import PageTitle from "../../../../components/PageTitle";
import EmployeeCard from "../../../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../../../Pages/Registrations/RegistrationEmployeeInfo";
import HorizontalVerticalTogglerLayout from "../../../../components/Layout/HorizontalVerticalTogglerLayout";
import { useGetRegistryEvaluationRegistryInfo } from "../../../Armodiothtes/data-access/useGetRegistryEvaluationRegistryInfo";
import {
  failedSubmitHandler,
  isSubmittingFormHandler,
  successfullySubmittedHandler,
} from "../../../shared/utils";
import useAuth from "../../../../hooks/useBackAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import ypodeigmaAnaptValidSchema from "../validationSChema";
import { router } from "../../../../App";
import { RenderStageYpodeigmatos } from "../../utils/renderStage";

function SharedModelTarget({ person }) {
  const { id } = useParams();
  const { data: caseInfo, isLoading: caseInfoIsLoading } =
    useGetModelTargetCaseInfo(id);
  const documentInfoRegistry = caseInfo?.data?.documentInfo?.registry;
  const documenType = caseInfo?.data?.documentInfo?.documentType;
  const { referenceUserInfo } = useAuth();

  const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } =
    useGetRegistryEvaluationRegistryInfo(
      caseInfo?.data?.documentInfo?.registry?.id
    );
  const { handlePostRequest } = usePostRequest(
    postCaseInfoEntipoYpodigmatosStoxothesias
  );

  const location = useLocation();
  const actionOfForm = location?.state?.action;
  const stageNumber =
    caseInfo?.data?.documentInfo?.submissionStage?.code?.split(":")[1];

  useEffect(() => {
    registryInfoDetails &&
      actionOfForm === "print" &&
      setTimeout(window.print(), 1500);
  }, []);
  const formContext = useForm({
    resolver: yupResolver(ypodeigmaAnaptValidSchema(stageNumber)),
  });
  useEffect(() => {
    if (caseInfoIsLoading) return;
    console.log(caseInfo?.data);
    formContext.reset({
      modelName: caseInfo?.data?.modelName,
      stage1: {
        ...caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9701
        ),
      },
      stage2: {
        ...caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9751
        ),
      },
      stage3: {
        ...caseInfo?.data?.modelTargetingFormDetails?.find(
          (row) => row?.modelType?.id == 9801
        ),
      },
      modelTargetType: String(
        caseInfo?.data?.modelTargetingFormDetails?.modelTargetType?.code?.split(
          "model_target_type:"
        )?.[1]
      ),
      referenceYear: caseInfo?.data?.referenceYear,
      startDate: caseInfo?.data?.startDate,
      endDate: caseInfo?.data?.endDate,
      submissionDate: caseInfo?.data?.submissionDate,
      formProtocolNumber: caseInfo?.data?.formProtocolNumber,
    });
  }, [caseInfoIsLoading]);

  if (registryInfoDetailsIsLoading || caseInfoIsLoading) return <></>;
  return (
    <Box mt={17}>
      <AppBreadcrumbs
        breadcrumbsList={[
          {
            title: "Μητρώο Αξιολόγησης Προσωπικού",
            url: "/personnel-registry-evaluation",
          },
          {
            title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
              referenceUserInfo?.name
            } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
            url: `/evaluation-card/${referenceUserInfo?.id}`,
          },
          {
            title: "Διενέργειες Αξιολογήσεων",
            url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
          },
          {
            title: `${documenType?.description || ""}:

                    ${documentInfoRegistry?.name || ""} ${
              documentInfoRegistry?.surname || ""
            } - ${documentInfoRegistry?.afm || ""}`,
            url: "/evaluation/groups/create",
          },
        ]}
      />

      {person === "manager" ? (
        <PageTitle title="Έντυπο Υποδείγματος Ανάπτυξης και Αποτύπωσης Δεξιότητας Προϊσταμένου" />
      ) : (
        <PageTitle title="Έντυπο Υποδείγματος Ανάπτυξης και Αποτύπωσης Δεξιότητας Υπαλλήλου" />
      )}

      <Typography variant="h3"> Στοιχεία Αξιολογούμενου </Typography>
      <Box display="flex" justifyContent="center" my={2}>
        <EmployeeCard data={registryInfoDetails?.data}>
          <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
        </EmployeeCard>
      </Box>

      <FormContainer
        onSuccess={async (data) => {
          const modelTargetingFormDetails = [
            {
              id: caseInfo?.data?.modelTargetingFormDetails?.find(
                (row) => row?.modelType?.id == 9701
              )?.id,
              modelType: {
                id: 9701,
              },
              ...data.stage1,
            },
            ...(stageNumber >= 2
              ? [
                  {
                    id: caseInfo?.data?.modelTargetingFormDetails?.find(
                      (row) => row?.modelType?.id == 9751
                    )?.id,
                    modelType: {
                      id: 9751,
                    },
                    modelTargetType: {
                      id: data.modelTargetType,
                    },
                    ...data.stage2,
                  },
                ]
              : []),
            ...(stageNumber >= 3
              ? [
                  {
                    id: caseInfo?.data?.modelTargetingFormDetails?.find(
                      (row) => row?.modelType?.id == 9801
                    )?.id,
                    modelType: {
                      id: 9801,
                    },
                    ...data.stage3,
                  },
                ]
              : []),
          ];
          const body = {
            referenceYear: data.referenceYear,
            modelName: data.modelName,

            id: caseInfo?.data?.id,
            documentInfo: caseInfo?.data?.documentInfo,
            modelTargetingFormDetails: modelTargetingFormDetails,
          };
          try {
            isSubmittingFormHandler();
            await handlePostRequest(body);
            successfullySubmittedHandler(referenceUserInfo?.id);
          } catch (error) {
            failedSubmitHandler();
          }
        }}
        formContext={formContext}
      >
        <HorizontalVerticalTogglerLayout>
          <TextFieldElement
            label="Έτος Αναφοράς"
            name="referenceYear"
            inputProps={{
              readOnly: true,
            }}
            sx={{
              input: { color: "grey" },
            }}
          />

          <TextFieldElement label="Όνομα υποδείγματος" name="modelName" />
        </HorizontalVerticalTogglerLayout>
        <fieldset disabled={actionOfForm === "preview"}>
          <RenderStageYpodeigmatos isManager={person === "manager"} stage={"stage1"} />
          {stageNumber >= 2 && <RenderStageYpodeigmatos stage={"stage2"} />}
          {stageNumber >= 3 && <RenderStageYpodeigmatos stage={"stage3"} />}
        </fieldset>

        <Box sx={{ marginY: 6 }}>
          <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
          {caseInfo?.data && (
            <Box display="flex" justifyContent="center" my={2}>
              <EmployeeCard data={caseInfo?.data?.evaluationGroupSupervisor}>
                <RegistrationEmployeeInfo
                  data={caseInfo?.data?.evaluationGroupSupervisor}
                />
              </EmployeeCard>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button type="button" onClick={() => router.navigate(-1)}>
            Επιστροφή
          </Button>
          <Button variant="contained" type="submit">
            Οριστικοποίηση
          </Button>
        </Box>
      </FormContainer>
    </Box>
  );
}

export default SharedModelTarget;
