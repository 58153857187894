export const assessmentProceduresColumns = [
  {
    id: "referenceYear",
    label: "Έτος Αναφοράς",
    minWidth: 170,
    isClickable: false,
  },
      {
      id: "documentType.description",
      label: "Έντυπο Αξιολόγησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "submissionStage.description",
      label: "Στάδιο Αξιολόγησης",
      minWidth: 170,
      isClickable: false,
  
    },
    {
      id: "fullName",
      label: "Ονοματεπώνυμο Αξιολογούμενου",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "registry.afm",
      label: "ΑΦΜ Αξιολογούμενου",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "position.description",
      label: "Θέση Αξιολογούμενου",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "institution.description",
      label: "Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "action",
      label: "Ενέργεια",
      minWidth: 170,
      isClickable: false,
    },
  ];
  