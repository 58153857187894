export const defaultServiceChangeRegistrationValues = {
    serviceChangeType: "",
    institution: "",
    order: "Α.Π.",
    serviceChangeDate: "",
    notes: "",
  }

  export  const formServiceChangeRegistrationSchema = {
    serviceChangeDate: {
      name: "serviceChangeDate",
      label: "Ημερομηνία",
    },
    serviceChangeType: {
      name: "serviceChangeType",
      label: "Επιλογή Υπηρεσιακής Μεταβολής",
    },
    institution: {
      name: "institution",
      label: "Προς Κατάστημα Κράτησης",
    },
    order: {
      name: "order",
      label: "Α.Π. Διαταγής",
    },
    notes: {
      name: "notes",
      label: "Παρατηρήσεις",
    },
  };
  export const serviceChangesColumns = [
    {
      id: "serviceModification.serviceDate",
      label: "Ημερομηνία",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceModification.typeOfService.description",
      label: "Υπηρεσιακή Μεταβολή",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceModification.institution.description",
      label: "Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceModification.orderNumber",
      label: "Α .Π Διαταγής",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceModification.notes",
      label: "Παρατηρήσεις",
      minWidth: 170,
      isClickable: false,
    },
  ];
  