import HttpService from "../../../services/httpClient";

export const getTargetAchievments = (id) => HttpService.getAxiosClient().get(`coded-type/achievement-type/code`)

export const getTargetTypes = (targetTypeNumber) => HttpService.getAxiosClient().get(`coded-type/codes/target_type:${targetTypeNumber}`)

export const getTargetAchievmentCaseInfo = (id) => HttpService.getAxiosClient().get(`target-achievement-form/document-info/${id}`)

export const getTargetAchievmentCaseInfoDetails = (id) => HttpService.getAxiosClient().get(`model-targeting-form/document-info/${id}`)

export const postTargetAchievmentCaseInfo = (data) => HttpService.getAxiosClient().post(`target-achievement-form/document/info`, data)


export const servicesPorivededRegistry = (registryId) => HttpService.getAxiosClient().get(`achievement-details/services-provided/registry/${registryId}`)

export const getAchievementDetailSpecificInfo = (skillType, registryId, isSupervisor) => { return !skillType ? { data: {} } : HttpService.getAxiosClient().get(`achievement-details/achievement-type/${skillType}/registry/${registryId}/isSupervisor/${isSupervisor}`) }