export const suggestionsColumns = [
  {
    id: "proposalDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "description",
    label: "Περιγραφή Πρότασης",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "isImplemented",
    label: "Υλοποιήθηκε ή Διακρίθηκε",
    minWidth: 170,
    isClickable: false,

  },
  {
    id: "notes",
    label: "Παρατηρήσεις για την εξέλιξη της πρότασης",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "evaluationGroupSupervisor.name",
    label: "Ονομα Αξιολογητή",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "evaluationGroupSupervisor.surname",
    label: "Επώνυμο Αξιολογητή",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "evaluationGroupSupervisor.afm",
    label: "ΑΦΜ  Αξιολογητή",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "action",
    label: "Ενέργεια",
    minWidth: 170,
    isClickable: false,
  },
];
export const improvingToolsColumns = [
    {
        id: "proposalDate",
        label: "Ημερομηνία",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "description",
        label: "Περιγραφή Εργαλείου",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "isImplemented",
        label: "Αναπτύχθηκε ή Χρησιμοποιήθηκε",
        minWidth: 170,
        isClickable: false,
        render: (value) => (value ? "Yes" : "No"), // Custom render function
    
      },
      {
        id: "notes",
        label: "Παρατηρήσεις για την ανάπτυξη και χρήση του εργαλείου",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "evaluationGroupSupervisor.name",
        label: "Ονομα Αξιολογητή",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "evaluationGroupSupervisor.surname",
        label: "Επώνυμο Αξιολογητή",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "evaluationGroupSupervisor.afm",
        label: "ΑΦΜ  Αξιολογητή",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "action",
        label: "Ενέργεια",
        minWidth: 170,
        isClickable: false,
      },
    ];
export const inspectionsColumns = [
    {
        id: "proposalDate",
        label: "Ημερομηνία",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "description",
        label: "Περιγραφή Επιθεώρησης",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "isImplemented",
        label: "Χωρίς σημαντικές παρατηρήσεις",
        minWidth: 170,
        isClickable: false,
        render: (value) => (value ? "Yes" : "No"), // Custom render function
    
      },
      {
        id: "notes",
        label: "Παρατηρήσεις για την εξέλιξη της Επιθεώρησης",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "evaluationGroupSupervisor.name",
        label: "Ονομα Αξιολογητή",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "evaluationGroupSupervisor.surname",
        label: "Επώνυμο Αξιολογητή",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "evaluationGroupSupervisor.afm",
        label: "ΑΦΜ  Αξιολογητή",
        minWidth: 170,
        isClickable: false,
      },
      {
        id: "action",
        label: "Ενέργεια",
        minWidth: 170,
        isClickable: false,
      },
    ];
