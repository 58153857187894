export const defaultSwitchRegistrationValues = {
  switchDate: "",
  switchType: "",
  notes: "",
}

export  const formSwitchRegistrationSchema = {
  
  switchDate: {
    name: "switchDate",
    label: "Ημερομηνία",
  },
  switchType: {
    name: "switchType",
    label: "Επιλογή Μεταγωγής",
  },
  notes: {
    name: "notes",
    label: "Παρατηρήσεις",
  },
};
export const switchesColumns = [
  {
    id: "transfer.transferDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: false,
  },
{
    id: "transfer.transferType.description",
    label: "Μεταγωγή",
    minWidth: 170,
    isClickable: false,
  },
  {
    id: "transfer.notes",
    label: "Παρατηρήσεις",
    minWidth: 170,
    isClickable: false,
  },
];