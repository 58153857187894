import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

const InfoBox = ({ title, content }) => {
    return (
        <Box mb={10} display="flex" justifyContent="center" alignItems="center">
            <IconButton color="primary" size="large">
                <InfoIcon fontSize="inherit" />
            </IconButton>
            <Paper elevation={3} style={{ padding: '16px', width: '70%' }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                </Box>
                <Typography fontStyle='italic' variant="subtitle2">{content}</Typography>

            </Paper>
        </Box>
    );
};

export default InfoBox;
