import RegistrationWrapper from "../RegistrationWrapper";
import EducationLevelRegistrationForm from './EducationLevelRegistrationForm';

const EducationLevelRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Εκπαιδευτικού Επιπέδου"}
      FormComponent={EducationLevelRegistrationForm}
    />
  );
};
export default EducationLevelRegistration;
