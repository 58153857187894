import * as yup from "yup";

export const switchesFieldValidation = yup.object().shape({
    switchDate: yup .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
    switchType: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    
});
