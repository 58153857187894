import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useNavigate, useParams } from "react-router-dom";
import "../../../App.css";
import Details from "../../../components/Details/Details";
import FormDatePicker from "../../../components/UI/FormDatePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";
import {
  addNewServiceChangeRegistries,
  addNewServiceChangeRegistry,
} from "../../../services/Registrations/ServiceChanges.service";

import { NoRowsOverlay } from "../../../helperFunctions";
import {
  getGradeInstitutionPositionDropdown,
  getServiceChangeDropdown,
} from "../../../services/dropdownData.service";
import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import {
  registrationDetailsTransformer,
  transformServiceChangeDataToBEFormat,
} from "../dataTransformFunctions";
import {
  transformServiceChangeMultipleDataToBEFormat,
  transformedMultipleRegistryData,
} from "./../../MultipleRegistrations/multipleDataTransformFunctions";
import { serviceChangeFieldValidation } from "./serviceChangeFieldValidation";
import {
  defaultServiceChangeRegistrationValues as defaultValues,
  formServiceChangeRegistrationSchema as formSchema,
} from "./serviceChangeRegistrationConfig";

const ServiceChangeRegistrationForm = ({
  data,
  isMultipleRegistration,
  selectedPeople,
  guardType,
  setModalContent,
  setModalOpen
}) => {
  const employeeId = useParams().employeeId;
  const navigate = useNavigate();
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [SCDropdowns, setSCDropdowns] = useState();
  const [institutions, setInstitutions] = useState();
  const [serviceChangeTypes, setServiceChangeTypes] = useState();
  const [institutionsTypes, setInstitutionsTypes] = useState();
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [multipleRegistriesData, setMultipleRegistriesData] = useState();
  const [configObjects, setConfigObjects] = useState();
  const [date, setDate] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });

  const registry = {
    id: data?.id,
    name: data?.name,
    surname: data?.surname,
    fathersName: data?.fathersName,
    fathersSurname: data?.fathersSurname,
    mothersName: data?.mothersName,
    mothersSurname: data?.mothersSurname,
    dateOfBirth: data?.dateOfBirth,
    placeOfBirth: data?.placeOfBirth,
    adt: data?.adt,
    issuingAuthority: data?.issuingAuthority,
    afm: data?.afm,
    bloodType: data?.bloodType,
    amka: data?.amka,
    payrollAccountNumber: data?.payrollAccountNumber,
    iban: data?.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };

  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const SCOptions = await getServiceChangeDropdown();
      const configObj = await getGradeInstitutionPositionDropdown();
      setConfigObjects(configObj);
      setServiceChangeTypes(SCOptions);
      setInstitutionsTypes(configObj.institution);
      setSCDropdowns(
        SCOptions["type_of_service"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );

      setInstitutions(
        configObj.institution.map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    fetchBEConfigInfo();
  }, []);
  const formContext = useForm({
    resolver: yupResolver(serviceChangeFieldValidation),
    defaultValues: defaultValues,
  });

  const addNewRegistryModification= async (data) => 
  {
    console.log('addNewRegistryModification')
    try {
      await addNewServiceChangeRegistry(data);
      navigate(`/employee-info/${employeeId}`);

    } catch (error) {
      switch (error.response.data.message) {
        case "There is already admin in new institution":
          setModalContent(
            "Δεν μπορεί να γίνει η καταχώριση της νέας υπηρεσιακής μεταβολής, γιατί το κατάστημα κράτησης υποδοχής διαθέτει ήδη Διευθυντή. Για να γίνει εφικτή η καταχώριση, πρέπει η θέση Διευθυντή του καταστήματος κράτησης υποδοχής να είναι κενή."
          );
          break;
        case "registry is already supervisor in an active group":
          setModalContent(
            "Δεν μπορεί να γίνει η καταχώριση της νέας υπηρεσιακής μεταβολής. Για να γίνει εφικτή η καταχώριση, πρέπει πρώτα να απενεργοποιηθεί η ομάδα αξιολόγησης στην οποία προϊσταται το συγκεριμένο μέλος του προσωπικού."
          );
          break;
        case "registry is already employee in an active group":
          setModalContent(
            "Δεν μπορεί να γίνει η καταχώριση της νέας υπηρεσιακής μεταβολής. Για να γίνει εφικτή η καταχώριση, πρέπει πρώτα να διαγραφεί το συγκεκριμένο μέλος του προσωπικού από την ομάδα αξιολόγησης στην οποία ανήκει."
          );
          break;

        default:
          console.log(error.response.data.message,'error');
      }
      setModalOpen(true);
    }
  }

  const onSave = () => {

    const data = isMultipleRegistration
      ? transformServiceChangeMultipleDataToBEFormat(
          multipleRegistriesData?.rows[0],
          {
            ...configObjects,
            serviceChangeTypes,
          },
          selectedPeople,
          guardType
        )
      : transformServiceChangeDataToBEFormat(formData, {
          serviceChangeTypes,
          institutionsTypes,
        });
    serviceChangeTypes && institutionsTypes && isMultipleRegistration
      ? addNewServiceChangeRegistries(data)
      : addNewRegistryModification(data);
    setIsShownSaveModal(false);
    isMultipleRegistration && navigate("/personnel-management")
  };
  const transformedRegistryData = (
    serviceChangeData,
    typeServiceChangeDescription,
    institutionDescription
  ) => {
    return registrationDetailsTransformer(formSchema, {
      ...serviceChangeData,
      serviceChangeType: typeServiceChangeDescription,
      institution: institutionDescription,
      userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
      userPosition: data.serviceAccount.position.description,
    });
  };

  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (serviceChangeData) => {
            setDate(
              serviceChangeData.serviceChangeDate.toLocaleString().split(",")[0]
            );
            const typeServiceChangeDescription = SCDropdowns.find(
              (option) => option.id === +serviceChangeData.serviceChangeType
            )?.label;
            const institutionDescription = institutions.find(
              (option) => option.id === +serviceChangeData.institution
            )?.label;

            const transformedData = isMultipleRegistration
              ? transformedMultipleRegistryData(
                  {
                    ...serviceChangeData,
                    serviceChangeType: typeServiceChangeDescription,
                    institution: institutionDescription,
                  },
                  formSchema,
                  selectedPeople
                )
              : transformedRegistryData(
                  serviceChangeData,
                  typeServiceChangeDescription,
                  institutionDescription
                );
            isMultipleRegistration
              ? setMultipleRegistriesData(transformedData)
              : setFormData({
                  ...serviceChangeData,
                  registry,
                  position: data.serviceAccount.position,
                });
            !isMultipleRegistration && setPreviewData(transformedData);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.serviceChangeDate.label}>
              <FormDatePicker
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.serviceChangeDate.name}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.serviceChangeType.label}>
              <SelectElement
                fullWidth
                label={formSchema.serviceChangeType.label}
                name={formSchema.serviceChangeType.name}
                options={SCDropdowns}
              />
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.institution.label}>
              {institutions && (
                <SelectElement
                  fullWidth
                  label={formSchema.institution.label}
                  name={formSchema.institution.name}
                  options={institutions}
                />
              )}
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.order.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.order.label}
                name={formSchema.order.name}
                value={"Α.Π."}
                variant="outlined"
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "100%", marginTop: 4 }}>
          <h4>{`Υπηρεσιακές Μεταβολές ${date}`}</h4>
          {isMultipleRegistration && multipleRegistriesData ? (
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              rows={multipleRegistriesData.rows}
              columns={multipleRegistriesData.columns.map((column) => ({
                ...column,
                cellClassName: "dataGridCell",
              }))}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          ) : (
            <Details
              renderFirstRowsAsHeader
              renderArraysAsColumns
              rows={[
                {
                  name: previewData.labels[0],
                  value: previewData.labels.slice(1, previewData.length),
                },
                {
                  name: previewData.values[0],
                  value: previewData.values.slice(1, previewData.length),
                },
              ]}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ServiceChangeRegistrationForm;
