import HttpService from "../httpClient";
export const addNewSwitchRegistry = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-transfer`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  export const addNewSwitchRegistries = (registry) => {
    console.log(registry,'registry')
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-transfer/multiple/registries`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  export const getSwitchesRegistriesById = (
    page,
    size,
    registryId
  ) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .get(`/registry-transfer`, {
          params: {
            page,
            size,
            "registryId.equals":registryId,
          },
        })
        .then(function (response) {
          const allRegistries = {
            data: response.data,
            count: +response.headers["x-total-count"],
          };
          resolve(allRegistries);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  export const deleteSwitchRewardRegistryById = (registryId) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .delete(`/registry-transfer/${registryId}`)
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };