import { getSuggToolsInspLabels } from "../../evaluationsHelperFuncs";
import AddSuggestionToolInspectionWrapper from "../AddSuggToolInsp/AddSuggestionToolInspectionWrapper";
import EducationRegistrationForm from "./../../../Registrations/Education/EducationRegistrationForm";
const AddFunctionalSuggestion = () => {
  return (
    <AddSuggestionToolInspectionWrapper
      title={
        "Καταχώριση-Επεξεργασία Πρότασης σχετικά με την εσωτερική οργάνωση και λειτουργία του καταστήματος κράτησης"
      }
      formTitle="Πρόταση σχετικά με την εσωτερική οργάνωση και λειτουργία του καταστήματος κράτησης"
      labels={getSuggToolsInspLabels(4401)}
      objectiveType={4401}

    />
  );
};
export default AddFunctionalSuggestion;
