import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  FormContainer,
  useFieldArray as a1,
  useFieldArray as a2,
  useFieldArray as a3,
  useForm,
  useWatch,
} from "react-hook-form-mui";
import { useLocation, useParams } from "react-router-dom";
import EmployeeCard from "../../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../../components/Breadcrumbs";

import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { router } from "../../../App";
import PageTitle from "../../../components/PageTitle";
import usePostRequest from "../../../hooks/useHttpCallHandler";
import { average } from "../../../services/utils";
import { useGetRegistryEvaluationRegistryInfo } from "../../Armodiothtes/data-access/useGetRegistryEvaluationRegistryInfo";
import GradeDisplay from "../../Armodiothtes/shared/ui/Display";
import FromItem from "../../Armodiothtes/shared/utils/PredefinedBackendForm/FromItem";
import { formatDateFNS } from "../../Armodiothtes/shared/utils/formatDates";
import InfoBox from "../../shared/ui/InfoBox";
import GenericModal from "../../shared/ui/modals/GenericModal";
import {
  failedSubmitHandler,
  isSubmittingFormHandler,
  successfullySubmittedHandler,
} from "../../shared/utils";
import {
  getAchievementDetailSpecificInfo,
  postTargetAchievmentCaseInfo,
} from "../data-access/gateway";
import { useGetTargetAchievment } from "../data-access/useGetTargetAchievment";
import { useGetTargetAchievmentCaseInfo } from "../data-access/useGetTargetAchievmentCaseInfo";
import { useGetTargetTypes } from "../data-access/useGetTargetTypes";
import { useServicesPorivededRegistry } from "../data-access/useServicesPorivededRegistry";
import entipoValidationSchema from "../entipoValidationSchema";
import FormItemComp from "../shared/FormItemComp";
import FormEvaluation from "../shared/ui/UpperForm";
import useAuth from "../../../hooks/useBackAuth";

export const AddButton = () => {
  return (
    <Box display="flex" justifyContent="center" my={2}>
      <Button variant="contained" color="primary">
        Προσθήκη
      </Button>
    </Box>
  );
};

function SharedEntipoForm({ mode }) {
  const location = useLocation();
  const { id } = useParams();

  const actionOfForm = location?.state?.action;
  const {
    handlePostRequest,
    responseData,
    isLoading: isPostLoading,
  } = usePostRequest(postTargetAchievmentCaseInfo);

  const { data: caseInfo, isLoading: caseInfoIsLoading } =
    useGetTargetAchievmentCaseInfo(id);
  const {
    data: automaticGradeLastCell,
    isLoading: automaticGradeLastCellIsLoading,
    automaticGradeLastCellIsError,
  } = useServicesPorivededRegistry(caseInfo?.data?.documentInfo?.registry?.id);
  const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } =
    useGetRegistryEvaluationRegistryInfo(
      caseInfo?.data?.documentInfo?.registry?.id
    );
  const { data: targetType1, isLoadTargetType1 } = useGetTargetTypes(1);
  const { data: targetType2, isLoadTargetType2 } = useGetTargetTypes(2);
  const { data: targetType3, isLoadTargetType3 } = useGetTargetTypes(3);
  const { data: achievement_type, isLoading: isLoadAchievementType } =
    useGetTargetAchievment();
  const formContext = useForm({
    resolver: yupResolver(entipoValidationSchema),
  });
  const type1 = useWatch({ control: formContext.control, name: "type1" });
  const type2 = useWatch({ control: formContext.control, name: "type2" });
  const type3 = useWatch({ control: formContext.control, name: "type3" });

  const currentStage = caseInfo?.data?.documentInfo?.submissionStage?.code;
  const { referenceUserInfo } = useAuth();

  const {
    fields: targetType1Fields,
    append: appendTargetType1,
    remove: removeTargetType1Fields,
  } = a1({ control: formContext.control, name: "type1" });
  const {
    fields: targetType2Fields,
    append: appendTargetType2,
    remove: removeTargetType2ields,
  } = a2({ control: formContext.control, name: "type2" });
  const {
    fields: targetType3Fields,
    append: appendTargetType3,
    remove: removeTargetType3Fields,
  } = a3({ control: formContext.control, name: "type3" });

  useEffect(() => {
    if (caseInfoIsLoading) return;
    // appendTargetType1(caseInfo?.data?.achievementDetails)
    // appendTargetType1({ achievementDetails: { targetScore: "2", descr: 'fsdf' } })
    const type1 = caseInfo?.data?.achievementDetails?.filter((x) => {
      return x?.achievementType?.code === "achievement_type:1";
    });
    const type2 = caseInfo?.data?.achievementDetails?.filter((x) => {
      return x?.achievementType?.code === "achievement_type:2";
    });
    const type3 = caseInfo?.data?.achievementDetails?.filter((x) => {
      return x?.achievementType?.code === "achievement_type:3";
    });
    formContext.reset({
      ...caseInfo?.data,
    });

    type1?.forEach((x) => {
      appendTargetType1({
        id: x.id,
        targetType: { id: String(x.targetType.id), code: x.targetType.code },
        targetScore: String(x.targetScore),
        notes: x.notes,
      });
    });

    type2?.forEach((x) => {
      appendTargetType2({
        id: x.id,
        targetType: { id: String(x.targetType.id), code: x.targetType.code },
        targetScore: String(x.targetScore),
        notes: x.notes,
      });
    });

    type3?.forEach((x) => {
      appendTargetType3({
        id: x.id,
        targetType: { id: String(x.targetType.id), code: x.targetType.code },
        targetScore: String(x.targetScore),
        notes: x.notes,
      });
    });
  }, [
    caseInfoIsLoading,
    isLoadTargetType1,
    isLoadTargetType2,
    isLoadTargetType3,
  ]);

  const documentInfoRegistry = caseInfo?.data?.documentInfo?.registry;
  const documenType = caseInfo?.data?.documentInfo?.documentType;

  useEffect(() => {
    registryInfoDetails &&
      actionOfForm === "print" &&
      setTimeout(window.print(), 1500);
  }, []);

  useEffect(() => {
    if (!automaticGradeLastCellIsLoading) return;
    if (automaticGradeLastCellIsError)
      enqueueSnackbar(
        "Αδυναμία υπολογισμού αυτόματης βαθμολόγησης σε Αρμοδιότητες Αξιολογούμενου Προϊσταμένου",
        { variant: "error" }
      );
    formContext.resetField("tesf323", {
      defaultValue: String(automaticGradeLastCell?.data) || undefined,
    });
  }, [automaticGradeLastCellIsLoading]);

  const calmAvgAndDetails = (data) => {
    const findAchievmenttype = (achievementCode) =>
      achievement_type?.data?.find(
        (zz) => zz?.achievementTypeCode == achievementCode
      )?.achievementType;

    const ad1 = data?.type1?.map((x) => {
      if (!x) return undefined;
      return {
        ...x,
        targetType: { id: x?.targetType?.id },
        achievementType: findAchievmenttype("achievement_type:1"),
      };
    });
    const ad2 = data?.type2?.map((x) => {
      if (!x) return undefined;
      return {
        ...x,
        targetType: { id: x?.targetType?.id },
        achievementType: findAchievmenttype("achievement_type:2"),
      };
    });

    const ad3 = data?.type3?.map((x) => {
      if (!x) return undefined;
      return {
        ...x,
        targetType: { id: x?.targetType?.id },
        achievementType: findAchievmenttype("achievement_type:3"),
      };
    });
    const achievementDetails = [
      ...(ad1 ? [...ad1] : []),
      ...(ad2 ? [...ad2] : []),
      ...(ad3 ? [...ad3] : []),
    ];

    const filteredGrades = achievementDetails
      ?.map((x) => +x.targetScore)
      .filter((z) => z);
    const avg = average(filteredGrades, filteredGrades.length);

    console.log(achievementDetails, "achievementDetails");
    return { avg, achievementDetails };
  };

  const onSubmit = async (data) => {
    isSubmittingFormHandler();
    const { avg, achievementDetails } = calmAvgAndDetails(data);

    const body = {
      ...data,
      startDate: formatDateFNS(data.startDate),
      endDate: formatDateFNS(data.endDate),
      id: caseInfo?.data?.id,
      achievementGrade: avg,
      documentInfo: caseInfo?.data?.documentInfo,
      achievementDetails: achievementDetails,
    };

    try {
      await handlePostRequest(body);
      successfullySubmittedHandler(referenceUserInfo?.id);
    } catch (error) {
      console.error(error);
      failedSubmitHandler();
    }
  };

  if (
    registryInfoDetailsIsLoading ||
    caseInfoIsLoading ||
    isLoadTargetType1 ||
    isLoadTargetType2 ||
    isLoadTargetType3
  )
    return <></>;

  return (
    <Box mt={17}>
      <AppBreadcrumbs
        breadcrumbsList={[
          {
            title: "Μητρώο Αξιολόγησης Προσωπικού",
            url: "/personnel-registry-evaluation",
          },
          {
            title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${referenceUserInfo?.name} - ${referenceUserInfo?.afm}`,
            url: `/evaluation-card/${referenceUserInfo?.id}`,
          },
          {
            title: "Διενέργειες Αξιολογήσεων",
            url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
          },
          {
            title: `${documenType?.description || ""}:

                    ${documentInfoRegistry?.name || ""} ${
              documentInfoRegistry?.surname || ""
            } - ${documentInfoRegistry?.afm || ""}`,
            url: "/evaluation/groups/create",
          },
        ]}
      />
      {mode === "ipallilos" ? (
        <PageTitle title="Έντυπο Επίτευξης Στόχων Yπαλλήλου" />
      ) : (
        <PageTitle title="Έντυπο Επίτευξης Στόχων Προϊσταμένου" />
      )}

      <InfoBox
        content={`
                    Καταχωρίστε την Κατηγορία καθώς και την Περιγραφή του κάθε Στόχου που είχε ανατεθεί στον Αξιολογούμενο . Εν συνεχεία, αποτυπώστε το επίπεδο επίτευξης κάθε στόχου από τον αξιολογούμενο με βάση την πενταβάθμια περιγραφική κλίμακα, όπου "Πολύ Χαμηλή Επίτευξη του
                    Στόχου" αντιστοιχεί σε βαθμολογία = ένα (1), και "Σημαντική Υπέρβαση Στόχου" αντιστοιχεί σε βαθμολογία = πέντε (5)
                    Αν σε κάποια επιλογή δεν επιλεχθεί βαθμολογία, αυτό δεν θα μετρήσει στον υπολογισμό του μέσου όρου των βαθμολογιών των στόχων.
                `}
      />
      <Box display="flex" justifyContent="center" my={2}>
        <EmployeeCard data={registryInfoDetails?.data}>
          <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
        </EmployeeCard>
      </Box>
      <FormContainer onSuccess={() => {}} formContext={formContext}>
        <fieldset disabled={actionOfForm === "preview"}>
          <FormEvaluation
            stage={caseInfo?.data?.documentInfo?.submissionStage?.code}
          />
          <Typography sx={{ mb: 2 }} variant="h4">
            Εσωτερική Οργάνωση και Λειτουργία του Καταστήματος Κράτησης
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            {targetType1Fields.map((row, index) => {
              const selectedCode = targetType1?.data?.["target_type:1"].find(
                (qq) => qq.id == type1?.[index]?.targetType?.id
              )?.code;
              return (
                <Box display="flex">
                  <FormItemComp
                    context={{
                      stage: currentStage,
                      isOther: selectedCode == "others",
                    }}
                    mainName={"type1"}
                    index={index}
                    targetTypes={targetType1?.data?.["target_type:1"]
                      .map((x, i) => {
                        return {
                          id: x?.id,
                          code: x?.code,
                          label: x?.description,
                        };
                      })
                      .filter(
                        (zz) =>
                          !type1?.find(
                            (xy, j) =>
                              xy?.targetType?.id == zz?.id && j != index
                          ) || zz?.code == "others"
                      )}
                  />
                  <Box mx={5} display="flex" alignItems="center">
                    <Button
                      disabled={
                        currentStage == "stage:1" ||
                        currentStage == "stage:2" ||
                        !selectedCode ||
                        selectedCode == "others"
                      }
                      variant="outlined"
                      color="secondary"
                      onClick={async () => {
                        try {
                          const selectedCode = targetType1?.data?.[
                            "target_type:1"
                          ].find(
                            (qq) => qq.id == type1[index].targetType.id
                          )?.code;
                          enqueueSnackbar("Υπολογισμός Βαθμολογίας", {
                            variant: "info",
                          });
                          const respAuto =
                            await getAchievementDetailSpecificInfo(
                              selectedCode,
                              caseInfo?.data?.documentInfo?.registry?.id,
                              caseInfo?.data?.documentInfo?.registry?.id ==
                                caseInfo?.data?.evaluationGroupSupervisor?.id
                            );
                          formContext.setValue(
                            `type1.${index}.targetScore`,
                            String(respAuto?.data) || undefined
                          );
                        } catch (error) {
                          console.error(error);
                          enqueueSnackbar("Αδυναμία αυτόματης βαθμολόγησης", {
                            variant: "error",
                          });
                        }
                      }}
                    >
                      Αυτόματη Βαθμολόγηση
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box display="flex" justifyContent="center" my={2}>
            <Button
              onClick={() => appendTargetType1({})}
              variant="contained"
              color="primary"
            >
              Προσθήκη
            </Button>
          </Box>
          <Typography sx={{ mb: 2 }} variant="h4">
            Παρεχόμενες Υπηρεσίες του Καταστήματος Κράτησης
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            {targetType2Fields.map((row, index) => {
              const selectedCode = targetType2?.data?.["target_type:2"].find(
                (qq) => qq.id == type2?.[index]?.targetType?.id
              )?.code;

              return (
                <Box display="flex">
                  <FormItemComp
                    context={{
                      stage: currentStage,
                      isOther: selectedCode == "others",
                    }}
                    mainName={"type2"}
                    index={index}
                    targetTypes={targetType2?.data?.["target_type:2"].map(
                      (x) => {
                        return {
                          id: x?.id,
                          code: x?.code,
                          label: x?.description,
                        };
                      }
                    )}
                  />
                  <Box mx={5} display="flex" alignItems="center">
                    <Button
                      disabled={
                        currentStage == "stage:1" ||
                        currentStage == "stage:2" ||
                        !selectedCode ||
                        selectedCode == "others"
                      }
                      variant="outlined"
                      color="secondary"
                      onClick={async () => {
                        try {
                          console.log(
                            caseInfo?.data?.documentInfo?.registry?.id,
                            caseInfo?.data?.evaluationGroupSupervisor?.id
                          );
                          const respAuto =
                            await getAchievementDetailSpecificInfo(
                              selectedCode,
                              caseInfo?.data?.documentInfo?.registry?.id,
                              caseInfo?.data?.documentInfo?.registry?.id ==
                                caseInfo?.data?.evaluationGroupSupervisor?.id
                            );

                          formContext.setValue(
                            `type2.${index}.targetScore`,
                            String(respAuto?.data) || undefined
                          );
                        } catch (error) {
                          console.error(error);
                          enqueueSnackbar("Αδυναμία αυτόματης βαθμολόγησης", {
                            variant: "error",
                          });
                        }
                      }}
                    >
                      Αυτόματη Βαθμολόγηση
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box display="flex" justifyContent="center" my={2}>
            <Button
              onClick={() => appendTargetType2({})}
              variant="contained"
              color="primary"
            >
              Προσθήκη
            </Button>
          </Box>

          <Typography sx={{ mb: 2 }} variant="h4">
            Γνώσεις, Δεξιότητες και Ικανότητες των Υπαλλήλων του Καταστήματος
            Κράτησης
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            {targetType3Fields.map((row, index) => {
              const selectedCode = targetType3?.data?.["target_type:3"].find(
                (qq) => qq.id == type3?.[index]?.targetType?.id
              )?.code;

              return (
                <Box display="flex">
                  <Box mx={5} display="flex" alignItems="center">
                    <FormItemComp
                      context={{
                        stage: currentStage,
                        isOther: selectedCode == "others",
                      }}
                      mainName={"type3"}
                      index={index}
                      targetTypes={targetType3?.data?.["target_type:3"].map(
                        (x) => {
                          return {
                            id: x?.id,
                            code: x?.code,
                            label: x?.description,
                          };
                        }
                      )}
                    />
                    <Button
                      disabled={
                        currentStage == "stage:1" ||
                        currentStage == "stage:2" ||
                        !selectedCode ||
                        selectedCode == "others"
                      }
                      color="secondary"
                      onClick={async () => {
                        try {
                          const respAuto =
                            await getAchievementDetailSpecificInfo(
                              selectedCode,
                              caseInfo?.data?.documentInfo?.registry?.id,
                              caseInfo?.data?.documentInfo?.registry?.id ==
                                caseInfo?.data?.evaluationGroupSupervisor?.id
                            );
                          formContext.setValue(
                            `type3.${index}.targetScore`,
                            String(respAuto?.data) || undefined
                          );
                        } catch (error) {
                          console.error(error);
                          enqueueSnackbar("Αδυναμία αυτόματης βαθμολόγησης", {
                            variant: "error",
                          });
                        }
                      }}
                    >
                      Αυτόματη Βαθμολόγηση
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box display="flex" justifyContent="center" my={2}>
            <Button
              onClick={() => appendTargetType3({})}
              variant="contained"
              color="primary"
            >
              Προσθήκη
            </Button>
          </Box>

          <Typography sx={{ mb: 2 }} variant="h4">
            Παρεχόμενες Υπηρεσίες Αξιολογούμενου Προϊσταμένου στο Κατάστημα
            Κρατησης
          </Typography>
          <Box>
            <Typography sx={{ mb: 2 }} variant="h4">
              Αρμοδιότητες Αξιολογούμενου Προϊσταμένου
            </Typography>
          </Box>
          <FromItem currentStage={currentStage} mode={2} code={"row?.code"} name={`tesf323`} />

          <Box sx={{ marginY: 6 }}>
            <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
            {caseInfo?.data && (
              <Box display="flex" justifyContent="center" my={2}>
                <EmployeeCard data={caseInfo?.data?.evaluationGroupSupervisor}>
                  <RegistrationEmployeeInfo
                    data={caseInfo?.data?.evaluationGroupSupervisor}
                  />
                </EmployeeCard>
              </Box>
            )}
          </Box>

          {!isPostLoading && (
            <Box display="flex" justifyContent="center">
              <GradeDisplay
                grade={responseData?.totalGrade || caseInfo?.data?.totalGrade}
              />
            </Box>
          )}

          {caseInfo?.data?.documentInfo?.isFinalized && (
            <Typography textAlign="center" variant="h3">
              Η αξιολόγηση έχει οριστικοποιηθεί
            </Typography>
          )}

          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => router.navigate(-1)} >
              <span>Επιστροφή</span>
            </Button>
            <GenericModal
              onAccept={
                formContext.formState.isValid &&
                formContext.handleSubmit(onSubmit)
              }
              agreeButtonTitle="Οριστικοποίηση"
              isValid={formContext.formState.isValid}
              title={
                <Typography>{`Η βαθμολογία που θα καταχωρηθεί είναι ${
                  calmAvgAndDetails(formContext.getValues())?.avg ||
                  "Απροσδιόριστη"
                }`}</Typography>
              }
              dialogContent={`Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποίησετε τo Έντυπο Επίτευξης Στόχων; ${currentStage == "stage:1" && 'Μετά την οριστικοποίηση δεν θα έχετε δικαίωμα να επεξεργαστείτε τις βαθμολογίες.'}`}
            >
              <Button
                disabled={
                  actionOfForm === "preview" || actionOfForm === "print"
                }
                type={"submit"}
                variant="contained"
              >
                <span>Οριστικοποίηση</span>
              </Button>
            </GenericModal>
          </Box>
        </fieldset>
      </FormContainer>
    </Box>
  );
}

export default SharedEntipoForm;
