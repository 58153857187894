import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AppAccordion from "../../components/UI/AppAccordion";
import AppTable from "../../components/UI/AppTable";
import Loader from "../../components/UI/Loader";
import RegistrationTableItem from "../Registrations/RegistrationTableItem";
import DeleteModal from "../History/DeleteModal";

const TableAccordionItem = (props) => {
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [approveDelete, setApproveDelete] = useState(false);

  const handleClick = async (row, action) => {
    if(props.evaluationTable) {
      action.onClickFunc(row)
    }
    else {
      setIsOpenDeleteModal(true);
      setApproveDelete(() => async () => {
        await action.onClickFunc(row);
        setIsUpdated((prevState) => !prevState);
        setApproveDelete(false);
      });
    }
   
  };
  useEffect(() => {
    const fetchAndSetData = async () => {
      setIsLoading(true);
      try {
        const result = await props.getCallFunc(page, rowsPerPage);
        setData(result.data);
        setCount(result.count);
        result && setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAndSetData();
  }, [page, rowsPerPage, props, isUpdated]);
  const getIsDisabled = (row) => {
    switch (props.id) {
      case "serviceGrade":
        console.log(row.id !== getMostRecentRegistry("actionDate").id,'row.id !== getMostRecentRegistry("actionDate").id')
        return row.id !== getMostRecentRegistry("actionDate").id;
      case "servicePosition":
        return row.id !== getMostRecentRegistry("actionDate").id;
      case "serviceChange":
        return row.id !== getMostRecentRegistry("serviceModification.serviceDate").id;

      default:
        return data.length > 2;
    }
  };
  const getMostRecentRegistry = (dateField) => {
    return data.reduce((mostRecent, registry) => {
      if (
        !mostRecent ||
        new Date(registry[dateField]) > new Date(mostRecent[dateField])
      ) {
        return registry;
      }
      return mostRecent;
    }, null);
  };

  return (
    <>
    <AppAccordion
      id={props.id}
      title={props.title}
      details={
        <RegistrationTableItem
          buttonLabel={props.buttonLabel}
          url={props.buttonUri}
          id={props.id}
          userData={props.userData}
        >
          {isLoading ? (
            <Loader />
          ) : data.length > 0 && count ? (
            <AppTable
              noDataMsg={"Χωρίς δεδομένα."}
              actionIcons={
                props.actionIcons &&
                props.actionIcons
                  .filter((action) => action)
                  .map((action) => ({
                    key: action.id,
                    icon: action.icon,
                    onClickFunc: (row) => handleClick(row, action),
                    disabled:(row) => getIsDisabled(row),
                  }))
                }
                columns={props.columns}
                rows={data}
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                />
                ) : (
                  <Box sx={{ textAlign: "center" }}>Δεν βρέθηκαν δεδομένα.</Box>
                  )}
        </RegistrationTableItem>
      }
      />
      {isOpenDeleteModal && (
        <DeleteModal
          setIsShownModal={setIsOpenDeleteModal}
          onDelete={() => approveDelete && approveDelete()}
        />
      )}
      </>
  );
};

export default TableAccordionItem;
