export const defaultOvertimeRegistrationValues = {
    overtimeDate: "",
    overtimeStart: "",
    overtimeEnd: "",
    reason: "",
    notes: "",
  }

  export  const formOvertimeRegistrationSchema = {
    overtimeDate: {
      name: "overtimeDate",
      label: "Ημερομηνία",
    },
    overtimeStart: {
      name: "overtimeStart",
      label: "Ώρα Από",
    },
    overtimeEnd: {
      name: "overtimeEnd",
      label: "Ώρα Έως",
    },
    reason: {
      name: "reason",
      label: "Αιτιολόγηση",
    },
    notes: {
      name: "notes",
      label: "Παρατηρήσεις",
    },
  };

  export const overTimeColumns = [

    {
      id: "overtime.overtimeDate",
      label: "Ημερομηνία",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "overtime.overtimeStart",
      label: "Ώρα Από",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "overtime.overtimeEnd",
      label: "Ώρα Έως",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "overtime.overtimeReason.description",
      label: "Αιτιολόγηση",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "overtime.notes",
      label: "Παρατηρήσεις",
      minWidth: 170,
      isClickable: false,
    },
  ];
  