import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RegistryDetailedHistoryWrapper from "../RegistryDetailedHistoryWrapper";
import {
  deleteOvertimeHistory,
  getOvertimeHistory,
} from "../../../services/history.service";
import { OvertimeDetailsColumns } from "./OvertimeColumnsConfig";
import DeleteModal from "../DeleteModal";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const OvertimeDetailedHistory = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const overtimeId = useParams().id;
  const date = useParams().date;
  const { userInfo, hasRole } = useAuth()
  const [ids, setIds] = useState();
  const [guardType, setGuardType] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [registriesLength, setRegistriesLength] = useState();
  const onDelete = () => {
    deleteOvertimeHistory(ids.employeeId, ids.overtimeId);
    setIsUpdated(true);
  };

  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getOvertimeHistory(
        0,
        10,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id,
        date,
        guardType && guardType
      );
      allRegistries.data.length > 0
        ? setOvertimeData(allRegistries.data)
        : setData([]);
      setIsLoading(false);
      setIsUpdated(false);
    };

    fetchAndSetHistoryRegistries();
  }, [date, guardType, isUpdated, registriesLength, overtimeId]);
  const setOvertimeData = (data) => {
    const overtime = data.find((overtime) => overtime.id === +overtimeId);
    overtime
      ? setData(
        overtime.registries.map((employee) => ({
          ...employee,
          id: employee.id,
          overtimeId: overtime.id,
          overtimeReason: overtime.overtimeReason.description,
          overtimeStart: overtime.overtimeStart,
          overtimeEnd: overtime.overtimeEnd,
          notes: overtime.notes,
        }))
      )
      : setData([]);
    overtime && setRegistriesLength(overtime.registries.length);
  };
  return (
    <>
      <RegistryDetailedHistoryWrapper
        breadcrumbs={[
          {
            title: "Υπερωριών",
            url: "/history/overtime",
          },
          {
            title: "Υπερωριών",
            url: "",
          },
        ]}
        title={"Υπερωρίες "}
        department={userInfo?.serviceAccount?.institute?.description}
        date={date}
        columns={OvertimeDetailsColumns(
          setIsOpenDeleteModal,
          registriesLength,
          setIds
        )}
        isLoading={isLoading}
        rows={data || []}
        guardType={guardType}
        setGuardType={setGuardType}
      />

      {isOpenDeleteModal && (
        <DeleteModal
          setIsShownModal={setIsOpenDeleteModal}
          onDelete={onDelete}
        />
      )}
    </>
  );
};
export default OvertimeDetailedHistory;
