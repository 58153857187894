import styled from "@emotion/styled";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import FormTimePicker from "./FormTimePicker";
import el from "date-fns/locale/el";

const DateRangePickerStyled = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const FormTimeRangePicker = (props) => {
  return (
    <DateRangePickerStyled>
      <LocalizationProvider adapterLocale={el} dateAdapter={AdapterDateFns}>
        <FormTimePicker name={props.start.name} placeholder={"ΩΩ:ΛΛ"} />
        <Box sx={{ mx: 2 }}> Έως </Box>
        <FormTimePicker name={props.end.name} placeholder={"ΩΩ:ΛΛ"} />
      </LocalizationProvider>
    </DateRangePickerStyled>
  );
};

export default FormTimeRangePicker;
