import { RadioButtonGroup, useWatch } from "react-hook-form-mui";
import { RenderCaseStage2 } from "../../../../utils/renderCaseStage2";

const Stadio2=({isManager, developTypes}) => {
  const value = useWatch({ name: "modelTargetType" });
  return (
    <>
      <RadioButtonGroup
        row
        name={"modelTargetType"}
        options={[
          {
            id: "20051",
            label: "Εξέλιξη Ανάπτυξης Επιλεγμένης Δεξιότητας",
          },
          {
            id: "10101",
            label: "Αναθεώρηση Βαθμού Ανάπτυξης Δεξιότητας",
          },
          {
            id: "10151",
            label: "Αναθεώρηση Δεξιότητας (Νέα Δεξιότητα)",
          },
        ]}
      />

      <RenderCaseStage2 stage={value} isManager={isManager} developTypes={developTypes}/>
    </>
  );
}

export default Stadio2;
