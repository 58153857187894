import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RegistryDetailedHistoryWrapper from "../RegistryDetailedHistoryWrapper";
import {
  deleteServiceActionHistory,
  getServiceActionHistory,
} from "../../../services/history.service";
import { serviceActionDetailsColumns } from "./ServiceActionColumnsConfig";
import DeleteModal from "../DeleteModal";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const ServiceActionDetailedHistory = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const date = useParams().date;
  const serviceActionId = useParams().id;
  const [ids, setIds] = useState();
  const { userInfo, hasRole} = useAuth()
  const [guardType, setGuardType] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [registriesLength, setRegistriesLength] = useState();
  const onDelete = () => {
    console.log(ids)
    deleteServiceActionHistory(ids.serviceActionId, ids.employeeId);
    setIsUpdated(true);
  };
  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getServiceActionHistory(
        0,
        10,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id, 
        date,
        guardType && guardType
      );
      console.log(allRegistries)
      allRegistries.data.length > 0
      ? setServiceActionData(allRegistries.data)

        : setData([]);
      setIsLoading(false);
      setIsUpdated(false);
    };

    fetchAndSetHistoryRegistries();
  }, [date, guardType, isUpdated, registriesLength, serviceActionId]);
  const setServiceActionData = (data) => {
    console.log(data,'data')
    const serviceAction = data.find((serviceAction) => (serviceAction.id === +serviceActionId));
  console.log(serviceAction, serviceActionId,'serviceAction')
    serviceAction ? setData(
      serviceAction.registries.map((employee) => ({
        ...employee,
        id: employee.id,
        serviceActionId: serviceAction.id,
        actionType: serviceAction.actionType.description,
        notes: serviceAction.notes,
      }))
    ): setData([])
    serviceAction && setRegistriesLength(serviceAction.registries.length);
  };
  return (
    <>
      <RegistryDetailedHistoryWrapper
        breadcrumbs={[
          {
            title: "Υπηρεσιακών Ενεργειών",
            url: "/history/service-actions",
          },
          {
            title: "Υπηρεσιακής Ενέργειας",
            url: "",
          },
        ]}
        title={"Υπηρεσιακές Ενέργειες"}
        department={userInfo?.serviceAccount?.institute?.description}
        date={date}
        columns={serviceActionDetailsColumns(
          setIsOpenDeleteModal,
          registriesLength,
          setIds
        )}
        isLoading={isLoading}
        rows={data}
        guardType={guardType}
        setGuardType={setGuardType}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          setIsShownModal={setIsOpenDeleteModal}
          onDelete={onDelete}
        />
      )}
    </>
  );
};
export default ServiceActionDetailedHistory;
