import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link, useNavigate } from "react-router-dom";
import packageJson from "../../package.json";
import useAuth from "../hooks/useBackAuth";
import UserService from "../services/userService";
import { config } from "../config";

const drawerWidth = 240;
export default function Menu() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { userInfo, hasRole, clearStorage } = useAuth();
  const historyMenu = {
    title: "Ιστορικό",
    submenu: [
      {
        title: "Ιστορικό Υπηρεσιακών Μεταβολών",
        url: "/history/service-changes",
      },
      { title: "Ιστορικό Υπερωριών", url: "/history/overtime" },
      {
        title: "Ιστορικό Υπηρεσιακών Ενεργειών",
        url: "/history/service-actions",
      },
      { title: "Ιστορικό Μεταγωγών", url: "/history/switches" },
      { title: "Ιστορικό Εκπαιδεύσεων", url: "/history/education" },
    ],
  };
  const multipleRegistryMenu = {
    title: "Καταχώριση",
    submenu: [
      {
        title: "Καταχώριση Υπηρεσιακής Μεταβολής",
        url: "/multiple-registries/service-changes",
      },
      { title: "Καταχώριση Υπερωρίας", url: "/multiple-registries/overtime" },
      {
        title: "Καταχώριση Υπηρεσιακής Ενέργειας",
        url: "/multiple-registries/service-actions",
      },
      { title: "Καταχώριση Μεταγωγής", url: "/multiple-registries/switches" },
      {
        title: "Καταχώριση Εκπαίδευσης",
        url: "/multiple-registries/education",
      },
    ],
  };
  const evaluationMenu = {
    title: "Αξιολόγηση",
    submenu: [
      {
        title: "Μητρώο Αξιολόγησης Προσωπικού",
        url: "/personnel-registry-evaluation",
      },
      (hasRole('SuperAdmin') || hasRole('admin')) && { title: "Ομάδες Αξιολόγησης", url: "/evaluation/groups" },
    ]
  };
  const registryMenu =
    [{ title: "Στοιχεία Μητρώου", url: hasRole("employee") ? `employee-info/${userInfo?.id}` : "/personnel-management" }]
  const getMenuByRole = () => {
    switch (true) {
      case hasRole("employee"):
        return [].concat(registryMenu, evaluationMenu);;
      case hasRole("KATAXORITIS"):
        return [].concat(registryMenu, multipleRegistryMenu, historyMenu);
      case hasRole("admin") || hasRole("SuperAdmin") || hasRole("supervisor"):
        return [].concat(registryMenu, historyMenu, evaluationMenu);
      default:
        return []
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          zIndex: "0",
          flexShrink: 0,
          alignContent: "flex-end",
          "& .MuiDrawer-paper": {
            // top: 120,
            // paddingTop: '120px',
            width: drawerWidth,
            // boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "88%",
          }}
        >
          <List sx={{ paddingTop: '125px' }}>
            {getMenuByRole().map((menuItem, index) => (
              <Box key={index}>
                {menuItem.submenu ? (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ color: "primary.main" }}>
                        {menuItem.title}
                      </Typography>
                    </AccordionSummary>
                    <Divider key={menuItem.title} />
                    {menuItem?.submenu && (
                      <AccordionDetails key={menuItem.title}>
                        {menuItem?.submenu?.map((submenuItem) => {
                          return submenuItem && <Box
                            key={submenuItem.title}
                            sx={{
                              paddingY: 1,
                              height: "46px",
                              marginLeft: 2,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              key={index}
                              style={{
                                fontFamily: "Roboto, Helvetica ,Arial,sans-serif",
                                color: theme.palette.primary.main,
                                textDecoration: 'none', // Ensure that this is set to 'none' to prevent default underline
                              }}
                              variant="body1"
                              to={submenuItem.url}
                            >
                              {submenuItem.title}
                            </Link>
                          </Box>
                        }
                        )}
                      </AccordionDetails>
                    )}
                  </Accordion>
                ) : (
                  <Box
                    sx={{
                      height: "46px",
                      marginLeft: 2,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Link
                      style={{
                        fontFamily: "Roboto, Helvetica ,Arial,sans-serif",
                        color: theme.palette.primary.main,
                        textDecoration: 'none', // Ensure that this is set to 'none' to prevent default underline
                      }}
                      to={menuItem.url}
                    >
                      {menuItem.title}
                    </Link>
                    <Divider />
                  </Box>
                )}
              </Box>
            ))}
          </List>
          <Button onClick={async () => {
            const sleep = (milliseconds) => {
              return new Promise(resolve => setTimeout(resolve, milliseconds))
            };
            navigate('/')
            clearStorage()
            UserService.doLogout({
              redirectUri: config.frontendURL
            })
          }} sx={{ fontWeight: "bold" }}>Αποσύνδεση</Button>
        </Box>
        {
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {`Έκδοση: ${packageJson.version}`}
          </Typography>
        }
      </Drawer>
    </Box>
  );
}
