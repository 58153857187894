import RegistrationWrapper from "../RegistrationWrapper";
import ServiceGradeRegistrationForm from './ServiceGradeRegistrationForm';

const ServiceGradeRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Υπηρεσιακού Βαθμού"}
      FormComponent={ServiceGradeRegistrationForm}
    />
  );
};
export default ServiceGradeRegistration;
