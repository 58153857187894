
import { getSuggToolsInspLabels } from '../../evaluationsHelperFuncs';
import AddSuggestionToolInspectionWrapper from './../AddSuggToolInsp/AddSuggestionToolInspectionWrapper';
const AddImprovingSuggestion = () => {
  return (
    <AddSuggestionToolInspectionWrapper
      title={
        "Πρόταση σχετικά με τη βελτίωση του συστήματος αξιολόγησης, παρακίνησης και ανταμοιβών"
      }
      formTitle="Πρόταση σχετικά με την εσωτερική οργάνωση και λειτουργία του καταστήματος κράτησης"
      labels={getSuggToolsInspLabels(4451)}  
      objectiveType={4451}

      />
  );
};
export default AddImprovingSuggestion;
