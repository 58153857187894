import { TextField } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../../../components/Details/utils";
import HorizontalVerticalTogglerLayout from "../../../../../../components/Layout/HorizontalVerticalTogglerLayout";

const Stage2Case3 = ({ developTypes, isManager }) => {
  return (
    <>
      <HorizontalVerticalTogglerLayout direction="column">
        <TextFieldElement
          fullWidth
          label="Περιγραφή Νέας Δεξιότητας*                "
          name="stage2.targetName"
        />

        <TextFieldElement
          fullWidth
          label="Νέοι Δείκτες Μέτρησης (και Τιμές Δεικτών)"
          name="stage2.measurementIndicator"
        />
        <TextFieldElement
          fullWidth
          label="Περιγραφή Επίτευξης Νέου Στόχου Ανάπτυξης Δεξιότητας                "
          name="stage2.description"
        />

        <TextFieldElement
          label="Αιτολόγηση Αναθεώρησης Επιλογής Στόχου"
          name="stage2.eligibility"
        />

        <TextField
          disabled={true}
          label="Ημερομηνία"
          value={defaultRenderDates(new Date())}
        />
      </HorizontalVerticalTogglerLayout>{" "}
    </>
  );
};

export default Stage2Case3;
