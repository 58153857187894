import RegistrationWrapper from "../RegistrationWrapper";
import SwitchRegistrationForm from "./SwitchRegistrationForm";

const SwitchesRegistration = () => {
  return (
    <RegistrationWrapper
      title={"Καταχώριση Μεταγωγής"}
      FormComponent={SwitchRegistrationForm}
    />
  );
};
export default SwitchesRegistration;
