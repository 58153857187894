import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useFormState } from "react-hook-form";

const PreviewButton = (props) => {
  const { isValid, isValidating } = useFormState();
  const onValid = () => {
    enqueueSnackbar('Όλα τα στοιχεία που εισάγατε είναι σωστά', { variant: 'success' });
    props.setIsPreviewMode(true)
  }
  return (
    <Button
      type="submit"
      variant="contained"
      onClick={(e) => {
        isValid ? onValid() : props.setIsPreviewMode(false);
      }}
    >
      {props.label}
    </Button>
  );
};

export default PreviewButton;