import * as yup from "yup";

export const overtimeFieldValidation = yup.object().shape({
    overtimeDate: yup.date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
    overtimeStart: yup.date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .required("Το πεδίο είναι υποχρεωτικό"),
    overtimeEnd: yup.date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .required("Το πεδίο είναι υποχρεωτικό"),
    reason: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    
});
