import ReactDOM from 'react-dom';

import styles from './AppModal.module.css';

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onHideModal}/>;
};

const ModalOverlay = (props) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById('overlay');

const AppModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onHideModal={props.onHideModal}/>, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay >{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default AppModal;