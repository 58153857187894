import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useLocation, useParams } from "react-router-dom";
import { router } from "../../../../App";
import EmployeeCard from "../../../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../../../components/Breadcrumbs";
import PageTitle from "../../../../components/PageTitle";
import Loader from "../../../../components/UI/Loader";
import { getAchievementType } from "../../../../helperFunctions";
import useAuth from "../../../../hooks/useBackAuth";
import usePostRequest from "../../../../hooks/useHttpCallHandler";
import { getEvaluationGroupData } from "../../../../services/Evaluations/SuggestionToolsInspections.service";
import { getRegistryById } from "../../../../services/PersonnelManagement.service";
import ReportModal from "../../ReportModal";
import { getSkillGrade, postReport } from "../../data-access/gateway";
import { useGetAchievementScore } from "../../data-access/useGetAchievementScore";
import { useGetDocumentInfo } from "../../data-access/useGetDocumentInfo";
import { useGetPulseScore } from "../../data-access/useGetPulseScore";
import { useGetRegistryInfoById } from "../../data-access/useGetRegistryInfoById";
import { useGetSkills } from "../../data-access/useGetSkills";
import reportWrapperValidation from "./../../reportWrapperValidation";
import ReportConfirmModal from "./ReportConfirmModal";
import ReportDenyModal from "./ReportDenyModal";
import ΕvaluationReportPeriod from "./ΕvaluationReportPeriod";
import { formatDateFNS } from "../../../Armodiothtes/shared/utils/formatDates";

function ReportWrapper({ title, isManager }) {
  const location = useLocation();
  const { id } = useParams();
  const { data: documentInfo, isLoading: documentInfoIsLoading } =
    useGetDocumentInfo(id);
  const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } =
    useGetRegistryInfoById(documentInfo?.data?.documentInfo?.registry?.id);
  const { data: skills, isLoading: skillsIsLoading } = useGetSkills();

  const { data: pulseScore, isLoading: pulseScoreIsLoading } = useGetPulseScore(
    documentInfo?.data?.documentInfo?.documentType?.code,
    registryInfoDetails?.data?.evaluationGroup?.id
  );
    const { data: achievementScore, isLoading: achievementScoreIsLoading } =
    useGetAchievementScore(
      documentInfo?.data?.documentInfo?.documentType?.code,
      +documentInfo?.data?.documentInfo?.registry?.id
    );
  const { handlePostRequest, isLoading: isPostLoading } =
    usePostRequest(postReport);
  const formContext = useForm({
    resolver: yupResolver(reportWrapperValidation),
  });
  const actionOfForm = location?.state?.action;
  const [mappedSkills, setMappedSkills] = useState();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { userInfo, referenceUserInfo } = useAuth();
  const [confirmModal, setConfirmModal] = useState(false);
  const [denyModal, setDenyModal] = useState(false);
  const [skillsScore, setSkillsScore] = useState();
  const [overallScore, setOverallScore] = useState();
  const selectNames = [
    { name: "developedSkill1", label: "Ανεπτυγμένη Δεξιότητα 1" },
    { name: "developedSkill2", label: "Ανεπτυγμένη Δεξιότητα 2" },
    { name: "developedSkill3", label: "Ανεπτυγμένη Δεξιότητα 3" },
    { name: "skillToDevelop1", label: "Δεξιότητα Προς Ανάπτυξη 1" },
    { name: "skillToDevelop2", label: "Δεξιότητα Προς Ανάπτυξη 2" },
    { name: "skillToDevelop3", label: "Δεξιότητα Προς Ανάπτυξη 3" },
  ];
  const [chosenOptions, setChosenOptions] = useState({});
  const [chosenOptionsGrades, setChosenOptionsGrades] = useState({});
  const [evaluatorData, setEvaluatorData] = useState();
  const [formData, setFormData] = useState();
  const isChosenByOther = (optionValue, selectName) => {
    for (let key in chosenOptions) {
      if (key !== selectName) {
        if (chosenOptions[key] === optionValue) {
          return true;
        }
      }
    }
    return false;
  };
  const getGrade = async (skillId) => {
    const achievementType = getAchievementType(+skillId);
    const grade = await getSkillGrade(
      "skills_form:1",
      achievementType,
      +documentInfo?.data?.documentInfo?.registry?.id
    );
    return grade?.data;
  };
  const handleChange = async (event, index) => {
    const updatedSkills = [...selectedSkills];
    updatedSkills[index] = event.target.value;
    setSelectedSkills(updatedSkills);
    setChosenOptions({
      ...chosenOptions,
      [event.target.name]: event.target.value,
    });
    const selectedName = selectNames[index].name;
    const selectedValue = event.target.value;
    const grade = await getGrade(selectedValue);

    setChosenOptionsGrades((prevOptions) => {
      if (prevOptions.hasOwnProperty(selectedName)) {
        // Update the value if it exists
        return {
          ...prevOptions,
          [selectedName]: grade,
        };
      } else {
        // If it doesn't exist, create a new entry with the result of getGrade()
        return {
          ...prevOptions,
          [selectedName]: grade || "", // Assuming getGrade() returns the value you want
        };
      }
    });
  };

  const calculateMOs = (data) => {
    const gradeSum = Object.values(chosenOptionsGrades).reduce(
      (acc, num) => acc + num,
      0
    );
    const skillScoreMO = gradeSum / 6;
    const overall =
      0.5 * achievementScore.data + 0.4 * skillScoreMO + 0.1 * pulseScore.data;
    setSkillsScore(parseFloat(skillScoreMO?.toFixed(2)));
    setOverallScore(parseFloat(overall?.toFixed(2)));
  };
  useEffect(() => {
    if (documentInfoIsLoading) return;
    formContext.reset({
      referenceYear: documentInfo?.data?.referenceYear,
      startDate: documentInfo?.data?.startDate,
      endDate: documentInfo?.data?.endDate,
      meetingDate: documentInfo?.data?.meetingDate,
      submissionDate: documentInfo?.data?.submissionDate,
      formProtocolNumber: documentInfo?.data?.formProtocolNumber,
      skillToDevelop1:
        documentInfo?.data?.assessmentReportDetails?.[0]?.skillSelectionType?.id.toString(),
      skillToDevelop2:
        documentInfo?.data?.assessmentReportDetails?.[1]?.skillSelectionType?.id.toString(),
      skillToDevelop3:
        documentInfo?.data?.assessmentReportDetails?.[2]?.skillSelectionType?.id.toString(),
      developedSkill1:
        documentInfo?.data?.assessmentReportDetails?.[3]?.skillSelectionType?.id.toString(),
      developedSkill2:
        documentInfo?.data?.assessmentReportDetails?.[4]?.skillSelectionType?.id.toString(),
      developedSkill3:
        documentInfo?.data?.assessmentReportDetails?.[5]?.skillSelectionType?.id.toString(),
      achievementScore: documentInfo?.data?.achievementScore || achievementScore?.data.toFixed(2),
      overallScore: documentInfo?.data?.overallScore || overallScore?.toFixed(2),
      pulseScore: documentInfo?.data?.pulseScore || pulseScore?.data?.toFixed(2),
      skillsScore: documentInfo?.data?.skillsScore || skillsScore?.toFixed(2),
      grade0: documentInfo?.data?.assessmentReportDetails?.[0]?.grade,
      grade1: documentInfo?.data?.assessmentReportDetails?.[1]?.grade,
      grade2: documentInfo?.data?.assessmentReportDetails?.[2]?.grade,
      grade3: documentInfo?.data?.assessmentReportDetails?.[3]?.grade,
      grade4: documentInfo?.data?.assessmentReportDetails?.[4]?.grade,
      grade5: documentInfo?.data?.assessmentReportDetails?.[5]?.grade,
      reason0: documentInfo?.data?.assessmentReportDetails?.[0]?.notes,
      reason1: documentInfo?.data?.assessmentReportDetails?.[1]?.notes,
      reason2: documentInfo?.data?.assessmentReportDetails?.[2]?.notes,
      reason3: documentInfo?.data?.assessmentReportDetails?.[3]?.notes,
      reason4: documentInfo?.data?.assessmentReportDetails?.[4]?.notes,
      reason5: documentInfo?.data?.assessmentReportDetails?.[5]?.notes,
    });

    const filteredSkills = skills?.data["skill_selection_type"].filter(
      (skill) => skill.code !== "orientation_to_the_colleague"
    );

    filteredSkills &&
      setMappedSkills(
        filteredSkills.map((skill) => ({
          value: skill.id.toString(),
          label: skill.description,
        }))
      );
  }, [documentInfoIsLoading]);
  useEffect(() => {
    const fetchAndEvaluatorData = async () => {
      if (userInfo?.id) {
        const evaluationGroupResults = await getEvaluationGroupData(
          userInfo?.supervisorEvaluationGroup?.evaluationGroup.id ||
            +documentInfo?.data?.documentInfo?.registry?.id
        );
        const evaluator = evaluationGroupResults.registries.find(
          (registry) => registry.isEvaluationGroupSupervisor === true
        );

        setEvaluatorData(evaluator);
      } else {
        const evaluationGroupResults = await getRegistryById(
          +documentInfo?.data?.documentInfo?.registry?.id
        );
        evaluationGroupResults && setEvaluatorData(evaluationGroupResults);
      }
    };
    fetchAndEvaluatorData();
  }, [mappedSkills]);
  useEffect(() => {
    registryInfoDetails &&
      actionOfForm === "print" &&
      setTimeout(window.print(), 3000);
  }, []);
  const dataTransform = (data, documentInfo) => {
    const updateAssessmentReportDetails = (data) => {
      console.log(data, "data");
      const assessmentReportDetails = [];
      for (const key in chosenOptions) {
        const isForDevelopment = key.startsWith("developedSkill");
        const skillId = parseInt(data[key]);
        const grade = chosenOptionsGrades[key];
        const notes = data[`reason${skillId}`];

        const skillSelectionType = {
          id: skillId,
          code: null,
          description: skills?.data["skill_selection_type"].find(
            (skill) => skill.id === +skillId
          )?.description,
        };

        const reportDetail = {
          isForDevelopment,
          skillSelectionType,
          grade,
          notes,
        };

        assessmentReportDetails.push(reportDetail);
      }

      return assessmentReportDetails;
    };
    const transformedData = {
      ...documentInfo.data,
      id: +documentInfo?.data?.id,
      endDate: formatDateFNS(new Date(data.endDate)),
      startDate: formatDateFNS(new Date(data.startDate)),
      formProtocolNumber: data.formProtocolNumber,
      meetingDate: formatDateFNS(new Date(data.meetingDate)),
      documentInfo: {
        id: +documentInfo?.data?.documentInfo?.id,
        ...documentInfo.data.documentInfo,
      },
      achievementScore: parseFloat(achievementScore?.data?.toFixed(2)),
      skillsScore: parseFloat(skillsScore?.toFixed(2)),
      pulseScore: parseFloat(pulseScore?.data?.toFixed(2)),
      overallScore: parseFloat(overallScore?.toFixed(2)),
      assessmentReportDetails: updateAssessmentReportDetails(data),
    };
    return transformedData;
  };
  if (registryInfoDetailsIsLoading) return <Loader />;
  return (
    <>
      {registryInfoDetailsIsLoading ||
      achievementScoreIsLoading ||
      pulseScoreIsLoading ? (
        <Loader />
      ) : (
        <Box mt={17}>
          {referenceUserInfo && (
            <AppBreadcrumbs
              breadcrumbsList={[
                {
                  title: "Μητρώο Αξιολόγησης Προσωπικού",
                  url: "/personnel-registry-evaluation",
                },
                {
                  title: `Καρτέλα Αξιολογήσεων:  ${
                    referenceUserInfo?.surname
                  } ${referenceUserInfo?.name} - ${
                    referenceUserInfo?.afm || referenceUserInfo?.afm
                  }`,
                  url: `/evaluation-card/${referenceUserInfo?.id}`,
                },
                {
                  title: "Διενέργειες Αξιολογήσεων",
                  url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
                },
                {
                  title: `${title}:${registryInfoDetails?.data?.surname} ${registryInfoDetails?.data?.name} - ${registryInfoDetails?.data?.afm}`,
                },
              ]}
            />
          )}

          <PageTitle title={title} />
          <FormContainer
            formContext={formContext}
            onSuccess={(data) => {
              calculateMOs(data);
              setModalOpen(true);
              setFormData(dataTransform(data, documentInfo));
            }}
          >
            <fieldset
              disabled={
                actionOfForm === "preview" ||
                documentInfo?.data?.documentInfo?.isFinalized
              }
            >
              <Typography variant="h3"> Στοιχεία Αξιολογούμενου </Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <EmployeeCard data={registryInfoDetails?.data}>
                  <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
                </EmployeeCard>
              </Box>
              <h2>Στοιχεία Επίτευξης Στόχων</h2>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography>
                  Συνολική Βαθμολογία Επίτευξης Στόχων (Μ.Ο):
                </Typography>
                {!achievementScoreIsLoading && (
                  <TextFieldElement
                    sx={{ marginLeft: 2 }}
                    name={"achievementScore"}
                    value={achievementScore.data}
                    InputProps={{
                      placeholder: "Βαθμός",

                      readOnly: true,
                    }}
                  />
                )}
              </Box>
              <h2> Στοιχεία Αποτύπωσης Δεξιοτήτων</h2>

              {mappedSkills ? (
                <Box>
                  {selectNames.map((selectedName, index) => {
                    return (
                      <Box key={index}>
                        {index === 3 && <h3>Προς Ανάπτυξη:</h3>}
                        {index === 0 && <h3>Ανεπτυγμένες:</h3>}
                        <Typography
                          sx={{ marginY: 2, marginLeft: 1 }}
                        >{`${selectedName.label}:`}</Typography>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          required={index === 0}
                        >
                          <InputLabel>{selectedName.label}</InputLabel>
                          <Controller
                            name={selectedName.name}
                            value={chosenOptions[selectedName.name] || ""}
                            control={formContext.control}
                            render={({ field, fieldState }) => (
                              <Box key={selectedName.name}>
                                <Box
                                  key={selectedName.name}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box
                                    key={selectedName.name}
                                    sx={{
                                      display: "flex",
                                      width: "100%",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Select
                                      key={selectedName.name}
                                      fullWidth
                                      name={selectedName.name}
                                      label={selectedName.label}
                                      value={field.value}
                                      onChange={(event) => {
                                        field.onChange(event);
                                        handleChange(event, index);
                                      }}
                                      error={Boolean(fieldState?.error)}
                                      disabled={
                                        actionOfForm === "preview" ||
                                        documentInfo?.data?.documentInfo
                                          ?.isFinalized
                                      }
                                    >
                                      <MenuItem
                                        key={selectedName.name}
                                        value={chosenOptions[selectedName.name]}
                                      >
                                        Choose Option
                                      </MenuItem>
                                      {mappedSkills
                                        .filter(
                                          ({ value }) =>
                                            !isChosenByOther(
                                              value,
                                              selectedName.name
                                            )
                                        )
                                        .map(({ label, value }, oIndex) => (
                                          <MenuItem value={value} key={oIndex}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText
                                      key={selectedName.name}
                                      sx={{ color: "red" }}
                                    >
                                      {fieldState?.error?.message}
                                    </FormHelperText>
                                  </Box>
                                  <TextFieldElement
                                    key={selectedName.name}
                                    sx={{ marginLeft: 2 }}
                                    name={`grade${
                                      selectedSkills[index]
                                        ? selectedSkills[index]
                                        : index
                                    }`}
                                    value={
                                      chosenOptionsGrades[selectedName.name] ||
                                      ""
                                    }
                                    label="Βαθμός"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Box>
                                <TextFieldElement
                                  key={selectedName.name}
                                  sx={{ marginTop: 2, width: "125" }}
                                  name={`reason${
                                    selectedSkills[index]
                                      ? selectedSkills[index]
                                      : index
                                  }`}
                                  fullWidth
                                  label="Αιτιολογία"
                                />
                              </Box>
                            )}
                          />
                        </FormControl>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Loader />
              )}
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography>
                  Συνολική Βαθμολογία Αποτύπωσης Δεξιοτήτων (Μ.Ο):
                </Typography>
                <TextField
                  name={"skillsScore"}
                  sx={{ marginLeft: 2, width: "125" }}
                  value={parseFloat(skillsScore?.toFixed(2)) || ""}
                  InputProps={{
                    placeholder: "Βαθμός",
                    readOnly: true,
                  }}
                />
              </Box>
              {isManager && (
                <Box>
                  <h2>Στοιχεία Σφυγμού Ομάδας</h2>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography>
                      Συνολική Βαθμολογία Σφυγμού Ομάδας (Μ.Ο):
                    </Typography>
                    {!pulseScoreIsLoading && (
                      <TextFieldElement
                        name={"pulseScore"}
                        sx={{ marginLeft: 2, width: "125" }}
                        value={parseFloat(pulseScore?.data?.toFixed(2))}
                        InputProps={{
                          placeholder: "Βαθμός",
                          readOnly: true,
                        }}
                      />
                    )}
                  </Box>

                  <h2>Συνολική Βαθμολογία Αξιολογούμενου</h2>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography>
                      Συνολική Βαθμολογία = 0,5*Συνολική Βαθμολογία Επίτευξης
                      Στόχων + 0,4*Συνολική Βαθμολογία Αποτύπωσης Δεξιοτήτων +
                      0,1*Συνολική Βαθμολογία Σφυγμού Ομάδας:
                    </Typography>
                    <TextFieldElement
                      sx={{ marginLeft: 2, width: "125" }}
                      name={"overallScore"}
                      value={overallScore || ""}
                      InputProps={{
                        placeholder: "Βαθμός",

                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box sx={{ marginY: 6 }}>
                <Typography variant="h3"> Στοιχεία Αξιολογητή </Typography>
                {evaluatorData && (
                  <Box display="flex" justifyContent="center" my={2}>
                    <EmployeeCard data={evaluatorData}>
                      <RegistrationEmployeeInfo data={evaluatorData} />
                    </EmployeeCard>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  marginY: 3,
                }}
              >
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => router.navigate(-1)}
                >
                  <span>Επιστροφή</span>
                </Button>
                {actionOfForm === "edit" &&
                  !documentInfo?.data?.documentInfo?.isFinalized && (
                    <Button type="submit" variant="contained">
                      <span>Οριστικοποίηση</span>
                    </Button>
                  )}
              </Box>
            </fieldset>
          </FormContainer>
          {modalOpen && (
            <ReportModal
              setModalOpen={setModalOpen}
              setConfirmModal={setConfirmModal}
              isManager={isManager}
              overallScore={parseFloat(overallScore?.toFixed(2))}
              achievementScore={parseFloat(achievementScore?.data?.toFixed(2))}
              skillsScore={parseFloat(skillsScore?.toFixed(2))}
              onConfirm={async () => {
                await handlePostRequest(formData);
              }}
            />
          )}
          <Box>
            {confirmModal && (
              <ReportConfirmModal
                setDenyModal={setDenyModal}
                setConfirmModal={setConfirmModal}
                employeeId={documentInfo?.data?.documentInfo?.registry?.id}
              />
            )}

            {denyModal && (
              <ReportDenyModal
                setDenyModal={setDenyModal}
                setConfirmModal={setConfirmModal}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default ReportWrapper;
