import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const serviceChangeDetailsColumns = (setIsOpenDeleteModal,registriesLength,setIds,isKATAXORITIS)=> 
[
  {
    field: "fullname",
    headerName: "Ονοματεπώνυμο",
    width: 150,
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography>{`${params.row.name} ${params.row.surname}`}</Typography>
    ),
  },
  {
    field: "afm",
    headerName: "ΑΦΜ",
    flex: 1,
  },
  {
    field: "position",
    headerName: "Θέση",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.serviceAccount?.position?.description || ""}
      </Typography>
    ),
  },
  {
    field: "typeOfService",
    headerName: "Υπηρεσιακή Μεταβολή",
    flex: 1,
   
  },
  {
    field: "orderNumber",
    headerName: "Α.Π Διαταγής",
    flex: 1,
  },
  {
    field: "institution",
    headerName: "Κατάστημα Κράτησης",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.serviceAccount?.institute?.description || ""}
      </Typography>
    ),
  },
  {
    field: "notes",
    headerName: "Παρατηρήσεις",
    flex: 1,
  },
 {
    field: "action",
    headerName: "Ενέργεια",
    width: 70,
    renderCell: (params) => 
      { isKATAXORITIS && <Button disabled={registriesLength<2 && !params.row.isKataxoritis}  onClick={()=>{setIsOpenDeleteModal(true); setIds({employeeId:params.row.id,serviceChangeId:params.row.serviceChangeId})}}>   <DeleteIcon /> 
      </Button> }
    ,

  },
];

export const serviceChangeColumns = [
  {
    id: "serviceDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: true,
  },
  {
    id: "typeOfService",
    label: "Υπηρεσιακή Μεταβολή",
    minWidth: 170,
    isClickable: false,
  },
];