import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  marginLeft: '10px',
  marginBottom: '20px',
  borderRadius: '20px',
  '& .MuiAccordion-root:before': {
    backgroundColor: 'white',
  },
  '& .MuiAccordionSummary-content': {
    marginBottom: '12px',
  },
  '&.Mui-expanded': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
  '&.MuiAccordion-root': {
    // Add any additional styles for the Accordion root here
  },
}));

export default function AppAccordion(props) {
  const { id, title, details, onChange } = props;

  return (
    <CustomAccordion onChange={onChange} square={true} defaultExpanded disableGutters>
      <AccordionSummary sx={{ bgcolor: 'gray.light', borderRadius: '20px' }} expandIcon={<ExpandMoreOutlined />} id={id}>
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </CustomAccordion>
  );
}
