import * as yup from "yup";

export const educationLevelFieldValidation = yup.object().shape({
  startDate: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό")
    .test('startDateBeforeEndDate', 'Η ημερομηνία έναρξης πρέπει να είναι πριν την ημερομηνία λήξης', function(value) {
      const endDate = this.resolve(yup.ref('endDate'));
      return !endDate || value <= endDate;
    }),
    
  endDate: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
    certificationTitle: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    educationLevel: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    certificationGrade: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  });
