import * as yup from "yup";

const developmentValidation = (stage) => {
  const allValues=[];

    const skillsData = { 
        description1: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
        implementation1: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped1: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[0] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method1: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description2: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation2: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped2: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[1] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method2: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description3: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation3: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped3: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[2] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method3: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description4: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation4: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped4: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[3] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method4: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description5: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation5: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped5: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[4] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method5: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description6: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation6: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped6: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[5] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method6: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description7: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation7: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped7:  yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[6] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method7: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        description8: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        implementation8: yup.string().transform((originalValue, originalObject) => {
            return originalValue ? originalValue.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
        isDeveloped8: yup.mixed().test({
          message: "Πρέπει να επιλέξετε μέχρι τρία πεδία ως 'Αναπτυγμένα'",
          test: function (value) {
            allValues[7] = value;
            const developedCount = allValues.filter((item) => item === "developed").length;
            return developedCount <= 3;
          },
        }).required("Το πεδίο είναι υποχρεωτικό"),
        method8: yup.string().transform((originalValue) => {
            return originalValue ? originalValue?.trim() : originalValue;
          }).required("Το πεδίο είναι υποχρεωτικό"),
}

  switch (stage) {
    case "stage:1": {
      return yup.object().shape({
        startDate: yup
          .date()
          .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
          .required("Το πεδίο είναι υποχρεωτικό"),
        endDate: yup
          .date()
          .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
          .required("Το πεδίο είναι υποχρεωτικό"),
        formProtocolNumber: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
        meetingDate: yup
        .date()
        .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
        .required("Το πεδίο είναι υποχρεωτικό"),
        ...skillsData
      });
    }
    case "stage:2": {
        return yup.object().shape({
          startDate: yup
            .date()
            .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
            .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
            .required("Το πεδίο είναι υποχρεωτικό"),
          endDate: yup
            .date()
            .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
            .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
            .required("Το πεδίο είναι υποχρεωτικό"),
          reviewDate: yup
          .date()
          .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
          .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
          .required("Το πεδίο είναι υποχρεωτικό"),
          ...skillsData

        });
      }
      case "stage:3": {
        return yup.object().shape({
          startDate: yup
            .date()
            .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
            .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
            .required("Το πεδίο είναι υποχρεωτικό"),
          endDate: yup
            .date()
            .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
            .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
            .required("Το πεδίο είναι υποχρεωτικό"),
            evaluationDate: yup
            .date()
            .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
            .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
            .required("Το πεδίο είναι υποχρεωτικό"),
            ...skillsData

        });
      }
      default: 
  }
};

export default developmentValidation;
