import MultipleRegistriesWrapper from "./MultipleRegistriesWrapper";
import SwitchRegistrationForm from './../Registrations/Switches/SwitchRegistrationForm';
import useAuth from "../../hooks/useBackAuth";

const MultipleSwitchesRegistries = () => {
  const { userInfo} = useAuth()

  return (
    <MultipleRegistriesWrapper
      title={"Μεταγωγής"}
      department={userInfo?.serviceAccount?.institute?.description}
      FormComponent={SwitchRegistrationForm}
    />
  );
};
export default MultipleSwitchesRegistries;
