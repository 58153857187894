import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  deleteSwitchHistory,
  getSwitchHistory,
} from "../../../services/history.service";
import RegistryDetailedHistoryWrapper from "../RegistryDetailedHistoryWrapper";
import { SwitchDetailsColumns } from "./SwitchColumnsConfig";
import DeleteModal from "../DeleteModal";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const SwitchDetailedHistory = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const date = useParams().date;
  const { userInfo, hasRole } = useAuth()
  const switchId = useParams().id;
  const [guardType, setGuardType] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [registriesLength, setRegistriesLength] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [ids, setIds] = useState();

  const onDelete = () => {
    deleteSwitchHistory(ids.switchId, ids.employeeId);
    setIsUpdated(true);
  };
  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getSwitchHistory(
        0,
        10,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id, date,
        guardType && guardType
      );
      allRegistries.data.length > 0
        ? setSwitchData(allRegistries.data)
        : setData([]);
      setIsLoading(false);
      setIsUpdated(false);
    };

    fetchAndSetHistoryRegistries();
  }, [date, guardType, isUpdated, registriesLength]);
  const setSwitchData = (data) => {
    const transfer = data.find((transfer) => (transfer.id === +switchId));
    transfer ? setData(
      transfer.registries.map((employee) => ({
        ...employee,
        id: employee.id,
        switchId: transfer.id,
        transferType: transfer.transferType.description,
        notes: transfer.notes,
      }))
    ) : setData([])
    transfer && setRegistriesLength(transfer.registries.length);

  }
  return (
    <>
      <RegistryDetailedHistoryWrapper
        breadcrumbs={[
          {
            title: "Μεταγωγών",
            url: "/history/switches",
          },
          {
            title: "Μεταγωγών",
            url: "",
          },
        ]}
        title={"Μεταγωγές "}
        department={userInfo?.serviceAccount?.institute?.description}
        date={date}
        columns={SwitchDetailsColumns(
          setIsOpenDeleteModal,
          registriesLength,
          setIds
        )}
        isLoading={isLoading}
        rows={data}
        guardType={guardType}
        setGuardType={setGuardType}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          setIsShownModal={setIsOpenDeleteModal}
          onDelete={onDelete}
        />
      )}
    </>
  );
};
export default SwitchDetailedHistory;
