import { Box, Button } from "@mui/material";
import AppModal from "./AppModal";

const SaveModal = (props) => {

  return (
    <AppModal onHideModal={() => props.setIsShownSaveModal(false)}>
            <Box sx={{ zIndex: "1000" }}>
        <h5>Είστε σίγουρος ότι τα στοιχεία είναι αληθή και σωστά;</h5>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 6,
          }}
        >
          <Button onClick={() => props.setIsShownSaveModal(false)}>
            Ακύρωση
          </Button>
          <Button onClick={props.onSave} type="submit" variant="contained">
            Αποθήκευση
          </Button>
        </Box>
      </Box>
          </AppModal>
  );
};
export default SaveModal;
