import HttpService from "../httpClient";
export const getEducationLevelRegistries = (
    page,
    size,
    registryId
  ) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .get(`/registry-educational-level`, {
          params: {
            page,
            size,
            "registryId.equals":registryId,
          },
        })
        .then(function (response) {
          const allRegistries = {
            data: response.data,
            count: +response.headers["x-total-count"],
          };
          resolve(allRegistries);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
export const addNewEducationLevelRegistry = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-educational-level`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  export const deleteEducationLevelRegistryById = (registryEducationalLevelId,registryId) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .delete(`/registry-educational-level/${registryEducationalLevelId}`)
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };