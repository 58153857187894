import { formatDateFNS } from "./formatDates";

export const amrmodiotitesMutateMapper = (data, registryInfoDetails, dataContext, caseInfo) => {
    const body = {
        id: caseInfo?.data?.id,
        referenceYear: data.referenceYear,
        startDate: formatDateFNS(new Date(data.startDate)),
        endDate: formatDateFNS(new Date(data.endDate)),
        submissionDate: formatDateFNS(new Date(data.submissionDate)),
        formProtocolNumber: data.formProtocolNumber,
        documentInfo: caseInfo?.data?.documentInfo,
        responsibilities: Object.keys(data.nested).map((key) => {
            const id = key.split('_')[1]
            return {
                id: caseInfo?.data?.documentInfo?.isFinalized ? caseInfo?.data?.responsibilities?.find((item) => item?.responsibilityType?.id == id)?.id : undefined,
                responsibilityType: {
                    id: id,
                    code: dataContext?.find((item) => item?.id == id)?.code
                },
                isPositive: data.nested[key] == '2'
            }
        })
    }
    return body;
}

export const reverseAmrmodiotitesMutateMapper = (body) => {
    const {
        year,
        startDate,
        endDate,
        documentType,
        submissionStage,
        registry,
        position,
        institution,
        evaluationGroup,
    } = body.documentInfo;

    const referenceYear = year;
    const chronicalStartDate = formatDateFNS(startDate);
    const chronicalEndDate = formatDateFNS(endDate);

    const nested = {};
    body.responsibilities.forEach((responsibility) => {
        const id = responsibility.responsibilityType.id;
        const key = `_${id}`;
        nested[key] = responsibility.isPositive ? '2' : '1';
    });

    const data = {
        referenceYear,
        startDate: chronicalStartDate,
        endDate: chronicalEndDate,
        nested,
    };

    return data;
};
