import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

export default function GradeDisplay({ label = "Συνολική Βαθμολογία", grade }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: 500,
                    height: 128,
                },
            }}
        >
            <Paper elevation={3}>
                <Typography variant='h2' display='flex' justifyContent='center' alignItems='center' alignContent='center' height='100%'>
                    {label}: {grade}
                </Typography>
            </Paper>
        </Box>
    );
}