import { Typography } from "@mui/material";

export function NoRowsOverlay() {
  return (
    <Typography
      sx={{
        marginTop: 7,
        position: "relative",
        bottom: 27,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      Δεν βρέθηκαν δεδομένα προς προβολή.
    </Typography>
  );
}
export const formatDate = (date) => {
  const parts = date.split("/");
  const year = parts[2];
  const month = parts[1].padStart(2, "0"); // Add leading zero if needed
  const day = parts[0].padStart(2, "0"); // Add leading zero if needed

  return `${year}-${month}-${day}`;
};
export const convertDate = (dateString) =>{

const date = new Date(dateString);
const day = date.getDate();
const month = date.getMonth() + 1; 
const year = date.getFullYear();

return (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;

}
export const years = [
  { id: 2024, label: "2024" },
  { id: 2023, label: "2023" },
  { id: 2022, label: "2022" },
  { id: 2021, label: "2021" },
  { id: 2020, label: "2020" },
  { id: 2019, label: "2019" },
  { id: 2018, label: "2018" },
  { id: 2017, label: "2017" },
  { id: 2016, label: "2016" },
  { id: 2015, label: "2015" },
  { id: 2014, label: "2014" },
  { id: 2013, label: "2013" },
  { id: 2012, label: "2012" },
  { id: 2011, label: "2011" },
  { id: 2010, label: "2010" },
  { id: 2009, label: "2009" },
  { id: 2008, label: "2008" },
  { id: 2007, label: "2007" },
  { id: 2006, label: "2006" },
  { id: 2005, label: "2005" },
  { id: 2005, label: "2005" },
  { id: 2004, label: "2004" },
  { id: 2003, label: "2003" },
  { id: 2002, label: "2002" },
  { id: 2001, label: "2001" },
  { id: 2000, label: "2000" },
];

export const getAchievementType = (skillId) => {
  switch (skillId) {
    case 17451:
      return "orientation_to_the_colleague";
    case 17501:
      return "teamwork";
    case 17551:
      return "adaptability";
    case 17601:
      return "result_orientation";
    case 17651:
      return "organisation_and_planning";
    case 17701:
      return "problem_solving_and_creativity";
    case 17751:
      return "professionalism_and_integrity";
    case 174801:
      return "knowledge_management";
    case 17851:
      return "leadership";
    default:
      console.log(skillId);
  }
};
export const getIsTimerFinished = (docType, subStage, timeArray) => {
  if (Array.isArray(timeArray)) {
    const timeObj = timeArray.find((item) => item.documentType === docType);
    switch (subStage) {
      case "stage:1":
        return isDateGreaterThanToday(timeObj.endDateFirstStage);
      case "stage:2":
        return isDateGreaterThanToday(timeObj.endDateSecondStage);
      case "stage:3":
        return isDateGreaterThanToday(timeObj.endDateThirdStage);
      default:
        console.log(timeObj);
    }
  } 
};

const isDateGreaterThanToday = (givenDate) => {
  const today = new Date();
  const parsedGivenDate = new Date(givenDate);
  return parsedGivenDate.getTime() > today.getTime();
};
