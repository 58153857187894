import { Box, Button } from "@mui/material";
import AppModal from "../../../../components/UI/Modal/AppModal";
import { useNavigate } from "react-router-dom";

const ReportConfirmModal = ({ setConfirmModal, setDenyModal, employeeId }) => {
  const navigate = useNavigate();

  return (
    <AppModal onHideModal={() => setConfirmModal(false)}>
      <Box>
        {
          "Θέλετε να δημιουργήσετε Σχέδιο Ανάπτυξης για το επόμενο έτος αναφοράς;"
        }
        <Box
          sx={{
            marginY: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 3,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setConfirmModal(false);
              setDenyModal(true);
            }}
          >
            <span>'Οχι</span>
          </Button>

          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              setConfirmModal(false);
              navigate(`/evaluation/growth_plan/${employeeId}`);
            }}
          >
            <span>Ναι</span>
          </Button>
        </Box>
      </Box>
    </AppModal>
  );
};
export default ReportConfirmModal;
