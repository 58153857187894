import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../../App.css";

import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useNavigate, useParams } from "react-router-dom";
import Details from "../../../components/Details/Details";
import AppAccordion from "../../../components/UI/AppAccordion";
import FormDatePicker from "../../../components/UI/FormDatePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";
import {
  addNewServiceActionRegistries,
  addNewServiceActionRegistry,
} from "../../../services/Registrations/ServiceActions.service";
import {
  getActionTypesDropdown,
  getGradeInstitutionPositionDropdown,
} from "../../../services/dropdownData.service";
import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import {
  defaultServiceActionRegistrationValues as defaultValues,
  formServiceActionRegistrationSchema as formSchema,
} from "./serviceActionRegistrationConfig";
import { serviceChangeActionsValidation } from "./serviceActionsFieldValidation";
import {
  registrationDetailsTransformer,
  transformServiceActionDataToBEFormat,
} from "../dataTransformFunctions";
import {
  transformedMultipleRegistryData,
  transformServiceActionMultipleDataToBEFormat,
} from "./../../MultipleRegistrations/multipleDataTransformFunctions";
import { NoRowsOverlay } from "../../../helperFunctions";

const ServiceActionRegistrationForm = ({
  data,
  isMultipleRegistration,
  guardType,
  selectedPeople,
}) => {
  const navigate = useNavigate();
  const employeeId = useParams().employeeId;
  const [actionTypes, setActionTypes] = useState();
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [actionOptions, setActionOptions] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [multipleRegistriesData, setMultipleRegistriesData] = useState();
  const [configObjects, setConfigObjects] = useState();
  const [date, setDate] = useState();
  const registry = {
    id: data?.id,
    name: data?.name,
    surname: data?.surname,
    fathersName: data?.fathersName,
    fathersSurname: data?.fathersSurname,
    mothersName: data?.mothersName,
    mothersSurname: data?.mothersSurname,
    dateOfBirth: data?.dateOfBirth,
    placeOfBirth: data?.placeOfBirth,
    adt: data?.adt,
    issuingAuthority: data?.issuingAuthority,
    afm: data?.afm,
    bloodType: data?.bloodType,
    amka: data?.amka,
    payrollAccountNumber: data?.payrollAccountNumber,
    iban: data?.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };

  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const actionTypesOptions = await getActionTypesDropdown();
      const configObj = await getGradeInstitutionPositionDropdown();
      setConfigObjects(configObj);
      setActionTypes(actionTypesOptions);
      setActionOptions(
        actionTypesOptions["action_type"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };
    fetchBEConfigInfo();
  }, []);
  const formContext = useForm({
    resolver: yupResolver(serviceChangeActionsValidation),
    defaultValues: defaultValues,
  });
  const onSave = () => {
    const data = isMultipleRegistration
      ? transformServiceActionMultipleDataToBEFormat(
          multipleRegistriesData?.rows[0],
          {
            ...configObjects,
            actionTypes,
          },
          selectedPeople,
          guardType
        )
      : transformServiceActionDataToBEFormat(formData, actionTypes);
    actionTypes && isMultipleRegistration
      ? addNewServiceActionRegistries(data)
      : addNewServiceActionRegistry(data);
    setIsShownSaveModal(false);
    isMultipleRegistration
      ? navigate("/personnel-management")
      : navigate(`/employee-info/${employeeId}`);
  };

  return (
    <>
      {!isPreviewMode && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (serviceActionData) => {
            setDate(
              serviceActionData.serviceActionDate.toLocaleString().split(",")[0]
            );
            const typeOfServiceActionDescription = actionOptions.find(
              (option) => option.id === +serviceActionData.serviceActionType
            )?.label;
            const transformedData = isMultipleRegistration
              ? transformedMultipleRegistryData(
                  {
                    ...serviceActionData,
                    serviceActionType: typeOfServiceActionDescription,
                  },
                  formSchema,
                  selectedPeople
                )
              : registrationDetailsTransformer(formSchema, {
                  ...serviceActionData,
                  serviceActionType: typeOfServiceActionDescription,
                  userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
                  userPosition: data.serviceAccount.position.description,
                });
            isMultipleRegistration
              ? setMultipleRegistriesData(transformedData)
              : setFormData({
                  ...serviceActionData,
                  registry,
                  institution: data.serviceAccount.institute,
                  position: data.serviceAccount.position,
                });

            !isMultipleRegistration && setPreviewData(transformedData);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.serviceActionDate.label}>
              <FormDatePicker
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.serviceActionDate.name}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.serviceActionType.label}>
              {actionOptions && (
                <SelectElement
                  fullWidth
                  label={formSchema.serviceActionType.label}
                  name={formSchema.serviceActionType.name}
                  options={actionOptions}
                />
              )}
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && previewData && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
          <h4>{`Υπηρεσιακές Ενέργειες ${date}`}</h4>
          {isMultipleRegistration && multipleRegistriesData ? (
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              rows={multipleRegistriesData.rows}
              columns={multipleRegistriesData.columns.map((column) => ({
                ...column,
                cellClassName: "dataGridCell",
              }))}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          ) : (
            <Details
              renderFirstRowsAsHeader
              renderArraysAsColumns
              rows={[
                {
                  name: previewData.labels[0],
                  value: previewData.labels.slice(1, previewData.length),
                },
                {
                  name: previewData.values[0],
                  value: previewData.values.slice(1, previewData.length),
                },
              ]}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ServiceActionRegistrationForm;
