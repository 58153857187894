import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Theme from './Theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import HttpService from './services/httpClient';
import { SnackbarProvider } from 'notistack';
import UserService from './services/userService';
import useAuth, { useGetUserInfo } from './hooks/useBackAuth';


const InitAuth = () => {
  useGetUserInfo({refetch: true});
  HttpService.configure()
}
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
HttpService.configure()
const renderRoot = () => root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>

    <ThemeProvider theme={Theme}>
      <SnackbarProvider>
        <InitAuth />
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </QueryClientProvider>

  //</React.StrictMode> 
);


const onFailedKeycloakInit = () => {
  return <>
    <h1 style={{ textAlign: 'center', }}>
      Failed To Connect To Authorization Service Provider
      Contact Your Admin
    </h1>
  </>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// renderRoot()
UserService.initKeycloak(renderRoot)
// .catch((err) => {
//   console.log('jaa', err);
//   onFailedKeycloakInit()
// });