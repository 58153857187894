import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React,
{ useState } from 'react';

export default function GenericModal({ title = "",
    AgreeButtonProps = {},
    agreeButtonTitle = "Υποβολή",
    disagreeButtonTitle = "Ακύρωση",
    buttonTitle = "",
    dialogContent = '',
    isValid=true,
    
    onAccept = () => { },
    children }) {
    const [open,
        setOpen] = useState(false);

    const handleClickOpen = () => {
        isValid && setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        onAccept()
        handleClose()
    }

    const ButtonFromChildren = children ? React.cloneElement(children,
        { onClick: handleClickOpen }) : null
    return (
        <>
            {ButtonFromChildren ?? <Button variant="outlined" onClick={handleClickOpen}>
                {buttonTitle}
            </Button>}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>{disagreeButtonTitle}</Button>
                    <Button
                        type="submit"
                        {...AgreeButtonProps}
                        onClick={handleSubmit} autoFocus>
                        {agreeButtonTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
