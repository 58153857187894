import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AppAccordion from "../../components/UI/AppAccordion";
import RadioGroupGenerator from "../../components/UI/AppRadioGroup";
import CustomIconBox from "../../components/UI/CustomIconBox";
import Loader from "../../components/UI/Loader";
import { NoRowsOverlay } from '../../helperFunctions';
import '../../App.css'
const RowSelectionTables = ({ paginationModel, setPaginationModel, count,title, isLoading, setGuardType, guardType, columns, rows, setSelectedPeople, selectedPeople}) => {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const guardTypes = [
    { id: "internal", title: "Εσωτερικής Φρούρησης" },
    { id: "external", title: "Εξωτερικής Φρούρησης" },
  ];
  const [rowCountState, setRowCountState] = useState(
    count || 0,
  );


  useEffect(() => {
    setRowCountState((prevRowCountState) =>
    count !== undefined
      ? count
      : prevRowCountState,
  );
    setSelectedPeople(
      rowSelectionModel.map((selectedId) => {
        const row = rows.find((item) => item.id === selectedId);
        const { id, ...rest } = row;
        return { id, ...rest };
      })
    );

  }, [rowSelectionModel, rows, paginationModel, count, setRowCountState]);

  return (
      <div style={{ width: "100%" }}>
        <Box sx={{ display: "flex", marginTop: 5, marginBottom:5 }}>
          <Box>
            <CustomIconBox icon={<PeopleIcon />} />
          </Box>
          <AppAccordion
            id="employees"
            title="Επιλογή Προσωπικού"
            details={
              <Box
                sx={{
                  width: "100%",
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "lightgrey",
                    fontSize: 16,
                  },
                }}
              >
                <RadioGroupGenerator
                  name="guardType"
                  onChange={(e) => setGuardType(e.target.value)}
                  items={guardTypes}
                  value={guardType}
                />
                <Typography sx={{ paddingY: 2, color: "grey" }}>
                  {` Διαθέσιμο Προσωπικό  ${
                    guardType
                      ? guardTypes.find((type) => type.id === guardType)
                          ?.title
                      : ""
                  }`}{" "}
                </Typography>
                <Box>
                  {isLoading && <Loader />}
                </Box>

                {!isLoading && !guardType ? <Typography>Παρακαλώ επιλέξτε είδος φρούρησης</Typography> : !isLoading && guardType && (
                  <DataGrid
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    rowCount={rowCountState}
                    loading={isLoading}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 25]}
                    rows={rows}
                    columns={columns.map((column) => ({
                      ...column,
                      cellClassName: 'dataGridCell', 
                    }))}
                    slots={{
                      noRowsOverlay: NoRowsOverlay,
                    }}
                    sx={{
                      backgroundColor: "white",
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          display: "none",
                        },
                    }}
                  ></DataGrid>
                )}
              </Box>
            }
          />
        </Box>

        <Box sx={{ display: "flex", width:'96%' }}>
          <Box>
            <CustomIconBox icon={<PersonAddIcon />} />
          </Box>
          <AppAccordion
            id="chosenEmployees"
            title={`Προσθήκη ${title}`}
            details={
              <Box
                sx={{
                  width: "100%",
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "lightgrey",
                    fontSize: 16,
                  },
                }}
              >
                <Typography sx={{ color: "grey" }}>
                  {` Επιλεγμένο  Προσωπικό  ${
                    guardType
                      ? guardTypes.find((type) => type.id === guardType)
                          ?.title
                      : ""
                  }`}{" "}
                </Typography>
                <DataGrid
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={selectedPeople}
                  columns={columns.map((column) => ({
                    ...column,
                    cellClassName: 'dataGridCell', 
                  }))}
                  sx={{
                    width:'100%',
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                      {
                        display: "none",
                      },
                  }}
                  slots={{
                    noRowsOverlay: NoRowsOverlay,
                  }}
                />
                <Box sx={{display:'flex', justifyContent:"center"}}>


                </Box>

              </Box>
            }
          />
        </Box>
      </div>
  );
};

export default RowSelectionTables;
