import * as yup from "yup";

export const servicePositionFieldValidation = yup.object().shape({
  servicePositionDate: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της τρέχουσας ημερομηνίας")
    .required("Το πεδίο είναι υποχρεωτικό"),
    servicePositionType: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    serviceOrder: yup.string().matches(/^Α.Π./,  "Το πεδίο πρεπει να ξεκινα με Α.Π.")
    .min(5, "Το πεδίο είναι υποχρεωτικό")    
    .required("Το πεδίο είναι υποχρεωτικό"),});
