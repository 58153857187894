import { TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../components/Details/utils";
import { TextField } from "@mui/material";

function Case1() {
  return (
    <>
      <TextFieldElement
        fullWidth
        label="Ονομασία Στόχου*  "
        name="stage2.targetName"
      />

      <TextFieldElement
        fullWidth
        label="Τιμές Δείκτών Μέτρησης Προόδου"
        name="stage2.measurementIndicator"
      />

      <TextFieldElement
        fullWidth
        label="Περιγραφή της Προόδου"
        name="stage2.description"
      />

      <TextFieldElement label="Παρατηρήσεις" name="stage2.notes" />
      <TextField
        label="Ημερομηνία"
        disabled={true}
        value={defaultRenderDates(new Date())}
      />
    </>
  );
}

export default Case1;
