import * as yup from "yup";

export const educationFieldValidation = yup.object().shape({
  startDate: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Μέχρι σήμερα")
    .required("Το πεδίο είναι υποχρεωτικό")
    .test('startDateBeforeEndDate', 'Η ημερομηνία έναρξης πρέπει να είναι πριν την ημερομηνία λήξης', function(value) {
      const endDate = this.resolve(yup.ref('endDate'));
      return !endDate || value <= endDate;
    }),
  endDate: yup
    .date()
    .typeError("Πρέπει να Συμπληρώσετε μία έγκυρη ημερομηνία")
    .max(new Date(), "Μέχρι σήμερα")
    .required("Το πεδίο είναι υποχρεωτικό"),
  typeOfEducation: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  durationInHours: yup
    .number()
    .positive("Η διάρκεια πρέπει να είναι ένας θετικός αριθμός"),
      educationTitle: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
});
