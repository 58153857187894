import { Box } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import FormDatePicker from "../../components/UI/FormDatePicker";

const CardItem = (props) => {
  const { name, label, value, editable, isEditing } = props;
  const getValue =(value)=>{
    if(value === 'Internal Guard' ){
      return'Εσωτερική Φρούρηση' 
    } 
    if(value === 'External Guard' ){
      return 'Εξωτερική Φρούρηση' 
    } 
    else {
      return value
    }
  }
  return (
    <>
      {(!isEditing && name) || (name && !editable) ? (
        <Box
          key={name}
          sx={{
            height: 30,
            display: "grid",
            gridTemplateColumns: "1fr 1fr", 
            alignItems: "center",
          }}
        >
          <h3 style={{ margin: 0 }}>{label}</h3>
          <p>{getValue(value)}</p>
        </Box>
      ) : isEditing &&
        name &&
        editable &&
        name.toString().toLowerCase().includes("date") ? (
        <Box
          key={name}
          sx={{
            height: 45,
            display: "flex",
            width: 220,
            marginBottom: 2,
            paddingX: 2.3,
          }}
        >
          {" "}
          <FormDatePicker key={name} name={name} placeholder={value} />
        </Box>
      ) : (
        <TextFieldElement
          sx={{ margin: 2, paddingRight: 15 }}
          key={name}
          name={name}
          value={value}
          label={label}
          placeholder={value}
        />
      )}
    </>
  );
};

export default CardItem;
