export const groupPulseFormColumnConfig = [
  {
    id: "referenceYear",
    label: "Έτος Αναφοράς",
    minWidth: 170,
    isClickable: false,
  },
    {
      id: "institution.description",
      label: "Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "action",
      label: "Ενέργεια",
      minWidth: 170,
      isClickable: false,
    },
  ];