import { Box, InputLabel } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import FormDatePicker from "../../components/UI/FormDatePicker";

const ChildData = (props) => {
  const fieldNames = Object.keys(props.values);
  const keyIndexes = [0, 1, 2];
  return (
    <Box sx={{ width: "100%" }} key={Math.random()}>
      {keyIndexes.map((fieldName) => (
        <Box key={fieldName}>
          <InputLabel
            key={fieldName}
            sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
            id={props.values[fieldNames[fieldName]].name}
          >
            {props.values[fieldNames[fieldName]].label}
          </InputLabel>
          {props.values[fieldNames[fieldName]].name
            .toString()
            .toLowerCase()
            .includes("date") ? (
            <FormDatePicker
              key={Math.random()}
              readOnly={props.isPreviewMode}
              name={props.values[fieldNames[fieldName]].name}
            />
          ) : (
            <TextFieldElement
              key={props.values[fieldNames[fieldName]].name}
              fullWidth
              inputProps={{
                readOnly: props.isPreviewMode,
              }}
              sx={{
                input: { color: props.isPreviewMode ? "grey" : "inherit" },
              }}
              id={props.values[fieldNames[fieldName]].name}
              name={props.values[fieldNames[fieldName]].name}
              value={props.values[fieldNames[fieldName]].name}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
export default ChildData;
