import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import { getRegistryById } from "../../services/PersonnelManagement.service";
import { getEducationRegistriesById } from "../../services/Registrations/Education.service";
import { deleteEducationLevelRegistryById, getEducationLevelRegistries } from "../../services/Registrations/EducationLevel.service";
import { deleteIntangibleRewardRegistryById, getIntangibleRewardRegistries } from "../../services/Registrations/IntangibleRewards.service";
import { getOvertimeRegistriesById } from "../../services/Registrations/Overtimes.service";
import { getServiceActionRegistriesById } from "../../services/Registrations/ServiceActions.service";
import { deleteServiceChangeRewardRegistryById, getServiceChangeRegistriesById } from "../../services/Registrations/ServiceChanges.service";
import {
  deleteServiceGradeRegistryById,
  getServiceGradeRegistries,
} from "../../services/Registrations/ServiceGrade.service";
import {
  deleteServicePositionRegistryById,
  getServicePositionRegistries,
} from "../../services/Registrations/ServicePosition.service";
import { getSwitchesRegistriesById } from "../../services/Registrations/Switches.service";
import { educationsLevelColumns } from "../Registrations/EducationLevel/educationLevelRegistrationConfig";
import { serviceChangesColumns } from "../Registrations/ServiceChanges/serviceChangeRegistrationConfig";
import { serviceGradeColumns } from "../Registrations/ServiceGrade/serviceGradeRegistrationConfig";
import { servicePositionColumns } from "../Registrations/ServicePosition/servicePositionRegistrationConfig";
import Loader from "./../../components/UI/Loader";
import { educationsColumns } from "./../Registrations/Education/educationRegistrationConfig";
import { intangibleRewardsColumns } from "./../Registrations/IntangibleRewards/intangibleRewardRegistrationConfig";
import { overTimeColumns } from "./../Registrations/Overtimes/overtimeRegistrationConfig";
import { serviceActionsColumns } from "./../Registrations/ServiceActions/serviceActionRegistrationConfig";
import { switchesColumns } from "./../Registrations/Switches/switchRegistrationConfig";
import EmployeeCard from "./EmployeeCard";
import EmployeeInfo from "./EmployeeInfo";
import TableAccordionItem from "./TableAccordionItem";
import useAuth from "../../hooks/useBackAuth";
import AppModal from "../../components/UI/Modal/AppModal";
const EmployeeRegistry = () => {
  const employeeId = useParams().employeeId;
  const [userData, setUserData] = useState();
  const [isUserUpdated, setIsUserUpdated] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const { hasRole } = useAuth();

  useEffect(() => {
    const fetchAndSetUserData = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
    };

    fetchAndSetUserData();
  }, [employeeId, isUserUpdated]);
  const getActionIcon = (action) => [
    {
      id: "delete",
      icon: <DeleteIcon />,
      onClickFunc: async (row) => 
      {
        try {
          await action(row.id, employeeId);
        } catch (error) {
          setModalContent("Δεν είναι δυνατή η διαγραφή αυτού του στοιχείου!");
          setModalOpen(true);
        }
      }
    },
  ];
  return (
    <Box>
      <AppBreadcrumbs
        breadcrumbsList={[
          !hasRole('employee') && {
            title: "Διαχείριση Φυλακτικού Προσωπικού",
            url: "/personnel-management",
          },
          { title: "Στοιχεία Μητρώου", url: "/" },
        ]}
      />
      <Box>
        {userData && (
          <Box>
            <h2>{`Στοιχεία Μητρώου ${userData.surname}  ${userData.name}  ${userData.afm} `}</h2>
            <Divider sx={{ bgcolor: "black.main" }} />
          </Box>
        )}

        <Box
          sx={{
            marginY: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {userData ? (
            <EmployeeCard data={userData}>
              <EmployeeInfo setIsUpdated={setIsUserUpdated} data={userData} />
            </EmployeeCard>
          ) : (
            <Loader />
          )}
          <Box
            sx={{
              display: "flex",
              marginTop: 4,
              width: "100%",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getServiceGradeRegistries(page, rowsPerPage, employeeId)
              }
              columns = {hasRole('KATAXORITIS')
              ? [...serviceGradeColumns, { id: "action", label: "Ενέργεια", minWidth: 100, isClickable: false }]
              : [...serviceGradeColumns]}
              employeeId={employeeId}
              actionIcons={getActionIcon(deleteServiceGradeRegistryById)}
              buttonLabel={"+ Προσθήκη Υπηρεσιακού Βαθμού"}
              buttonUri={"service-grade-registry"}
              title={"Υπηρεσιακός Βαθμός"}
              id={"serviceGrade"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getServicePositionRegistries(page, rowsPerPage, employeeId)
              }
              columns = {hasRole('KATAXORITIS')
              ? [...servicePositionColumns, { id: "action", label: "Ενέργεια", minWidth: 100, isClickable: false }]
              : [...servicePositionColumns]}
              employeeId={employeeId}
              actionIcons={getActionIcon(deleteServicePositionRegistryById)}
              buttonLabel={"+ Προσθήκη Νέας Θέσης"}
              buttonUri={"service-position-registry"}
              title={"Υπηρεσιακή Θέση"}
              id={"servicePosition"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getEducationLevelRegistries(page, rowsPerPage, employeeId)
              }
              columns = {hasRole('KATAXORITIS')
              ? [...educationsLevelColumns, { id: "action", label: "Ενέργεια", minWidth: 100, isClickable: false }]
              : [...educationsLevelColumns]}
              employeeId={employeeId}
              actionIcons={getActionIcon(deleteEducationLevelRegistryById)}
              buttonLabel={"+ Προσθήκη Εκπαιδευτικού Προσόντος"}
              buttonUri={"education-level-registry"}
              title={"Εκπαιδευτικό Επίπεδο"}
              id={"educationLevel"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getServiceChangeRegistriesById(page, rowsPerPage, employeeId)
              }
              columns = {hasRole('KATAXORITIS')
              ? [...serviceChangesColumns, { id: "action", label: "Ενέργεια", minWidth: 100, isClickable: false }]
              : [...serviceChangesColumns]}
              employeeId={employeeId}
              actionIcons={getActionIcon(deleteServiceChangeRewardRegistryById)}
              buttonLabel={"+ Προσθήκη Υπηρεσιακής Μεταβολής "}
              buttonUri={"service-changes-registry"}
              title={"Υπηρεσιακές Μεταβολές"}
              id={"serviceChange"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getOvertimeRegistriesById(page, rowsPerPage, employeeId)
              }
              columns={overTimeColumns}
              buttonLabel={"+ Προσθήκη Υπερωρίας "}
              buttonUri={"overtime-registry"}
              employeeId={employeeId}
              title={"Υπερωρίες"}
              id={"overtime"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getServiceActionRegistriesById(page, rowsPerPage, employeeId)
              }
              columns={serviceActionsColumns}
              employeeId={employeeId}
              buttonLabel={"+ Προσθήκη Ενέργειας"}
              buttonUri={"service-actions-registry"}
              title={"Υπηρεσιακές Ενέργειες"}
              id={"serviceAction"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getSwitchesRegistriesById(page, rowsPerPage, employeeId)
              }
              columns={switchesColumns}
              employeeId={employeeId}
              buttonLabel={"+ Προσθήκη Μεταγωγής"}
              buttonUri={"switches-registry"}
              title={"Μεταγωγές"}
              id={"switches"}
            />
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getEducationRegistriesById(page, rowsPerPage, employeeId)
              }
              columns={educationsColumns}
              employeeId={employeeId}
              buttonLabel={"+ Προσθήκη Εκπαίδευσης "}
              buttonUri={"education-registry"}
              title={"Εκπαιδεύσεις"}
              id={"education"}
            />{" "}
            <TableAccordionItem
              getCallFunc={(page, rowsPerPage) =>
                getIntangibleRewardRegistries(page, rowsPerPage, employeeId)
              }
              columns = {hasRole('KATAXORITIS')
              ? [...intangibleRewardsColumns, { id: "action", label: "Ενέργεια", minWidth: 100, isClickable: false }]
              : [...intangibleRewardsColumns]}
              actionIcons={getActionIcon(deleteIntangibleRewardRegistryById)}
              buttonLabel={"+ Προσθήκη Άυλης Αμοιβής"}
              buttonUri={"intangible-rewards-registry"}
              employeeId={employeeId}
              title={"Άυλες Αμοιβές"}
              id={"intangibleReward"}
            />
          </Box>
        </Box>
      </Box>
      {modalOpen && <AppModal onHideModal={() => setModalOpen(false)}>
        {modalContent}
      </AppModal>}

    </Box>
  );
};

export default EmployeeRegistry;
