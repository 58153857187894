import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const CustomIconBox = ({ icon, bgColor, iconColor, ...rest }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 50,
        height: 50,
        borderRadius: 10,
        bgcolor: bgColor || "primary.main",
        zIndex: 0,
        ...rest,
      }}
    >
      {React.cloneElement(icon, {
        sx: {
          zIndex: 100,
          position: "absolute",
          color: iconColor || "white.main",
          marginLeft: 0.7,
          marginTop: 1,
        },
        fontSize: "large",
      })}
    </Box>
  );
};

CustomIconBox.propTypes = {
  icon: PropTypes.element.isRequired,
  bgColor: PropTypes.string,
  iconColor: PropTypes.string,
};

export default CustomIconBox;