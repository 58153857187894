import { Box, Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import AppTable from "../../components/UI/AppTable";
import Filters from "../../components/Filters";
import { getFilteredRegistries } from "../../services/PersonnelManagement.service";
import { useEffect, useState } from "react";
import Loader from "../../components/UI/Loader";
import useAuth from "../../hooks/useBackAuth";
import UserService from "../../services/userService";

const PersonnelManagement = () => {
  const navigate = useNavigate();
  const initialFilterValues = {
    name: "",
    surname: "",
    institutionId: "",
    positionId: "",
    guardType: "",
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilterValues);
  const { userInfo, hasRole } = useAuth();

  const columns = [
    {
      id: "fullName",
      label: "Ονοματεπώνυμο",
      minWidth: 170,
      isClickable: true,
    },
    {
      id: "serviceAccount.grade.description",
      label: "Βαθμός",
      minWidth: 100,
      isClickable: false,
    },
    {
      id: "serviceAccount.position.description",
      label: "Θέση στο Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceAccount.institute.description",
      label: "Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
  ];
  const handleClick = (row) => {
    navigate(`/employee-info/${row.id}`);
  };
  useEffect(() => {
    const fetchAndSetRegistries = async () => {
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      setIsLoading(true);
      console.log(userInfo?.serviceAccount?.institute?.id,UserService.profile()?.attributes?.instituteId?.[0])
      const allRegistries = await getFilteredRegistries(
       { page,
        size:rowsPerPage,
        name:nonEmptyFilters.name,
        surname:nonEmptyFilters.surname,
        guardType:nonEmptyFilters.guardType,
        evaluationGroupId:hasRole("supervisor") ? (userInfo.supervisorEvaluationGroup.evaluationGroup.id || undefined) : undefined,
        institutionIds:hasRole("SuperAdmin")
          ? nonEmptyFilters.institutionId
          : (userInfo?.serviceAccount?.institute?.id || UserService.profile()?.attributes?.instituteId?.[0]),
          positionIds:nonEmptyFilters.positionId}
      );
      setCount(allRegistries.count);
      setData(allRegistries.data);
      setIsLoading(false); 
    };

    fetchAndSetRegistries();
  }, [page, rowsPerPage, filters]);
  return (
    <Box>
      <Box >
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: "Διαχείριση Φυλακτικού Προσωπικού",
              url: "/personnel-management",
            },
          ]}
        />
      </Box>
      <h2>{`Διαχείριση Φυλακτικού Προσωπικού ${hasRole('SuperAdmin') ? 'Καταστημάτων': 'Καταστήματος'} Κράτησης`}</h2>
      <Divider sx={{ bgcolor: "black.main" }} />
      {hasRole("SuperAdmin") && (
        <Box sx={{ justifyContent: "flex-end", display: "flex", marginTop: 3 }}>
          <Button variant="contained" onClick={() => navigate("/add-officer")}>
            Καταχώριση Φυλακτικού Προσωπικού
          </Button>
        </Box>
      )}
      <Filters setFilters={setFilters} filters={filters} />
      {data ? (
        <AppTable
          noDataMsg={"Χωρίς δεδομένα."}
          isLoading={isLoading}
          columns={columns}
          rows={data}
          handleClick={handleClick}
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : (
        <Loader />
      )}
    </Box>
  );
};
export default PersonnelManagement;
