import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";

export default function AppTable(props) {
    const [page, setPage] = useState(0);
  const [rows, setRows] = useState(props.rows || []);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { isPaginationByBE = true } = props;
  const handleChangePage = (event, newPage) => {
    isPaginationByBE ? props.setPage(newPage) : setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    isPaginationByBE
      ? props.setRowsPerPage(+event.target.value)
      : setRowsPerPage(+event.target.value);
    isPaginationByBE ? props.setPage(0) : setPage(0);
  };
  function getNestedPropertyValue(row, colId) {
        const isTime = colId.toLowerCase().includes("time");
    const keys = colId.split(".");
    const value = keys.reduce(
      (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
      row
    );
    
    return value && isTime && value.includes(":")
      ? value.toString().split("T")[1].substring(0, 5)
      : typeof value == "boolean" || colId === 'isImplemented' ? (value ? "Ναι" : "Όχι") : value;
  }
  useEffect(() => {
    setRows(
      isPaginationByBE
        ? props.rows
        : props.rows.slice(
          props.page * props.rowsPerPage,
          props.page * props.rowsPerPage + props.rowsPerPage
        )
    );
  }, [props.rows]);
  const clickableCellWithDivider = {
    cursor: "pointer",
    color: "blue",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    minWidth: 0,
  };
  const cellStyleWithDivider = {
    borderRight: "1px solid rgba(224, 224, 224, 1)", // Add border-right to create column dividers
    minWidth: 0, // Ensure cell content doesn't overflow
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "bold",
                    backgroundColor: "lightgray",
                    ...cellStyleWithDivider,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {props?.rows?.length > 0 && rows ? (
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {props.columns.map((column) => {
                      column.id.toString().toLowerCase().includes("time");
                      const value =
                        column.id === "fullName"
                          ? `${row.name || row.registry.name} ${row.surname || row.registry.surname
                          }`
                          : getNestedPropertyValue(row, column.id);
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={
                            column.isClickable
                              ? clickableCellWithDivider
                              : cellStyleWithDivider
                          }
                          onClick={() => {
                            column.isClickable &&
                              props.handleClick(row)
                          }
                          }
                        >
                          {props.actionIcons && column.id === "action" ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {props.actionIcons && props.actionIcons.map((item, index) => (
                                <span key={index}
                                  onClick={() => {
                                    !item?.disabled(row) &&
                                    item.onClickFunc(row)
                                  }}
                                  style={{
                                     cursor: item?.disabled(row) ? "auto" : "pointer",
                                     opacity: item?.disabled(row) ? 0.5 : 1,
                                  }}>
                                  {item.icon}
                                </span>
                              ))}
                            </Box>
                          ) : (
                            <div style={{ whiteSpace: 'normal' }}>{value}</div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Typography>{props.noDataMsg}</Typography>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={isPaginationByBE ? props.count : rows.length}
        rowsPerPage={isPaginationByBE ? props.rowsPerPage : rowsPerPage}
        page={isPaginationByBE ? props.page : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
