import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBreadcrumbs from "../../../../components/Breadcrumbs";
import Loader from "../../../../components/UI/Loader";
import {
  getEvaluationGroupData,
} from "../../../../services/Evaluations/SuggestionToolsInspections.service";
import { getRegistryById } from "../../../../services/PersonnelManagement.service";
import EmployeeCard from "../../../EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "./../../../Registrations/RegistrationEmployeeInfo";
import AddSuggToolInspForm from "./AddSuggToolInspForm";
import useAuth from "../../../../hooks/useBackAuth";

const AddSuggestionToolInspectionWrapper = ({
  title,
  labels,
  formTitle,
  objectiveType,
}) => {
  const employeeId = useParams().id;
  const [userData, setUserData] = useState();
  const [evaluatorData, setEvaluatorData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, referenceUserInfo } = useAuth();

  useEffect(() => {
    const fetchAndSetUserData = async () => {
      setIsLoading(true);
      const userResults = await getRegistryById(employeeId);
      const evaluationGroupResults = await getEvaluationGroupData(userInfo?.supervisorEvaluationGroup?.evaluationGroup.id);
      const evaluator = evaluationGroupResults.registries.find(
        (registry) => (registry.isEvaluationGroupSupervisor === true)
        );
      setEvaluatorData(evaluator);
      setUserData(userResults);
      setIsLoading(false);
    };
    fetchAndSetUserData();
  }, [employeeId]);
  const userFullName = evaluatorData
    ? `${evaluatorData?.surname} ${evaluatorData?.name} ${evaluatorData?.afm}`
    : "";
  const breadcrumbsList = [
    {
      title: "Μητρώο Αξιολόγησης Προσωπικού",
      url: "/personnel-registry-evaluation",
    },
    {
      title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
        referenceUserInfo?.name
      } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
      url: `/evaluation-card/${referenceUserInfo?.id}`,
    },
    {
      title:
        "Προτάσεις - Εργαλεία - Επιθεωρήσεις για Εσωτερική Οργάνωση και Λειτουργία",
        url: `/evaluation-card/${referenceUserInfo?.id}/suggestions-tools-inspections`,
      },
    {
      title: title,
      url: "/",
    },
  ];
  return userData && !isLoading ? (
    <>
      <Box>
        <AppBreadcrumbs breadcrumbsList={breadcrumbsList} />
      </Box>
      <Box>
        <h2>{title}</h2>
        <Divider sx={{ bgcolor: "black.main" }} />
      </Box>
      <Box
        sx={{
          marginY: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Στοιχεία Αξιολογούμενου</h2>
        <EmployeeCard data={userData}>
          <RegistrationEmployeeInfo data={userData} />
        </EmployeeCard>

        <h2>{formTitle}</h2>
        <AddSuggToolInspForm
          data={userData}
          labels={labels}
          formTitle={formTitle}
          objectiveType={objectiveType}
        />
        <h2>Στοιχεία Αξιολογητή</h2>
        {evaluatorData && (
          <EmployeeCard data={evaluatorData}>
            <RegistrationEmployeeInfo data={evaluatorData} />
          </EmployeeCard>
        )}
      </Box>
    </>
  ) : (
    <Box>
      {" "}
      <Loader />{" "}
    </Box>
  );
};
export default AddSuggestionToolInspectionWrapper;
