import ReportWrapper from "./shared/ui/ReportWrapper";

  
  function EvaluationReportEmployee() {

    return (
    <ReportWrapper title={'Ενδιάμεση Έκθεση Αξιολόγησης Προϊσταμένου'} isManager={true} />
    );
  }
  
  export default EvaluationReportEmployee;