import * as yup from "yup";

const dexiotitesValidationSchema = yup.object().shape({
  referenceYear: yup
    .number()
    .required("Εισαγάγετε το Έτος Αναφοράς")
    .integer("Το Έτος Αναφοράς πρέπει να είναι ακέραιος αριθμός"),

  startDate: yup.date().nullable().required("Επιλέξτε ημερομηνία Έναρξης"),

  endDate: yup.date()
    .nullable()
    .required("Επιλέξτε ημερομηνία Λήξης")
    .min(yup.ref("startDate"), "Η Λήξη δεν μπορεί να είναι προγενέστερη από την Έναρξη"),

  submissionDate: yup.date().nullable().required("Επιλέξτε ημερομηνία Υποβολής Εντύπου"),

  formProtocolNumber: yup.string().required("Εισαγάγετε τον Αριθμό Πρωτοκόλλου Εντύπου"),

  skillsFormDetails: yup.array().of(
    yup.object().shape({
      targetScore: yup.string().required("Επιλέξτε επίπεδο επίτευξης στόχου"),
    })
  ),
});

export default dexiotitesValidationSchema;
