import { Box, Button, Typography } from "@mui/material";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useParams } from "react-router-dom";
import EmployeeCard from "../../Pages/EmployeeRegistry/EmployeeCard";
import RegistrationEmployeeInfo from "../../Pages/Registrations/RegistrationEmployeeInfo";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import HorizontalVerticalTogglerLayout from "../../components/Layout/HorizontalVerticalTogglerLayout";
import PageTitle from "../../components/PageTitle";
import { useGetRegistryEvaluationRegistryInfo } from "../Armodiothtes/data-access/useGetRegistryEvaluationRegistryInfo";
import GradeDisplay from "../Armodiothtes/shared/ui/Display";
import DatePickerFns from "../Groups/ui/DatePickerFns";
import { useGetSinglePulse } from "./data-access/useGetSinglePulse";
import { average } from "../../services/utils";
import usePostRequest from "../../hooks/useHttpCallHandler";
import { postSignlePulse } from "./data-access/gateway";
import GenericModal from "../shared/ui/modals/GenericModal";
import { useEffect } from "react";
import { failedSubmitHandler, isSubmittingFormHandler, successfullySubmittedHandler } from "../shared/utils";
import useAuth from "../../hooks/useBackAuth";
import { router } from "../../App";

function EnieosSfigmos() {
    const { id } = useParams();
    const { data: caseInfo, isLoading: caseInfoIsLoading } = useGetSinglePulse(id);
    const { handlePostRequest, isLoading: isPostLoading } = usePostRequest(postSignlePulse)
    const { data: registryInfoDetails, isLoading: registryInfoDetailsIsLoading } = useGetRegistryEvaluationRegistryInfo(701); // TODO: caseinfo?.data?.documentInfo?.registry?.id
    const { referenceUserInfo } = useAuth();
    const formContext = useForm({
        // resolver: yupResolver(sfigmosValidationSchema)
    });
    useEffect(() => {
        if (caseInfoIsLoading) return;
        formContext.reset({
            referenceYear: caseInfo?.data?.referenceYear,
            formSubmissionDate: caseInfo?.data?.formSubmissionDate || new Date(),

        })
    }, [caseInfoIsLoading])
    if (registryInfoDetailsIsLoading || caseInfoIsLoading) return <></>
    const totalAvg = caseInfo?.data?.singlePulseFormDetails.length > 0 ? average(caseInfo?.data?.singlePulseFormDetails?.map(q => average(q?.registries?.map(w => w?.registryGrade)))) : null

    const onSubmit = async (data) => {
        console.log(caseInfo?.data?.documentInfo);
        const body = {
            "referenceYear": data.referenceYear,
            "startDate": data.modelName,
            "endDate": data.modelName,
            totalPulseForms: data.totalPulseForms,
            formSubmissionDate: data.formSubmissionDate,
            formProtocolNumber: data.formProtocolNumber,
            overallTeamScore: totalAvg,
            id: caseInfo?.data?.id,
            documentInfo: caseInfo?.data?.documentInfo,
        };
        try {
            isSubmittingFormHandler()
            await handlePostRequest(body);
            successfullySubmittedHandler(referenceUserInfo?.id)
        } catch (error) {
            console.log(error);
            failedSubmitHandler()
        }
    }


    return (<Box mt={16}>
        <AppBreadcrumbs
            breadcrumbsList={[
                {
                    title: "Μητρώο Αξιολόγησης Προσωπικού",
                    url: "/personnel-registry-evaluation",
                  },
                  {
                    title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
                      referenceUserInfo?.name
                    } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
                    url: `/evaluation-card/${referenceUserInfo?.id}`,
                  },
                  {
                    title: "Διενέργειες Αξιολογήσεων",
                    url: `/evaluation-card/${referenceUserInfo?.id}/assessment-procedures`,
                  },
                {
                    title: `Αρμοδιότητες Αρχιφύλακα Εσωτερικής Φρούρησης:

                        Επώνυμο Όνομα - ΑΦΜ - Θέση στο Κατάστημα Κράτησης
                        
                         - Κατάστημα Κράτησης - Έτος Αναφοράς`,
                    url: "/evaluation/groups/create",
                },
            ]}
        />

        <PageTitle title="Ενιαίο Έντυπο Σφυγμού Ομάδας" />
        <Box display="flex" justifyContent="center" my={2}>
            <EmployeeCard data={registryInfoDetails?.data}>
                <RegistrationEmployeeInfo data={registryInfoDetails?.data} />
            </EmployeeCard>
        </Box>
        <FormContainer
            formContext={formContext}
        >
            <Typography mb={1} variant="h4">
                Χρονική Περίοδος Αξιολόγησης
            </Typography>
            <HorizontalVerticalTogglerLayout direction='column'>

                <HorizontalVerticalTogglerLayout>

                    <TextFieldElement
                        fullWidth
                        inputProps={
                            {
                                readOnly: true
                            }
                        }
                        sx={{
                            input: { color: "grey" },
                        }}

                        label="Έτος Αναφοράς"
                        name="referenceYear"
                    />




                    <DatePickerFns

                        label="Από"
                        name="startDate"
                    />


                    <DatePickerFns
                        name="endDate"
                        label="Έως"
                    />
                </HorizontalVerticalTogglerLayout>

                <TextFieldElement
                    fullWidth
                    type="number"
                    label="Συνολικός Αριθμός Εντύπων Σφυγμού Ομάδας που συμπληρώθηκαν *"
                    name="totalPulseForms"
                />

                <DatePickerFns
                    readOnly={true}
                    label="Ημερομηνία Υποβολής Εντύπου"
                    name="formSubmissionDate" />

                <TextFieldElement
                    name="formProtocolNumber"
                    label="Αριθμός Πρωτοκόλλου Εντύπου"
                />

            </HorizontalVerticalTogglerLayout>


            <Typography mt={3} variant="h4">
                Σφυγμός Ομάδας
            </Typography>


            {caseInfo?.data?.singlePulseFormDetails?.map((zz, i) => {

                return <>
                    <Typography mt={4} variant="h3">{i + 1}. {zz?.singlePulseFormType?.description || ''}</Typography>
                    <Typography color="grey" variant="subtitle2">{zz?.pulseFormType?.description}</Typography>
                    <Box display="flex" flexDirection='column' justifyContent='center' gap={10}>
                        <Box display="flex" gap={20} >
                            <Box>
                                {zz.registries.map((item, index) => {
                                    return <Box mt={4} display='flex' gap={4} alignContent='center' alignItems='center'>
                                        <Typography key={index}>{index + 1}. {item.name} {item.surname} - {item.afm} </Typography>
                                        <Typography variant="h3">{item.registryGrade}</Typography>
                                    </Box>
                                })}
                            </Box>

                            <Box display="flex" justifyContent='center' flexDirection={"column"} alignContent="center" alignItems="center">
                                <Typography>Βαθμολογία (Μ.Ο.)</Typography>
                                <Typography variant="h3">{parseFloat(average(zz.registries.map(q => q.registryGrade))?.toFixed(3))}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </>
            })
            }
            <Box display="flex" justifyContent='center'>

                {totalAvg && <GradeDisplay grade={parseFloat(totalAvg?.toFixed(2))}></GradeDisplay>}
            </Box>
 
            <Box my={5} display="flex" justifyContent='flex-end'>
            <Button sx={{mr:2}}
            variant="outlined"
            type="button"
            onClick={() => router.navigate(-1)}
          >
            Επιστροφή
          </Button>
                <GenericModal
                    onAccept={formContext.handleSubmit(onSubmit)}
                    agreeButtonTitle="Οριστικοποίηση"
                    dialogContent="Είστε σίγουρος/η ότι επιθυμείτε να οριστικοποίησετε το Ενιαίο Έντυπο Σφυγμού Ομάδας;">
                    <Button variant="contained">Οριστικοποίηση</Button>
 
                </GenericModal>
            </Box>
        </FormContainer>
    </Box>);
}

export default EnieosSfigmos;