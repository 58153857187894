import axios from "axios";
import HttpService, { _axios } from "../../../services/httpClient";

export const getEvaluationGroups = (id) => { return id == null ? { data: [] } : HttpService.getAxiosClient().get(`evaluation-group?${id ? 'institutionId.equals=' + id : ""}`) }

export const getKatastimata = () => HttpService.getAxiosClient().get(`coded-type/codes/institution`)

export const getEvaluationGroup = (id) => { return id == null ? { data: [] } : HttpService.getAxiosClient().get(`registry-evaluation/group/${id}`) }

export const getRegistries = (instituteId) => HttpService.getAxiosClient().get(`registry?instituteId.equals=${instituteId}`)

export const postEvaluationGroup = (data) => HttpService.getAxiosClient().post(`registry-evaluation/group`, data)

export const putEvaluationGroup = (data) => HttpService.getAxiosClient().put(`registry-evaluation/group`, data)

export const getPossibleSupervisors = (instituteId) => HttpService.getAxiosClient().get(`registry-evaluation/group/potential-supervisors/institution/${instituteId}`)

export const getPossibleEvaluators = (instituteId) => HttpService.getAxiosClient().get(`registry-evaluation/group/potential-evaluators/institution/${instituteId}`)

export const postDeactivateEvalutionGroup = (id) => HttpService.getAxiosClient().post(`evaluation-group/deactivation/${id}`)