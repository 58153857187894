import { Box, Divider, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AppBreadcrumbs from "../../components/Breadcrumbs";
import RadioGroupGenerator from "../../components/UI/AppRadioGroup";
import Loader from "../../components/UI/Loader";
import "../../App.css";
import { defaultRenderDates } from "../../components/Details/utils";
import useAuth from "../../hooks/useBackAuth";
const RegistryDetailedHistoryWrapper = (props) => {
  const guardTypes = [
    { id: "internal", title: "Εσωτερικής Φρούρησης" },
    { id: "external", title: "Εξωτερικής Φρούρησης" },
  ];
  const { hasRole} = useAuth()

  function NoRowsOverlay() {
    return (
      <Typography
        sx={{
          marginTop: 7,
          position: "relative",
          bottom: 27,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Δεν βρέθηκαν εγγραφές με τα κριτήρια που επιλέξατε!
      </Typography>
    );
  }

  return (
    <>
      <Box>
        <AppBreadcrumbs
          breadcrumbsList={[
            {
              title: `Ιστορικό ${props.breadcrumbs[0].title}`,
              url: props.breadcrumbs[0].url,
            },
            {
              title: `Φύλλο ${props.breadcrumbs[1].title}`,
              url: props.breadcrumbs[1].url,
            },
          ]}
        />
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <h2>
          {props.title} {props.department}
        </h2>
        <Divider sx={{ bgcolor: "black.main" }} />
      </Box>
      <h4>{` ${props.title} ${defaultRenderDates(new Date(props.date))}`}</h4>{" "}
      {
        <>
          <Box sx={{ paddingY: 3, marginLeft: 2 }}>
            <RadioGroupGenerator
              name="guardType"
              onChange={(e) => props.setGuardType(e.target.value)}
              items={guardTypes}
              value={props.guardType}
            />
          </Box>
          {props.isLoading ? (
            <Loader />
          ) : (
            <DataGrid
              sx={{ overflow: "hidden" }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              rows={props.rows.map(row=>({...row, isKataxoritis: hasRole('KATAXORITIS') }))}
              columns={props.columns.map((column) => ({
                ...column,
                cellClassName: "dataGridCell",
              }))}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          )}
        </>
      }
    </>
  );
};
export default RegistryDetailedHistoryWrapper;
