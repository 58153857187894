import HttpService from "../httpClient";

export const addNewEducationRegistry = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-education`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  export const addNewEducationRegistries = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-education/multiple/registries`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  export const getEducationRegistriesById = (
    page,
    size,
    registryId
  ) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .get(`/registry-education`, {
          params: {
            page,
            size,
            "registryId.equals":registryId,
          },
        })
        .then(function (response) {
          const allRegistries = {
            data: response.data,
            count: +response.headers["x-total-count"],
          };
          resolve(allRegistries);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  export const deleteEducationRegistryById = (educationId,registryId) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .delete(`/registry-education/education/${educationId}/registry/${registryId}`)
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };