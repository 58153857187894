import { FormContainer, useForm } from "react-hook-form-mui";
import { initialFormSchema } from "../AddNewOfficer/fieldConfiguration";
import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CardItem from "./CardItem";
import { useEffect, useState } from "react";
import { getBEConfigInfo } from "../../services/dropdownData.service";
import { useNavigate, useParams } from "react-router-dom";
import { updateRegistryById } from "../../services/EmployeeRegistry.service";
import { transformUserDataToBEFormat, transformUserDataToFEFormat } from "../Registrations/dataTransformFunctions";
import useAuth from "../../hooks/useBackAuth";

const EmployeeInfo = (props) => {
  const [BEConfigInfo, setBEConfigInfo] = useState();
  const employeeId = useParams().employeeId;
  const [internalData, setInternalData] = useState(transformUserDataToFEFormat(props.data));
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const editableFields = [
    "postalCode",
    "city",
    "region",
    "street",
    "numberOfStreet",
    "telephone",
    "mobile",
    "adt",
    "issuingAuthority",
  ];
  const { hasRole } = useAuth();

  const formContx = useForm({
    defaultValues: internalData,
  });
  useEffect(() => {
    setInternalData(transformUserDataToFEFormat(props.data));
    const fetchBEConfigInfo = async () => {
      const BEConfigInfo = await getBEConfigInfo();
      setBEConfigInfo(BEConfigInfo);
    };

    fetchBEConfigInfo();
  }, [props.data]);
  return (
    <FormContainer
      formContext={formContx}
      onSuccess={async (data) => {
        console.log(data, 'mydata')
        const transformedData = transformUserDataToBEFormat({ ...data, serviceAccount: props.data.serviceAccount }, BEConfigInfo);
        const response = await updateRegistryById(employeeId, transformedData);
        response && props.setIsUpdated(prevState => !prevState)
      }}
    >
      {!(hasRole('employee') || hasRole('supervisor')) && <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: "row",
          marginRight: 1,
          bottom: 60,
          position: "relative",
        }}
      >
        <Box sx={{ display: `${isEditing ? "flex" : "none"}` }}>
          <Button
            sx={{ padding: 0, margin: 0 }}
            type="submit"
            onClick={() => {
              setIsEditing(false);
            }}
            endIcon={<CheckIcon />}
          />
          <Button
            sx={{ padding: 0, margin: 0 }}
            type="button"
            onClick={() => setIsEditing(false)}
            endIcon={<ClearIcon />}
          />
        </Box>

        <Button
          sx={{ display: `${!isEditing ? "flex" : "none"}` }}
          type="button"
          onClick={() => navigate(`/employee-info/` + employeeId + "/edit")}
          endIcon={<EditIcon />}
        />
      </Box>}

      {Object.keys(internalData).map((fieldName) => {
        if (fieldName === "children") {
          const children = internalData.children;
          return children.map((child, index) => {
            return (
              <Box key={index}>
                <CardItem
                  key={`childFirstName${index}`}
                  editable={true}
                  isEditing={isEditing}
                  name={`childFirstName${index}`}
                  label={"Όνομα Τέκνου:"}
                  value={children[index] ? child.childFirstName : " - "}
                />
                <CardItem
                  key={`childLastName${index}`}
                  editable={true}
                  isEditing={isEditing}
                  name={`childLastName${index}`}
                  label={"Επώνυμο Τέκνου:"}
                  value={children[index] ? child.childLastName : " - "}
                />
                <CardItem
                  key={`childDateOfBirth${index}`}
                  editable={true}
                  isEditing={isEditing}
                  name={`childDateOfBirth${index}`}
                  label={"Ημερομηνία Γέννησης Τέκνου:"}
                  value={children[index] ? child.childDateOfBirth : " - "}
                />
              </Box>
            );
          });
        }

        if (fieldName === "wives") {
          const wives = internalData.wives;
          return wives.map((wife, index) => {
            return (
              <Box key={index}>
                <CardItem
                  key={`spouseFirstName${index}`}
                  editable={true}
                  isEditing={isEditing}
                  name={`spouseFirstName${index}`}
                  label={" Όνομα Συζύγου:"}
                  value={wives[index] ? wife.spouseFirstName : " - "}
                />
                <CardItem
                  key={`spouseLastName${index}`}
                  isEditing={isEditing}
                  editable={true}
                  name={`spouseLastName${index}`}
                  label={"Επώνυμο Συζύγου:"}
                  value={wives[index] ? wife.spouseLastName : " - "}
                />
                <CardItem
                  key={`weddingDate${index}`}
                  isEditing={isEditing}
                  editable={true}
                  name={`weddingDate${index}`}
                  label={"Ημερομηνία Τέλεσης Γάμου:"}
                  value={
                    wives[index] ? internalData.wives[index].weddingDate : " - "
                  }
                />
                {/* <CardItem
                  key={`expWeddingDate${index}`}
                  isEditing={isEditing}
                  editable={true}
                  name={`expWeddingDate${index}`}
                  label={"Ημερομηνία Λήξης Γάμου:"}
                  value={
                    wives[index]
                      ? internalData.wives[index].expWeddingDate
                      : " - "
                  }
                /> */}
              </Box>
            );
          });
        }
        // TODO: DELETE GUARD TYPE PROPERLY!
        if (fieldName === 'guardType') {
          return <></>
        }
        if (fieldName !== "id") {
          return (
            <CardItem
              key={initialFormSchema[fieldName].name}
              isEditing={isEditing}
              editable={editableFields.includes(
                initialFormSchema[fieldName].name
              )}
              name={initialFormSchema[fieldName].name}
              label={initialFormSchema[fieldName].label
                .toString()
                .replace("*", ":")}
              value={internalData[fieldName] ? internalData[fieldName] : " - "}
            />
          );
        }
      })}
    </FormContainer>
  );
};
export default EmployeeInfo;
