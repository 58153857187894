import { Box, Button } from "@mui/material";
import { Outlet } from "react-router-dom";
import "../App.css";
import useAuth from "../hooks/useBackAuth";
import AppBar from "./AppBar";
import Menu from "./Menu";
import UserService from "../services/userService";
import { config } from "../config";
import { useEffect, useState } from "react";
import { getInstitutionDropdown } from "../services/dropdownData.service";

const MainLayout = () => {
  const { userInfo, hasRole } = useAuth();
  const [institute, setInstitute] = useState('')
  useEffect(() => {
    const fetchInstitution = async () => {

      const resp = await getInstitutionDropdown();
      const inst = resp.institution.find(z=> z.id == UserService.profile()?.attributes?.instituteId?.[0])
      setInstitute(inst?.description)
    }
    if (UserService.hasRole(['KATAXORITIS'])) {
      fetchInstitution()
    }
  }, [])
  return (
    <>
      <Box sx={{ position: "fixed", width: "100%", top: 0, zIndex: "9" }}>
        <AppBar />
        {/* {JSON.stringify(userInfo)} */}
        <Box
          color="white.main"
          sx={{
            top: 0,
            paddingLeft: 2,
            height: 40,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <h2 style={{ marginTop: 0 }}>
            {hasRole("SuperAdmin")
              ?
              "Διαχειριστικό Περιβάλλον"
              : hasRole("KATAXORITIS")
                ? `Περιβάλλον Καταχωρητή ${institute || ""}`
                : userInfo?.serviceAccount?.institute?.description}{" "}
          </h2>
        </Box>
      </Box>

      <Box sx={{ width: "100%", zIndex: "9" }}>
        <Menu />

        <Box sx={{ marginLeft: 34, marginRight: 5, marginTop: 17 }}>
          {/* {UserService.isAuthenticated && <Button onClick={() =>
                UserService.doLogout({
                  redirectUri: "https://mzrs.knowledge.gr",
                })}>Logout</Button>} */}
          {UserService.isAuthenticated ? (
            <Outlet />
          ) : (
            <Button
              onClick={() =>
                UserService.doLogin({
                  redirectUri: `${config.frontendURL}/login`,
                })
              }
            >
              Login
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
