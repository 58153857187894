import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const SwitchDetailsColumns= (setIsOpenDeleteModal,registriesLength,setIds)=> [
  {
    field: "fullname",
    headerName: "Ονοματεπώνυμο",
    width: 150,
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography>{`${params.row.name} ${params.row.surname}`}</Typography>
    ),
  },
  {
    field: "afm",
    headerName: "ΑΦΜ",
    flex: 1,
  },
  {
    field: "position",
    headerName: "Θέση",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.serviceAccount?.position?.description || ""}
      </Typography>
    ),
  },
  {
    field: "grade",
    headerName: "Βαθμός",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.serviceAccount?.grade?.description || ""}
      </Typography>
    ),
  },
  {
    field: "transferType",
    headerName: "Μεταγωγή σε",
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.transferType || ""}
      </Typography>
    ),

  },
  {
    field: "notes",
    headerName: "Παρατηρήσεις",
    flex: 1,
  },
  {
    field: "action",
    headerName: "Ενέργεια",
    width: 70,
    renderCell: (params) => (
      <Button disabled={registriesLength<2  && !params.row.isKataxoritis}  onClick={()=>{setIsOpenDeleteModal(true); setIds({employeeId:params.row.id,switchId:params.row.switchId})}}>   <DeleteIcon /> 
      </Button>
    ),

  },



];

export const SwitchColumns = [
  {
    id: "transferDate",
    label: "Ημερομηνία",
    minWidth: 170,
    isClickable: true,
  },
  {
    id: "transferType",
    label: "Μεταγωγή σε",
    minWidth: 170,
    isClickable: false,
  },

];