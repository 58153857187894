import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PreviewIcon from "@mui/icons-material/Preview";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import AppTable from "../../../components/UI/AppTable";
import Loader from "../../../components/UI/Loader";
import { getIsTimerFinished } from "../../../helperFunctions";
import useAuth from "../../../hooks/useBackAuth";
import { getFilteredSubordinateEvaluations } from "../../../services/Evaluations/EvaluationRegister.service";
import { getRegistryById } from "../../../services/PersonnelManagement.service";
import UserService from "../../../services/userService";
import { useGetTimeArray } from "../AssessmentProcedures/data-access/useGetTimeArray";
import { getDocumentFormPath } from "../evaluationsHelperFuncs";
import { subordinateEvaluationsColumns } from "./SubordinateEvaluationsColumnConfig";
import SubordinateEvaluationsFilters from "./SubordinateEvaluationsFilters";
const SubordinateEvaluations = () => {
  const initialFilterValues = {
    name: "",
    surname: "",
    referenceYear: "",
    institutionId: "",
    submissionStage: "",
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const employeeId = useParams().id;
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilterValues);
  const navigate = useNavigate();
  const { referenceUserInfo } = useAuth();
  const handleClick = (row, action) => action.onClickFunc(row);
  const createOnClickFunc = (type, row) => {
    console.log(type, "type");
    navigate(getDocumentFormPath(row.documentType.code, row.id), {
      state: {
        action: type,
        data: row,
        employeeId,
        evaluatorData: {
          id: userData?.id,
          name: userData?.name,
          surname: userData?.surname,
          afm: userData?.afm,
          evaluationGroupId: userData?.userData?.evaluationGroup?.id,
        },
      },
    });
  };
  const actionIcons = [
    {
      id: "preview",
      icon: <PreviewIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("preview", row),
    },
    {
      id: "print",
      icon: <PictureAsPdfIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("print", row),
    },
  ];
  useEffect(() => {
    const fetchAndSetUserData = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
    };
    const fetchAndSetRegistries = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      setIsLoading(true);
      const allRegistries = await getFilteredSubordinateEvaluations(
        page,
        rowsPerPage,
        employeeId,
        nonEmptyFilters.institutionId ||
          UserService.profile()?.attributes?.instituteId?.[0],
        nonEmptyFilters.submissionStage,
        nonEmptyFilters.referenceYear
      );
      setCount(allRegistries?.count);
      setData(allRegistries?.data);
      setIsLoading(false);
    };
    fetchAndSetUserData();
    fetchAndSetRegistries();
  }, [page, rowsPerPage, filters, employeeId]);
  const { data: timeArray, isLoading: timeArrayIsLoading } =
    useGetTimeArray(true);

  const getIsActive = (row, id) => {
    const isTimerFinished = getIsTimerFinished(
      row?.documentType?.code,
      row?.submissionStage?.code,
      timeArray
    );
    switch (id) {
      case "preview":
        return isTimerFinished;
      case "print":
        return isTimerFinished;
      default:
    }
    return false;
  };
  return (
    <Box>
      <Box>
        {userData && (
          <AppBreadcrumbs
            breadcrumbsList={[
              {
                title: "Μητρώο Αξιολόγησης Προσωπικού",
                url: "/personnel-registry-evaluation",
              },
              {
                title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
                  referenceUserInfo?.name
                } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
                url: `/evaluation-card/${referenceUserInfo?.id}`,
              },
              {
                title: "Υφιστάμενενες Αξιολογήσεις",
                url: ``,
              },
            ]}
          />
        )}
      </Box>
      <h2>Προβολή Υφιστάμενων Αξιολογήσεων (ως Υφιστάμενος)</h2>
      <Divider sx={{ bgcolor: "black.main" }} />
      <SubordinateEvaluationsFilters
        setFilters={setFilters}
        filters={filters}
      />
      {userData && (
        <h3>{`Στοιχεία Αξιολογούμενου:  ${userData.surname}  ${userData.name},  ${userData.afm},  ${userData.serviceAccount.position.description},  ${userData.serviceAccount.institute.description} `}</h3>
      )}
      <h2>Στοιχεία Υφιστάμενων Αξιολογήσεων</h2>
      {!isLoading || !timeArrayIsLoading ? (
        <AppTable
          noDataMsg={"Δεν βρέθηκαν έντυπα αξιολόγησης."}
          isLoading={isLoading}
          actionIcons={actionIcons
            .filter((action) => action)
            .map((action) => ({
              key: action.id,
              icon: action.icon,
              onClickFunc: (row) => handleClick(row, action),
              disabled: (row) => !getIsActive(row, action.id),
            }))}
          columns={subordinateEvaluationsColumns}
          rows={data}
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : (
        <Loader />
      )}
    </Box>
  );
};
export default SubordinateEvaluations;
