import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import Details from "../../../components/Details/Details";
import FormDatePicker from "../../../components/UI/FormDatePicker";
import FormTimeRangePicker from "../../../components/UI/FormTimeRangePicker";
import ResetModal from "../../../components/UI/Modal/ResetModal";
import SaveModal from "../../../components/UI/Modal/SaveModal";

import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import "../../../App.css";
import { NoRowsOverlay } from "../../../helperFunctions";
import {
  addNewOvertimeRegistries,
  addNewOvertimeRegistry,
} from "../../../services/Registrations/Overtimes.service";
import {
  getGradeInstitutionPositionDropdown,
  getOvertimeReasonDropdown,
} from "../../../services/dropdownData.service";
import RegistrationFormItem from "../RegistrationForm/RegistrationFormItem";
import {
  registrationDetailsTransformer,
  transformOvertimeDataToBEFormat,
} from "../dataTransformFunctions";
import {
  transformOvertimeMultipleDataToBEFormat,
  transformedMultipleRegistryData,
} from "./../../MultipleRegistrations/multipleDataTransformFunctions";
import { overtimeFieldValidation } from "./overtimeFieldValidation";
import {
  defaultOvertimeRegistrationValues as defaultValues,
  formOvertimeRegistrationSchema as formSchema,
} from "./overtimeRegistrationConfig";

const OvertimeRegistrationForm = ({
  data,
  isMultipleRegistration,
  selectedPeople,
  guardType,
}) => {
  const employeeId = useParams().employeeId;
  const navigate = useNavigate();
  const [overtimeReasons, setOvertimeReasons] = useState();
  const [overtimeTypes, setOvertimeTypes] = useState();
  const [previewData, setPreviewData] = useState({ labels: [], values: [] });
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isShownResetModal, setIsShownResetModal] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [formData, setFormData] = useState();
  const [multipleRegistriesData, setMultipleRegistriesData] = useState();
  const [configObjects, setConfigObjects] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const registry = {
    id: data?.id,
    name: data?.name,
    surname: data?.surname,
    fathersName: data?.fathersName,
    fathersSurname: data?.fathersSurname,
    mothersName: data?.mothersName,
    mothersSurname: data?.mothersSurname,
    dateOfBirth: data?.dateOfBirth,
    placeOfBirth: data?.placeOfBirth,
    adt: data?.adt,
    issuingAuthority: data?.issuingAuthority,
    afm: data?.afm,
    bloodType: data?.bloodType,
    amka: data?.amka,
    payrollAccountNumber: data?.payrollAccountNumber,
    iban: data?.iban,
    isInternalGuard: data?.serviceAccount?.isInternalGuard,
  };

  useEffect(() => {
    const fetchBEConfigInfo = async () => {
      const overtimeReasons = await getOvertimeReasonDropdown();
      const configObj = await getGradeInstitutionPositionDropdown();
      setConfigObjects(configObj);
      setOvertimeTypes(overtimeReasons);
      setOvertimeReasons(
        overtimeReasons["overtime_reason"].map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    fetchBEConfigInfo();
  }, []);
  const formContext = useForm({
    resolver: yupResolver(overtimeFieldValidation),
    defaultValues: defaultValues,
  });

  const onSave = () => {
    const data = isMultipleRegistration
      ? transformOvertimeMultipleDataToBEFormat(
          {
            ...multipleRegistriesData?.rows[0],
            overtimeEnd: time.end,
            overtimeStart: time.start,
          },
          {
            ...configObjects,
            overtimeTypes,
          },
          selectedPeople,
          guardType
        )
      : transformOvertimeDataToBEFormat(formData, overtimeTypes);
    overtimeTypes && isMultipleRegistration
      ? addNewOvertimeRegistries(data)
      : addNewOvertimeRegistry(data);
    setIsShownSaveModal(false);
    isMultipleRegistration
      ? navigate("/personnel-management")
      : navigate(`/employee-info/${employeeId}`);
  };

  return (
    <>
      {!isPreviewMode && previewData && (
        <FormContainer
          formContext={formContext}
          onSuccess={async (overtimeData) => {
            console.log(overtimeData, "overtimeDAta");
            setDate(format(overtimeData.overtimeDate, "yyyy-MM-dd"));
            setTime({
              start: overtimeData.overtimeStart,
              end: overtimeData.overtimeEnd,
            });
            const typeOfOvertimeDescription = overtimeReasons.find(
              (option) => option.id === +overtimeData.reason
            )?.label;
            const transformedData = isMultipleRegistration
              ? transformedMultipleRegistryData(
                  {
                    ...overtimeData,
                    reason: typeOfOvertimeDescription,
                  },
                  formSchema,
                  selectedPeople
                )
              : registrationDetailsTransformer(formSchema, {
                  ...overtimeData,
                  reason: typeOfOvertimeDescription,
                  userInfo: `${registry.surname} ${registry.name} (${registry.afm})`,
                  userPosition: data.serviceAccount.position.description,
                });

            isMultipleRegistration
              ? setMultipleRegistriesData(transformedData)
              : setFormData({
                  ...overtimeData,
                  registry,
                  institution: data.serviceAccount.institute,
                  position: data.serviceAccount.position,
                });

            !isMultipleRegistration && setPreviewData(transformedData);
            setIsPreviewMode(true);
          }}
        >
          <Box
            sx={{
              marginY: 7,
              width: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <RegistrationFormItem label={formSchema.overtimeDate.label}>
              <FormDatePicker
                placeholder={"ΗΗ/ΜΜ/ΕΕΕΕ"}
                name={formSchema.overtimeDate.name}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.overtimeStart.label}>
              <FormTimeRangePicker
                end={formSchema.overtimeEnd}
                start={formSchema.overtimeStart}
              />
            </RegistrationFormItem>
            <RegistrationFormItem label={formSchema.reason.label}>
              {overtimeReasons && (
                <SelectElement
                  fullWidth
                  label={formSchema.reason.label}
                  name={formSchema.reason.name}
                  options={overtimeReasons}
                />
              )}
            </RegistrationFormItem>

            <RegistrationFormItem label={formSchema.notes.label}>
              <TextFieldElement
                fullWidth
                id={formSchema.notes.label}
                name={formSchema.notes.name}
                multiline
                rows={3}
                variant="outlined"
              />
            </RegistrationFormItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: 3,
                gap: 2,
              }}
            >
              <Button onClick={() => setIsShownResetModal(true)}>
                Ακύρωση
              </Button>
              <Button type="submit" variant="contained">
                Προεπισκόπηση
              </Button>
            </Box>
          </Box>
          {isShownResetModal && (
            <ResetModal setIsShownResetModal={setIsShownResetModal} />
          )}
        </FormContainer>
      )}
      {isShownSaveModal && (
        <SaveModal setIsShownSaveModal={setIsShownSaveModal} onSave={onSave} />
      )}
      {isPreviewMode && (
        <Box sx={{ width: "80%", marginTop: 4 }}>
          <h4>{`Υπερωρίες ${date}`}</h4>
          {isMultipleRegistration && multipleRegistriesData ? (
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              rows={multipleRegistriesData.rows}
              columns={multipleRegistriesData.columns.map((column) => ({
                ...column,
                cellClassName: "dataGridCell",
              }))}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          ) : (
            <Details
              renderFirstRowsAsHeader
              renderArraysAsColumns
              rows={[
                {
                  name: previewData.labels[0],
                  value: previewData.labels.slice(1, previewData.length),
                },
                {
                  name: previewData.values[0],
                  value: previewData.values.slice(1, previewData.length),
                },
              ]}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 3,
              gap: 2,
            }}
          >
            <Button
              type="button"
              onClick={() => setIsPreviewMode(false)}
              variant="contained"
            >
              Επιστροφή
            </Button>
            {/* post call */}
            <Button
              onClick={() => setIsShownSaveModal(true)}
              variant="contained"
            >
              Οριστικοποίηση
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default OvertimeRegistrationForm;
