import * as yup from "yup";

const sfigmosValidationSchema = yup.object().shape({
  referenceYear: yup
    .number()
    .required("Εισαγάγετε το Έτος Αναφοράς")
    .integer("Το Έτος Αναφοράς πρέπει να είναι ακέραιος αριθμός"),

  startDate: yup.date().nullable().required("Επιλέξτε ημερομηνία Έναρξης"),

  endDate: yup.date()
    .nullable()
    .required("Επιλέξτε ημερομηνία Λήξης")
    .min(yup.ref("startDate"), "Η Λήξη δεν μπορεί να είναι προγενέστερη από την Έναρξη"),


  formProtocolNumber: yup.string().required("Εισαγάγετε τον Αριθμό Πρωτοκόλλου Εντύπου"),

  groupPulseFormDetails: yup.array().of(
    yup.object().shape({
      // pulseReason: yup.string().required("Επιλέξτε λόγο παλμικής αξιολόγησης"),

      targetScore: yup
        .string()
        .required("Επιλέξτε βαθμό από τη λίστα")
        .oneOf(["1", "2", "3", "4", "5"], "Επιλέξτε έναν έγκυρο βαθμό"),
    })
  ),
});

export default sfigmosValidationSchema;
