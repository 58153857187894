import { useEffect, useState } from "react";
import RegistryHistoryWrapper from "../RegistryHistoryWrapper";
import { getServiceChangeHistory } from "../../../services/history.service";
import { useNavigate } from "react-router-dom";
import { serviceChangeColumns } from "./ServiceChangeColumnsConfig";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const ServiceChangeHistory = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo, hasRole } = useAuth()
  const handleClick = (row) => {
    const date = data.find((item) => (item.id === row.id)).serviceDate;
    navigate(`/detailed-history/service-changes/${date}/${row.id}`);
  };

  console.log(userInfo);
  useEffect(() => {
    const fetchAndSetHistoryRegistries = async () => {
      setIsLoading(true);
      const allRegistries = await getServiceChangeHistory(
        page,
        pageSize,
        hasRole('SuperAdmin')
          ? null : hasRole("KATAXORITIS")
            ? UserService.profile()?.attributes?.instituteId?.[0]
            : userInfo?.serviceAccount?.institute?.id,
      );
      const rows = allRegistries.data.flatMap((item) => ({
        id: item.id,
        serviceDate: item.serviceDate,
        typeOfService: item.typeOfService.description,
      }));

      setData(rows);
      setCount(allRegistries.count);
      setIsLoading(false);
    };

    fetchAndSetHistoryRegistries();
  }, [page, pageSize]);

  return (
    <RegistryHistoryWrapper
      title={"Υπηρεσιακών Μεταβολών"}
      department={userInfo?.serviceAccount?.institute?.description}
      rows={data}
      columns={serviceChangeColumns}
      isLoading={isLoading}
      onClickFunc={handleClick}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      count={count || data.length}
      buttonTitle={'Καταχώριση Υπηρεσιακής Μεταβολής '}
      buttonURL={'/multiple-registries/service-changes'}
    />
  );
};
export default ServiceChangeHistory;
