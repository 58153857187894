import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import AppAccordion from "../../../components/UI/AppAccordion";
import { useGetEvaluationGroups } from "../data-access/useGetEvaluationGroups";
import { useGetKatastimata } from "../data-access/useGetKatastimata";
import AccordionContentEvaluationTeam from "../ui/AccordionContent/AccordionContentEvalutionTeam";
import EvaluationGroupAccordionContentActionButtons from "../ui/AccordionContent/EvaluationGroupAccordionContentActionButtons";
import PageTitle from "../../../components/PageTitle";
import useAuth from "../../../hooks/useBackAuth";
import usePostRequest from "../../../hooks/useHttpCallHandler";
import { postDeactivateEvalutionGroup } from "../data-access/gateway";
import { enqueueSnackbar } from "notistack";

function ListEvaluationGroups() {
    const navigate = useNavigate();
    const { data: katastiamtaList, isLoading: loadingKatastimata, refetch: KatastimataRefetch} = useGetKatastimata();
    const [selectedKatastima, setSelectedKatastima] = useState(null);
    const { handlePostRequest: deactivateGroup, isLoading: deactivateLoading } = usePostRequest(postDeactivateEvalutionGroup);
    const { data: evaluationGroups, isLoading: loadingEvaluationGroups, isFetching, refetch:groupRefetch } = useGetEvaluationGroups(selectedKatastima?.value); // when selectedKatastima changes, refetch the data
    const internalGuardGroups = (loadingEvaluationGroups || isFetching) ? [] : evaluationGroups?.data?.filter(x => x?.isInternalGuard && x?.isActive) || [];
    const externalGuardGroups = (loadingEvaluationGroups || isFetching) ? [] : evaluationGroups?.data?.filter(x => !x?.isInternalGuard && x?.isActive) || [];
    const { hasRole, userInfo } = useAuth()
    useEffect(() => {
        // console.log('hello');
        if (hasRole('admin')) {
            setSelectedKatastima({ label: userInfo?.serviceAccount?.institute?.description, value: userInfo?.serviceAccount?.institute?.id })
        }
        KatastimataRefetch()
    }, [])
    if (isFetching) return <></>
    const listOptions = hasRole("SuperAdmin")
        ? (katastiamtaList?.data?.institution?.map(x => { return { label: x?.description, value: x?.id } }) || [])
        : katastiamtaList?.data?.institution?.filter(x => userInfo?.serviceAccount?.institute?.id === x?.id).map(x => { return { label: x?.description, value: x?.id } }) || [];


    const deactivateHandler = async (id) => {
        try {

            await deactivateGroup(id)
            enqueueSnackbar('Η ομάδα αξιολόγησης απενεργοποιήθηκε επιτυχώς', { variant: 'success' })
            groupRefetch()

        } catch (error) {
            enqueueSnackbar('Η ομάδα αξιολόγησης δεν απενεργοποιήθηκε', { variant: 'error' })
        }
    }



    return (<>
        <Box mt={17}>
            <AppBreadcrumbs
                breadcrumbsList={[
                    {
                        title: "Ομάδες Αξιολόγησης",
                        url: "/evaluation/groups",
                    },

                ]}
            />
            <PageTitle title={`Ομάδες Αξιολόγησης καταστήματος ${selectedKatastima?.label || ""
                }`} />

            {hasRole('SuperAdmin') && <Autocomplete
                loading={loadingKatastimata || isFetching}
                value={selectedKatastima}
                onChange={(event, newValue) => {
                    setSelectedKatastima(newValue);
                }}
                disablePortal
                id="combo-box-demo"
                options={listOptions}
                sx={{ width: 800 }}
                renderInput={(params) => <TextField {...params} label="Καταστήματα" />}
            />

            }
            {!hasRole('SuperAdmin') && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" disabled={!selectedKatastima} onClick={() => navigate(`/evaluation/groups/create?instituteId=${selectedKatastima?.value}`)}>Δημιουργία Ομάδας Αξιολόγησης</Button>
            </Box>
            }

            <AppAccordion title='Εσωτερική Φρούρηση' details={<>
                {internalGuardGroups?.length === 0 && <Typography textAlign='center'>Δεν υπάρχουν ομάδες αξιολόγησης Εσωτερικής Φρούρησης</Typography>}
                {internalGuardGroups?.map(evalGroup => {
                    if (!evalGroup) return <></>
                    return <AppAccordion key={evalGroup.id} title={`Ομάδα Αξιολόγησης: «${evalGroup?.groupName}»`} details={<div>

                        {!hasRole('SuperAdmin') && <EvaluationGroupAccordionContentActionButtons deactivateGroup={deactivateHandler} groupName={evalGroup.groupName} evalGroupId={evalGroup.id} />}

                        <Typography mb={2} variant="h3">
                            Ημερομηνία Ορισμού Ομάδας: {(evalGroup?.creationDate) || ""}
                        </Typography>

                        <AccordionContentEvaluationTeam id={evalGroup?.id} />
                    </div>} />
                })}


            </>} />

            <AppAccordion title='Εξωτερική Φρούρηση' details={<>
                {externalGuardGroups?.length === 0 && <Typography textAlign='center'>Δεν υπάρχουν ομάδες αξιολόγησης Εξωτερικής Φρούρησης</Typography>}
                {externalGuardGroups?.map(evalGroup => {
                    if (!evalGroup) return <></>
                    return <AppAccordion key={"out" + evalGroup.id} title={`Ομάδα Αξιολόγησης: «${evalGroup?.groupName}»`} details={<div>

                        {!hasRole('SuperAdmin') && <EvaluationGroupAccordionContentActionButtons deactivateGroup={deactivateHandler} groupName={evalGroup.groupName} evalGroupId={evalGroup?.id} />}

                        <Typography mb={2} variant="h3">
                            Ημερομηνία Ορισμού Ομάδας: {(evalGroup?.creationDate) || ""}
                        </Typography>

                        <AccordionContentEvaluationTeam id={evalGroup?.id} />
                    </div>} />
                })}

            </>} />
        </Box>
    </>);
}

export default ListEvaluationGroups;