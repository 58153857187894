import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBreadcrumbs from "../../../components/Breadcrumbs";
import AppTable from "../../../components/UI/AppTable";
import Loader from "../../../components/UI/Loader";
import { getRegistryById } from "../../../services/PersonnelManagement.service";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PreviewIcon from "@mui/icons-material/Preview";
import GroupPulseFormFilters from "./GroupPulseFormFilters";
import { groupPulseFormColumnConfig } from "./GroupPulseFormColumnConfig";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  getEvalGroupIds,
  getFilteredGroupPulseDocs,
} from "../../../services/Evaluations/EvaluationRegister.service";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";
import { getIsTimerFinished } from "../../../helperFunctions";
import { useGetTimeArray } from "../AssessmentProcedures/data-access/useGetTimeArray";
import { getDocumentFormPath } from "../evaluationsHelperFuncs";
const GroupPulseForm = () => {
  const initialFilterValues = {
    name: "",
    surname: "",
    referenceYear: "",
    institutionId: "",
    submissionStage: "",
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(100);
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const employeeId = useParams().id;
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilterValues);
  const navigate = useNavigate();
  const { userInfo, hasRole, referenceUserInfo } = useAuth();
  const handleClick = (row, action) => action.onClickFunc(row);
  const createOnClickFunc = (type, row) => {
    navigate(getDocumentFormPath("group_pulse_form", row.id, row.registry.id), {
      state: {
        action: type,
        data: row,
      },
    });
  };
  const { data: timeArray, isLoading: timeArrayIsLoading } =
    useGetTimeArray(true);

  const actionIcons = [
    {
      id: "edit",
      icon: <BorderColorIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("edit", row),
    },
    {
      id: "preview",
      icon: <PreviewIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("preview", row),
    },
    {
      id: "print",
      icon: <PictureAsPdfIcon sx={{ cursor: "pointer" }} />,
      onClickFunc: (row) => createOnClickFunc("print", row),
    },
  ];
  const [evaluationGroupId, setEvaluationGroupId] = useState();
  useEffect(() => {
    const fetchAndSetRegistries = async () => {
      const data = await getRegistryById(employeeId);
      setUserData(data);
      setEvaluationGroupId(
        data?.supervisorEvaluationGroup?.evaluationGroup?.id ||
          data?.registryEvaluationGroup?.evaluationGroup?.id
      );

      const nonEmptyFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      setIsLoading(true);
      const allRegistries = await getFilteredGroupPulseDocs({
        page,
        size: rowsPerPage,
        // 13901 is the type of the document info which corresponds to group pulse form
        documentTypeId: 13901,
        referenceYears: nonEmptyFilters.referenceYear,
        institutionIds:
          nonEmptyFilters.institutionId ||
          UserService.profile()?.attributes?.instituteId?.[0],
        evaluationGroupIds: data?.supervisorEvaluationGroup?.evaluationGroup?.id ||
        data?.registryEvaluationGroup?.evaluationGroup?.id,
        registryId: employeeId,
        isSupervisor: hasRole("admin"),
      });
      setCount(allRegistries.count);
      setData(allRegistries.data);
      setIsLoading(false);
    };

    fetchAndSetRegistries();
  }, [page, rowsPerPage, filters, employeeId]);
  const getIsActive = (row, id) => {
    const isTimerFinished = getIsTimerFinished(
      row?.documentType?.code,
      row?.submissionStage?.code,
      timeArray
    );

    switch (id) {
      case "edit":
        return (
          +employeeId === +userInfo?.id &&
          hasRole("employee") &&
          !isTimerFinished
        );
      case "preview":
        return +employeeId === +userInfo?.id
          ? row?.isFinalized
          : isTimerFinished;
      case "print":
        return isTimerFinished;
      default:
    }
    return false;
  };
  return (
    <Box>
      <Box>
        {userData && (
          <AppBreadcrumbs
            breadcrumbsList={[
              {
                title: "Μητρώο Αξιολόγησης Προσωπικού",
                url: "/personnel-registry-evaluation",
              },
              {
                title: `Καρτέλα Αξιολογήσεων:  ${referenceUserInfo?.surname} ${
                  referenceUserInfo?.name
                } - ${referenceUserInfo?.afm || referenceUserInfo?.afm}`,
                url: `/evaluation-card/${referenceUserInfo?.id}`,
              },
              {
                title: "Συμπληρώσεις Εντύπου Σφυγμού Ομάδας",
                url: "",
              },
            ]}
          />
        )}
      </Box>
      <h2>Προβολή Συμπληρώσεων Εντύπου Σφυγμού Ομάδας (ως Υφιστάμενος)</h2>
      <Divider sx={{ bgcolor: "black.main" }} />
      <GroupPulseFormFilters setFilters={setFilters} filters={filters} />
      {userData && (
        <h3>{`Στοιχεία Υφιστάμενου:  ${userData.surname}  ${userData.name},  ${userData.afm},  ${userData.serviceAccount.position.description},  ${userData.serviceAccount.institute.description} `}</h3>
      )}
      <h2>Στοιχεία Συμπλήρωσης Σφυγμού Ομάδας Αξιολογούμενων Προϊσταμένων</h2>
      {!isLoading || !timeArrayIsLoading ? (
        evaluationGroupId ? (
          <AppTable
            noDataMsg={"Δεν βρέθηκαν έντυπα αξιολόγησης."}
            isLoading={isLoading}
            actionIcons={actionIcons
              .filter((action) => action)
              .map((action) => ({
                key: action.id,
                icon: action.icon,
                onClickFunc: (row) => handleClick(row, action),
                disabled: (row) => !getIsActive(row, action.id),
              }))}
            columns={groupPulseFormColumnConfig}
            rows={data}
            count={count}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <Typography>
            Ο χρήστης αυτός δεν ανοίκει σε κάποια Ομάδα Αξιολόγησης
          </Typography>
        )
      ) : (
        <Loader />
      )}
    </Box>
  );
};
export default GroupPulseForm;
