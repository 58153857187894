import HttpService from "../httpClient";

export const getFilteredEvaluationRegistries = (
  {page,
  size,
  name,
  surname,
  afm,
  guardType,
  evaluationGroupId,
  institutionIds,
  positionIds,
isSupervisor}
) => {
  return new Promise((resolve, reject) => {
    console.log(afm,'afm')
    HttpService.getAxiosClient()
      .get(`/registry-evaluation/group`, {
        params: {
          page,
          size,
          "name.equals": name,
          "surname.equals": surname,
          "afm.equals": afm,
          "evaluationGroupId.equals": evaluationGroupId || null,
          "isInternalGuard.equals": guardType && guardType === "internal",
          "institutionId.in": Array.isArray(institutionIds)
            ? institutionIds.length > 1
              ? institutionIds?.join(",")
              : institutionIds[0]
            : institutionIds || null,

          "positionId.in": Array.isArray(positionIds)
            ? positionIds.length > 1
              ? positionIds?.join(",")
              : positionIds[0]
            : positionIds|| null,
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getEvaluationGroupId = (registryId) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-evaluation/group/registry/${registryId}`)
      .then(function (response) {
        const data = response.data;
        resolve(data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getEvalGroupIds = (evaluationGroupId) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/registry-evaluation/group/members/${evaluationGroupId}`)
      .then(function (response) {
        const data = response.data;
        resolve(data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getFilteredAssessmentProcedures = (
  {page,
  size,
  referenceYears,
  institutionIds,
  name,
  surname,
  afm,
  evaluationGroupIds,
  positionIds,
  submissionStageIds,
  isSupervisor}
) => {
  return new Promise((resolve, reject) => {
    console.log(evaluationGroupIds, 'evaluationGroupIds')
    HttpService.getAxiosClient()
      .get(`/document-info`, {
        params: {
          page,
          size,
          "name.equals": name,
          "surname.equals": surname,
          "afm.equals": afm,
          "referenceYear.in":
          Array.isArray(referenceYears)
          ? (referenceYears.length > 1
            ? referenceYears?.join(",")
            : referenceYears[0])
          : referenceYears || null,
          "evaluationGroupId.in":Array.isArray(evaluationGroupIds)
          ? (evaluationGroupIds.length > 1
            ? evaluationGroupIds?.join(",")
            : evaluationGroupIds[0])
          : evaluationGroupIds || null,
          "institutionId.in": Array.isArray(institutionIds)
            ? (institutionIds.length > 1
              ? institutionIds?.join(",")
              : institutionIds[0])
            : institutionIds || null,

          "positionId.in": Array.isArray(positionIds)
            ? (positionIds.length > 1
              ? positionIds?.join(",")
              : positionIds[0])
            : positionIds || null,
          "submissionStageId.in":
          Array.isArray(submissionStageIds)
          ? (submissionStageIds.length > 1
            ? submissionStageIds?.join(",")
            : submissionStageIds[0])
          : submissionStageIds || null,
          "isSupervisor.equals" : isSupervisor,
          'excludeDocumentTypeByCode.equals':true

        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export const getFilteredSubordinateEvaluations = (
  page,
  size,
  registryId,
  institutionIds,
  submissionStageIds,
  referenceYears
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/document-info`, {
        params: {
          page,
          size,
          "registryId.equals": registryId,
          "referenceYear.in":
          Array.isArray(referenceYears)
          ? (referenceYears.length > 1
            ? referenceYears?.join(",")
            : referenceYears[0])
          :referenceYears ||  null,
          "institutionId.in": Array.isArray(institutionIds)
            ? institutionIds.length > 1
              ? institutionIds?.join(",")
              : institutionIds[0]
            :institutionIds||  null,
            "submissionStageId.in":
            Array.isArray(submissionStageIds)
            ? (submissionStageIds.length > 1
              ? submissionStageIds?.join(",")
              : submissionStageIds[0])
            :submissionStageIds || null,
        },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
export const getFilteredGroupPulseDocs = (
  {page,
  size,
  documentTypeId,
  referenceYears,
  institutionIds,
  evaluationGroupIds,
  registryId,
  isSupervisor}
) => {
  return new Promise((resolve, reject) => {
    HttpService.getAxiosClient()
      .get(`/document-info`, {
        params: {
          page,
          size,
          "documentTypeId.equals": documentTypeId,
          "referenceYear.in":
          Array.isArray(referenceYears)
          ? (referenceYears.length > 1
            ? referenceYears?.join(",")
            : referenceYears[0])
          :referenceYears || null,
          "institutionId.in": Array.isArray(institutionIds)
            ? institutionIds.length > 1
              ? institutionIds?.join(",")
              : institutionIds[0]
            : institutionIds|| null,

            "evaluationGroupId.in":Array.isArray(evaluationGroupIds)
            ? (evaluationGroupIds.length > 1
              ? evaluationGroupIds?.join(",")
              : evaluationGroupIds[0])
            : evaluationGroupIds || null,  
            "registryId.equals":registryId,
            "isSupervisor.equals" : isSupervisor

          },
      })
      .then(function (response) {
        const allRegistries = {
          data: response.data,
          count: +response.headers["x-total-count"],
        };
        resolve(allRegistries);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};
