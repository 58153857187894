import { getSuggToolsInspLabels } from "../../evaluationsHelperFuncs";
import AddSuggestionToolInspectionWrapper from "./../AddSuggToolInsp/AddSuggestionToolInspectionWrapper";

const AddImprovingTool = () => {
  return (
    <AddSuggestionToolInspectionWrapper
      title={
        "Καταχώριση-Επεξεργασία Εργαλείου για τη βελτιστοποίηση διαδικασιών του καταστήματος κράτησης"
      }
      formTitle="Εργαλείο για τη βελτιστοποίηση διαδικασιών του καταστήματος κράτησης"
      labels={getSuggToolsInspLabels(4501)}
      objectiveType={4501}
    />
  );
};
export default AddImprovingTool;
