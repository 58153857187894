import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getServiceActionHistory } from "../../../services/history.service";
import RegistryHistoryWrapper from "../RegistryHistoryWrapper";
import { serviceActionColumns } from "./ServiceActionColumnsConfig";
import useAuth from "../../../hooks/useBackAuth";
import UserService from "../../../services/userService";

const ServiceActionHistory =() => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(100);
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { userInfo, hasRole} = useAuth()
    const handleClick = (row) => {
        const date = data.find((item) => (item.id === row.id)).actionDate;
        navigate(`/detailed-history/service-actions/${date}/${row.id}`);
      };

      useEffect(() => {
        const fetchAndSetHistoryRegistries = async () => {
          setIsLoading(true);
          const allRegistries = await getServiceActionHistory(
            page,
            pageSize,
            hasRole('SuperAdmin')
              ? null : hasRole("KATAXORITIS")
                ? UserService.profile()?.attributes?.instituteId?.[0]
                : userInfo?.serviceAccount?.institute?.id, 
            );
          console.log(allRegistries);
          const rows = allRegistries.data.flatMap((item) => ({
            id: item.id,
            actionDate: item.actionDate,
            actionType: item.actionType.description,
          }));
    
          setData(rows);
          setCount(allRegistries.count);
          setIsLoading(false);
        };
    
        fetchAndSetHistoryRegistries();
      }, [page, pageSize]);
    return( <RegistryHistoryWrapper
        title={"Υπηρεσιακών Ενεργειών"}
        department={userInfo?.serviceAccount?.institute?.description}
        rows={data}
        columns={serviceActionColumns}
        isLoading={isLoading}
        onClickFunc={handleClick}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        count={count || data.length}
        buttonTitle={'Καταχώριση Υπηρεσιακής Ενέργειας'}
        buttonURL={'/multiple-registries/service-actions'}
      />)
}
export default ServiceActionHistory
