import { Box, InputLabel } from "@mui/material";
import { RadioButtonGroup, useWatch } from "react-hook-form-mui";
import { initialFormSchema } from "./fieldConfiguration";
import FormSelectField from "../../components/FormSelectField";
import { useEffect, useState } from "react";
import { getGradeInstitutionPositionDropdown } from "../../services/dropdownData.service";

const ProfessionalData = (props) => {
  const [gradeOptions, setGradeOptions] = useState();

  const selectedPosition = useWatch({
    name: initialFormSchema.position.name,
  })


  const outsideGuards = [351, 251, 301, 401, 451];
  const insideGradeIds = [3751, 3801, 3701, 3851];

  const insideGuards = [51, 101, 201, 151];
  const outsideGradeIds = [3951, 4001, 4051, 4101, 3901]

  const isOutsideGuardSelected = outsideGuards.some(guardId => guardId === selectedPosition);
  const isInsideGuardSelected = insideGuards.some(guardId => guardId === selectedPosition);

  const [positionOptions, setPositionOptions] = useState();
  const [institutionOptions, setInstitutionOptions] = useState();



  // const guardTypes = [
  //   { id: "internal", label: "Εσωτερική Φρούρηση" },
  //   { id: "external", label: "Εξωτερική Φρούρηση" },
  // ];
  useEffect(() => {
    const getDropdowns = async () => {
      const dropdowns = await getGradeInstitutionPositionDropdown();
      setGradeOptions(
        dropdowns.grade.map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
      setPositionOptions(
        dropdowns.position.map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
      setInstitutionOptions(
        dropdowns.institution.map((item) => ({
          id: item.id,
          label: item.description,
        }))
      );
    };

    getDropdowns();
  }, []);

  return (
    <>
      <FormSelectField
        label={initialFormSchema.institution.label}
        name={initialFormSchema.institution.name}
        options={institutionOptions}
        isPreviewMode={props.isPreviewMode || props.values?.institution?.readOnly}
      />

      <FormSelectField
        label={initialFormSchema.position.label}
        name={initialFormSchema.position.name}
        options={positionOptions}
        isPreviewMode={props.isPreviewMode || props.values?.position?.readOnly}
      />

      <FormSelectField
        label={initialFormSchema.grade.label}
        name={initialFormSchema.grade.name}
        options={gradeOptions?.filter((grade) => { 
          if (isOutsideGuardSelected) {
            return outsideGradeIds.includes(grade.id)
          }
          if (isInsideGuardSelected) {
            return insideGradeIds.includes(grade.id)
          }
          return true;
        })|| []}
        isPreviewMode={props.isPreviewMode || props.values?.grade?.readOnly || !selectedPosition}
      />


      {/* 
      <Box>
        <InputLabel
          sx={{ color: "grey", marginLeft: 0.5, marginTop: 1 }}
          id={props.values.guardType.name}
        >
          {props.values.guardType.label}{" "}
        </InputLabel>

        <RadioButtonGroup
          row
          labelProps={{
            sx: {
              color: props.isPreviewMode ? "grey" : "inherit",
              pointerEvents: props.isPreviewMode ? "none !important" : "auto",
            },
          }}
          key={props.values.guardType.name}
          name={props.values.guardType.name}
          value={props.values.guardType.name}
          options={guardTypes}
        />
      </Box> */}
    </>
  );
};
export default ProfessionalData;
