import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";
import {
  defaultRenderArray,
  defaultRenderDates,
  defaultRenderObject,
} from "./utils";

function Details(props) {
  /** In case we want to render arrays as columns  */
  const calculateMaxNumberOfColumns = () => {
    const rows = props?.rows;
    if (!rows || !Array.isArray(rows)) return 0;
    const maxNumberOfColumns = rows.reduce((max, row) => {
      return Math.max(max, row?.value?.length);
    }, 0);
    return maxNumberOfColumns;
  };

  const {
    rows = [],
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style, // this is unused
    renderArraysAsColumns = false,
    renderAsStripped = false,
    renderFirstRowsAsHeader = false,
    removeLines = false,
    renderDates = defaultRenderDates,
    renderArrays = defaultRenderArray,
    renderObject = defaultRenderObject,
  } = props;

  const maxNumberOfColumns = useMemo(
    () => calculateMaxNumberOfColumns(),
    [renderArraysAsColumns, rows.length]
  );

  const renderValue = (value) =>
    value instanceof Date
      ? renderDates(value)
      : Array.isArray(value)
      ? renderArrays(value)
      : typeof value === "object" && value !== null && "url" in value
      ? value.label
      : typeof value === "object" && value !== null
      ? renderObject(value)
      : value;

  const renderCell = (
    value,
    renderFirstRowsAsHeader,
    index,
    renderBold
    // eslint-disable-next-line max-params
  ) => {
    return (
      <TableCell
        sx={{
          bgcolor:
            renderFirstRowsAsHeader && index === 0 ? "lightgrey" : "white",
        }}
        variant={index === 0 ? "head" : "body"}
        scope="row"
        align="left"
        key={value}
      >
        {typeof value === "object" && value !== null && "url" in value ? (
          "externalLink" in value ? (
            <a href={renderValue(value)} target="_blank" rel="noreferrer">
              {renderValue(value)}
            </a>
          ) : (
            <Link
              fontWeight={
                renderFirstRowsAsHeader && index === 0 ? "650" : "500"
              }
            >
              {renderValue(value)}
            </Link>
          )
        ) : (
          <Typography
            fontWeight={
              renderFirstRowsAsHeader
                ? index === 0
                  ? "650"
                  : "500"
                : renderBold
                ? "bold"
                : "regular"
            }
          >
            {renderValue(value)}
          </Typography>
        )}
      </TableCell>
    );
  };

  const renderEmptyCells = (numberOfCells) =>
    [...Array.from(Array(numberOfCells).keys())].map((x) => (
      <TableCell align="left" key={x}>
        <Typography></Typography>
      </TableCell>
    ));

  /** Render cells adding as many cells as the max number of columns */
  const renderValuesAsColumns = (row, indexRow) =>
    row.value.map((value, index) => {
      return index < row.value.length
        ? renderCell(value, renderFirstRowsAsHeader, indexRow)
        : renderEmptyCells(maxNumberOfColumns - row.value.length);
    });

  const renderRow = (row, index) => {
    // const isInMobileView = window.innerWidth < 600;

    return !renderArraysAsColumns ? (
      <>
        <TableRow
          key={JSON.stringify(row.name)}
          sx={{
            width: "10000px",
            display: "table-row",
          }}
        >
          <Box
            key={index}
            sx={{ marginLeft: 1, marginRight: 1, whiteSpace: "pre-line" }}
          >
            {renderCell(row.name, renderFirstRowsAsHeader, index, true)}
          </Box>
        </TableRow>
        <TableRow
          key={JSON.stringify(row.name)}
          sx={{
            width: "10000px",
            display: "table-row",
          }}
        >
          {renderArraysAsColumns && Array.isArray(row.value) ? (
            renderValuesAsColumns(row, index)
          ) : (
            <Box sx={{ marginLeft: 1, marginRight: 1, whiteSpace: "pre-line" }}>
              {renderCell(row.value, renderFirstRowsAsHeader, index)}{" "}
            </Box>
          )}
        </TableRow>
      </>
    ) : (
      <TableRow
        key={JSON.stringify(row.name)}
        sx={{
          backgroundColor: renderAsStripped
            ? index % 2 === 0
              ? "#eeeeee"
              : ""
            : "",
        }}
      >
        {renderCell(row.name, renderFirstRowsAsHeader, index, true)}
        {renderArraysAsColumns && Array.isArray(row.value)
          ? renderValuesAsColumns(row, index)
          : renderCell(row.value, renderFirstRowsAsHeader, index)}
      </TableRow>
    );
  };

  const renderRows = (rows) =>
    rows.map((row, index) => {
      return row.isHiddenRow ? null : renderRow(row, index);
    });

  return (
    <div>
      <Box
        display="flex"
        gap="10px"
        // flexWrap='wrap'
        flexDirection="column"
      >
        <Typography variant="h4" style={{ marginLeft: "10px" }}>
          {title}
        </Typography>
        <TableContainer id="table-details">
          <Table
            size="small"
            aria-label="simple table"
            sx={{
              tableLayout: "fixed",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: removeLines && "none",
              },
            }}
          >
            <TableBody>{renderRows(rows)}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default Details;
