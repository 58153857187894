
export const columns = [
    {
      id: "fullName",
      label: "Ονοματεπώνυμο",
      minWidth: 170,
      isClickable: true,
    },
    {
      id: "registry.afm",
      label: "ΑΦΜ",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "position.description",
      label: "Θέση στο Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "evaluationGroup.institution.description",
      label: "Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
  ];

  export const adminColumns = [
    {
      id: "fullName",
      label: "Ονοματεπώνυμο",
      minWidth: 170,
      isClickable: true,
    },
    {
      id: "afm",
      label: "ΑΦΜ",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceAccount.position.description",
      label: "Θέση στο Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
    {
      id: "serviceAccount.institute.description",
      label: "Κατάστημα Κράτησης",
      minWidth: 170,
      isClickable: false,
    },
  ];