import { TextField } from "@mui/material";
import {
  RadioButtonGroup,
  TextFieldElement,
  TextareaAutosizeElement,
} from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../../components/Details/utils";
import HorizontalVerticalTogglerLayout from "../../../../../components/Layout/HorizontalVerticalTogglerLayout";

export const Stadio3 = ({ developTypes, isManager }) => (
  <HorizontalVerticalTogglerLayout direction="column">
    <TextFieldElement
      fullWidth
      label="
        Περιγραφή Δεξιότητας"
      name="stage3.targetName"
    />
    {isManager && (
      <RadioButtonGroup row name={"isDeveloped"} options={developTypes} />
    )}
    <TextFieldElement
      fullWidth
      label="Τιμές Αξιολόγησης των Αποτελεσμάτων"
      name="stage3.measurementIndicator"
    />

    <TextFieldElement
      fullWidth
      label="Συμπεράσματα"
      name="stage3.description"
    />

    <TextareaAutosizeElement
      fullWidth
      label="Παρατηρήσεις"
      name="stage3.notes"
    />
    <TextField
      disabled={true}
      label="Ημερομηνία"
      value={defaultRenderDates(new Date())}
    />
  </HorizontalVerticalTogglerLayout>
);
