import { Typography } from "@mui/material";
import { getSuggToolsInspLabels } from "../../evaluationsHelperFuncs";

export const defaultSuggestionsToolsInspectionsValues = {
  description: "",
  proposalDate: "",
  isImplemented: "",
  notes: "",
};

export const formSuggestionsToolsInspectionsSchema = {
  description: {
    name: "description",
  },
  proposalDate: {
    name: "proposalDate",
  },
  isImplemented: {
    name: "isImplemented",
  },
  notes: {
    name: "notes",
  },
};

export const suggToolsInsColumns = (objectiveType)=>{
  const labels = getSuggToolsInspLabels(objectiveType)
  return[
  {
    field: "description",
    headerName: labels[0].replace('*:',''),
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "proposalDate",
    headerName: labels[1].replace('*:',''),
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "isImplemented",
    headerName:labels[2],
    flex: 1,
    headerAlign: "center",
    align: "center",

    renderCell: (params) => (
      <Typography>{params.row.isImplemented ? "Ναι" : "Όχι"}</Typography>
    ),
  },
  {
    field: "notes",
    headerName: labels[3].replace(':',''),
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];}
