import { TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../components/Details/utils";
import { TextField } from "@mui/material";

function Case3() {
  return (
    <>
      <TextFieldElement
        fullWidth
        label="Ονομασία Νέου Στόχου προς Επίτευξη*"
        name="stage2.targetName"
      />
      <TextFieldElement
        fullWidth
        label="Νέοι Δείκτες Μέτρησης (και Τιμές Δεικτών)"
        name="stage2.measurementIndicator"
      />

      <TextFieldElement
        fullWidth
        label="Περιγραφή Επίτευξης Νέου Στόχου και Αναμενόμενου Οφέλους για το Κατάστημα Κράτησης"
        name="stage2.description"
      />
      <TextFieldElement
        label="Αιτολόγηση Αναθεώρησης Επιλογής Στόχου"
        name="stage2.eligibility"
      />
      <TextField
        label="Ημερομηνία"
        disabled={true}
        value={defaultRenderDates(new Date())}
      />
    </>
  );
}

export default Case3;
