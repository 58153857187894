// Get Requests that only fetch data

import { useQuery } from "@tanstack/react-query";
import { getEvaluationGroup } from "./gateway";

export function useGetEvaluationGroup(id) {
    return useQuery({
      queryKey: ['evaluationGroups', id],
      queryFn: () => getEvaluationGroup(id),
      staleTime: 5000,
    });
  }