import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePickerElement } from "react-hook-form-mui";
import el from "date-fns/locale/el";

export default function FormTimePicker(props) {
  return (
    <LocalizationProvider adapterLocale={el} dateAdapter={AdapterDateFns}>
      <DemoContainer  sx={{ padding: 0 }} components={["TimePicker"]}>
        <TimePickerElement ampm={false}
          sx={{
            "&.MuiTextField-root": {
              minWidth: "unset", // or use 'initial'
            },
          }}
          name={props.name}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
