import HttpService from "../httpClient";
export const getServicePositionRegistries = (
    page,
    size,
    registryId
  ) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .get(`/registry-position`, {
          params: {
            page,
            size,
            "registryId.equals":registryId,
          },
        })
        .then(function (response) {
          const allRegistries = {
            data: response.data,
            count: +response.headers["x-total-count"],
          };
          resolve(allRegistries);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
export const addNewServicePositionRegistry = (registry) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .post(`/registry-position`, { ...registry })
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  export const deleteServicePositionRegistryById = (positionId,registryId) => {
    return new Promise((resolve, reject) => {
      HttpService.getAxiosClient()
        .delete(`/registry-position/${positionId}/registry/${registryId}`)
        .then(function (response) {
          const registry = response.data;
          console.log(registry);
          resolve(registry);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };