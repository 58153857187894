export const getSuggToolsInspPath = (objectiveType, id) => {
  switch (objectiveType) {
    case 4401:
      return `/evaluation-card/${id}/suggestions-tools-inspections/add-functional-suggestion`;
    case 4451:
      return `/evaluation-card/${id}/suggestions-tools-inspections/add-improving-suggestion`;
    case 4501:
      return `/evaluation-card/${id}/suggestions-tools-inspections/add-improving-tool`;
    case 4551:
      return `/evaluation-card/${id}/suggestions-tools-inspections/add-inspection`;
    default:
      console.log("default");
  }
};

export const getDocumentFormPath = (documentType, id, registryId) => {
  console.log(documentType, id, "doctype");
  switch (documentType) {
    case "evaluation:1":
      return `/evaluation/competencies/type1/${id}`;
    case "evaluation:2":
      return `/evaluation/competencies/type2/${id}`;
    case "evaluation:3":
      return `/evaluation/competencies/type3/${id}`;
    case "evaluation:4":
      return `/evaluation/competencies/type4/${id}`;
    case "evaluation:5":
      return `/evaluation/competencies/type5/${id}`;
    case "evaluation:6":
      return `/evaluation/competencies/type6/${id}`;

    case "target_achievement_form:1":
      return `/evaluation/targetAchievement/type1/${id}`;
    case "target_achievement_form:2":
      return `/evaluation/targetAchievement/type2/${id}`;

    case "skills_form:1":
      return `/evaluation/skillsForm/type1/${id}`;
    case "skills_form:2":
      return `/evaluation/skillsForm/type2/${id}`;

    case "model_targeting_form:1":
      return `/evaluation/modelTargetUpodeigmatos/type1/${id}`;
    case "model_targeting_form:2":
      return `/evaluation/modelTargetUpodeigmatos/type2/${id}`;
    case "model_targeting_form:3":
      return `/evaluation/modelTargeting/type1/${id}`;
    case "model_targeting_form:4":
      return `/evaluation/modelTargeting/type2/${id}`;

    case "growth_plan:1":
      return `/evaluation/growth_plan/${registryId}/${id}`;
    case "growth_plan:2":
      return `/evaluation/growth_plan/${registryId}/${id}`;
    case "growth_plan:3":
      return `/evaluation/growth_plan/${registryId}/${id}`;

    case "group_pulse_form":
      return `/evaluation/sfigmos/type1/${id}`;
    case "single_pulse_form":
      return `/evaluation/whole-pulse/${id}`;
    case "assessment_report:1":
      return `/evaluation/evaluationReport/type1/${id}`;
    case "assessment_report:2":
      return `/evaluation/evaluationReport/type2/${id}`;
    case "assessment_report:3":
      return `/evaluation/evaluationReport/type3/${id}`;

    default:
      console.log("default");
  }
};

export const getSuggToolsInspLabels = (objectiveType) => {
  switch (objectiveType) {
    case 4401:
      return [
        "Περιγραφή Πρότασης*:",
        "Ημερομηνία προσθήκης Πρότασης*:",
        "Υλοποίηθηκε ή Διακρίθηκε",
        "Παρατηρήσεις για την εξέλιξη της πρότασης:",
      ];
    case 4451:
      return [
        "Περιγραφή Πρότασης*:",
        "Ημερομηνία προσθήκης Πρότασης*:",
        "Υλοποίηθηκε ή Διακρίθηκε",
        "Παρατηρήσεις για την εξέλιξη της πρότασης:",
      ];
    case 4501:
      return [
        "Περιγραφή Εργαλείου*:",
        "Ημερομηνία προσθήκης πρότασης Εργαλείου*:",
        "Αναπτύχθηκε ή Χρησιμοποιήθηκε",
        "Παρατηρήσεις για την ανάπτυξη και χρήση του εργαλείου:",
      ];
    case 4551:
      return [
        "Περιγραφή Επιθεώρησης*:",
        "Ημερομηνία Επιθεώρησης*:",
        "Χωρίς σημαντικές παρατηρήσεις",
        "Παρατηρήσεις για την επιθεώρηση:",
      ];
    default:
      console.log("default");
  }
};
