import { TextField } from "@mui/material";
import { RadioButtonGroup, TextFieldElement } from "react-hook-form-mui";
import { defaultRenderDates } from "../../../../../../components/Details/utils";
import HorizontalVerticalTogglerLayout from "../../../../../../components/Layout/HorizontalVerticalTogglerLayout";

const Stage2Case2 = ({ developTypes, isManager }) => {
  return (
    <>
      <HorizontalVerticalTogglerLayout direction="column">
        <TextFieldElement
          fullWidth
          label="Περιγραφή Δεξιότητας*"
          name="stage2.targetName"
        />
        {isManager && (
          <RadioButtonGroup row name={"isDeveloped"} options={developTypes} />
        )}
        <TextFieldElement
          fullWidth
          label="Τιμές Δείκτών Μέτρησης Προόδου"
          name="stage2.measurementIndicator"
        />
        <TextFieldElement
          fullWidth
          label="Περιγραφή της Προόδου"
          name="stage2.description"
        />

        <TextFieldElement label="Παρατηρήσεις" name="stage2.eligibility" />
        <TextFieldElement
          label="Περιγραφή των Αναθεωρημένων (Νέων) Τιμών των Δεικτών"
          name="stage2.notes"
        />
        <TextField
          disabled={true}
          label="Ημερομηνία"
          value={defaultRenderDates(new Date())}
        />
      </HorizontalVerticalTogglerLayout>
    </>
  );
};

export default Stage2Case2;
